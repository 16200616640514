import React from "react";
import { images } from "../../constants";
import { useNavigate } from "react-router-dom";

const ThanksModalContent = (props) => {
  const navigate = useNavigate();
  const { message } = props;
  return (
    <>
      <img src={images.AlertSuccessIcon} alt="icon" />
      <h3 className="mt-2 mb-2 text-2xl font-bold text-white">
        {message?.subTitle}
      </h3>
      <p className="mb-10 text-xs text-grey-100">{message?.description}</p>

      <button onClick={() => navigate(message.path)} className="btn-primary">
        {message.btnTitle}
      </button>
    </>
  );
};

export default ThanksModalContent;
