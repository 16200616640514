import React from "react";
import { useSelector } from "react-redux";
import { images } from "../../../constants";
import UserAvatar from "./UserAvatar";
import WalletAddress from "./WalletAddress";

const DashboardHeader = (props) => {
  const { title } = props;
  const clientInfo = useSelector((state) => state?.user?.clientDetail);

  return (
    <>
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-2xl font-bold text-white">{title}</h1>
        <div className="flex items-center justify-between">
          {/* <WalletAddress
            walletAddress={clientInfo?.clientWalletAddress ?? "-"}
          /> */}
          <UserAvatar
            firstname={clientInfo?.contactFirstName ?? "-"}
            lastname={clientInfo?.contactLastName ?? "-"}
            pic={images.avatar}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;
