import React, { useState, useMemo, useEffect, useRef } from "react";
import Modal from "../common/ModalLayout";
import BasicTable from "../components/BasicTable";
import AddClientModalContent from "../components/Modals/AddClientModalContent";
import SearchFilter from "../components/SearchFilter";
import more_icon from "./../assets/images/icons/ellipsis_icon.svg";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { COLUMNS } from "../components/Table/columns";
import clientService from "./../services/client.service";
import { images } from "../constants/images";
import Loader from "../helpers/Loader";
import CustomPopover from "../components/ui-components/popover-component/CustomPopover";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../redux/features/loading/loaderSlice";
import { Menu, MenuList, MenuHandler } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import AddPriceConfigureModal from "../components/Modals/AddPriceConfigureModal";
import clientPackagesService from "../services/clientpackages.service";
import { clientPackages } from "../redux/features/user/userSlice";
import useClientPackages from "../helpers/custom-hooks/useClientPackages";
import AdminWalletAddress from "./AdminPortal/AdminHeaderComponent/AdminWalletAddress";
import WalletBalanceHook from "../helpers/custom-hooks/FetchWalletBalance";
import KeyValueView from "./../components/ui-components/views-component/KeyValueView";

const Client = () => {
  const [showModal, setShowModal] = useState(false);
  const [ClientData, setClientData] = useState([]);
  const [ClientPackagesData, setClientPackagesData] = useState([]);
  const { fetchClientAllPackages } = useClientPackages();
  const [statsStatus, setstatsStatus] = useState({
    pending: "",
    deactive: "",
  });

  const user = useSelector((state) => state?.user);

  const [activeTab, setActiveTab] = useState("total");
  const [alertMessage, setAlertMessage] = useState({
    title: "",
    description: "",
  });
  const dispatch = useDispatch();
  const [openPriceConfigureModal, setOpenPriceConfigureModal] = useState(false);
  const selectedClientDataRef = useRef("");
  const [WalletBalance, setWalletBalance] = useState("");
  const [WalletBalancePlatformFee, setWalletBalancePlatformFee] = useState("");
  const [WalletBalanceAdmin, setWalletBalanceAdmin] = useState("");
  const [dataNotFoundMessage, setDataNotFoundMessage] = useState(
    "You do not have any client created at the moment. Click on add client to start."
  );
  const { getWalletBalance } = WalletBalanceHook();

  useEffect(() => {
    if (!openPriceConfigureModal) getAllClientData();

    getAllClientPackages();
    getWalletsBalance();
    // eslint-disable-next-line
  }, [openPriceConfigureModal]);

  const getAllClientData = () => {
    dispatch(setLoader(true));
    clientService
      .getAllClients()
      .then((response) => {

        if (response?.data?.statusCode === 200) {
          setClientData(response.data.payload);
          const filterPending = response?.data?.payload?.filter(
            (data) => data.status === "Pending"
          );
          const filterDeactive = response?.data?.payload?.filter(
            (data) => data.status === "Deactive"
          );
          setstatsStatus({
            pending: filterPending.length,
            deactive: filterDeactive.length,
          });
          dispatch(setLoader(false));
        } else {
          dispatch(setLoader(false));
        }

      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };

  // get client packages available
  const getAllClientPackages = async () => {
    const packagesReslt = await fetchClientAllPackages();
    if (packagesReslt) {
      setClientPackagesData(packagesReslt);
      dispatch(clientPackages(packagesReslt));
    } else dispatch(setLoader(false));
  };

  const getWalletsBalance = async () => {
    getContractWalletBalance();
    getPlatformFeeWalletBalance();
    getSuperAdminWalletBalance();
  };
  const getContractWalletBalance = async () => {
    const balance = await getWalletBalance(process.env.REACT_APP_DROP_ADDRESS);
    if (balance) {
      setWalletBalance(balance);
    }
  };
  const getPlatformFeeWalletBalance = async () => {
    const platformFeeWalletBalance = await getWalletBalance(
      process.env.REACT_APP_PLATFORM_FEE_WALLET
    );
    if (platformFeeWalletBalance) {
      setWalletBalancePlatformFee(platformFeeWalletBalance);
    }
  };
  const getSuperAdminWalletBalance = async () => {
    const adminWalletBalance = await getWalletBalance(
      process.env.REACT_APP_SUPERADMIN_WALLET
    );
    if (adminWalletBalance) {
      setWalletBalanceAdmin(adminWalletBalance);
    }
  };

  const handleTabClick = (option) => {
    setActiveTab(option);
    setGlobalFilter("");
    if (option === "total") {
      setDataNotFoundMessage(
        "You do not have any client created at the moment. Click on add client to start."
      );
      return;
    }
    setGlobalFilter(option);
    setDataNotFoundMessage(
      "Sorry we couldn’t find any matches for that. Please try searching with another term"
    );
  };

  const handleCloseModal = () => {
    if (alertMessage?.title === "Success") {
      getAllClientData();
    }

    setShowModal(false);
    setAlertMessage({ title: "", description: "" });
  };

  const handlePriceConfiguration = (clientUuId, plateFormPrice) => {
    setOpenPriceConfigureModal(true);
    selectedClientDataRef.current = {
      clientUuId: clientUuId,
      plateFormFee: plateFormPrice,
    };
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            {" "}
            Actions
          </div>
        ),
        accessor: "Action",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="">
            <div className="flex justify-center">
              {(row.values.status === "InQueue" ||
                row.values.status === "Deactive") && (
                  <>
                    <Link
                      to={`/client/${row.values.clientUuid}`}
                      state={{ status: `${row.values.status}` }}
                      className="inline-block  font-normal text-base text-white   font-manrope  active:text-white  hover:text-[#FFFFFF] outline-0"
                    >
                      <img src={images.arweaveViewIcon} alt="icon" />
                    </Link>
                  </>
                )}
              {row.values.status === "Active" && (
                <>
                  <Menu placement="bottom-end">
                    <MenuHandler>
                      <img
                        className="self-center mr-1 text-center cursor-pointer hover:opacity-90"
                        src={more_icon}
                        alt="icon"
                      />
                    </MenuHandler>
                    <MenuList className="bg-[#0A130D] flex flex-col min-w-[115px] py-4 px-0  z-20 border rounded-lg border-[#A4B1A7]/[0.12]">
                      <Link
                        to={`/client/${row.values.clientUuid}`}
                        state={{ status: `${row.values.status}` }}
                        className="inline-block  font-normal  px-4 py-1 text-base text-white  focus-visible:bg-[#12221A]/80 font-manrope active:bg-[#12221A]/80 active:text-white hover:bg-[#12221A]/80 hover:text-[#FFFFFF] outline-0"
                      >
                        View
                      </Link>
                      <Link
                        onClick={() =>
                          handlePriceConfiguration(
                            row.values.clientUuid,
                            row.original.customePlatfromFee
                          )
                        }
                        className="inline-block  font-normal  px-4 py-1 mt-6 text-base text-white  focus-visible:bg-[#12221A]/80 font-manrope active:bg-[#12221A]/80 active:text-white hover:bg-[#12221A]/80 hover:text-[#FFFFFF] outline-0"
                      >
                        Set Pricing
                      </Link>
                    </MenuList>
                  </Menu>
                </>
              )}
              {row.values.status === "Pending" && (
                <>
                  <Link
                    to={`/client/${row.values.clientUuid}`}
                    state={{ status: `${row.values.status}` }}
                    className="inline-block  font-normal text-base text-white   font-manrope  active:text-white  hover:text-[#FFFFFF] outline-0"
                  >
                    <img src={images.arweaveViewIcon} alt="icon" />
                  </Link>
                </>
              )}
              {row.values.status === "Declined" && (
                <img
                  className="mr-1 text-center"
                  src={images.DeclinedClientIcon}
                  alt="approved_icon"
                />
              )}
            </div>
          </div>
        ),
      },
    ]);
  };

  // React table suggests to memoize the columns and data for avoiding the re creation of data on every render
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => ClientData, [ClientData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    gotoPage,
    pageCount,
    state, // For filtering
    setGlobalFilter, // For filtering
  } = useTable(
    {
      // ES6 shorthand notation having same key value name
      columns,
      data,
      initialState: {
        hiddenColumns: ["clientUuid"],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination,
    tableHooks
  );

  const { globalFilter, pageIndex } = state;

  return (
    <>
      <main
        className={`
				${showModal && "blur-sm"} 
				 `}
      >
        {/* Heading and button */}
        <div className="flex items-center justify-between mb-10">
          <h1 className="text-2xl font-bold grow">Client Management</h1>
          {/* <AdminWalletAddress walletAddressPlatformFee={'0x38cda83032d46c30'} WalletBalancePlatformFee={WalletBalancePlatformFee} walletAddress={user?.adminConnectWalletAddress} WalletBalance={WalletBalance} /> */}

          <button
            data-testid="add-client-btn1"
            type="button"
            data-modal-toggle="Add_Modal"
            onClick={() => setShowModal(true)}
            className="min-w-[202px] text-[#12221A] font-bold   px-8 py-3 bg-[#A5F33C] rounded-full hover:btn-primary-after"
          >
            Add Client
          </button>
        </div>
        {/* <h1 className="mb-4 text-base font-bold grow">Admin Platform Fee Receiver Wallet </h1> */}
        <div className="background-border-rad p-[1px] rounded-full mb-10  ">
          <div className="flex  bg-[#060C07] justify-between p-6 rounded-full  ">
            <div className="mr-2">
              <AdminWalletAddress
                title={"Platform Fee Address"}
                titleValue={process.env.REACT_APP_PLATFORM_FEE_WALLET}
                success={true}
              />
            </div>
            <div className="">
              <KeyValueView
                title={"Wallet Balance"}
                value={
                  WalletBalancePlatformFee
                    ? `${parseFloat(WalletBalancePlatformFee)?.toFixed(2)} Flow`
                    : "0"
                }
                success={true}
                minWidth="min-w-full"
              />
            </div>
            <div className="mr-2">
              <AdminWalletAddress
                title={"Admin Wallet"}
                titleValue={process.env.REACT_APP_SUPERADMIN_WALLET}
                success={true}
              />
            </div>
            <div className="mr-2">
              <KeyValueView
                title={"Wallet Balance"}
                value={
                  WalletBalanceAdmin
                    ? `${parseFloat(WalletBalanceAdmin)?.toFixed(2)} Flow`
                    : "0"
                }
                success={true}
                minWidth="min-w-full"
              />
            </div>

            <div className="">
              <AdminWalletAddress
                title={"Contract Address"}
                titleValue={process.env.REACT_APP_DROP_ADDRESS}
                success={true}
                minWidth="min-w-full"
              />
            </div>
            <div className="">
              <KeyValueView
                title={"Contract Wallet Balance"}
                value={
                  WalletBalance
                    ? `${parseFloat(WalletBalance)?.toFixed(2)} Flow`
                    : "0"
                }
                success={true}
                minWidth="min-w-full"
              />
            </div>
          </div>
        </div>

        <>
          {/* Tabs and Search */}
          <div className="flex items-center justify-between mb-8">
            <div
              onClick={() => handleTabClick("total")}
              className={`mr-3 flex flex-grow items-center font-bold hover:border-[#A5F33C]   border hover:border-b-0  justify-center py-3 px-6 rounded-t-[4px] ${activeTab === "total"
                ? "bg-[#A5F33C]/10 border-[#A5F33C] hover:bg-[#A5F33C]/10 border-b-0"
                : "border-[#A4B1A7]/25 hover:bg-[#A5F33C] hover:text-[#12221A]"
                } cursor-pointer`}
            >
              <span className="mr-1 font-bold">{ClientData?.length}</span>

              <span className="">Total Clients</span>
            </div>

            <div
              onClick={() => handleTabClick("Pending")}
              className={`mr-3 flex flex-grow items-center justify-center hover:border-[#A5F33C]   hover:border-b-0 border   py-3 px-6 rounded-t-[4px] ${activeTab === "Pending"
                ? "bg-[#A5F33C]/10 hover:bg-[#A5F33C]/10 border-[#A5F33C] border-b-0 "
                : "border-[#A4B1A7]/25 hover:bg-[#A5F33C] hover:text-[#12221A]"
                } cursor-pointer`}
            >
              <span className="mr-1 font-bold">{statsStatus?.pending}</span>
              <span>Pending Requests</span>
            </div>

            <div
              onClick={() => handleTabClick("Deactive")}
              className={`mr-3 flex flex-grow items-center  hover:border-[#A5F33C] border    hover:border-b-0 justify-center py-3 px-6 rounded-t-[4px] ${activeTab === "Deactive"
                ? "bg-[#A5F33C]/10 border-[#A5F33C] hover:bg-[#A5F33C]/10  border-b-0 "
                : "border-[#A4B1A7]/25 hover:bg-[#A5F33C] hover:text-[#12221A]"
                } cursor-pointer`}
            >
              <span className="mr-1 font-bold">{statsStatus?.deactive}</span>
              <span>Deactive Clients</span>
            </div>

            {/* Vertical line */}
            {/* <div className="flex justify-center mx-auto">
              <span className="w-[1px] bg-gray-500 h-10"></span>
            </div> */}

            {/* Search */}
            <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>

          {/* Table Here */}
          <BasicTable
            columns={columns}
            data={data}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            nextPage={nextPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            prepareRow={prepareRow}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            pageCount={pageCount}
            showHover={true}
            rows={rows}
            defaultMessage={dataNotFoundMessage}
          />
        </>
      </main>
      <Modal
        modalVisible={showModal}
        closeModal={setShowModal}
        children={
          <AddClientModalContent
            alertMessage={alertMessage}
            setAlertMessage={setAlertMessage}
            setShowModal={setShowModal}
            handleCloseModal={handleCloseModal}
            ClientPackagesData={ClientPackagesData}
          />
        }
        modal_Id="Add_Modal"
      />
      {openPriceConfigureModal && (
        <Modal
          modalVisible={openPriceConfigureModal}
          closeModal={setOpenPriceConfigureModal}
          children={
            <AddPriceConfigureModal
              alertMessage={alertMessage}
              setAlertMessage={setAlertMessage}
              setOpenPriceConfigureModal={setOpenPriceConfigureModal}
              clientData={selectedClientDataRef.current}
            />
          }
        />
      )}
    </>
  );
};

export default Client;
