import React from 'react'

const RightArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className='inline'>
      <path d="M1 6L11 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round" />
      <path d="M6 0.999999L11 6L6 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round" />
    </svg>)
}

export default RightArrow