import * as yup from "yup";
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
  "text/plain"
];

export const addAssetSchema = yup.object().shape({
  assetType: yup.object().shape({
    label: yup.string().required("Asset is required "),
    value: yup.string().required("Asset is required"),
  }),
  assetName: yup
    .string()
    .required("Asset name is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),
  // assetRarity: yup.object()
  //   .shape({
  //     label: yup.string().required("Asset Rarity is required "),
  //     value: yup.string().required("Asset Rarity is required")
  //   }),
  //    assetCategory: yup.object()
  //    .shape({
  //      label: yup.string().required("Asset Rarity is required "),
  //      value: yup.string().required("Asset Rarity is required")
  //    }),
  assetDescription: yup
    .string()
    .required("Asset description is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(500, "Max  length 500 characters"),

  addImmutableDocument: yup.boolean(),
  immutableDocumentName: yup.string().when("addImmutableDocument", {
    is: true,
    then: yup
      .string()
      .required("File name is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    otherwise: yup.string(),
  }),

  immutableDocumentFile: yup.mixed().when("addImmutableDocument", {
    is: true,
    then: yup
      .mixed()
      .test("required", "File is required", (file) => {
        if (file && file[0]) return true;
        return false;
      })
      .test("fileSize", "File size is too large", (value) => {
        if (!value[0]) return true;
        return value[0]?.size <= 5242880;
      })
      .test(
        "fileFormat",
        "Unsupported Format",
        value => value[0] && SUPPORTED_FORMATS.includes(value[0].type)),

    otherwise: yup.mixed(),
  }),
});
