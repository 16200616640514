import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { serverAuthorization } from "./SignatureTx";

let transactionStatus = {
  error: false,
  message: "",
};
const transactionError = (ex) => {
  transactionStatus.error = true;
  transactionStatus.message = ex ?? "Script Execution failed";
  return transactionStatus;
};
/// This method will execute all the scripts on block chain. Scripts are used to fetch data from blockchain

const ExecuteScripts = async (response) => {
  try {
    const resp = await fcl.decode(response);
    transactionStatus.error = false;
    transactionStatus.message = resp;
    return transactionStatus;
  } catch (ex) {
    return transactionError(ex);
  }
};
const ExecuteGetBrandsScripts = async (scriptName, brandId) => {
  try {
    const response = await fcl.send([
      fcl.script(scriptName),
      fcl.args([
        fcl.arg(brandId, t.UInt64), // brand Id
      ]),
    ]);
    const result = await ExecuteScripts(response);
    return result;
  } catch (ex) {
    return transactionError(ex);
  }
};
const ExecuteBrandsCapabilityScript = async (scriptName, clientAddress) => {
  try {
    const response = await fcl.send([
      fcl.script(scriptName),
      fcl.args([fcl.arg(clientAddress, t.Address)]),
    ]);
    const result = await ExecuteScripts(response);
    return result;
  } catch (ex) {
    return transactionError(ex);
  }
};

const execTransactionMethod = async (transactionId) => {
  try {
    const transaction = await fcl.tx(transactionId).onceSealed();
    if (
      (transaction?.status === 4 || transaction?.status === 3) &&
      transaction?.errorMessage
    ) {
      transactionStatus.error = true;
      transactionStatus.message = transaction?.errorMessage;
      return transactionStatus;
    }
    if (
      (transaction?.status === 4 || transaction?.status === 3) &&
      transaction?.events[0]?.data
    ) {
      transactionStatus.error = false;
      let resp = { txId: transactionId, data: transaction?.events[0]?.data };
      transactionStatus.message = resp;
      return transactionStatus;
    }
  } catch (error) {
    return transactionError(error);
  }
};

const CreateAssetExecuteTransaction = async (
  script,
  immutableData,
  mutableData,
  brandId,
  maxSupply,
  transferable,
  royaltiesAddress,
  royaltiesDescription,
  royaltiesCut,
  addRoyalty
) => {
  try {
 
    const tx = await fcl.send([
      fcl.transaction(script),
      fcl.args([
        fcl.arg(brandId, t.UInt64),
        fcl.arg(maxSupply, t.Optional(t.UInt64)),
        fcl.arg(transferable, t.Bool),
        fcl.arg(
          immutableData,
          t.Dictionary({ key: t.String, value: t.String })
        ),
        fcl.arg(
          mutableData,
          t.Optional(t.Dictionary({ key: t.String, value: t.String }))
        ),

        fcl.arg(royaltiesAddress, t.Array(t.Address)),
        fcl.arg(royaltiesCut, t.Array(t.UFix64)),
        fcl.arg(royaltiesDescription, t.Array(t.String)),

        //incase of dynamic contributers , please uncomment below line no 88 and add dynamic data also comment out line no 89
        // fcl.arg([{key:"",value:""}],t.Optional(t.Dictionary({ key: t.Address, value: t.UFix64 }))),
        fcl.arg(
          null,
          t.Optional(t.Dictionary({ key: t.Address, value: t.UFix64 }))
        ),
        fcl.arg(addRoyalty, t.Bool),
      ]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.payer(fcl.currentUser().authorization),
      //fcl.ref(block.id),
      fcl.limit(9999),
      fcl.authorizations([fcl.currentUser().authorization]),
    ]);
    const { transactionId } = tx;

    const result = await execTransactionMethod(transactionId);
    return result;
  } catch (error) {
    return transactionError(error);
  }
};
const UpdateAssetTransaction = async (script, mutableData, templateId) => {
  try {
    const tx = await fcl.send([
      fcl.transaction(script),
      fcl.args([
        fcl.arg(templateId, t.UInt64),
        fcl.arg(mutableData, t.Dictionary({ key: t.String, value: t.String })),
      ]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.payer(fcl.currentUser().authorization),
      fcl.limit(9999),
      fcl.authorizations([fcl.currentUser().authorization]),
    ]);
    const { transactionId } = tx;
    const result = await execTransactionMethod(transactionId);
    return result;
  } catch (error) {
    return transactionError(error);
  }
};
const BrandMultiSignTransaction = async (script) => {
  const result = await ExecuteTransactionsFunction(script, true);
  return result;
};
const DeleteAssetTransaction = async (script, templateId) => {
  try {
    const tx = await fcl.send([
      fcl.transaction(script),
      fcl.args([fcl.arg(templateId, t.UInt64)]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.payer(fcl.currentUser().authorization),
      //fcl.ref(block.id),
      fcl.limit(9999),
      fcl.authorizations([fcl.currentUser().authorization]),
    ]);
    const { transactionId } = tx;
    const result = await execTransactionMethod(transactionId);
    return result;
  } catch (error) {
    return transactionError(error);
  }
};

const ExecuteGetAssetScripts = async (scriptName, brandId) => {
  try {
    const response = await fcl.send([
      fcl.script(scriptName),
      fcl.args([
        fcl.arg(brandId, t.UInt64), // brand Id
      ]),
    ]);
    const result = await ExecuteScripts(response);
    return result;
  } catch (ex) {
    return transactionError(ex);
  }
};
const ExecuteTransactionsFunction = async (script, bMultiSign = false) => {
  try {
    let auth = bMultiSign
      ? [serverAuthorization, fcl.currentUser().authorization]
      : [fcl.currentUser().authorization];
    const tx = await fcl.send([
      fcl.transaction(script),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.payer(fcl.currentUser().authorization),
      fcl.limit(9999),
      fcl.authorizations(auth),
    ]);
    const { transactionId } = tx;
    const result = await execTransactionMethod(transactionId);
    return result;
  } catch (error) {
    return transactionError(error);
  }
};
const Transactions = {
  CreateAssetExecuteTransaction,
  UpdateAssetTransaction,
  ExecuteGetBrandsScripts,
  ExecuteBrandsCapabilityScript,
  BrandMultiSignTransaction,
  ExecuteGetAssetScripts,
  ExecuteTransactionsFunction,
  DeleteAssetTransaction,
};

export default Transactions;
