import React from "react";
import { images } from "../../../../constants";
import SwitchComponent from "../../../ui-components/switch-component/SwitchComponent";
import { Link, useNavigate } from "react-router-dom";
import ThemeInfoComponent from "../../../ui-components/ThemeInfoComponent/ThemeInfoComponent";

const PreferenceCheckbox = (props) => {
  const navigate = useNavigate();
  const {
    title,
    name,
    preferLink,
    preferChecked,
    preferHandleChange,
    infothemeImage,
    InfoImageLeftText,
    InfoImageRightText,
    InfoImageBottomText,
    infoTitle,
    infoDesc,
    showtoggle,
    defaultDesc,
  } = props;

  return (
    <div className="mr-6 mb-6 border border-[#A4B1A7]/10 rounded-lg p-4 max-w-[494px] bg-[#12221A]/30 hover:bg-[#12221A] cursor-pointer ">
      <div className="flex justify-between items-center mb-3">
        <span className="text-base font-bold leading-6 ">{title}</span>
        <span className="grow">
          {" "}
          <ThemeInfoComponent
            themeImage={infothemeImage}
            backgroundText={""}
            colorText={""}
            title={infoTitle}
            desc={infoDesc}
            sm={true}
            InfoImageLeftText={InfoImageLeftText}
            InfoImageRightText={InfoImageRightText}
            InfoImageBottomText={InfoImageBottomText}
          />
        </span>
        {showtoggle && (
          <SwitchComponent
            onChange={preferHandleChange}
            value={preferChecked}
            id={name}
          />
        )}

        {preferLink && (
          <Link to={preferLink}>
            <img
              className="ml-6 rounded-full p-1  bg-[#A5F33C]/10"
              src={images.NavigateIcon}
              alt="icon"
            />
          </Link>
        )}
      </div>
      <p className="text-xs text-[#8D9D91] font-normal">{defaultDesc}</p>
    </div>
  );
};

export default PreferenceCheckbox;
