import * as yup from "yup";

const domainRegex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/)

export const DomainSchema = yup.object().shape({
    domainName: yup
        .string()
        .required("Domain is required")
        .matches(
            domainRegex,
            "Invalid domain format. Valid formats include .com, .xyz, etc."
        ),
});
