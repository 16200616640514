import React, { useState } from "react";
import { images } from "../../../constants";
import CopiedUrlClipBoard from "./CopiedUrlClipBoard";
import { Tooltip } from "@material-tailwind/react";


const KeyValueView = (props) => {
  const {
    title,
    value,
    success,
    path,
    style,
    media,
    mainStyle,
    minWidth,
    beforeIconValue,
  } = props;

  const copyUrl = () => {
    const url = `https://${value}`;

    navigator.clipboard.writeText(url).then(() => {
      const interval = setTimeout(() => {
        clearInterval(interval);
      }, 3000);
    });
  };
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleTooltipClick = () => {
    setTooltipVisible(true);

    // Auto-hide after 3 seconds
    setTimeout(() => {
      setTooltipVisible(false);
    }, 3000);
  };

  return (
    <div className="relative">
      <div
        className={`
          }  ${mainStyle} cursor-pointer`}
        onClick={copyUrl}
      >
        <p className="text-xs text-grey-100">
          {title}{" "}
          <span
            onClick={handleTooltipClick}
            className="hover:text-[#A5F33C] text-white ml-2 "
          >
            {media && <CopiedUrlClipBoard />}
          </span>
          {tooltipVisible && (
            <div className="  bg-[#0A130D] custom-tooltip-style   absolute bottom-full left-20 transform -translate-x-1/2 mb-1 px-4 py-2">
              Copied to clipboard
            </div>
          )}
        </p>
        {path ? (
          <>
            <a
              className={`${style ?? ""} text-sm `}
              href={`https://${value}`}
              target="_blank"
            >
              {value}
            </a>{" "}
            {/* {showText && (
              <p className="text-[#A5F33C] text-sm absolute right-16 top-0 ">
                (Copied to clipboard)
              </p>
            )} */}
          </>
        ) : (
          <>
            <p
              className={`text-base relative ${beforeIconValue &&
                `ml-4  ${value === "Successful"
                  ? "before-key-value-icon-success"
                  : value === "Unsuccessful"
                    ? "before-key-value-icon-error"
                    : value === "Mint Transfer Failed"
                      ? "before-key-value-icon-other"
                      : ""
                } `
                }  ${success && "text-[#B0E577] font-semibold "} ${value === "Successful"
                  ? "text-[#B0E577]"
                  : value === "Unsuccessful"
                    ? "text-[#F87171]"
                    : value === "Mint Transfer Failed"
                      ? "text-[#DC2626]"
                      : ""
                }`}
            >
              {value}
              {((value === "Unsuccessful" || value === 'Mint Transfer Failed' ) && title === "Blockchain Response") && (
                <>
                <span className="inline-block">
                  <Tooltip
                    className="custom-tooltip-style  bg-[#0A130D]  text-xs  z-[50] max-w-[220px]  rounded"
                    offset={10}
                    placement={"top-start"}
                    content={"The transaction could not be recorded on the blockchain because of an irregularity."}
                  >
                    <img
                      className="inline ml-1 align-baseline"
                      src={images.InfoIconSm}
                      alt="icon"
                    />
                  </Tooltip>
                  </span>
                </>
              )}
            </p>
          </>
        )}
      </div>
    </div >
  );
};

export default KeyValueView;
