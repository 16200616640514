import { BeatLoader } from "react-spinners";

const ProcessingModal = (props) => {
  const { loadingMessage } = props;
  return (
    <>
      {loadingMessage ? (
        <div className="font-manrope rounded-lg border border-[#A4B1A7]/[.12] bg-[#12221A4D]/30 p-14 max-w-[512px] ">
          <h4 className="mb-2 text-2xl font-bold text-center text-white">
            {loadingMessage ? "Processing" : "Waiting..."}
          </h4>
          <p className="font-normal text-xs text-center text-[#8D9D91] mb-7">
            {loadingMessage ?? ""}
          </p>
          <div className="text-center">
            <BeatLoader
              color={"#A5F33C"}
              loading={true}
              size={12}
              speedMultiplier="1"
            />
          </div>
        </div>
      ) : (
        <div className="text-center">
          <BeatLoader
            color={"#A5F33C"}
            loading={true}
            size={12}
            speedMultiplier="1"
          />
        </div>
      )}
    </>
  );
};

export default ProcessingModal;
