const PaymentSourceOptions = [
    { label: "All", value: null },
    { label: "Stripe", value: "1" },
    { label: "Blocto", value: "2" },
  ];

  const transactionTypeOptions = [
    { label: "All", value: 0 },
    { label: "Successful", value: 1 },
    { label: "Unsuccessful", value: 2 },
    { label: "Mint Transfer Failed", value: 3 },
  ];
  const mintProcessDesc = `While the payment process proceeded smoothly, the asset was not successfully minted and transferred to the purchaser.  Manual minting and transfer procedure  initiated to ensure the asset is delivered to the user.`
  const mintTransSuccess =`The asset has been minted and seamlessly transferred to the user. To verify, kindly access the purchaser profile within the detailed transaction history.`
  const transactionConstant = {
    PaymentSourceOptions,
    transactionTypeOptions,
    mintProcessDesc,
    mintTransSuccess
  }

  export default transactionConstant;