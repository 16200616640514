import React, { useState } from 'react'
import BasicTable from '../../BasicTable'
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    usePagination,
} from "react-table";
import { Menu, MenuHandler, MenuList } from "@material-tailwind/react";
import { images } from '../../../constants';
import SearchFilter from '../../SearchFilter';
import ConfigurationService from '../../../services/configuration.service';
import { setLoader } from '../../../redux/features/loading/loaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import useClientDetails from '../../../helpers/custom-hooks/useClientDetails';
import Modal from '../../../common/ModalLayout';
import AlertModalContent from '../../Modals/AlertModalContent';

const DomainListing = (props) => {
    const { data, columns, setOpenAlertModal, setAddDomainAlertModal, getDomainDetails } = props
    const dispatch = useDispatch()
    const { clientDetail } = useSelector((state) => state?.user);
    const { clientDetails } = useClientDetails()
    const [openDeleteSuccessModal, setOpenDeleteSuccessModal] = useState(false);
    const [alertContent, setAlertContent] = useState({
      title: "",
      description: "",
      path: "",
      sublabel: "",
    });
    

    const markAsPrimaryHandler = (data) => {
        const primary = data?.customDomainUuid ? false : true;
        makeAsPrimaryFunc({
            clientId: clientDetail?.clientUuid,
            customDomainUuid: data?.customDomainUuid ? data?.customDomainUuid : '',
            markDefaultAsPrimary: primary
        })
    }

    // API Data
    const makeAsPrimaryFunc = async (payload) => {
        dispatch(setLoader(true));
        await ConfigurationService.postMarkDomainPrimary(payload)
            .then((response) => {
                if (response?.data?.statusCodeValue === 200) {
                    getDomainDetails()
                    
                    clientDetails(clientDetail?.clientUuid)
                    dispatch(setLoader(false));
                    
                }
            })
            .catch((err) => {
                dispatch(setLoader(false));
            });

    };

    const deleteClientDomain = async (data) => {
        dispatch(setLoader(true));
        await ConfigurationService.deleteDomain(data?.customDomainUuid)
            .then((response) => {
                if (response?.data?.statusCodeValue === 200) {
                    getDomainDetails()
                    clientDetails(clientDetail?.clientUuid)
                    dispatch(setLoader(false));
                    setOpenDeleteSuccessModal(true)
                    setAlertContent({
                        title: "Success",
                        mainTitle: "Deleted Successfully!",
                        description: "Changes have been saved successfully.",
                        sublabel: "Auto closing in 3 seconds...",
                      });
                      setTimeout(() => {
                        setOpenDeleteSuccessModal(false);
                      }, 3000);
                }
            })
            .catch((err) => {
                dispatch(setLoader(false));
            });
    };

    // Action
    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                            flexGrow: 1,
                        }}
                    >
                        {" "}
                        Actions
                    </div>
                ),
                accessor: "Action",
                disableSortBy: true,
                Cell: ({ row }) => (
                    (
                        <div className={`flex justify-center ${(row?.original?.isPrimary && row?.original?.customDomainUuid === undefined) ? 'opacity-40' : 'opacity-100'}`}>
                            <Menu className="" placement="bottom-end">
                                <MenuHandler className="">
                                    <img
                                        className="mr-1 text-center cursor-pointer opacity-50 "
                                        src={images.more_icon}
                                        alt="icon"
                                    />
                                </MenuHandler>

                                <MenuList className={`${(row?.original?.isPrimary && row?.original?.customDomainUuid === undefined) ? 'hidden' : 'flex'}  bg-[#0A130D] py-4 flex-col min-w-[115px] px-0 z-20 border rounded-lg border-[#A4B1A7]/[0.12]`}>
                                    <span
                                        className={`drop-menu-link cursor-pointer ${row?.original?.domainStatus && row?.original?.isPrimary ? 'hidden' : row?.original?.domainStatus != undefined && !row?.original?.domainStatus ? 'hidden' : row?.original.isPrimary ? 'hidden' : 'block'}`}
                                        onClick={() => markAsPrimaryHandler(row?.original)}
                                    >
                                        Set as default
                                    </span>
                                    <span
                                        className={`drop-menu-link cursor-pointer ${row?.original?.defaultDomainName ? 'hidden' : 'block'}`}
                                        onClick={() => deleteClientDomain(row?.original)}
                                    >
                                        Delete
                                    </span>
                                </MenuList>
                            </Menu>
                        </div>
                    )
                ),
            },
        ]);
    };

    const guideCustomDomain = () => {
        setOpenAlertModal(true)
    }

    const addDomainHandler = () => {
        setAddDomainAlertModal(true)
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        nextPage,
        canNextPage,
        previousPage,
        canPreviousPage,
        pageOptions,
        prepareRow,
        gotoPage,
        pageCount,
        state, // For filtering
        setGlobalFilter, // For filtering
    } = useTable(
        {
            // ES6 shorthand notation having same key value name
            columns,
            data,
        },
        tableHooks,
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageIndex } = state;
  


    return (
        <>
            <div className="flex items-center justify-between mb-8">
                <div className="w-full">
                    <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
                </div>
                <p className='text-base not-italic font-bold leading-6 text-white w-80 cursor-pointer mx-8' onClick={guideCustomDomain} >Domain Setup Guide</p>
          
                <button

                    type="button"
                    data-modal-toggle="Add_Modal"
                    className="max-w-[202px]  btn-primary rounded-full"
                    onClick={addDomainHandler}
                >
                    Add Domain
                </button>
            </div>
            <BasicTable
                columns={columns}
                data={data}
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                page={page}
                nextPage={nextPage}
                canNextPage={canNextPage}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                prepareRow={prepareRow}
                pageOptions={pageOptions}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                pageCount={pageCount}
                rows={rows}
                defaultMessage={
                    " "
                }
                icon={images.TableNotFoundIcon}
            />
             {openDeleteSuccessModal && (
        <Modal
          modalVisible={openDeleteSuccessModal}
          closeModal={setOpenDeleteSuccessModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Save_Modal"
          isClose={true}
        />
      )}
        </>
    )
}

export default DomainListing