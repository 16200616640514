import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clientService from "./../../services/client.service";
import Spinner from "./../ui-components/loader-components/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  reset_menuItems,
  update_menu_items_state,
} from "../../redux/features/menu/menuItemsSlice";
import authService from "./../../services/auth.service";
import BrandThemeCheckbox from "../ui-components/custom-checkbox/BrandThemeCheckbox";
import { clientDetailAction } from "../../redux/features/user/userSlice";
import { images } from "../../constants";
import Modal from "../../common/ModalLayout";
import KybThankyouModal from "../Modals/KybThankyouModal";

const KybBrandTheme = () => {
  const [loading, setLoading] = useState(false);
  const [selectTheme, setSelectTheme] = useState();
  const [showThankyouModal, setShowThankyouModal] = useState(false);
  const [stepsCompleted, setStepsCompleted] = useState(false);
  const [isClientApproved, setIsClientApproved] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const handleCloseThankyouModal = () => {
    setShowThankyouModal(false);
  };

  useEffect(() => {
    if (
      user.clientDetail.stepsCompleted === true &&
      !user.clientDetail.isClientApproved
    ) {
      setShowThankyouModal(true);
      setStepsCompleted(true);
    }
  }, []);

  useEffect(() => {
    if (user.clientDetail.themeId) {
      setSelectTheme(user.clientDetail.themeId);
    }
  }, []);

 
  const handleChangeTheme = (value) => {
    setSelectTheme(value);
  };

  

  const submitThemeForm = () => {

    setLoading(true);
    const formData = new FormData();
    formData.append("ClientUuid", user.clientUuid);
    formData.append("ThemeId", selectTheme);
    formData.append("stepsEnum", 4);

    clientService
      .updateClient(formData)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          setLoading(false);
      
          if (response.data.payload.isClientApproved) {
            setIsClientApproved(true);
            setStepsCompleted(false);
            setShowThankyouModal(true);
            dispatch(clientDetailAction(response.data.payload));
          } else {
            setStepsCompleted(true);
            setLoading(false);
            setShowThankyouModal(true);
            dispatch(reset_menuItems(5));
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //     authService.refreshAccessToken(user?.userInfo?.refresh_token, user?.clientUuid);
          authService.logoutUser();
          navigate("/login");
        }
        setLoading(false);
      });
  };

  return (
    <>
      <div>
        <h1 className="text-2xl font-bold">Brand Theme</h1>
        <p className="mt-1 mb-6 text-xs text-grey-100 max-w-[448px]">
          Please select one of the following themes.
          <br />
          More customizability options are available in our paid plans.
        </p>

        <div className="flex items-center justify-start mb-12">
          <BrandThemeCheckbox
            value={1}
            media={images.ThemeDark}
            isChecked={selectTheme}
            onChange={() => handleChangeTheme(1)}
            style={"mr-6"}
            themeName="Drops Dark Theme"
          />
          <BrandThemeCheckbox
            value={2}
            media={images.ThemeDrop}
            isChecked={selectTheme}
            onChange={() => handleChangeTheme()}
            style={"mr-6"}
            disabled={true}
            themeName={"Drops Light Theme"}
          />
          <BrandThemeCheckbox
            value={2}
            media={images.ThemeMemberShip}
            isChecked={selectTheme}
            onChange={() => handleChangeTheme()}
            disabled={true}
            style={""}
            themeName={"Membership Theme"}
          />
        </div>

        <button
          onClick={submitThemeForm}
          disabled={!selectTheme || loading}
          className="max-w-md mb-16 text-base btn-primary disabled:opacity-40 disabled:pointer-events-none"
        >
          {loading && <Spinner />} Save &amp; Continue
        </button>
      </div>

      <Modal
        modalVisible={showThankyouModal}
        closeModal={handleCloseThankyouModal}
        children={
          <KybThankyouModal
            closeModal={handleCloseThankyouModal}
            stepsCompleted={stepsCompleted}
            isClientApproved={isClientApproved}
          />
        }
        modal_Id="Thankyou_Modal"
        isClose={false}
      />
    </>
  );
};

export default KybBrandTheme;
