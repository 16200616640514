import React from 'react'

const AssetNontransferIcon = () => {

  return (
    <>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.625 1.75L11.375 3.5L9.625 5.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.625 6.125V5.25C2.625 4.78587 2.82986 4.34075 3.19451 4.01256C3.55917 3.68437 4.05375 3.5 4.56944 3.5H11.375" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.375 12.25L2.625 10.5L4.375 8.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.375 7.875V8.75C11.375 9.21413 11.1701 9.65925 10.8055 9.98744C10.4408 10.3156 9.94625 10.5 9.43056 10.5H2.625" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.875 6.125L6.125 7.875" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.125 6.125L7.875 7.875" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

    </>
  )
}

export default AssetNontransferIcon