import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuItems: [
    {
      id: 1,
      step: 1,
      label: "Connect Wallet",
      link: "/connect-wallet",
      isActive: false,
    },
    {
      id: 2,
      step: 2,
      label: "Brand Details",
      link: "/brand-details",
      isActive: false,
    },
    {
      id: 3,
      step: 3,
      label: "Brand Categories",
      link: "/brand-categories",
      isActive: false,
    },
    {
      id: 4,
      step: 4,
      label: "Brand Theme",
      link: "/brand-theme",
      isActive: false,
    },
    // {
    //   id: 4,
    //   step: 4,
    //   label: "Social Links",
    //   link: "/social-links",
    //   isActive: false,
    // },
  ],
};

const menuSlice = createSlice({
  name: "menuItems",
  initialState,
  reducers: {
    update_menu_items_state: (state, action) => {
      state.menuItems.map((item) => {
        return item.id === action.payload && (item.isActive = true);
      });
    },
    reset_menuItems: (state, action) => {
      state.menuItems.map((item) => {
        return action.payload === 5 && (item.isActive = false);
      });
    },
    activate_all_menuItems: (state, action) => {
      state.menuItems.map((item) => {
        return action.payload === "activate" && (item.isActive = true);
      });
    },
  },
});

export const {
  update_menu_items_state,
  reset_menuItems,
  activate_all_menuItems,
} = menuSlice.actions;

export default menuSlice.reducer;
