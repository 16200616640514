import React, { useState, useRef } from "react";
const useCopyLink = () => {
  const [showCopiedText, setShowCopiedText] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const copyAddress = useRef();

  const copyText = async (event) => {
    event.preventDefault();
    await navigator.clipboard
      .writeText(copyAddress.current.getAttribute("value"))
      .then(() => {
        setShowCopiedText(true);
        setTooltipVisible(true);

        const interval = setTimeout(() => {
          setShowCopiedText(false);
          setTooltipVisible(false);
          clearInterval(interval);
        }, 3000);
      })
      .catch(() => {});
  };
  return {
    copyText,
    copyAddress,
    showCopiedText,
    tooltipVisible,
  };
};
export default useCopyLink;
