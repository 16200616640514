import { Tooltip } from "@material-tailwind/react";
import { images } from "../../../constants";
import DateFormat from "../../../helpers/DateFormat";
import SizeConverter from "../../../helpers/SizeConverter";
import stringTruncate from "../../../helpers/StringTruncate";

export const COLUMNS = [
  {
    Header: "Client Name",
    Footer: "Client Name",
    accessor: "Creator-Name" || "creator-Name",
    Cell: ({ value }) => (
      <>
        {
          <Tooltip
            className="custom-tooltip-style bg-[#0A130D]"
            offset={10}
            placement={"top-start"}
            content={value}
          >
            <p className="text-xs cursor-pointer">
              {stringTruncate(value, 15)}
            </p>
          </Tooltip>
        }
      </>
    ),
  },
  {
    Header: "Asset Name",
    Footer: "Asset Name",
    accessor: "Asset-Name",
    Cell: ({ value }) => (
      <>
        {
          <Tooltip
            className="custom-tooltip-style bg-[#0A130D]"
            offset={10}
            placement={"top-start"}
            content={value}
          >
            <p className="text-xs cursor-pointer">
              {stringTruncate(value, 15)}
            </p>
          </Tooltip>
        }
      </>
    ),
  },
  {
    Header: "Date",
    Footer: "Date",
    accessor: "Date",
    Cell: ({ value }) => (
      <>
        {
          <p className="text-xs cursor-pointer">
            {" "}
            <DateFormat date={value} />
          </p>
        }
      </>
    ),
  },
  {
    Header: "Type",
    Footer: "Type",
    accessor: "imageType",
    Cell: ({ value }) => (
      <>
        {
          <p className="text-xs capitalize cursor-pointer">
            {value?.split("/")[0]}
          </p>
        }
      </>
    ),
  },
  {
    Header: "Asset Size",
    Footer: "Asset Size",
    accessor: "imageSize",
    Cell: ({ value }) => (
      <>{<p className="text-xs cursor-pointer">{SizeConverter(value)}</p>}</>
    ),
  },

  {
    Header: () => (
      <div
        style={{
          textAlign: "center",
          flexGrow: 1,
        }}
      >
        {" "}
        Asset Link
      </div>
    ),
    Footer: "Asset Link",
    accessor: "ImageValue",
    Cell: ({ row }) => (
      <>
        {
          <p className="flex justify-center text-xs cursor-pointer">
            <a
              href={`https://viewblock.io/arweave/tx/${row?.original?.ImageValue}`}
              target="_blank"
            >
              <img
                width={"24px"}
                height="24px"
                src={
                  row?.original?.imageType?.includes("image")
                    ? images.imagePlaceHolder
                    : row?.original?.imageType?.includes("audio")
                    ? images?.audioPlaceHolder
                    : images.videoPlaceHolder
                }
                alt="arweaveImg"
              />
            </a>
          </p>
        }
      </>
    ),
  },
];
