import React from "react";

const FormatNumber = (number) => {
  const formattedNumber = number?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedNumber;
};

export default FormatNumber;
