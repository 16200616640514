import * as yup from "yup";

export const SetPasswordSchema = yup.object().shape({
	isOldPassword: yup.boolean(),
	oldPassword: yup.string().when("isOldPassword", {
		is: true,
		then: yup.string().required("Old password is required"),
		otherwise: yup.string(),
	}),
	password: yup
		.string()
		.required("Password is required")
		.min(6,"Min  length 6 characters")
		.max(18,"Max  length 18 characters")
		.matches(/(\d)/, "Must contain one number")
		.matches(/[A-Z]/, "Must contain one uppercase")
		.matches(/([a-z])/, "Must contain one lowercase")
		.matches(/(\W)/, "Must contain one special character"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null])
		.required(),
});
