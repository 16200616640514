import React from "react";
import ClientHeaderComponent from "../components/ClientHeaderComponent";
import ClientPreferencesContent from "../../../components/ClientTheme/ClientPreferences/ClientPreferencesContent";

const ClientPreferences = () => {
  return (
    <>
      <ClientPreferencesContent />
    </>
  );
};

export default ClientPreferences;
