import React from "react";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import AlertModalDate from "../Modals/AlertModalDate";
import { images } from "../../constants";
import DateFormatYearMonth from "../../helpers/DateFormatYearMonth";

const CalenderPopup = ({
  donughtMonth,
  openPopover,
  setopenPopover,
  openReportCalender,
  reportStartDate,
  reportEndDate,
  handleDateSelection,
  ...rest
}) => {
  return (
    <>
      <Popover
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0, y: 25 },
        }}
        placement="bottom-end"
        open={openPopover}
        handler={() => setopenPopover(!openPopover)}
      >
        <PopoverHandler
          onClick={() => setopenPopover(!openPopover)}
          open={openPopover}
        >
          {openReportCalender ? (
            <div className=" min-w-[230px] relative   ">
              {reportStartDate && (
                <label
                  className={`text-xs text-grey-100    absolute  -top-7   transition-all     `}
                >
                  Time Period
                </label>
              )}
              <span
                onClick={handleDateSelection}
                className="cursor-pointer min-w-[220px] relative before:absolute before:w-[100%] before:h-[0.5px] before:bg-[#A4B1A7] before:-bottom-3 text-base font-normal grow "
              >
                {reportStartDate || reportEndDate ? (
                  <>
                    {" "}
                    <DateFormatYearMonth date={reportStartDate} /> /{" "}
                    <DateFormatYearMonth date={reportEndDate} />{" "}
                  </>
                ) : (
                  <span className="text-[#8D9D91]">Time Period</span>
                )}
                <img
                  className="inline ml-3  absolute right-0"
                  src={images.CalenderLgIcon}
                  alt=""
                />
              </span>
            </div>
          ) : (
            <div className={`flex justify-between items-center cursor-pointer`}>
              <img src={images.dateIcon} className="mr-1" alt="" />
              <span className="text-xs font-normal">{`${donughtMonth}`}</span>
            </div>
          )}
        </PopoverHandler>{" "}
        <PopoverContent
          className="z-[100] w-[422] h-[397] p-0 rounded-2xl border border-[#12221A] bg-[#12221A]/30"
          style={{ left: "520px !important" }}
        >
          <AlertModalDate {...rest} />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default CalenderPopup;
