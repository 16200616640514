import * as yup from "yup";
import { getDocumentType } from "../../helpers/GetDocumentType";
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
  "text/plain",
];
export const addMutableDocumentSchema = yup.object().shape({
  addMutableDocument: yup.boolean(),
  mutableDocumentName: yup.string().when("addMutableDocument", {
    is: true,
    then: yup
      .string()
      .required("File name is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    otherwise: yup.string(),
  }),

  mutableDocumentFile: yup.mixed().when("addMutableDocument", {
    is: true,
    then: yup
      .mixed()
      .test("required", "File is required", (file) => {
        if (file[0]) return true;
        return false;
      })
      .test("fileSize", "File size is too large", (value) => {
        if (
          (typeof value === "string" && getDocumentType(value) === "image") ||
          (typeof value === "string" && getDocumentType(value) === "pdf") ||
          (typeof value === "string" && getDocumentType(value) === "txt")
        ) {
          return true;
        } else {
          if (!value) return true;
          return value[0]?.size <= 5242880;
        }
      })
      .test("fileFormat", "Unsupported Format", (value) => {
        if (
          (typeof value === "string" && getDocumentType(value) === "image") ||
          (typeof value === "string" && getDocumentType(value) === "pdf") ||
          (typeof value === "string" && getDocumentType(value) === "txt")
        ) {
          return true;
        } else {
          return value[0] && SUPPORTED_FORMATS.includes(value[0].type);
        }
      }),
    otherwise: yup.mixed(),
  }),
});
