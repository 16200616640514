import React from 'react'

const StatsKeyValue = (props) => {
  const { keytext, value, style, line } = props
  return (
    <>
      <div className={`mr-10 text-base text-white flex items-center ${line && 'relative after:absolute after:w-[1px] after:h-8 after:bg-[#A4B1A7]/[0.12]'} `}>
        <span className={` w-3 h-3 rounded-[2px]  ${style} mr-2`}></span>
        <span className="text-white/30">{keytext} {' '}</span>
        <span className="font-bold ml-1">{' '} {value}</span>
      </div>
    </>
  )
}
export default StatsKeyValue