import { Tooltip } from "@material-tailwind/react";
import { images } from "../../../constants";
import DateFormat from "../../../helpers/DateFormat";
import stringTruncate from "../../../helpers/StringTruncate";
import { GetStorageData } from "../../../helpers/custom-hooks/getStorageData";

export const COLUMNS = [
  {
    Header: "Asset",
    Footer: "Asset",
    accessor: "assetVariationObject",
    Cell: ({ row }) => (
      <>
        {row?.values?.assetVariationObject?.arweaveAssetType === "Image" && (
          <img
            className=" rounded w-6 h-6 "
            onLoad={(e) => {
              e.currentTarget.attributes.src.value =
                row?.values?.assetVariationObject?.croppedImage;
            }}
            src={images.DefaultImageIcon}
            alt="icon"
          />
        )}
        {row?.values?.assetVariationObject?.arweaveAssetType === "Video" && (
          <img
            className=" rounded w-6 h-6 "
            onLoad={(e) => {
              e.currentTarget.attributes.src.value =
                row?.values?.assetVariationObject?.videoThumbnailUri;
            }}
            src={images.DefaultImageIcon}
            alt="icon"
          />
        )}
        {row?.values?.assetVariationObject?.arweaveAssetType === "Audio" && (
          <img
            className=" rounded w-6 h-6 "
            src={images.AudioIcon}
            alt="icon"
          />
        )}
      </>
    ),
  },
  {
    Header: "Asset Name",
    Footer: "Asset Name",
    accessor: "assetName",
    Cell: ({ row }) => (
      <>{<p>{stringTruncate(row?.values?.assetName, 6)}</p>}</>
    ),
  },
  {
    Header: "Purchaser",
    Footer: "Purchaser",
    accessor: "purchaserName",
    Cell: ({ row }) => (
      <>
        {
          <Tooltip

            className="custom-tooltip-style bg-[#0A130D]"
            offset={12}
            placement={"top-start"}
            content={row?.values?.purchaserName !== " " ? row?.values?.purchaserName : row?.values?.emailAddress}
          >
            <p className="group hover:text-[#A5F33C]">
              {row?.values?.purchaserName !== " " &&
                row?.values?.purchaserName ? (
                row?.values?.orderStatus === "Successful" ||
                  row?.values?.orderStatus === "Mint Transfer Failed" ? (
                  <a
                    className={`underline ${row?.values?.orderStatus === "Successful"
                      }`}
                    href={`https://${GetStorageData()?.clientDomainName
                      }/collections/${row?.values?.userWalletAddress}`}
                    target="_blank"
                  >
                    {" "}
                    {row?.values?.purchaserName}
                  </a>
                ) : (
                  <span className="underline">{row?.values?.purchaserName}</span>
                )
              ) : (
                row?.values?.emailAddress ?

                  <a
                    className={`underline ${row?.values?.orderStatus === "Successful"
                      }`}
                    href={`https://${GetStorageData()?.clientDomainName
                      }/collections/${row?.values?.userWalletAddress}`}
                    target="_blank"
                  >{stringTruncate(row?.values?.emailAddress, 18)}</a> : "---"
              )}
            </p>
          </Tooltip>
        }
      </>
    ),
  },
  {
    Header: "Total Price ($)",
    Footer: "Total Price",
    accessor: "totalPriceUsd",
    Cell: ({ row }) => (
      <>
        {
          <p>
            {row?.values?.totalPriceUsd
              ? `$${row?.values?.totalPriceUsd}`
              : "---"}
          </p>
        }
      </>
    ),
  },
  {
    Header: "Platform Fee ($)",
    Footer: "Platform Fee",
    accessor: "platformFee",
    Cell: ({ row }) => (
      <>
        {
          <p>
            {row?.values?.platformFee ? `$${row?.values?.platformFee}` : "---"}
          </p>
        }
      </>
    ),
  },
  {
    Header: "Order Date",
    Footer: "Order Date",
    accessor: "orderDate",
    Cell: ({ row }) => (
      <>
        {
          <p className="">
            {row?.values?.orderDate ? (
              <DateFormat date={row?.values?.orderDate} />
            ) : (
              "-"
            )}
          </p>
        }
      </>
    ),
  },

  {
    Header: "Payment Source",
    Footer: "Payment Source",
    accessor: "paymentProcessor",
    Cell: ({ row }) => (
      <>
        {
          <img
            className=" w-6 h-6 "
            src={
              row?.values?.paymentProcessor === "Blocto"
                ? images.FlowFcIcon
                : images.MasterCardIcon
            }
            alt="icon"
          />
        }
      </>
    ),
  },
  {
    Header: "Status",
    Footer: "Status",
    accessor: "orderStatus",
    Cell: ({ row }) => (
      <>
        <p
          className={`relative ml-4 pt-1 ${row?.values?.orderStatus === "Successful"
              ? "before-key-value-icon-success text-[#B0E577]"
              : row?.values?.orderStatus === "Unsuccessful"
                ? "text-[#F87171] before-key-value-icon-error"
                : "text-[#DC2626] before-key-value-icon-other"
            } `}
        >
          <span
            className={` ${row?.values?.orderStatus === "Successful"
                ? " text-[#B0E577]"
                : row?.values?.orderStatus === "Unsuccessful"
                  ? "text-[#F87171] "
                  : "text-[#DC2626]"
              } `}
          >
            {row?.values?.orderStatus}
          </span>
        </p>
      </>
    ),
  },
  {
    Header: "orderId",
    Footer: "orderId",
    accessor: "orderId",
  },

  {
    Header: "AssetDescription",
    Footer: "AssetDescription",
    accessor: "description",
  },

  {
    Header: "paymentResponseJson",
    Footer: "paymentResponseJson",
    accessor: "paymentResponseJson",
  },
  {
    Header: "transactionHash",
    Footer: "transactionHash",
    accessor: "transactionHash",
  },
  {
    Header: "dropUuId",
    Footer: "dropUuId",
    accessor: "dropUuId",
  },
  {
    Header: "userwalletaddress",
    Footer: "userwalletaddress",
    accessor: "userWalletAddress",
  },
  {
    Header: "purchaseemailAddress",
    Footer: "purchaseemailAddress",
    accessor: "emailAddress",

  },
  {
    Header: "TransferAble",
    Footer: "TransferAble",
    accessor: "isTransferAble",

  }
];
