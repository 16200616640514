import { Tooltip } from 'flowbite-react'
import React from 'react'

const CustomTooltip = ({ totalSlice, sliceUpto = 7 }) => {
    return (
        <>
            {totalSlice?.length > sliceUpto ? (
                <Tooltip
                    className="custom-tooltip-style bg-[#0A130D]"
                    offset={10}
                    placement={"top-start"}
                    content={totalSlice}
                >
                    <p className=" cursor-pointer">
                        {totalSlice?.slice(0, sliceUpto) + "..."}
                    </p>
                </Tooltip>
            ) : (
                <p>{totalSlice}</p>
            )}
        </>
    )
}

export default CustomTooltip