import React, { useMemo, useState } from 'react'
import { COLUMNS } from "../../../components/ClientReporting/ArweaveReporting/ArweaveColumn";
import ReportingData from "../../../data/arweavedata.json";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import ArweaveViewModalContent from '../../../components/Modals/ArweaveViewModalContent';
import Modal from "../../../common/ModalLayout";
import BasicTable from '../../../components/BasicTable';
import SearchFilter from '../../../components/SearchFilter';
import { images } from '../../../constants';

const ArweaveListing = ({ arweaveData }) => {
  const [openViewModal, setOpenViewModal] = useState(false);
  const [ViewData, setViewData] = useState({})

  // React table suggests to memoize the columns and data for avoiding the re creation of data on every render
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => arweaveData, []);


  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
              flexGrow: 1
            }}
          > Actions</div>),
        accessor: "Actions",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="flex justify-center">
            <img className="cursor-pointer " src={images.arweaveViewIcon} alt="icon" onClick={() => viewArweaveImageInPopUp(row?.original)} />
          </div>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    gotoPage,
    pageCount,
    state, // For filtering
    setGlobalFilter, // For filtering
  } = useTable(
    {
      // ES6 shorthand notation having same key value name
      columns,
      data,

    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;
  const viewArweaveImageInPopUp = (data) => {
    setOpenViewModal(true);
    setViewData(data);
  }
  return (
    <>
      <div className='mb-6'>
        {/* Search */}
        <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
      <div>
        <BasicTable
          columns={columns}
          data={data}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          prepareRow={prepareRow}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          pageCount={pageCount}
          // getRowDataDetail={getRowDataDetail}
          rows={rows}

        />
      </div>
      {openViewModal &&
        <Modal
          modalVisible={openViewModal}
          closeModal={setOpenViewModal}
          children={
            <ArweaveViewModalContent
              ViewData={ViewData}
              setOpenViewModal={setOpenViewModal}
            />
          }
          modal_Id="Add_Modal"

        />

      }
    </>
  )
}

export default ArweaveListing