import React from "react";
import { Chart as ChartJs, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import CustomTooltip from "./CustomTooltip";
import FormatNumber from "../../helpers/FormatNumber";

ChartJs.register(ArcElement, Tooltip, Legend);

function DoughnutChart({ largeDonughtChart }) {
  const data = {
    datasets: [
      {
        data: [largeDonughtChart?.platformFee, largeDonughtChart?.netSales],
        backgroundColor: ["#E7E15A", "#4F80FD"],
        hoverBackgroundColor: ["#E7E15A", "#4F80FD"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        labels: {
          boxWidth: 10,
          padding: 15,
          borderRadius: 80,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataIndex = context.dataIndex;
            const value = context.dataset.data[dataIndex];
            const arrangeLable =
              Object.entries(largeDonughtChart).reverse()[dataIndex];

            let label = "";

            if (arrangeLable[0] === "netSales") {
              label = "Net Sales";
            } else if (arrangeLable[0] === "platformFee") {
              label = "PlatForm Fee";
            } else {
              label = arrangeLable[0];
            }

            return ` ${label}: $${value}`;
          },
        },
        backgroundColor: "#0A130D",
        padding: 16,
        bodyColor: "#fff",
        borderColor: "rgba(164, 177, 167, 0.12)",
        borderWidth: 1,
      },
    },

    cutout: "75%",
    responsive: true,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    borderRadius: 8,
    spacing: 2,
  };

  const totalTransaction = largeDonughtChart?.platformFee + largeDonughtChart?.netSales;
  const totalSlice = isNaN(totalTransaction)
    ? 0
    : Math.abs(totalTransaction).toString();
  return (
    <>
      <div className="flex justify-center ">
        <div className="w-[172px] h-[172px] relative">
          <Doughnut data={data} options={options} className="relative z-50" />
          <div className="absolute top-[50%] left-[50%] text-center -translate-x-[50%]    -translate-y-[50%]  ">
            <span className="text-[#A5F33C] text-xl font-bold align-baseline  ">
           { totalSlice && `$${FormatNumber(parseInt(totalSlice))}`}
            </span>
            <p className="text-[10px] text-[#8D9D91] min-w-[98px]">
              Total Transactions in USD
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-4 ">
        <div className="flex justify-center items-center mr-6">
          <p className="w-3 rounded-sm h-3 mr-2 bg-[#4F80FD]"></p>
          <p className="text-xs font-normal text-[#8D9D91] ">Net Sale:</p>
          <p className="text-[#FFF] text-xs font-semibold pl-1">
            <CustomTooltip
              totalSlice={`$${
                isNaN(largeDonughtChart?.netSales)
                  ? 0
                  : largeDonughtChart?.netSales
              }`}
              sliceUpto={8}
            />
          </p>
        </div>
        <div className="flex justify-center items-center">
          <p className="w-3 rounded-sm h-3 mr-2 bg-[#E7E15A]"></p>
          <p className="text-xs font-normal text-[#8D9D91]">Platform Fee:</p>
          <p className="text-[#FFF] text-xs font-semibold pl-1">
            <CustomTooltip
              totalSlice={`$${
                isNaN(largeDonughtChart?.platformFee)
                  ? 0
                  : largeDonughtChart?.platformFee
              }`}
              sliceUpto={8}
            />
          </p>
        </div>
      </div>
    </>
  );
}

export default DoughnutChart;
