import { useEffect, useState } from "react";
import eye_off_icon from "./../../assets/images/icons/eye_off.svg";
import eye_on_icon from "./../../assets/images/icons/eye-on.svg";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Link } from "react-router-dom";

import { adminAddress, clientDetailAction, login } from "../../redux/features/user/userSlice";
import { useDispatch } from "react-redux";
import authService from "../../services/auth.service";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema } from "../../utils/ValidationSchemas/LoginSchema";
import clientService from "./../../services/client.service";
import AuthAlertComponent from "./AuthAlertComponent";

const LoginComponent = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    mode: "all",
  });

  useEffect(() => {
    getInitialValues();
    //eslint-disable-next-line
  }, []);
  const getInitialValues = () => {
    const userEmail = sessionStorage.getItem("email");
    const userPass = sessionStorage.getItem("password");


    if (userEmail) {
      setValue("email", userEmail);
      setValue("password", userPass);
      setValue("rememberMe", true);
    }
  };


  const [showPassword, setShowPassword] = useState(false);
  const { inputFieldStyle, setAlert, alert } = useOutletContext();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const submitForm = (data) => {

    if (data?.rememberMe) {
      sessionStorage.setItem("email", data.email);
      sessionStorage.setItem("password", data.password);
    } else {
      sessionStorage.removeItem('email')
      sessionStorage.removeItem('password')
    }

    setLoading(true);
    authService
      .loginUser({ email: data.email, password: data.password })
      .then((response) => {
        if (response.status === 200) {
          
          let interval;
       
          if (response?.data?.role?.includes("Client")) {
            dispatch(login(response.data));
            interval = setTimeout(async () => {
              await clientIdResponse();
              clearInterval(interval);
            }, 3000);

            const clientIdResponse = async () => {
              try {
                setLoading(true);
                const clientId = await clientService.getClientDetailsById(
                  response?.data?.clientid,
                  {
                    headers: {
                      Authorization: `Bearer ${response?.data?.token?.access_token}`,
                    },
                  }
                );
                dispatch(clientDetailAction(clientId?.data?.payload));
                
                if (clientId?.data?.payload?.isClientApproved === false) {
                 
                  setLoading(false);
                  if (clientId?.data?.payload?.stepsCompleted) {
                    navigate("/onboarding/brand-theme");
                  } else {
                    navigate("/onboarding");
                  }
                } else {
                  setLoading(false);

                  navigate("/client-dashboard");
                }
              } catch (err) {
                setLoading(false);
                setAlert(
                  err?.response?.data?.error?.text ?? "something went wrong"
                );
              }
            };
            // clientService.getClientDetailsById(response?.data?.clientid).then((response) => {
            //     if (response?.data?.statusCodeValue === 200) {
            //         dispatch(clientDetail(response?.data?.payload));
            //     }
            // })
          } else if (response?.data?.role?.toLowerCase() === "super admin") {
            dispatch(login(response.data));
            dispatch(adminAddress(response.data.walletAddress))
            navigate("/dashboard");
            setLoading(false);
          }
          else{
            setLoading(false);
            setAlert(
              "Invalid email or password"
            );
          }
        }
      })
      .catch((err) => {
        setAlert(err?.response?.data?.error?.text ?? "something went wrong");
        setLoading(false);
      });
  };

  const spinner = () => {
    return (
      <svg
        role="status"
        className="inline w-5 h-5 mr-3 text-[#12221A] animate-spin"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#E5E7EB"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor"
        />
      </svg>
    );
  };

  return (
    <div className="md:w-6/12 lg:w-4/12">
      <h1 className="mb-2 text-2xl font-bold text-white">Sign In</h1>

      <p className=" text-md text-grey-100">
      Build, Launch and Grow with Bloomly
      </p>

      <form
        className="w-full text-white lg:w-10/12"
        onSubmit={handleSubmit(submitForm)}
      >
        <AuthAlertComponent alert={alert} setAlert={setAlert} />

        {/* Email input  */}
        <div className="relative z-10 mt-8 mb-8 ">
          <input
            type="email"
            // name="email"
            autoComplete="false"
            className={` peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-white/70 rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-white block w-full p-3 bg-transparent ${errors?.email?.message && 'focus:border-b-red-600'} + " autofill:!bg-yellow-200"`}
            placeholder=" "
            // required
            {...register("email")}
          />
          <label
            className={`text-xs text-grey-100 z-[-1] absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
          >
            Enter Email
          </label>
          <p className="mt-1 text-xs text-red-600">{errors?.email?.message}</p>
        </div>

        {/* Password input  */}
        <div className="relative z-10 mt-8">
          <input
            type={!showPassword ? "password" : "text"}
            // name="password"
            className={` peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-white/70 rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-white block w-full p-3 bg-transparent ${errors?.password?.message && 'focus:border-b-red-600'} + " autofill:!bg-yellow-200`}
            placeholder=" "
            // required
            {...register("password")}
          />
          <label
            className={`text-xs text-grey-100  z-[-1]  absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
          >
            Password
          </label>
          <p className="mt-1 mb-3 text-xs text-red-600">
            {errors?.password?.message}
          </p>
          <img
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-0 cursor-pointer top-3"
            src={!showPassword ? eye_on_icon : eye_off_icon}
            alt="eye_off"
          />
        </div>

        <div className="flex items-center justify-between mb-10">
          <div className="flex items-center justify-between">
            <div className="flex items-center form-group form-check">
              <input
                {...register("rememberMe")}
                type="checkbox"
                className="float-left w-5 h-5  mr-4  transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border-2 border-[#94A3B8] rounded-[4px] appearance-none cursor-pointer form-check-input checked:bg-[#A5F33C] checked:border-[#A5F33C] checked:bg-[url('/src/assets/images/icons/checkedIconAuth.svg')]   focus:shadow-none focus:outline-none focus:ring-0 focus:ring-offset-0"
                id="exampleCheck2"
              />
              <label
                className="inline-block text-grey-100 form-check-label"
                htmlFor="exampleCheck2"
              >
                Remember me
              </label>
            </div>
          </div>

          <Link className="text-xs font-bold text-white hover:underline hover:decoration-solid" to="/forgot-password">
            Forgot Password?
          </Link>
        </div>

        {/* Submit button  */}
        <button
          type="submit"
          className={`btn-primary   disabled:cursor-not-allowed`}
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          disabled={(loading || (!isValid))}
        >
          {loading && spinner()}
          Login
        </button>

        {/* OR LINES AND TEXT */}
        {/* <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
            <p className="mx-4 mb-0 font-semibold text-center">OR</p>
        </div> */}
      </form>
    </div>
  );
};

export default LoginComponent;
