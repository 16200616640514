import React, { useState } from "react";
import Select, { components } from "react-select";
import CustomSearchField from "../../../ui-components/form-component/CustomSearchField";
import { customStyles } from "../../../ui-components/react-select-style/customStyle";
import { images } from "../../../../constants";
import UseDatePicker from "../../../../helpers/custom-hooks/UseDatePicker";
import transactionConstant from "../../../../constants/client-admin-constants/clientTransactionConstants/ClientTransactionConstants";
import DateFormatYearMonth from "../../../../helpers/DateFormatYearMonth";
import CalenderPopup from "../../../ClientDashboardComponent/CalenderPopup";

const TransactionFilters = (props) => {
  const [searchbyChar, setSearchbyChar] = useState("");
  const { params, setParams } = props;
  const {
    formatDateToMonthYear,
    staticRanges,
    handleCancel,
    transactionReportingDate,
    handleChangeTransactionReport,
    openPopover,
    setopenPopover
  } = UseDatePicker();

  // show it in the Popup
  const formatDate = (date) =>
    date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });

  const formattedStartDate = transactionReportingDate[0]?.startDate ? formatDate(new Date(transactionReportingDate[0]?.startDate)) : null;
  const formattedEndDate = formatDate(new Date(transactionReportingDate[0]?.endDate));
  const reportTrnsStartAndEndDate = formattedStartDate === null ? ' ' : `${formattedStartDate} - ${formattedEndDate}`;
  //ends here

  const handleDateFilter = () => {
    setopenPopover(false);
    const formattedStartDate = formatDateToMonthYear(
      transactionReportingDate[0]?.startDate
    );
    const formattedEndDate = formatDateToMonthYear(
      transactionReportingDate[0]?.endDate
    );
    setParams({
      ...params,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };
  const handleKeySearch = (event) => {
    setSearchbyChar(event.target.value);
    if (event.target.value.length > 2) {
      setParams({ ...params, search: event.target.value });
    } else {
      if (event.target.value.length < 1) {
        setParams({ ...params, search: "" });
      }
    }
  };

  const handleDateSelection = () => {
    setopenPopover(true);
  };

  const { SingleValue } = components;
  const SingleOption = (props) => (
    <SingleValue {...props} className="text-capitalize">
      {props.data.label ? (
        <>{props.data.label}</>
      ) : (
        <p className="text-base text-white">Payment Source</p>
      )}
    </SingleValue>
  );
  const DropdownIcon = (props) => {
    return (
      <img
        className={`p-0 transition-all ${props.selectProps.menuIsOpen ? "rotate-180 " : ""
          }`}
        src={images.DropdownIndicatorIcon}
        alt="icon"
      />
    );
  };

  const SingleOptionTransaction = (props) => (
    <SingleValue {...props} className="text-capitalize">
      {props.data.label ? (
        <>{props.data.label}</>
      ) : (
        <p className="text-base text-white">Transaction Type</p>
      )}
    </SingleValue>
  );
  return (
    <>
      <div className="mb-3 py-4 px-6 bg-[#12221A] bg-opacity-30 rounded-lg flex min-h-[100px] items-center ">
        <CustomSearchField onChange={handleKeySearch} value={searchbyChar} />
        <div className="min-w-[185px] relative mr-6">
          <label className={`text-xs text-grey-100    absolute  -top-4`}>
            Transaction Type
          </label>
          <Select
            styles={customStyles}
            components={{
              SingleValue: SingleOptionTransaction,
              DropdownIndicator: DropdownIcon,
            }}
            onChange={(e) => {
              setParams({ ...params, OrderStatusType: e.value });
            }}
            options={transactionConstant.transactionTypeOptions}
            defaultValue={[
              {
                label: "All",
                value: null,
              },
            ]}
          />
        </div>
        <div className="min-w-[181px] relative mr-6 ">
          <label className={`text-xs text-grey-100    absolute  -top-4 `}>
            Payment Source
          </label>
          <Select
            styles={customStyles}
            components={{
              SingleValue: SingleOption,
              DropdownIndicator: DropdownIcon,
            }}
            onChange={(e) => {
              setParams({ ...params, paymentSource: e.value });
            }}
            options={transactionConstant.PaymentSourceOptions}
            defaultValue={[
              {
                label: "All",
                value: null,
              },
            ]}
          />
        </div>

        <CalenderPopup
          handleDateSelection={handleDateSelection}
          reportStartDate={params?.startDate}
          reportEndDate={params?.endDate}
          dateCalenderPopups={reportTrnsStartAndEndDate}
          staticRanges={staticRanges}
          handleCancel={handleCancel}
          handleChange={handleChangeTransactionReport}
          handleUpdate={handleDateFilter}
          openPopover={openPopover}
          range={transactionReportingDate}
          setopenPopover={setopenPopover}
          openReportCalender={true}
        />
      </div>


    </>
  );
};

export default TransactionFilters;
