import React, { useEffect } from "react";
import PreferenceCheckbox from "./ClientPreferenceComponents/PreferenceCheckbox";
import ClientHeaderComponent from "../../../pages/ClientPortal/components/ClientHeaderComponent";
import useClientThemeHook from "../useClientThemeHook";
import { images } from "../../../constants";
import themePreferencesConstants from "../../../constants/client-admin-constants/clientPreferenceConstants/ClientPreferenceConstant";
import { useSelector } from "react-redux";
import Spinner from "../../ui-components/loader-components/Spinner";
import Modal from "../../../common/ModalLayout";
import AlertModalContent from "../../Modals/AlertModalContent";

const ClientPreferencesContent = () => {
  const { clientDetail } = useSelector((state) => state.user);
  const {
    pagePreferences,
    functionaltyPreference,
    handleChangePagePreferences,
    handleChangeFuncPreferences,
    handlePreferences,
    getClientThemeDetails,
    loading,
    setLoading,
    OpenAlertModal,
    setOpenAlertModal,
    alertContent,
    resetLoading,
    setResetLoading,
    handleResetPreference,
    defaultDisablePreferences
  } = useClientThemeHook();

  useEffect(() => {
    getClientThemeDetails();
  }, []);

  const handleCreateUpdatePreferences = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("ClientId", clientDetail?.clientUuid);
    formData.append("ShowAboutUsPage", pagePreferences?.aboutPage);
    formData.append("ShowPrivacyPolicyPage", pagePreferences?.privacyPage);
    formData.append("ShowTermsAndConditionsPage", pagePreferences?.termsPage);
    formData.append("ShowMaintinancePage", pagePreferences?.maintenancePage);
    formData.append(
      "ShowAdditionalDrops",
      functionaltyPreference?.additionalDrop
    );
    formData.append(
      "ShowEmailSubscription",
      functionaltyPreference?.emailSubscription
    );
    formData.append(
      "ShowSocialMediaSharingOption",
      functionaltyPreference?.socialSharing
    );
    formData.append(
      "ShowSocialMediaIcons",
      functionaltyPreference?.socialLinks
    );
    handlePreferences(formData);
  };

  const handleReset = () => {
    setResetLoading(true);
    handleResetPreference();
    let formData = new FormData();
    formData.append("ClientId", clientDetail?.clientUuid);
    handlePreferences(formData);
  };

  return (
    <>
      <ClientHeaderComponent title={"Preferences"} />
      <h3 className="text-xl font-bold mb-1">Pages</h3>
      <p className="text-xs text-[#8D9D91] max-w-[625px] mb-6">
        {themePreferencesConstants.PREFERENCES.PAGE_DESC}
        <img className="inline align-text-bottom" src={images.QuestionIcon} alt='icon' />
        {themePreferencesConstants.PREFERENCES.PAGE_DESC_END}
      </p>
      <div className="max-w-[1044px] flex  flex-wrap  ">
        <PreferenceCheckbox
          title={"Home Page"}
          preferLink={"/preferences/home"}
          preferChecked={pagePreferences.homePage}
          preferHandleChange={handleChangePagePreferences}
          name={"homePage"}
          infothemeImage={images.ThemeHomeImage}
          InfoImageLeftText={"Hero Heading"}
          InfoImageRightText={"Hero Description"}
          InfoImageBottomText={"Hero Description"}
          infoTitle={"Primary Brand Color"}
          infoDesc={themePreferencesConstants?.HOME_PAGE_DESC}
          showtoggle={false}
          defaultDesc={themePreferencesConstants.PREFERENCES.HOME_DESC}
        />
        <PreferenceCheckbox
          title={"Terms and Conditions "}
          preferLink={"/preferences/terms"}
          preferChecked={pagePreferences.termsPage}
          preferHandleChange={handleChangePagePreferences}
          name={"termsPage"}
          infothemeImage={images.ThemeTermImage}
          InfoImageLeftText={"Terms and Conditions Text"}
          infoTitle={"Terms and Conditions "}
          infoDesc={themePreferencesConstants?.TERMS_DESC}
          showtoggle={true}
          defaultDesc={themePreferencesConstants.PREFERENCES.TERM_DESC}
        />
        <PreferenceCheckbox
          title={"About Us"}
          preferLink={"/preferences/about"}
          preferChecked={pagePreferences.aboutPage}
          preferHandleChange={handleChangePagePreferences}
          name={"aboutPage"}
          infothemeImage={images.ThemeAboutImage}
          InfoImageLeftText={"About us Text"}
          infoTitle={"About us"}
          infoDesc={themePreferencesConstants?.ABOUT_US_DESC}
          showtoggle={true}
          defaultDesc={themePreferencesConstants.PREFERENCES.ABOUT_DESC}
        />
        <PreferenceCheckbox
          title={"Maintenance Mode"}
          preferLink={"/preferences/maintenace"}
          preferChecked={pagePreferences.maintenancePage}
          preferHandleChange={handleChangePagePreferences}
          name={"maintenancePage"}
          infothemeImage={images.ThemeMaintenanceImage}
          InfoImageLeftText={""}
          infoTitle={"Maintenance Mode"}
          infoDesc={themePreferencesConstants?.MAINTENANCE_DESC}
          showtoggle={true}
          defaultDesc={themePreferencesConstants.PREFERENCES.MAINTENANCE_DESC}
        />
        <PreferenceCheckbox
          title={"Privacy Policy"}
          preferLink={"/preferences/privacy"}
          preferChecked={pagePreferences.privacyPage}
          preferHandleChange={handleChangePagePreferences}
          name={"privacyPage"}
          infothemeImage={images.ThemePrivacyImage}
          InfoImageLeftText={"Privacy Policy Text"}
          infoTitle={"Privacy Policy"}
          infoDesc={themePreferencesConstants?.PRIVACY_DESC}
          showtoggle={true}
          defaultDesc={themePreferencesConstants.PREFERENCES.PRIVACY_DESC}
        />
      </div>
      <h3 className="text-xl font-bold leading-7 mb-1 mt-2">Features</h3>
      <p className="text-[#8D9D91] text-xs max-w-[625px] mb-6">
        {themePreferencesConstants.PREFERENCES.FEATURE_DESC}
        <img className="inline align-text-bottom" src={images.QuestionIcon} alt='icon' />
        {' '}  icons.
      </p>
      <div className="max-w-[1044px] flex  flex-wrap">
        <PreferenceCheckbox
          title={"Social Media Links"}
          preferLink={"/preferences/socials-media"}
          preferChecked={functionaltyPreference.socialLinks}
          preferHandleChange={handleChangeFuncPreferences}
          name="socialLinks"
          infothemeImage={images.ThemeSocialLinkImage}
          InfoImageRightText={"Social Media Links"}
          infoTitle={"Social Media Links "}
          infoDesc={themePreferencesConstants?.SOCIAL_LINK_DESC}
          showtoggle={true}
          defaultDesc={themePreferencesConstants.PREFERENCES.SOCIAL_DESC}
        />
        <PreferenceCheckbox
          title={"Additional Drops "}
          preferLink={""}
          preferChecked={functionaltyPreference.additionalDrop}
          preferHandleChange={handleChangeFuncPreferences}
          name="additionalDrop"
          infothemeImage={images.ThemeAdditionDropImage}
          InfoImageLeftText={""}
          infoTitle={"Additional Drops "}
          infoDesc={themePreferencesConstants?.ADDITION_DROP_DESC}
          showtoggle={true}
          defaultDesc={themePreferencesConstants.PREFERENCES.ADDITION_DROP_DESC}
        />
        <PreferenceCheckbox
          title={"Email Subscription"}
          preferLink={""}
          preferChecked={functionaltyPreference.emailSubscription}
          preferHandleChange={handleChangeFuncPreferences}
          name="emailSubscription"
          infothemeImage={images.ThemeEmailSubsImage}
          InfoImageLeftText={"Email Subscription"}
          infoTitle={"Email Subscription "}
          infoDesc={themePreferencesConstants?.EMAIL_SUBS_DESC}
          showtoggle={true}
          defaultDesc={themePreferencesConstants.PREFERENCES.EMAIL_DESC}
        />
        <PreferenceCheckbox
          title={"Social Media Sharing "}
          preferLink={""}
          preferChecked={functionaltyPreference.socialSharing}
          preferHandleChange={handleChangeFuncPreferences}
          name="socialSharing"
          infothemeImage={images.ThemeSocialSharingImage}
          InfoImageLeftText={""}
          infoTitle={"Social Media Sharing "}
          infoDesc={themePreferencesConstants?.SOCIAL_SHARING_DESC}
          showtoggle={true}
          defaultDesc={themePreferencesConstants.PREFERENCES.SOCIAL_SHAR_DESC}
        />
      </div>
      <div className="flex justify-between mt-10 mb-[30px] max-w-[494px] ">
        {/** We will implement this is next phase  */}
        <button
          type="button"
          className={`btn-secondary mr-3 `}
          onClick={handleReset}
          disabled={resetLoading || defaultDisablePreferences}
        >
          {resetLoading && <Spinner />}
          Reset
        </button>
        <button
          type="submit"
          className={`btn-primary  `}
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          onClick={handleCreateUpdatePreferences}
          disabled={loading || defaultDisablePreferences}
        >
          {loading && <Spinner />}
          Save Changes
        </button>
      </div>
      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Save_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default ClientPreferencesContent;
