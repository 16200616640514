import { useState } from 'react';
import AddExitingDomain from './AddExitingDomain';
import CustomDomainModal from './CustomDomainModal';

const AddDomainModal = (props) => {
    const { setAddDomainAlertModal, getDomainDetails } = props
    const [step, setStep] = useState(1)
    const saveHandler = () => {
        setAddDomainAlertModal(false)
    }

    return (
        <>
            {step === 1 ?
                <AddExitingDomain getDomainDetails={getDomainDetails} setStep={setStep} />
                : <CustomDomainModal btnClick={saveHandler} btnTitle='Save' stepTwo='Step: 2 of 2' />}
        </>
    )
}

export default AddDomainModal