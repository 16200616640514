import React from "react";
import ClientHeaderComponent from "../../../pages/ClientPortal/components/ClientHeaderComponent";
import ThemeCustomization from "./ThemeCustomization";
import themePreferencesConstants from "../../../constants/client-admin-constants/clientPreferenceConstants/ClientPreferenceConstant";
import { images } from "../../../constants";

const ClientBrandingContent = () => {
  return (
    <>
      <ClientHeaderComponent title={"Branding"} />
      <h1 className="mb-1 text-xl font-bold">Brand Color</h1>
          <p className="text-[#8D9D91] text-xs  mb-6 max-w-[625px]">
            {themePreferencesConstants.BRAND.DESC}
            <img className="inline align-text-bottom" src={images.QuestionIcon} alt='icon' />
            {themePreferencesConstants.BRAND.DESC_AFTER}
          </p>
          <ThemeCustomization />
    </>
  );
};

export default ClientBrandingContent;
