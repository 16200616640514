export const getDocumentType = (value) => {
    switch (true) {
      case value?.split(".").pop().toLowerCase() === "jpeg":
        return "image";
      case value?.split(".").pop().toLowerCase() === "jpg":
        return "image";
      case value?.split(".").pop().toLowerCase() === "gif":
        return "image";
      case value?.split(".").pop().toLowerCase() === "png":
        return "image";
      case value?.split(".").pop().toLowerCase() === "png":
        return "image";
      case value?.split(".").pop().toLowerCase() === "pdf":
        return "pdf";
      case value?.split(".").pop().toLowerCase() === "txt":
        return "txt";
      default:
        return null;
    }
  };
  