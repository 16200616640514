import React, { useState } from "react";
import clientService from "../../../services/client.service";

const InputField = (props) => {
  const { register, name, label, error, type, ...rest } = props;

  return (
    <>
      <div className={`relative z-10`}>
        <input
          {...rest}
          className={`${error && "focus:border-b-red-600 border-b-red-600"
            } peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-white/70 rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-white block w-full p-3 bg-transparent`}
          type={type}
          placeholder=" "
          {...register(name)}
        />

        <label
          className={`text-xs text-grey-100  z-[-1]  absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
        >
          {label}
        </label>
        {error && <p className="mt-1 text-xs text-red-600">{error}</p>}
      </div>
    </>
  );
};

export default InputField;
