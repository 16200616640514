import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { images } from "../../../constants";
import ThemeInfoComponent from "../../ui-components/ThemeInfoComponent/ThemeInfoComponent";
import ColorPickerInputField from "../../ui-components/form-component/ColorPickerInputField";
import themeConstants from "../../../constants/client-admin-constants/clientThemeConstants/ClientThemeConstant";
import Spinner from "../../ui-components/loader-components/Spinner";
import AlertModalContent from "../../Modals/AlertModalContent";
import Modal from "../../../common/ModalLayout";
import { FaviconSchema } from "../../../utils/ValidationSchemas/faviconIcon";
import useClientThemeHook from "../useClientThemeHook";
import themePreferencesConstants from "../../../constants/client-admin-constants/clientPreferenceConstants/ClientPreferenceConstant";


const ThemeCustomization = () => {
  const {
    updateDataFunc,
    loading,
    OpenAlertModal,
    setOpenAlertModal,
    alertContent,
    clientThemeDetail,
    setLoading,
    getClientThemeDetails,
    setthemeColor,
    themeColor,
    faviconFile,
    setFaviconFile,
    resetLoading,
    setResetLoading,
  } = useClientThemeHook();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(FaviconSchema),
    mode: "onChange",
    defaultValues: {
      isFavIconExist: false
    }
  });
  const user = useSelector((state) => state.user);
  const [fileSize, setFileSize] = useState();

  const handleChange = (e) => {
    setValue('isFavIconExist', false)
    setFileSize((e.target.files[0]?.size / 1024).toFixed(2));
    setFaviconFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleRemoveImage = () => {
    setValue('isFavIconExist', false)
    setFaviconFile("");
    reset({ favIconImg: "" });
  };

  useEffect(() => {
    getClientThemeDetails();
  }, []);

  useEffect(() => {
    if (faviconFile && !fileSize) {
      setValue('isFavIconExist', true)
    }
  }, [faviconFile])


  const submitForm = (image) => {
    setLoading(true);
    let themeColorScheme = {
      backgroundColor: themeColor?.bodybackground,
      backgroundPrimaryColor: themeColor?.primarybackground,
      textPrimaryColor: themeColor?.primarytext,
      textBodyColor: themeColor?.bodytext,
      backgroundTertiaryColor: themeColor?.terytiarybackground,
      textTertiaryColor: themeColor?.tertiarytext,
      textSecondaryColor: themeColor?.secondarytext,
    };
    let formData = new FormData();
    formData.append("clientThemeSteps", 5);
    formData.append("ClientThemeUuid", clientThemeDetail?.clientThemeUuid);
    formData.append("ClientId", user?.clientDetail?.clientUuid);
    formData.append("BrandName", user?.clientDetail?.clientName);
    formData.append("ThemeColorJson", JSON.stringify(themeColorScheme));
    formData.append("FaviconImage", image);
    updateDataFunc(formData);
  };

  const handleReset = () => {
    setResetLoading(true);
    let themeColorScheme = {
      backgroundColor: '#060C07',
      backgroundPrimaryColor: '#A5F33C',
      textPrimaryColor: '#12221A',
      textBodyColor: '#FFFFFF',
      backgroundTertiaryColor: '#12221A',
      textTertiaryColor: '#FFFFFF',
      textSecondaryColor: '#8D9D91',
    };

    let formData = new FormData();
    formData.append("clientThemeSteps", 5);
    formData.append(
      "ClientThemeUuid",
      clientThemeDetail?.clientThemeUuid);
    formData.append("ClientId", user?.clientDetail?.clientUuid);
    formData.append("BrandName", user?.clientDetail?.clientName);
    formData.append("ThemeColorJson", JSON.stringify(themeColorScheme));

    updateDataFunc(formData);
    setthemeColor({
      ...themeColor,
      primarybackground: "#A5F33C",
      primarytext: "#12221A",
      bodybackground: "#060C07",
      bodytext: "#FFFFFF",
      terytiarybackground: "#12221A",
      tertiarytext: "#FFFFFF",
      secondarytext: "#8D9D91",
    });

  };

  const faviconSubmit = (values) => {
    if (values.favIconImg[0]) {
      submitForm(values.favIconImg[0]);
    } else {
      submitForm(faviconFile);
    }

  };

  const handleThemeColorChange = (event) => {
    const { name, value } = event.target;
    setthemeColor({ ...themeColor, [name]: value });
  };

  return (
    <>
      <div className="">
        <div className="grid grid-cols-3 gap-8 ">
          <div className="p-4 border border-[#A4B1A7]/[.12] rounded-lg bg-[#12221A]/30 ">
            <div className="flex justify-between items-center mb-3">
              <span className="text-base text-white font-bold">
                Primary Brand Color
              </span>
              <ThemeInfoComponent
                themeImage={images.ThemePrimaryColorImage}
                backgroundText={"Primary Color"}
                colorText={"Button Text Color"}
                title={"Primary Brand Color"}
                desc={themeConstants.PRIMARY_COLOR_DESC}
                sm={true}
              />
            </div>
            <p className="mb-1 text-grey-100 text-xs">Primary Color</p>
            <ColorPickerInputField
              value={themeColor?.primarybackground}
              handleChange={handleThemeColorChange}
              name="primarybackground"
            />
            <p className="mb-1 mt-4 text-grey-100 text-xs">Button Text Color</p>
            <ColorPickerInputField
              value={themeColor?.primarytext}
              handleChange={handleThemeColorChange}
              name="primarytext"
            />
          </div>

          <div className="p-4 border border-[#A4B1A7]/[.12] rounded-lg bg-[#12221A]/30 ">
            <div className="flex justify-between items-center mb-3">
              <span className="text-base text-white font-bold">Body Color</span>
              <ThemeInfoComponent
                themeImage={images.ThemebodyPage}
                backgroundText={"Background Color"}
                colorText={"Body Text Color"}
                title={"Body Color"}
                desc={themeConstants.BODY_COLOR_DESC}
                sm={true}
              />
            </div>
            <p className="mb-1 text-grey-100 text-xs">Background Color</p>
            <ColorPickerInputField
              value={themeColor.bodybackground}
              handleChange={handleThemeColorChange}
              name="bodybackground"
            />
            <p className="mb-1 mt-4 text-grey-100 text-xs">Body Text Color</p>
            <ColorPickerInputField
              value={themeColor.bodytext}
              handleChange={handleThemeColorChange}
              name="bodytext"
            />
          </div>

          <div className="p-4 border border-[#A4B1A7]/[.12] rounded-lg bg-[#12221A]/30 ">
            <div className="flex justify-between items-center mb-3">
              <span className="text-base text-white font-bold">
                Footer Color
              </span>
              <ThemeInfoComponent
                themeImage={images.ThemeFooterImage}
                backgroundText={"Footer Color"}
                colorText={"Text Color"}
                title={"Footer Color"}
                desc={themeConstants.FOOTER_COLOR_DESC}
                sm={true}
              />
            </div>
            <p className="mb-1 text-grey-100 text-xs">Background Color</p>
            <ColorPickerInputField
              value={themeColor.terytiarybackground}
              handleChange={handleThemeColorChange}
              name="terytiarybackground"
            />
            <p className="mb-1 mt-4 text-grey-100 text-xs">Text Color</p>
            <ColorPickerInputField
              value={themeColor.tertiarytext}
              handleChange={handleThemeColorChange}
              name="tertiarytext"
            />
          </div>
          <div className="p-4 border border-[#A4B1A7]/[.12] rounded-lg bg-[#12221A]/30 ">
            <div className="flex justify-between items-center mb-3">
              <span className="text-base text-white font-bold">
                Secondary Color
              </span>
              <ThemeInfoComponent
                themeImage={images.ThemeSecondaryColorImage}
                backgroundText={"Paragraph Color"}
                colorText={""}
                title={"Secondary Color"}
                desc={themeConstants.SECONDARY_COLOR_DESC}
                sm={true}
              />
            </div>
            <p className="mb-1 text-grey-100 text-xs">Paragraph Color</p>
            <ColorPickerInputField
              value={themeColor.secondarytext}
              handleChange={handleThemeColorChange}
              name="secondarytext"
            />
          </div>
        </div>
        <div className="mt-8">
          <h1 className="text-xl font-bold mb-1">Favicon</h1>
          <p className="text-xs font-normal leading-4 text-grey-100 ">
            {themePreferencesConstants.BRAND.FAVICON_DESC}
          </p>
          {/* File Uploader */}
          <form action="" onSubmit={handleSubmit(faviconSubmit)}>
            <label
              htmlFor="inputFileSelector2"
              className=" cursor-pointer flex w-1/2"
            >
              {/* Image Selector */}
              <input
                hidden={true}
                id="inputFileSelector2"
                accept={".jpg,.jpeg,.png"}
                type="file"
                name="favIconImg"
                // className={inputFieldStyle}
                {...register("favIconImg", {
                  onChange: (e) => {
                    handleChange(e);
                  },
                })}
              />
              <div className="flex items-center justify-between text-white font-poppins border border-[#A4B1A7] p-4  border-solid rounded-xl w-full max-w-[630px] min-h-[96px] h-full mt-6">
                <div className="flex items-center justify-start flex-grow-1">
                  <img className="mr-3" src={images.UploadIcon} alt="" />
                  <div className="flex flex-col">
                    <h4 className="text-base font-bold">Upload Favicon</h4>
                    <p className="mb-2 text-xs"> Browse File</p>
                    <p className="text-xs text-grey-100">
                      PNG,JPG,JPEG | Max file size: 5 MB (16×16 px)
                    </p>
                  </div>
                </div>

                <p className="flex-shrink text-base font-bold text-center text-[#A5F33C] ">
                  Browse
                </p>
              </div>
            </label>
            {/* Image Displayer */}
            <div className="w-3/6">
              {faviconFile && (
                <div className="flex justify-between items-center text-xs mt-4 flex-grow py-3 px-4 rounded-xl bg-transparent border border-[#A4B1A7] min-w-[80px] max-w-[630px]">
                  <div className="flex items-center">
                    <img
                      className="object-contain mr-2 w-7 h-7"
                      src={faviconFile || getValues("favIconImg")}
                      alt="logo"
                    />
                    <span className="w-full p-0 font-bold bg-transparent border-none focus:ring-transparent">
                      Favicon
                    </span>
                  </div>
                  {fileSize && (
                    <span className="text-grey-100">{fileSize + "kb"}</span>
                  )}
                  <img
                    className="cursor-pointer hover:opacity-80"
                    src={images.cross_icon}
                    alt="X"
                    onClick={handleRemoveImage}
                  />
                </div>
              )}
              <p className="mt-1 text-xs text-red-600">
                {errors?.favIconImg?.message}
              </p>
            </div>
            <div className="flex mt-10 mb-[30px] max-w-[494px]  ">
              <button
                type="submit"
                className={`btn-secondary mr-3 disabled:cursor-not-allowed  `}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                onClick={handleReset}
                disabled={resetLoading}
              >
                {resetLoading && <Spinner />}
                Reset
              </button>
              <button
                type="submit"
                className={`btn-primary   disabled:cursor-not-allowed  `}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                disabled={loading}
              >
                {loading && <Spinner />}
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Save_Modal"
          isClose={true}
        />
      )}
    </>
  );
};
export default ThemeCustomization;
