import { Menu, MenuHandler, MenuList } from "@material-tailwind/react";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import script from "../../../../blockchain-scripts/BlockchainScripts";
import Transactions from "../../../../Blockchain-transactions/BlockchainTransactions";
import Modal from "../../../../common/ModalLayout";
import BasicTable from "../../../../components/BasicTable";
import AlertModalContent from "../../../../components/Modals/AlertModalContent";
import InvalidWalletContent from "../../../../components/Modals/InvalidWalletContent";
import SearchFilter from "../../../../components/SearchFilter";
import { images } from "../../../../constants";
import BlockChainErrorsHandler from "../../../../helpers/BlockChainErrorsHandler";
import BlockchainScriptHook from "../../../../helpers/custom-hooks/BlockchainScriptHook";
import MatchApproveAndConnectWalletHook from "../../../../helpers/custom-hooks/MatchApproveAndConnectWalletHook";
import { setLoader } from "../../../../redux/features/loading/loaderSlice";
import DropService from "../../../../services/drop.services";
import ClientHeaderComponent from "../../components/ClientHeaderComponent";
import { COLUMNS } from "./dropColumn";

const DropContent = (props) => {
  const {
    dropListData,
    createDropHandler,
    clientWalletAddress,
    setUpdateDropList,
    blockChainBrandId,
    clientUuid
  } = props;
  const dispatch = useDispatch();
  const [OpenAlertModal, setOpenAlertModal] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: "",
    description: "",
    path: "",
  });
  const [openInvalidWalletModal, setOpenInvalidWalletModal] = useState(false);

  const { matchConnectedWallet, disconnetWallet } =
    MatchApproveAndConnectWalletHook();
  const tableHooks = (hooks) => {
    const { getBlockChainScriptFromDB } = BlockchainScriptHook();
    const deleteDropHandler = async (DropUuid, dropId, dropStatus) => {
      dispatch(setLoader(true));
      if (dropStatus) {
        const matchWalletResponse = await matchConnectedWallet(
          clientWalletAddress
        );
        if (matchWalletResponse) {
          const modal = { scriptName: 10 };
          const deleteScript = await getBlockChainScriptFromDB(modal);
          const executeTx = await Transactions.ExecuteTransactionsFunction(
            script.deleteDropScript(deleteScript, dropId, blockChainBrandId)
          );
          if (!executeTx?.error) {
            let modal = {
              DropUuid: DropUuid,
              BCTransactionId: executeTx?.message?.txId,
              clientId:clientUuid
            };
            await deleteDropFromDB(modal);
          } else {
            dispatch(setLoader(false));
            setOpenAlertModal(true);
            let defaultErrMsg = "Failed to delete drop from BlockChain ";
            let errDescription = BlockChainErrorsHandler(
              executeTx,
              defaultErrMsg
            );
            setAlertContent({
              title: "Error",
              description: errDescription,
            });
          }
        } else {
          if (matchWalletResponse === null) {
            dispatch(setLoader(false));
          } else {
            dispatch(setLoader(false));
            setOpenInvalidWalletModal(true);

            await disconnetWallet();
          }
        }
      } else {
        let modal = {
          DropUuid: DropUuid,
          BCTransactionId: "",
        };
        await deleteDropFromDB(modal);
      }
    };
    const deleteDropFromDB = async (modal) => {
      DropService.deleteDropFromDB(modal)
        .then((result) => {
          dispatch(setLoader(false));
          setUpdateDropList(true);
          if (result?.data?.payload?.deleted) {
            setOpenAlertModal(true);
            setAlertContent({
              title: "Success",
              description: "Drop Deleted Successfully ",
            });
          }
        })
        .catch((error) => {
          dispatch(setLoader(false));
          setOpenAlertModal(true);
          setAlertContent({
            title: "Error",
            description:
              error?.response.data?.errors?.BCTransactionId?.[0] ??
              "Failed to delete drop from DB ",
          });
          setUpdateDropList(true);
        });
    };
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            {" "}
            Actions
          </div>
        ),
        accessor: "Action",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="">
            <div className="flex justify-center">
              <Menu placement="bottom-end">
                <MenuHandler>
                  <img
                    className="mr-1 text-center cursor-pointer hover:opacity-90 "
                    src={images.more_icon}
                    alt="icon"
                  />
                </MenuHandler>
                <MenuList className="bg-[#0A130D] flex flex-col min-w-[115px]  py-4 px-0 z-20 border rounded-lg border-[#A4B1A7]/[0.12]">
                  <Link
                    to={"/create-drop"}
                    state={{
                      uuid: row?.original?.uuid,
                      isview: true,
                      isEdit: false,
                      templateId: row?.original?.blockChainTemplateId,
                    }}
                    className="inline-block  font-normal  px-4 py-1  text-base text-white  focus-visible:bg-[#12221A]/80 font-manrope active:bg-[#12221A]/80 active:text-white hover:bg-[#12221A]/80 hover:text-[#FFFFFF] outline-0"
                  >
                    View
                  </Link>
                  <Link
                    to={"/create-drop"}
                    state={{
                      uuid: row?.original?.uuid,
                      isview: false,
                      isEdit: true,
                      templateId: row?.original?.blockChainTemplateId,
                    }}
                    className="inline-block  font-normal  px-4 py-1 mt-6 text-base text-white  focus-visible:bg-[#12221A]/80 font-manrope active:bg-[#12221A]/80 active:text-white hover:bg-[#12221A]/80 hover:text-[#FFFFFF] outline-0"
                  >
                    Update
                  </Link>
                  <Link
                    onClick={() =>
                      deleteDropHandler(
                        row?.original?.uuid,
                        row?.original?.id,
                        row?.original?.b_C_Status
                      )
                    }
                    className="inline-block  font-normal  px-4 py-1  mt-6  text-base text-white  focus-visible:bg-[#12221A]/80 font-manrope active:bg-[#12221A]/80 active:text-white hover:bg-[#12221A]/80 hover:text-[#FFFFFF] outline-0"
                  >
                    Delete
                  </Link>
                </MenuList>
              </Menu>
            </div>
          </div>
        ),
      },
    ]);
  };

  // React table suggests to memoize the columns and data for avoiding the re creation of data on every render
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => dropListData, [dropListData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    gotoPage,
    pageCount,
    state, // For filtering
    setGlobalFilter, // For filtering
  } = useTable(
    {
      // ES6 shorthand notation having same key value name
      columns,
      data,
    },

    useGlobalFilter,
    useSortBy,
    usePagination,
    tableHooks
  );
  const { globalFilter, pageIndex } = state;
  return (
    <>
      <ClientHeaderComponent title={"Drop List"} />
      <div className="flex items-center justify-between mb-10">
        <div className="mr-8 w-full">
          <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
        <button
          type="button"
          data-modal-toggle="Add_Modal"
          className="max-w-[202px] px-8 py-3 btn-primary  rounded-full"
          onClick={createDropHandler}
        >
          Create Drop
        </button>
      </div>
      {/**  Drop listing table  */}
      <div>
        <BasicTable
          columns={columns}
          data={data}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          prepareRow={prepareRow}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          pageCount={pageCount}
          rows={rows}
          defaultMessage={
            "You do not have any drops created at the moment. Click on Create Drop to attach your assets and present on your storefront. "
          }
          icon={images.DropNotfoundIcon}
        />
      </div>

      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Add_Modal"
          isClose={true}
        />
      )}
      {openInvalidWalletModal && (
        <Modal
          modalVisible={openInvalidWalletModal}
          closeModal={setOpenInvalidWalletModal}
          children={<InvalidWalletContent />}
          modal_Id="Delete_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default DropContent;
