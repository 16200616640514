import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../../../redux/features/loading/loaderSlice";
import DropService from "../../../../../services/drop.services";
import WhitelistFileUploader from "./WhitelistAndClaim/WhitelistFileUploader";
import { images } from "../../../../../constants";

const AddWhitelistSelectionOption = (props) => {
  const {
    components,
    getValues,
    Controller,
    control,
    Select,
    customStyles,
    register,
    errors,
    watch,
    reset,
    setSelectedWhitelistOption,
    selectedWhitelistOption,
    airdropUserList,
    setAirdropUserList,
    viewEditData,
    dropDetailData,
    setValue,
  } = props;

  const fileInputRef = useRef();

  const { clientUuid } = useSelector((state) => state.user.clientDetail);

  const dispatch = useDispatch();
  const { SingleValue } = components;
  const SingleOption = (props) => (
    <SingleValue {...props} className="shadow-none text-capitalize">
      {props.data.value ? (
        <>
          <div className="relative">{props.data.label}</div>
        </>
      ) : (
        <p className="text-base text-white">Whitelist Options</p>
      )}
    </SingleValue>
  );
  const DropdownIcon = (props) => {
    return (
      <img
        className={`p-0 transition-all ${
          props.selectProps.menuIsOpen ? "rotate-180 " : ""
        }`}
        src={images.DropdownIndicatorIcon}
        alt="icon"
      />
    );
  };

  const checkAddAirDropFile = watch("addAirDropFile");

  useEffect(() => {
    fetchAirdropUserList();
    // return () => {
    // 	second;
    // };
  }, []);

  const fetchAirdropUserList = async () => {
    dispatch(setLoader(true));
    DropService.getAirdropUserList(clientUuid)
      .then((result) => {
        dispatch(setLoader(false));
        if (result?.data?.payload?.length > 0) {
          let options = result?.data?.payload.map((item) => {
            return { label: item.userListName, value: item.id };
          });
          setAirdropUserList(options);

          if (viewEditData?.isEdit) {
            if (dropDetailData?.whitelistOptions?.value) {
              const whitelistlabel = options?.filter(
                (item) =>
                  item?.value === dropDetailData?.whitelistOptions?.value
              );
              if (whitelistlabel?.length > 0) {
                setValue("whitelistOptions", {
                  value: whitelistlabel[0]?.value,
                  label: whitelistlabel[0]?.label,
                });
              }
            }
          }
        }
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };

  // const hideShowFileUploadHandler = () => {
  // 	setIsSelectFile(!isSelectFile);
  // };

  return (
    <div className="max-w-[494px] pb-10">
      <div className="py-4 pt-0 bg-[#12221A]/30 border-[#A4B1A7]/[.12] form-group border rounded-lg">
        <div className="text-xs flex justify-between px-3 py-4 bg-[#12221A] rounded-lg rounded-b-[0px]">
          <span>Click here to download your template</span>
          <span className="cursor-pointer hover:text-[#a4f33cc9] text-[#A5F33C] font-bold">
            <a
              target="_blank"
              href={
                "https://d1b6upo1s7pshq.cloudfront.net/bloomly-prod/SampleFile.xlsx"
              }
            >
              Download
            </a>
          </span>
        </div>
        <div className="px-4 pt-4 content">
          <>
            {airdropUserList?.length > 0 && (
              <>
                {/* User list Dropdown */}
                {!checkAddAirDropFile && (
                  <div>
                    <p className="text-xs text-grey-100">Whitelist File</p>
                    <div className="relative my-2 ring-0 ring-offset-0">
                      {getValues("whitelistOptions")?.value && (
                        <label
                          className={`text-xs text-grey-100 absolute -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                        ></label>
                      )}

                      <Controller
                        name="whitelistOptions"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            styles={customStyles}
                            components={{
                              SingleValue: SingleOption,
                              DropdownIndicator: DropdownIcon,
                            }}
                            onChange={(e) => {
                              // crossImgClickHandler();

                              field.onChange({
                                label: e.label,
                                value: e.value,
                              });
                              const selectedOption = e.value;
                              setSelectedWhitelistOption(e);
                              // setSelectedSchemaType(selectedSchemaVal);
                            }}
                            options={airdropUserList}
                          />
                        )}
                      />
                      <p className="mt-2 text-xs text-red-600">
                        {errors?.whitelistOptions?.value?.message}
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Input Field incase of new file */}
            {checkAddAirDropFile && (
              <div className="relative z-10 mb-3">
                <input
                  type="text"
                  className={
                    "peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent"
                  }
                  placeholder=" "
                  {...register("whitelistName")}
                />
                <label
                  className={`text-xs text-grey-100 z-[-1]   absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                >
                  Enter Whitelist Name
                </label>
                <p className="mt-2 text-xs text-red-600">
                  {errors?.whitelistName?.message}
                </p>
              </div>
            )}
            {/* Add New FIle Checkbox */}
            <div class="flex items-center ">
              <input
                id="addAirDropFile"
                type="checkbox"
                {...register("addAirDropFile")}
                className="float-left w-5 h-5    transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border-2 border-[#A4B1A7] rounded-[4px] appearance-none cursor-pointer form-check-input checked:bg-[#A5F33C] checked:border-[#A5F33C] checked:bg-[url('/src/assets/images/icons/checkedIconAuth.svg')]   focus:shadow-none focus:outline-none focus:ring-0 focus:ring-offset-0 "
              />
              <label
                for="link-checkbox"
                class="ml-2 text-sm font-medium text-white"
              >
                Add New FIle
              </label>
            </div>
          </>
          {/* FILE UPLOADER FOR WHITELIST USERS */}
          {checkAddAirDropFile && (
            <div className="mt-6">
            <WhitelistFileUploader
              fileInputRef={fileInputRef}
              errors={errors}
              register={register}
              watch={watch}
              reset={reset}
              getValues={getValues}
              setSelectedWhitelistOption={setSelectedWhitelistOption}
            />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddWhitelistSelectionOption;
