import React, { useState } from "react";
import { createStaticRanges } from "react-date-range";

import {
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  differenceInMonths,
} from "date-fns";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfCurrentMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  lastMonth: startOfMonth(new Date() - 1),
  lastTwoMonths: startOfMonth(addMonths(new Date(), -1)),
  lastTwoMonthsEnd: endOfMonth(addMonths(new Date(), -1)),
  lastThreeMonths: startOfMonth(addMonths(new Date(), -2)),
  lastFourMonth: startOfMonth(addMonths(new Date(), -3)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  currentDay: new Date(),
};

const initialbarDate = [
  {
    startDate: defineds.lastThreeMonths,
    endDate: new Date(),
    key: "selection",
    isChanged: false,
  }
];
// Initial States
const intitalDonughtDate = [
  {
    startDate: defineds.startOfCurrentMonth,
    endDate: new Date(),
    key: "selection",
    isChanged: false,
  }
];
const initialTransactionReport = [
  {
    startDate: defineds.startOfCurrentMonth,
    endDate: new Date(),
    key: "selection",
    isChanged: false,
  }
];

const UseDatePicker = () => {
  const [barDateState, setBarDateState] = useState(initialbarDate);
  const [monthsGap, setMonthsGap] = useState(0)
  const [openPopover, setopenPopover] = useState(false)
  const [donughtStartDate, setDonughtStartDate] = useState(intitalDonughtDate);
  const [transactionReportingDate, setTransactionReportingDate] = useState(initialTransactionReport);

  const sideBarOptions = () => {
    const customDateObjects = [
      {
        label: "This Month",
        range: () => ({
          startDate: defineds.lastMonth,
          endDate: defineds.currentDay,
        }),
      },
      {
        label: "Last Month",
        range: () => ({
          startDate: defineds.lastTwoMonths,
          endDate: defineds.lastTwoMonthsEnd,
        }),
      },
      {
        label: "Last 2 Month",
        range: () => ({
          startDate: defineds.lastThreeMonths,
          endDate: defineds.lastTwoMonthsEnd,
        }),
      },
      // {
      //   label: "3 Months",
      //   range: () => ({
      //     startDate: defineds.lastFourMonth,
      //     endDate: defineds.lastTwoMonthsEnd,
      //   }),
      // },
      {
        label: "Custom",
        range: () => ({
          startDate: null,
          endDate: new Date(""),
        }),
        onclick: (event) => {
         

        }
      },
    ];
    return customDateObjects;
  };

  const sideBar = sideBarOptions();

  const staticRanges = [
    ...createStaticRanges(sideBar),
  ];

  const formatDateToMonthYear = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dateObject = new Date(dateString);
    const month = months[dateObject.getMonth()];
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    return `${month} ${day}  ${year}`;
  };

  const handleChangeDonught = (item) => {
    setDonughtStartDate([item.selection])
  }
  const handleChangeTransactionReport = (item) => {
    setTransactionReportingDate([item.selection])

  }
  const handleChangeBar = (item) => {
    const selectedStart = item.selection.startDate;
    const selectedEnd = item.selection.endDate;
    const diffInMonths = differenceInMonths(selectedEnd, selectedStart);
    if (diffInMonths > 2) {
      setMonthsGap(diffInMonths)

    } else {
      setMonthsGap(0)
      setBarDateState([item.selection])
    }
  }
  const handleCancel = () => {
    setBarDateState(initialbarDate);
    setDonughtStartDate(intitalDonughtDate);
    setTransactionReportingDate(initialTransactionReport);
    setopenPopover(false)
  };

  return {
    sideBarOptions,
    staticRanges,
    sideBarOptions,
    openPopover,
    setopenPopover,
    formatDateToMonthYear,
    donughtStartDate,
    handleChangeTransactionReport,
    handleCancel,
    transactionReportingDate,
    monthsGap,
    setMonthsGap,
    barDateState,
    handleChangeDonught,
    handleChangeBar
  };
};

export default UseDatePicker;
