import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import BasicTable from "../../../../components/BasicTable";
import { COLUMNS } from "../../../../components/ClientReporting/TransactionReporting/TransactionColumn";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Menu, MenuHandler, MenuList } from "@material-tailwind/react";
import { images } from "./../../../../constants/images";
import Modal from "../../../../common/ModalLayout";
import MintTransferModal from "../../../../components/Modals/MintTransferModal";
import StatsKeyValue from "../../../../components/ClientReporting/TransactionReporting/ClientReportingComponents/StatsKeyValue";
import TransactionFilters from "../../../../components/ClientReporting/TransactionReporting/ClientReportingComponents/TransactionFilters";
import { retryMintTransfer } from "../../../../redux/features/user/userSlice";
import { useDispatch } from "react-redux";

const TransactionListing = (props) => {
  const dispatch = useDispatch();
  const {
    transactionRecords,
    params,
    setParams,
    SummaryData,
    getTransactionDetailReport,
  } = props;

  const [openProcessModal, setOpenProcessModal] = useState(false);
  const [mintTransferData, setMintTransferData] = useState();

  // React table suggests to memoize the columns and data for avoiding the re creation of data on every render

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => transactionRecords, [transactionRecords]);

  const handleMintTransferModal = (data) => {
    setMintTransferData(data);
    setOpenProcessModal(true);
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            {" "}
            Actions
          </div>
        ),
        accessor: "Action",
        disableSortBy: true,
        Cell: ({ row }) => (

          (
            <div className="flex justify-center">
              <Menu className="" placement="bottom-end">
                <MenuHandler className="">
                  <img
                    className="mr-1 text-center cursor-pointer hover:opacity-90 "
                    src={images.more_icon}
                    alt="icon"
                  />
                </MenuHandler>
                <MenuList className="bg-[#0A130D] flex flex-col min-w-[115px] py-4 px-0  z-20 border rounded-lg border-[#A4B1A7]/[0.12]">
                  <Link
                    to={`/tx-detail`}
                    state={{ data: row.values }}
                    className={`drop-menu-link
                     `}
                    onClick={() => {
                      dispatch(retryMintTransfer(false));
                    }}
                  >
                    View
                  </Link>
                  <Link
                    onClick={() => handleMintTransferModal(row.values)}
                    className={`drop-menu-link mt-6 ${row?.values?.orderStatus === "Mint Transfer Failed"
                        ? "block"
                        : "hidden"
                      }`}
                  >
                    Mint and Transfer
                  </Link>
                </MenuList>
              </Menu>
            </div>
          )
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    gotoPage,
    pageCount,
    state, // For filtering
    setGlobalFilter, // For filtering
  } = useTable(
    {
      // ES6 shorthand notation having same key value name
      columns,
      data,
      initialState: {
        hiddenColumns: [
          "orderNumber",
          "description",
          "orderId",
          "dropUuId",
          "paymentResponseJson",
          "transactionHash",
          "userWalletAddress",
          "emailAddress",
          "isTransferAble"
        ],
      },
    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex } = state;

  return (
    <>
      <TransactionFilters params={params} setParams={setParams} />
      {/** statistics start  */}
      <div className="flex py-3 mb-2">
        <StatsKeyValue
          keytext={"Total Sales:"}
          value={
            SummaryData?.totalPriceUsd && SummaryData?.platformFee
              ? "$" + (SummaryData?.platformFee + SummaryData?.totalPriceUsd)
              : "$0"
          }
          style={"bg-[#A5F33C]"}
          line={false}
        />
        <StatsKeyValue
          keytext={"Net Sales:"}
          value={`$${SummaryData?.totalPriceUsd ? SummaryData?.totalPriceUsd : "0"
            } `}
          style={"bg-[#4F80FD] ml-10"}
          line={true}
        />
        <StatsKeyValue
          keytext={"Platform Fee:"}
          value={`$${SummaryData?.platformFee ? SummaryData?.platformFee : "0"
            }`}
          style={"bg-[#E7E15A] ml-10"}
          line={true}
        />
      </div>
      {/** statistics end  */}

      <div className="">
        <BasicTable
          columns={columns}
          data={data}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          prepareRow={prepareRow}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          pageCount={pageCount}
          rows={rows}
          reportingRowLink={true}
          defaultMessage={
            "You do not have any transactions history at the moment."
          }
          icon={images.RecordNotFoundIcon}
        />
      </div>
      {openProcessModal && (
        <Modal
          modalVisible={openProcessModal}
          closeModal={setOpenProcessModal}
          children={
            <MintTransferModal
              data={mintTransferData}
              openProcessModal={openProcessModal}
              setOpenProcessModal={setOpenProcessModal}
              getTransactionDetailReport={getTransactionDetailReport}
            />
          }
          modal_Id="Save_Modal"
          isClose={false}
        />
      )}
    </>
  );
};

export default TransactionListing;