import React, { useEffect } from "react";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const ColumnChart = ({ dropPerformanceData }) => {

  let dropPerformanceList = dropPerformanceData?.saleDropPerformanceReport?.map(
    (i) => ({
      month: new Date(i.saleDate).toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      }),
      value: i.totalNoOfSales,
    })
  );

  let airDropPerformanceList = dropPerformanceData?.airDropPerformanceReport?.map(
    (i) => ({
      month: new Date(i.claimDate).toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      }),
      value: i.totalNoOfClaims,
    })
  );

  const combinedData = {};

  // Map dropPerformanceList to combinedData
  dropPerformanceList?.forEach((item) => {
    const { month, value } = item;
    if (!combinedData[month]) combinedData[month] = { drops: 0, airdrops: 0 };
    combinedData[month].drops = value;
  });

  // Map airDropPerformanceList to combinedData
  airDropPerformanceList?.forEach((item) => {
    const { month, value } = item;
    if (!combinedData[month]) combinedData[month] = { drops: 0, airdrops: 0 };
    combinedData[month].airdrops = value;
  });

  // Get xAxisCategories and data for the chart
  const xAxisCategories = Object?.keys(combinedData);

  const data = {
    labels: xAxisCategories,
    datasets: [
      {
        label: "Drops",
        data: xAxisCategories.map((month) => combinedData[month].drops),
        backgroundColor: "#008FFB",
        hoverBackgroundColor: "#008FFB",
        barThickness: 35,
        maxBarThickness: 30,
        borderWidth: 1,
        // barPercentage: 0.9,
        categoryPercentage: 0.3,
        borderColor: '#000',
        borderRadius: [30, 30],
        borderSkipped: false,
        pointStyle: "rect",
      },
      {
        label: "Airdrops",
        data: xAxisCategories.map((month) => combinedData[month].airdrops),
        backgroundColor: "#8d8ff4",
        hoverBackgroundColor: "#8d8ff4",
        barThickness: 35,
        maxBarThickness: 30,
        borderWidth: 1,
        // barPercentage: 0.9,
        categoryPercentage: 0.3,
        borderRadius: [30, 30],
        borderSkipped: false,
        pointStyle: "rect",
      },
    ],
  };
  const plugin = {
    beforeInit: function (chart) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 20;
      };
    },
  };

  const options = {
    colors: ["#008FFB", "#8d8ff4"],

    plugins: {
      legend: {
        position: "top",
        align: "start",
        display: false,
        labels: {
          usePointStyle: true,
          color: '#8D9D91',
          borderRadius: 10,
          textAlign: 'left'
        },
        padding: {
          bottom: 20,
        },
        boxWidth: 30,
        borderRadius: 30
      },

      tooltip: {
        caretSize: 0,
        borderColor: 'rgba(164, 177, 167, 0.12)',
        borderWidth: 1,
        backgroundColor: "#0A130D",
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || "";
            let value = context.parsed.y || 0;

            // Add custom tooltip for Drops dataset
            if (label === "Drops") {
              const index = context.dataIndex;
              const totalNoOfSales = dropPerformanceData?.saleDropPerformanceReport[index]?.totalNoOfSales;
              const totalPriceUsd =
                dropPerformanceData?.saleDropPerformanceReport[index]
                  ?.totalPriceUsd;
              const noOFDrops =
                dropPerformanceData?.saleDropPerformanceReport[index]
                  ?.totalNoOfDrops;

              return [
                ` Drops`,
                ` No. Of Drops: ${noOFDrops}`,
                ` No. Of Sales: ${totalNoOfSales}`,
                ` Sales in USD: $${totalPriceUsd}`,
              ];
            }

            // Add custom tooltip for AirDrops dataset
            if (label === "Airdrops") {
              const index = context.dataIndex;
              // No. Of Air Drops && No. Of Claims
              const noOfAirDrops = dropPerformanceData?.airDropPerformanceReport?.map((i) => i?.totalNoOfAirDrops)
              const noOfAirDropsClaims = dropPerformanceData?.airDropPerformanceReport?.map((i) => i?.totalNoOfClaims
              )

              return [
                ` AirDrop`,
                ` No. Of Air Drops: ${noOfAirDrops}`,
                ` No. Of Claims: ${noOfAirDropsClaims}`,
              ];
            }

            // For other datasets, show the regular tooltip
            return `${label}: ${value}`;
          },
        },
      },
    },
    elements: {
      bar: {
        borderSkipped: "start",
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },

        ticks: {
          color: "#fff",

        },
      },
      y: {
        grid: {
          color: "#8D9D91",
        },
        ticks: {
          color: "#fff",
          maxTicksLimit: 6,
          stepSize: (dropPerformanceList?.length === 0 && airDropPerformanceList?.length === 0) ? 0 : 1,
          callback: function (value, index) {
            if (!isNaN(value)) {
              const hasNonZeroDrops = dropPerformanceList?.some((val) => val !== 0);
              const hasNonZeroAirdrops = airDropPerformanceList?.some((val) => val !== 0);
              if (hasNonZeroDrops || hasNonZeroAirdrops) {
                return value >= 10 ? value : Math.floor(value);
              }
            }
            return "";
          },
        },
        border: {
          dash: [6, 6],
          borderColor: '#000'
        },
      },
    },
  };
  return (
    <>
      <div className="flex justify-between items-center pt-2 w-1/4">
        <div className="flex justify-center items-center  mr-3">
          <p className="w-3 rounded-sm h-3 mr-2 bg-[#4F80FD]"></p>
          <p className="text-xs font-normal text-[#8D9D91] ">
            Drops
          </p>
        </div>
        <div className="flex justify-center items-center">
          <p className="w-3 rounded-sm h-3 mr-2 bg-[#8D8FF4]"></p>
          <p className="text-xs font-normal text-[#8D9D91]">
            Airdrops
          </p>
        </div>
      </div>
      <div className="border  border-[#a4b1a7]/[.12] mt-4 mb-3">

      </div >
      <Bar
        data={data}
        options={options}
        height={130}
        width={120}
        plugins={[plugin]}
        style={{ maxHeight: "284px" }}
      />
    </>
  );
};

export default ColumnChart;
