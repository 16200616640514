import React from 'react'
import ClientBrandingContent from '../../../components/ClientTheme/ClientBranding/ClientBrandingContent'

const ClientBranding = () => {
  return (
    <>
    <ClientBrandingContent />
    </>
  )
}

export default ClientBranding