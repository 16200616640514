import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { images } from "../../../constants";
import { CategorySchema } from "../../../utils/ValidationSchemas/AddCategorySchema";
import SelectedImageComponent from "../../../components/ui-components/selected-image-component/SelectedImageComponent";

const AddCategory = () => {
  const [image, setImage] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CategorySchema),
  });
  const HandleAddCategory = (values) => {};
  const handleRemoveImage = () => {
    setImage("");
    reset({ categoryImage: "" });
  };
  return (
    <>
     
      
        <div className="flex items-center justify-between mb-8">
          <div className="relative z-20 grid grid-cols-2 ">
            <div className="columns-md max-w-[494px]">
              <form onSubmit={handleSubmit(HandleAddCategory)}>
                <div className="mb-8">
                  <input
                    type="text"
                    className={
                      "placeholder:text-white  outline-none border-t-0 border-r-0 border-l-0 border-b-2 border-white rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-blue-500 block w-full p-2.5 bg-transparent"
                    }
                    placeholder="Category Name"
                    {...register("categoryName")}
                  />
                  <p className="mt-2 text-xs text-red-600">
                    {errors?.categoryName?.message}
                  </p>
                </div>
                <div className="mb-10">
                  <label htmlFor="inputFileSelector" className="cursor-pointer">
                    {/* Image Selector */}
                    <input
                      hidden={true}
                      id="inputFileSelector"
                      type="file"
                      name="heroImage"
                      {...register("categoryImage", {
                        onChange: (e) => {
                          setImage(e.target.files[0]);
                        },
                      })}
                    />

                    <div className="flex items-center justify-between text-white font-poppins border-2 p-4 border-neutral-700 border-solid rounded-xl w-full min-h-[96px] h-full">
                      <div className="flex items-center justify-start flex-grow-1">
                        <img className="mr-3" src={images.UploadIcon} alt="" />
                        <div className="flex flex-col">
                          <h4 className="text-base font-bold">Upload Logo</h4>
                          <p className="mb-2 text-xs">Browse Files</p>
                          <p className="text-[#64748B] text-xs">
                            JPG, PNG, JPEG | Max file size: 5 MB
                          </p>
                        </div>
                      </div>
                      {/* <p className="text-[#64748B] font-bold text-xs flex-shrink">
                        OR
                      </p> */}
                      <button
                        type="button"
                        className="flex-shrink text-base font-bold text-center text-grey-100 "
                      >
                        Browse
                      </button>
                    </div>
                  </label>
                  <p className="mt-2 text-xs text-red-600">
                    {errors?.categoryImage?.message}
                  </p>
                </div>
                <div className="mb-3">
                  <SelectedImageComponent
                    image={image}
                    handleRemoveImage={handleRemoveImage}
                  />
                </div>
                <button type="submit" className="btn-primary max-w-[194px]">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
     
    </>
  );
};

export default AddCategory;
