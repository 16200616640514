import { deleteRequest, getRequest, postRequest } from "../utils/api/axiosMethods";

const getClientDomainDetails = () => {
    return getRequest('ClientDomain/get_client_domains').then((response) => {
        return response;
    });
}
const postClientDomainDetails = (payload) => {
    return postRequest(`ClientDomain/add_client_domain`, payload).then((response) => {
        return response;
    });
}
const postMarkDomainPrimary = (payload) => {
    return postRequest('ClientDomain/mark_domain_primary', payload).then((response) => {
        return response;
    });
}
const deleteDomain = (domainId) => {
    return deleteRequest(`ClientDomain/delete_domain?clientDomainUuid=${domainId}`).then((response) => {
        return response;
    });
}

const ConfigurationService = {
    getClientDomainDetails,
    postClientDomainDetails,
    postMarkDomainPrimary,
    deleteDomain
}

export default ConfigurationService;