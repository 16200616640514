import React from 'react'
import { useSelector } from 'react-redux';
import AppSpinner from '../components/ui-components/loader-components/AppSpinner';
import ProcessingModal from '../components/Modals/ProcessingModal';


const Loader = () => {

  const { loadingState ,loadingMessage} = useSelector(state => state.loader);
    
 
    return (
      <>
        <div
          className="sweet-loading backdrop-blur-[14px] custom-gradient"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 999999,
            width: "100vw",
            height: "100vh",
          
            
            display: loadingState ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            flexDirection:"column"
          }}
        >
          
          {loadingState && <ProcessingModal loadingMessage={loadingMessage} /> }  
         
        </div>
      </>
    );
}

export default Loader