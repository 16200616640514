import { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ClientHeaderComponent from "../../../pages/ClientPortal/components/ClientHeaderComponent";
import SwitchComponent from "../../ui-components/switch-component/SwitchComponent";
import AlertModalContent from "../../Modals/AlertModalContent";
import Modal from "../../../common/ModalLayout";
import Spinner from "../../ui-components/loader-components/Spinner";
import EditorQuill from "../EditorQuill";
import useClientThemeHook from "../useClientThemeHook";
import themePreferencesConstants from "../../../constants/client-admin-constants/clientPreferenceConstants/ClientPreferenceConstant";


const TermsAndConditions = () => {
  const navigate = useNavigate();
  
  const [buttonClicked, setButtonClicked] = useState(false);

  const {
    updateDataFunc,
    loading,
    OpenAlertModal,
    setOpenAlertModal,
    alertContent,
    clientThemeDetail,
    setLoading,
    getClientThemeDetails,
    termsData,
    setTermsData,
    resetLoading,
    setResetLoading,
    termsRef
  } = useClientThemeHook();

  const user = useSelector((state) => state.user);

  const handleChangeTerms = (value, ) => {
    termsRef.current=value
    setButtonClicked(false);
  
  };
  const handleChangeEnableTerms = (event) => {
    setTermsData({ ...termsData, enableTerms: event.target.checked });
  };

  useEffect(() => {
    getClientThemeDetails();
  }, []);

  const submitForm = async () => {
   
    setLoading(true);
    let formData = new FormData();
    formData.append("clientThemeSteps", 3);
    formData.append(
      "ClientThemeUuid",
      clientThemeDetail?.clientThemeUuid
        ? clientThemeDetail?.clientThemeUuid
        : ""
    );
    formData.append("ClientId", user?.clientDetail?.clientUuid);
    formData.append("BrandName", user?.clientDetail?.clientName);
    formData.append("TermsAndConditionsHtmlString", termsRef?.current?.toString());
    formData.append("ShowTermsAndConditionsPage", termsData.enableTerms)
    setTermsData({ ...termsData, termsString: termsRef?.current?.toString()});
    updateDataFunc(formData);
  };



  const handleReset = () => {
    setResetLoading(true);
    let formData = new FormData();
    formData.append("clientThemeSteps", 3);
    formData.append(
      "ClientThemeUuid",
      clientThemeDetail?.clientThemeUuid
        ? clientThemeDetail?.clientThemeUuid
        : ""
    );
    formData.append("ClientId", user?.clientDetail?.clientUuid);
    formData.append("BrandName", user?.clientDetail?.clientName);
    formData.append("TermsAndConditionsHtmlString", "<p><br></p>");
    formData.append("ShowTermsAndConditionsPage", true)
    setTermsData({ ...termsData, termsString: "<p><br></p>" ,enableTerms:true});
    termsRef.current="<p><br></p>"
    updateDataFunc(formData);
  }

  return (
    <>
      <ClientHeaderComponent
        title={"Back to Preferences"}
        backLink={"/preferences"}
      />
      <div className="flex justify-between items-center mb-1">
        <h1 className="text-xl font-bold ">Terms and Conditions</h1>
        <SwitchComponent
          onChange={handleChangeEnableTerms}
          value={termsData?.enableTerms}
          id={"enableterms"}
        />
      </div>
      <p className="text-[#8D9D91] text-xs  mb-6">
        {themePreferencesConstants.PREFERENCES.TERM_DESC}
      </p>

      <div className="mt-6">
        <EditorQuill
          toolbarId={"t3"}
          value={termsData?.termsString}
          onChange={handleChangeTerms}
        />

        {termsData?.termsString === "" && buttonClicked === true && (
          <p className="mt-1 text-xs text-red-600">
            Please write something to save.
          </p>
        )}
           <button
          onClick={handleReset}
          className={`btn-secondary mt-10 mr-3  max-w-[240px] disabled:cursor-not-allowed  px-12`}
          disabled={resetLoading}
        >
          {resetLoading && <Spinner />}
          Clear
        </button>

        <button
          onClick={submitForm}
          type="submit"
          className={`btn-primary mt-10  max-w-[240px]  px-12`}
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          disabled={loading}
        >
          {loading && <Spinner />}
          Save Changes
        </button>
      </div>

      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Add_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default TermsAndConditions;
