import React from "react";
// import { useTable, useSortBy, useGlobalFilter } from 'react-table';
// import MOCK_DATA from './../data/MOCK_DATA.json';
// import { COLUMNS } from './Table/columns';
// import SearchFilter from './SearchFilter';
import { useState } from "react";
import { images } from "./../constants/images";
import { useLocation, useNavigate } from "react-router-dom";

const BasicTable = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  page,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageOptions,
  pageIndex,
  gotoPage,
  pageCount,
  prepareRow,
  hideHeader,
  defaultMessage,
  hidePagination,
  data,
  icon,
  reportingRowLink
}) => {

  // React table suggests to memoize the columns and data for avoiding the re creation of data on every render
  // const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable({
  //     // ES6 shorthand notation having same key value name
  //     columns,
  //     data
  // }, useGlobalFilter, useSortBy)

  // const { globalFilter } = state;

  const StyledArrowDownwardIcon = () => {
    return <img className="ml-2" src={images.DecendingIcon} alt="" />;
  };
  const StyledArrowUpwardIcon = () => {
    return <img className="ml-2" src={images.AscendingIcon} alt="" />;
  };
  const navigate = useNavigate()

  const [paginationCount, setPaginationCount] = useState(0);
  const lastHeaderGroup = headerGroups[headerGroups.length - 1];
  const lastColumn = lastHeaderGroup.headers[lastHeaderGroup.headers.length - 1]
  const { state } = useLocation();
  return (
    <>
      {/* <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg rounded-lg border border-[#A4B1A7]/[0.12]">
        <table
          className="w-full pb-2 text-xs text-left text-white "
          {...getTableProps()}
        >
          {hideHeader ? (
            <></>
          ) : (
            <thead className="text-xs  text-white uppercase bg-[#12221A]/30  rounded-lg ">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className={`py-4  pl-6  ${column.Header === "Action" && "text-center"
                        } ${column === lastColumn ? 'pr-6' : 'pr-4'} `}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex items-center font-semibold capitalize">
                        {column.render("Header")}
                        {column.isSorted ? (
                          <>
                            {column.isSortedDesc ? (
                              <span>
                                {" "}
                                <StyledArrowDownwardIcon />
                              </span>
                            ) : (
                              <span>
                                {" "}
                                <StyledArrowUpwardIcon />
                              </span>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          {page?.length > 0 ? (
            <>
              <tbody
                className="min-h-[calc(100vh-390px)]"
                {...getTableBodyProps()}
              >
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      className={"hover:bg-[#12221A]/80"}
                      {...row.getRowProps()}
                    // onClick={() => navigate(reportingRowLink && '/tx-detail', { state: { data: row?.values } })}

                    >

                      {row.cells.map((cell) => {
                        return (
                          <td
                            className={`py-2 pl-6 ${row.values.status === "Deactive" &&
                              "text-[#8D9D91]"
                              } ${cell?.column === lastColumn ? 'pr-6' : 'pr-4'} `}
                            {...cell.getCellProps()}

                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </>
          ) : (
            <tbody className="h-[calc(100vh-390px)]">
              <tr className="">
                <td
                  className=""
                  colspan={headerGroups[0]?.headers?.length || "8"}
                >
                  <div className="flex flex-col items-center justify-center my-4 ">
                    <img src={icon ?? images.TabelSearchIcon} alt="icon" className="mb-2" />
                    {defaultMessage ? (
                      <h6 className="text-sm text-[#8D9D91] max-w-[400px] text-center">
                        {defaultMessage}
                      </h6>
                    ) : (
                      <>
                        <h6 className="text-base">
                          Sorry we couldn’t find any matches for that
                        </h6>
                        <p className="text-xs text-grey-100">
                          Please try searching with another term
                        </p>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          )}

          {/* <tfoot className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        {headerGroups.map((footerGroup) => (
                            <tr {...footerGroup.getFooterGroupProps()}>
                                {
                                    footerGroup.headers.map((column) => (
                                        <th scope="col" className="px-6 py-4" {...column.getFooterProps()}>
                                            {column.render('Footer')}
                                        </th>

                                    ))
                                }
                            </tr>

                        ))}
                    </tfoot> */}
        </table>
      </div >

      {(page.length > 0) && (<>
        {!hidePagination && (
          <div className="flex items-center justify-between mt-4">
            <span className="mr-2 text-xs font-sm">
              Showing {pageIndex + 1} to 10{" "}
              <span className="text-grey-100">of {rows?.length}</span>{" "}
            </span>
            {
              data?.length > 10 && (
                <div>
                  {/* <button className="px-3 py-2 bg-black disabled:text-slate-500" disabled={!canPreviousPage} onClick={() => gotoPage(0)}> {'<<'} </button> */}
                  <button
                    className="w-8 align-middle h-8  p-1 mr-2 text-center text-white bg-[#12221A]/30 border-[1.5px] border-[#A4B1A7]/25 rounded-lg hover:hover:bg-[#12221A]/80   disabled:text-[#4D6553]"
                    disabled={!canPreviousPage}
                    onClick={() => {
                      previousPage();
                      if (paginationCount > 0)
                        setPaginationCount(paginationCount - 1);
                    }}
                  >
                    <img
                      src={
                        canPreviousPage
                          ? images.ChevronLeftActive
                          : images.ChevronLeftDisable
                      }
                      alt="icon"
                    />
                  </button>

                  {pageOptions
                    .slice(paginationCount, paginationCount + 3)
                    ?.map((number) => {
                      return (
                        <button
                          key={number}
                          onClick={() => {
                            setPaginationCount(number);
                            gotoPage(number);
                          }}
                          className={`disabled:text-slate-500 border-[1.5px] text-xs border-[#A4B1A7]/25
                                      ${pageIndex + 1 === number + 1
                              ? "bg-[#A5F33C] hover:bg-[#A5F33C]  text-[#060C07] font-bold"
                              : " bg-[#12221A]/30 hover:bg-[#12221A]/80 text-[#8D9D91]"
                            }
                                      mr-2 
                                      
                                      text-center w-8 h-8
                                      rounded-lg  p-1`}
                        >
                          {number + 1}
                        </button>
                      );
                    })}
                  <button className=" text-white/70 mr-2  align-middle  relative border-[1.5px]  border-[#A4B1A7]/25 hover:hover:bg-[#12221A]/80  text-center w-8 h-8 rounded-lg bg-[#12221A]/30 px-2 py-2">
                    <img className="ml-[2px]" src={images.PaginationDot} alt="icon" />
                  </button>
                  {pageOptions.slice(pageCount - 1, pageCount)?.map((number) => {
                    return (
                      <button
                        key={number}
                        onClick={() => {
                          setPaginationCount(number);
                          gotoPage(number);
                        }}
                        className={`disabled:text-slate-500 text-xs border-[1.5px] border-[#A4B1A7]/25
                                      ${pageIndex + 1 === number + 1
                            ? " bg-[#A5F33C] hover:bg-[#A5F33C]  text-[#060C07] font-bold"
                            : " bg-[#12221A]/30 hover:bg-[#12221A]/80  text-[#8D9D91]"
                          }
                                      mr-2
                                     
                                      text-center w-8 h-8
                                      rounded-lg  p-1`}
                      >
                        {number + 1}
                      </button>
                    );
                  })}
                  {/* <button className="p-2 mr-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:bg-slate-700 disabled:text-slate-500"
                                 disabled={!canNextPage}
                                 onClick={() => nextPage()}>
                                 next
                             </button> */}
                  <button
                    className="w-8 h-8  p-1 align-middle   rounded-lg  text-xs border-[1.5px] border-[#A4B1A7]/25 text-center text-[#FFFFFF] bg-[#12221A]30  hover:bg-[#12221A]/80  disabled:text-[#4D6553]"
                    disabled={!canNextPage}
                    onClick={() => {
                      nextPage();
                      setPaginationCount(paginationCount + 1);
                    }}
                  >
                    <img
                      src={
                        canNextPage
                          ? images.ChevronRightActive
                          : images.ChevronRightDisable
                      }
                      alt="icon"
                    />
                  </button>

                  {/* <button className="disabled:text-slate-500" disabled={!canNextPage} onClick={() => gotoPage(pageCount - 1)}> {'>>'} </button> */}
                </div>
              )
            }

          </div>
        )
        }

      </>)}

      {/* Pagination starts here, need to create separate component */}
    </>
  );
};

export default BasicTable;
