import React from "react";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function AlertModalDate({
  staticRanges,
  handleCancel,
  handleChange,
  handleUpdate,
  range,
  monthsGap,
  setMonthsGap,
  dateCalenderPopups,
}) {

  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("en-US", { month: "long" });
  const currentYear = currentDate.getFullYear();
  const formattedCurrentDate = `${currentMonth} ${currentYear}`;
  const [fullMonthName, setFullMonthName] = useState(formattedCurrentDate)
  return (
    <div className="bg-[#060C07] rounded-2xl border-[#A4B1A7]/[0.12]">
      <div className="flex relative font-manrope">
        <DateRangePicker
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          onChange={handleChange}
          ranges={range}
          showSelectionPreview={true}
          calendarFocus={true}
          fixedHeight={true}
          rangeColors={["#a5f33c17"]}
          maxDate={new Date()}
          direction="vertically"
          showDateDisplay={true}
          staticRanges={staticRanges}
          inputRanges={[]}
          defaultValue={[]}
          numberOfCalendars={1}
          onShownDateChange={(DateRange) => {
            const fullMonthNamee = DateRange.toLocaleDateString("en-US", { month: "long", year: "numeric", });
            setFullMonthName(fullMonthNamee)
          }}        // onRangeFocusChange={() => {
        //   if (monthsGap) {
        //     setMonthsGap(0)
        //   }
        // }}
        // focusedRange={[0, range[0]]}
        />
        {
          monthsGap > 2 &&
          <span className="font-normal leading-4 text-[10px] text-red-600 absolute top-20 right-[24px]">Max limit upto 3 months</span>
        }
        <span className="text-[#FFFFFF] absolute top-[24px] left-[136px] text-sm not-italic font-bold">{fullMonthName}</span>
        <span className="absolute top-[54px] right-6 text-[10px] text-[#A5F33C] font-normal leading-4">{dateCalenderPopups}</span>
        <span className="absolute top-[54px] left-[138px] text-[10px] text-[#8D9D91] font-normal leading-4">Pacific Time</span>
        <span className="absolute top-6 left-6 text-[#FFFFFF] text-xs not-italic font-semibold leading-[18px]">Date Presets</span>
        <span className="absolute top-[78px] left-[142px] w-[256px] border border-[#A4B1A7]/[.12]"></span>
      </div>
      <div className="relative before:w-[114px] before:h-full before:bg-[#12221A]/30 before:absolute before:-left-[114px] flex justify-center   pb-6 ml-[113px]" style={{ borderBottomRightRadius: '16px' }}>
        <button
          onClick={handleCancel}
          className=" py-1 max-w-[98px] px-0 btn-secondary mr-4"
        >
          Cancel
        </button>
        <button disabled={monthsGap > 2} onClick={handleUpdate} className="py-1 px-0 btn-primary max-w-[98px]">
          Update
        </button>
      </div>
    </div>
  );
}
export default AlertModalDate;
