import React from "react";

const BrandCategory = (props) => {
  const { clientData } = props;

  return (
    <div className="text-white">
      <h1 className="mb-1 text-3xl font-bold">Brand Category</h1>
      <p className="mb-6 text-xs text-grey-100">
        Brand category selected by the client.
      </p>

      <div className="flex flex-wrap items-center justify-start max-w-3xl">
        <span className="px-8 py-2 mb-5 mr-6 border-2 rounded-full text-[#A5F33C] bg-[#A5F33C]/10  bg-opacity-20 border-[#A5F33C]">
          {clientData?.categoryTypeName ? clientData?.categoryTypeName : "-"}
        </span>
      </div>
      {clientData?.othersCategoryText && (
        <div>
          <p className="text-base  border pb-2 border-b-[#A4B1A7]/25 border-l-0 border-r-0 border-t-0 max-w-[448px]">
            {clientData?.othersCategoryText ?? "-"}
          </p>
        </div>
      )}
    </div>
  );
};

export default BrandCategory;
