export const customStyles = {
    container: (provided ,state) => ({
      ...provided,
      borderBottom: state?.isFocused ? "1px solid #A4B1A7" :  "1px solid #A4B1A7",
     
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
      paddingTop:'6px',
      paddingBottom:'6px',
      paddingRight:'6px',
      paddingLeft:'0px'
     
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius:"8px",
      backgroundColor: "#0A130D",
      border:'1px solid #a4b1a71f',
      color: "white",
      zIndex:100,
      WebkitScrollbar: { width: "5px", backgroundColor: "red" },
      WebkitScrollbarTrack: {
        borderRadius: "3px",
      },
      WebkitScrollbarThumb: {
        background: "grey",
        borderRadius: "3px",
      },
      
    }),
    menuList: (base) => ({
      ...base,
      
      
     
      paddingBottom:"8px",
      paddingTop:"8px",
      
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
        
        
      },
      "::-webkit-scrollbar-track": {
        background: "#222222",
       
      },
      "::-webkit-scrollbar-thumb": {
        background: "black",
        minHeight:"60px",
        borderRight :"8px solid #222222",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "black",
      },
    }),
    option: (provided,state ) => ({
      ...provided,
      backgroundColor:state?.isFocused ? "rgba(18, 34, 26, 0.80)" : "#0A130D",
      color: "white",
      
      ":hover": {
        backgroundColor: "rgba(18, 34, 26, 0.80)",
        color: "white",
        cursor:"pointer",
      },
      
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white",
      boxShadow:'none !important',
      boxShadow:state?.isFocused ? 'none' :'none',
      '& input':{
        boxShadow:'none !important',
      }
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "white",
      padding:"0px"
      
    }),
    indicatorSeparator:(provided) => ({
        ...provided,
        backgroundColor: "transparent",
        
        
      }),
      valueContainer:(provided) => ({
        ...provided,
        paddingTop:'2px',
      paddingBottom:'2px',
      paddingRight:'8px',
      paddingLeft:'0px'
        
        
      }),
      clearIndicator  : (provided) => ({
        ...provided,
        paddingTop:'0px',
        paddingBottom:'0px',
        paddingRight:'0px',
        paddingLeft:'0px'
        
      }), 
  };