import React, { useState } from "react";
import AddRoyaltyFields from "./AddRoyaltyFields";
import { configurationService } from "../../../services/client.configuration.service";
import Modal from "../../../common/ModalLayout";
import AlertModalContent from "../../Modals/AlertModalContent";
import Spinner from "../../ui-components/loader-components/Spinner";
import SwitchComponent from "../../ui-components/switch-component/SwitchComponent";


const AddRoyalty = (props) => {
  const {
    clientId,
    royaltyData,
    inputFields,
    setInputFields,
    register,
    handleSubmit,
    reset,
    setValue,
    unregister,
    clearErrors,
    errors,
    remove,
    setOpenRoyalty,
    openRoyalty,
    getClientRoyalty
  } = props;

  const [loading, setLoading] = useState(false);
  const [OpenAlertModal, setOpenAlertModal] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: "",

    description: "",
    path: "",
    sublabel: "",
  });



  const handleAddRoyalty = (values) => {
    setLoading(true);
  

    const royaltyJson = JSON.stringify(values?.royalty);
    let formData = new FormData();
    formData.append("ClientId", clientId);
    formData.append("ClientRoyaltyJson", royaltyJson);

    configurationService
      .addRoyaltyConfiguration(formData)
      .then((response) => {
        setLoading(false);
        if (response?.data?.statusCode === 200) {
          setOpenAlertModal(true);
          setTimeout(() => {
            setOpenAlertModal(false);
            
          }, 3000);
          setAlertContent({
            title: "Success",
            description: "Changes have been saved successfully.",
            sublabel: "Auto closing in 3 seconds...",
          });
          getClientRoyalty()
        }
      })
      .catch((err) => {
        setLoading(false);
        setOpenAlertModal(true);
        setTimeout(() => {
          setOpenAlertModal(false);
        }, 3000);
        setAlertContent({
          title: "Error",
          description: "Failed to save changes. Please try again.",
          sublabel: "Auto closing in 3 seconds...",
        });
      });
  };
  const handleSetRoyalty = () => {
  
    setOpenRoyalty(!openRoyalty);
  };
  
  return (
    <>
      <div className="flex justify-between mb-1 max-w-[494px]">
        <h4 className="text-xl font-bold text-white">Assign Royalty</h4>
        <div>
            <SwitchComponent
              onChange={handleSetRoyalty}
              value={openRoyalty}
              id={'royalty'}
            />
          </div>
      </div>
      <div className=" max-w-[494px]">
        <p className="text-xs text-grey-100">
          Lorem ipsum dolor sit amet consectetur. Lectus leo sed. Lorem ipsum
          dolor sit amet consectet{" "}
        </p>
      </div>
      {openRoyalty && (
        <>
           <form
        className=" h-[calc(100vh-280px)] relative"
        onSubmit={handleSubmit(handleAddRoyalty)}
      >
        <div className="container relative z-20 grid h-full grid-cols-2 overflow-y-auto ">
          <div className="columns-md max-w-[494px]">
            <div>
              <AddRoyaltyFields
                register={register}
                handleSubmit={handleSubmit}
                reset={reset}
                setValue={setValue}
                unregister={unregister}
                clearErrors={clearErrors}
                errors={errors}
                setInputFields={setInputFields}
                inputFields={inputFields}
                remove={remove}
               
              />
            </div>
          </div>
        </div>
        <div className="absolute -bottom-5 z-50 max-w-[494px] w-full">
          <div className="grid grid-cols-2 gap-3  bg-[#060C07]   ">
            <button className="btn-secondary">Cancel</button>
            <button className="btn-primary" disabled={loading} type="submit">
              {loading && <Spinner />}
              Submit
            </button>
          </div>
        </div>
      </form>
      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Save_Modal"
          isClose={true}
        />
      )}
        </>
      )}

   
    </>
  );
};

export default AddRoyalty;
