import { getRequest, postRequest } from "../utils/api/axiosMethods";

const getAllDropsByClient = (clientUuId) => {
  return getRequest(
    `Drop/get_all_drops_by_client?clientUuId=${clientUuId}`
  ).then((response) => {
    return response;
  });
};
const getDropAssetIds = (clientUuid) => {
  return getRequest(`Drop/drop_asset_ids?ClientId=${clientUuid}`).then((response) => {
    return response;
  });
};
const uploadDropVideo = (clientId, formData) => {
  return postRequest(
    `Drop/upload_drop_banner_video?clientId=${clientId}`,
    formData
  ).then((response) => {
    return response;
  });
};
const createUpdateDrop = (formData) => {
  return postRequest(`Drop/create_update_drop`, formData).then((response) => {
    return response;
  });
};
const updateDropTx = (modalData) => {
  return postRequest(`Drop/update_drop_tx`, modalData).then((response) => {
    return response;
  });
};
const getDropDetailsByUuId = (dropUuId) => {
  return getRequest(`Drop/get_drop_details?dropUuId=${dropUuId}`).then(
    (response) => {
      return response;
    }
  );
};
const deleteDropFromDB = (modalData) => {
  return postRequest(`Drop/delete_drop`, modalData).then((response) => {
    return response;
  });
};

// Airdrop White list users
const getAirdropUserList = (clientUuId) => {
  return getRequest(
    `Configuration/get_airdrop_userlists?clientId=${clientUuId}`
  ).then((response) => {
    return response;
  });
};
// Add airdrop user list
const createAirdropUserList = (formData) => {
  return postRequest(`Configuration/add_airdrop_userlist`, formData).then(
    (response) => {
      return response;
    }
  );
};

const createSmallDonughtChart = (payload) => {
  return postRequest(`Configuration/get_drop_stats`, payload).then(
    (response) => {
      return response;
    }
  );
};
const DropService = {
  getAllDropsByClient,
  getDropAssetIds,
  uploadDropVideo,
  createSmallDonughtChart,
  createUpdateDrop,
  updateDropTx,
  getDropDetailsByUuId,
  deleteDropFromDB,
  getAirdropUserList,
  createAirdropUserList,
};

export default DropService;
