import { images } from "../../images";

const HOME_PAGE_DESC = [
  "The copy will appear in the respective areas as indicated in the guide image, reflecting the brand.",
  
];

const PRIVACY_DESC = ["The privacy policy is visible in the footer across the storefront and opens in a new tab for ease of reading. "];

const ABOUT_US_DESC = ["The About Us section is prominently placed in the header, allowing visitors to easily grasp and explore more about the brand at first sight. "];

const TERMS_DESC = ["Throughout the storefront, the Terms and Conditions (T&Cs) are displayed in the footer and open in a new tab for readability."];

const MAINTENANCE_DESC = ["While in maintenance mode, the storefront will present a message to notify visitors of the temporary downtime and relaunch.  "];

const SOCIAL_SHARING_DESC = [
  "Enable social media sharing for visitors, empowering them to share any asset on their preferred platforms with a simple click.  ",
];

const SOCIAL_LINK_DESC = ["Social media channels are showcased in the footer across the storefront, enabling visitors to subscribe and stay updated with your latest news and content. "];

const EMAIL_SUBS_DESC = ["The email subscription feature is visible in the footer throughout the storefront, providing visitors the option to subscribe and receive your latest news and updates. "];
const ADDITION_DROP_DESC = [
  "Showcasing additional drops under detail page allows users to view other valuable assets available for purchase enhancing their shopping experience.  ",
];

const PREFERENCES = {
  PAGE_DESC: `Easily customize your storefront's copy and visibility to align with your brand's voice and style. Hover over the `,
  PAGE_DESC_END:` icon to see where the changes reflect on your storefront.`,
  FEATURE_DESC: `Boost your brand awareness and user engagement by customizing your storefront's features and presenting with a simple toggle. Discover where the changes reflect on your storefront by hovering over the `,
  HOME_DESC: `Share your brand's story with a personalized touch. Customize headings, subheadings, and visuals to captivate your audience from the start.`,
  TERM_DESC :`Take charge of your brand's legal representation by tailoring the terms and conditions.`,
  ABOUT_DESC :`Communicate your brand's values, mission, and vision to foster a deeper connection with your audience.`,
  MAINTENANCE_DESC:`Temporarily pause your storefront for maintenance and keep your audience informed with a branded message.`,
  PRIVACY_DESC:`Craft a privacy policy that clearly outlines how your users data is protected and utilized.`,
  SOCIAL_DESC:`Enhance brand connection and boost audience engagement by sharing links to your social media platforms.`,
  ADDITION_DROP_DESC:`Increase storefront engagement with additional drops presented on your detail page allowing users to view more options enhancing their shopping experience.`,
 EMAIL_DESC:`Increase your brand's outreach by providing visitors the option to subscribe and receive the latest news, exclusive offers, and exciting updates.`,
SOCIAL_SHAR_DESC:`Connect with a wider audience by enabling your visitors to share directly on their social media platforms or via link.`,

};

const BRAND = {
  DESC :`Customize your storefront according to your brand using the color palettes below. Hover over the   `,
  DESC_AFTER:` icon to see where each color reflects on your storefront.`,
  FAVICON_DESC :`Upload a 16 x 16 pixel, PNG or JPG to reflect your brand's identity on browser tabs.`
}

const themePreferencesConstants = {
  HOME_PAGE_DESC,
  PRIVACY_DESC,
  ABOUT_US_DESC,
  TERMS_DESC,
  MAINTENANCE_DESC,
  SOCIAL_SHARING_DESC,
  SOCIAL_LINK_DESC,
  EMAIL_SUBS_DESC,
  ADDITION_DROP_DESC,
  PREFERENCES,
  BRAND
};

export default themePreferencesConstants;
