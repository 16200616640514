import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../../../redux/features/loading/loaderSlice";
import DropContent from "./DropContent";
import DropData from '../../../../data/Dropdata.json'
import DropService from "../../../../services/drop.services";

const DropListing = () => {
    const {
        clientUuid,
        clientWalletAddress,
        blockChainBrandId,
    } = useSelector((state) => state?.user?.clientDetail);
    const dispatch = useDispatch();
    const [dropListData, setDropListData] = useState([]);
    const [updateDroplist, setUpdateDropList] = useState(false)

    const navigate = useNavigate();
    useEffect(() => {
        fetchDropList();
        //eslint-disable-next-line
    }, [updateDroplist]);
    const fetchDropList = async () => {
        dispatch(setLoader(true));
        DropService.getAllDropsByClient(clientUuid).then((result)=>{
            dispatch(setLoader(false));
            if(result?.data?.payload?.length > 0){
                setDropListData(result?.data?.payload?.reverse());
            }

        }).catch((error)=>{
            dispatch(setLoader(false));

        });
    }
    const createDropHandler = () => {
        navigate("/create-drop");
    }
    return (
        <>
            <DropContent clientUuid={clientUuid} dropListData={dropListData} createDropHandler={createDropHandler} clientWalletAddress={clientWalletAddress} setUpdateDropList={setUpdateDropList} blockChainBrandId={blockChainBrandId}/>
        </>
    )

}
export default DropListing;