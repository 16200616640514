import React from "react";
import { Doughnut } from "react-chartjs-2";

const EmphtyDonutChart = () => {
  const emptyData = {
    datasets: [
      {
        data: [100],
        backgroundColor: ["#a5f33c17"],
      },
    ],
  };

  const options = {
    cutout: "75%",
    responsive: true,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    borderRadius: 20,
    spacing: 2,
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className="w-[172px] h-[172px]  relative">
      <Doughnut data={emptyData} options={options} />
      <div className="absolute top-[50%] left-[50%] text-center -translate-x-[50%] -translate-y-[50%]">
        <span className="text-[#A5F33C] text-xl font-bold align-baseline">
          $0
        </span>
        <p className="text-[10px] text-[#8D9D91] min-w-[98px]">
          Total Transactions in USD
        </p>
      </div>
    </div>
  );
};

export default EmphtyDonutChart;
