import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "./../ui-components/loader-components/Spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BrandDetailsSchema } from "../../utils/ValidationSchemas/BrandDetailsSchema";
import { images } from "../../constants";
import clientService from "./../../services/client.service";
import { useDispatch, useSelector } from "react-redux";
import { update_menu_items_state } from "../../redux/features/menu/menuItemsSlice";
import { clientDetailAction } from "../../redux/features/user/userSlice";
import { removewhitespace } from "../../helpers/RemoveWhiteSpace";
import { getPostFixUrl } from "../../helpers/getPostfixUrl";
import { Tooltip } from "@material-tailwind/react";

const KybBrandDetails = () => {
  const [postfixWelUrl, setPostfixWelUrl] = useState(
    getPostFixUrl(window?.location?.host)
  );
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [isBrandAvailable, setIsBrandAvailable] = useState(true);
  const [imageSizeError, setImageSizeError] = useState(false);
  const [clientImageUrl, setclientImageUrl] = useState("");
  const [brandWebUrlAvailable, setBrandWebUrlAvailable] = useState(true);
  const [editWebUrlField, setEditWebUrlField] = useState(false);
  const [previewWebUrl, setPreviewWebUrl] = useState("");
  const [disableOnFocus, setDisableOnFocus] = useState(false);

  const fileInputRef = useRef();

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(BrandDetailsSchema),
    mode: "onChange",
  });

  useEffect(() => {
    let clientDetail = user.clientDetail;
    if (
      clientDetail.clientName &&
      clientDetail.registerationNo &&
      clientDetail.clientDescription &&
      clientDetail.logo &&
      clientDetail.clientDomainName
    ) {
      setValue("brandName", clientDetail.clientName);
      setValue("brandWebUrl", clientDetail.clientDomainName);
      setValue("registrationNumber", clientDetail.registerationNo);
      setValue("brandImage", clientDetail.logo);
      setValue("brandDescription", clientDetail.clientDescription);
      setclientImageUrl(clientDetail.logo);
    }
    if (clientDetail.clientWalletAddress) {
      if(clientDetail?.contributerJson){
        setValue("contributorAddress", clientDetail.contributerJson);
      }else{
        setValue("contributorAddress", clientDetail.clientWalletAddress);
      }
    
    }
    //eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setImageSizeError(false);

    if (e.target.files[0]?.size > 5000000) {
      setImageSizeError(true);
      return false;
    } else {
      setclientImageUrl("");
      if(e.target.files[0]?.size > 0){
        setFileSize((e.target.files[0]?.size / 1024).toFixed(2));
        
      }
      setFile(URL.createObjectURL(e.target.files[0]));
      
    }
  };

  // Cross icon to remove the selected file
  const handleRemoveImage = (e) => {
    e.preventDefault();
    setFile("");
    // setValue('brandImage','')
    reset({ ...getValues(), brandImage: "" });
  };

  const callDispatch = (payload) => {
    dispatch(update_menu_items_state(2));
    dispatch(clientDetailAction(payload));
    navigate("/onboarding/brand-categories");
  };

  const checkBrandAvailability = (data) => {
    setLoading(true);

    if (
      user?.clientDetail?.clientName !== null ||
      user.clientDetail.isClientApproved
    ) {
      if (user?.clientDetail?.registerationNo === data.registrationNumber) {
        updateBrandDetails(data);
      } else {
        BrandAvailability(data.registrationNumber, data);
      }
    } else {
      BrandAvailability(data.registrationNumber, data);
    }
  };

  const BrandAvailability = (registrationNumber, data) => {
    const body = {
      RegistrationNo: registrationNumber,
    };
    clientService
      .brandAvailability(body)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          setLoading(false);

          // if brand exists already, then goes inside if and returns, flow stops!
          if (response.data.message.includes("Not")) {
            setIsBrandAvailable(false);
            return;
          }
          setIsBrandAvailable(true); // remove the error
          updateBrandDetails(data); // call the brand details api
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsBrandAvailable(false);
      });
  };

  const updateBrandDetails = (data) => { 
    let formData = new FormData();
    formData.append("ClientUuid", user.clientUuid);
    // if (user?.clientDetail?.clientName !== null) {
    formData.append("BrandName", data.brandName);
    formData.append("ClientSubDoamin", data.brandWebUrl);
    formData.append("RegisterationNo", data.registrationNumber);
    formData.append("ContributerJson", '');
    
    // }

    formData.append("Logo", data.brandImage[0] ?? "");
    formData.append("BrandDescription", data?.brandDescription?.trim());
    formData.append("stepsEnum", 2);
    formData.append("LogoUrl", clientImageUrl);

   
    setLoading(true);

    clientService
      .updateClient(formData)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          setLoading(false);
          callDispatch(response?.data?.payload);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const browseHandler = (e) => {
    // 👇️ open file input box on click of other element
    // fileInputRef?.current?.click();
  };

  const submitForm = (data) => {
    checkBrandAvailability(data);
  };

  const handleChangeWebUrl = (e) => {
    setValue("brandName", e.target.value);

    if (e.target.value.length === 0) {
      setValue("brandWebUrl", "");
    } else {
      setValue(
        "brandWebUrl",
        e.target.value.replace(removewhitespace, "") + postfixWelUrl
      );
      clearErrors("brandWebUrl");

      setPreviewWebUrl("");
    }
  };
  const handleCheckBrandWebUrlExist = (e) => {
    const webUrl = getValues("brandWebUrl");

    if (user?.clientDetail?.clientDomainName === webUrl) {
      return;
    }
    clientService
      .checkBrandWelUrlAvailibility(webUrl)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          if (
            response?.data?.message?.toLowerCase() === "specified domain name is available"
          ) {
            setBrandWebUrlAvailable(true);
          } else {
            if (
              response?.data?.message?.toLowerCase() ===
              "specified domain name is not available"
            ) {
              setBrandWebUrlAvailable(false);
            }
          }
        }
      })
      .catch((err) => {});
  };

  return (
    <div>
      <h1 className="text-2xl font-bold">Brand Details</h1>
      <p className="mt-1 mb-8 text-xs text-grey-100">
      Please fill in the brand details in the given fields below.
      </p>

      <form className="w-6/12 text-white" onSubmit={handleSubmit(submitForm)}>
        {/* Brand Name Input  */}
        <div className="relative z-10 mb-10">
          <input
            disabled={user?.clientDetail?.isClientApproved}
            type="text"
            name="brandName"
            onFocus={() => setDisableOnFocus(true)}
            className={`${
              user?.clientDetail?.isClientApproved
                ? " cursor-not-allowed opacity-50"
                : ""
            } peer outline-none border-t-0 border-r-0 border-l-0 border-b border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent`}
            placeholder=" "
            {...register("brandName", {
              onChange: (e) => {
                handleChangeWebUrl(e);
              },
              onBlur: (e) => {
                setDisableOnFocus(false);
                if (e.target.value.length > 0) {
                  handleCheckBrandWebUrlExist(e);
                }
              },
            })}
          />
          <label
            className={`text-xs text-grey-100  z-[-1]  absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
          >
            Brand Name*
          </label>

          <p className="mt-1 text-xs text-red-600">
            {errors?.brandName?.message}
          </p>
          {/* {BrandTitle && <p className="mt-1 text-xs text-red-600">{BrandTitle}</p> } */}
        </div>
        {/* Brand URl Input  */}
        <div className="relative z-10 mb-10">
          <input
            disabled={user?.clientDetail?.isClientApproved}
            value={getValues("brandWebUrl") || ""}
            type="text"
            name="brandWebUrl"
            className={`${
              user?.clientDetail?.isClientApproved
                ? " cursor-not-allowed opacity-50"
                : ""
            } peer outline-none border-t-0 border-r-0 border-l-0 border-b border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent`}
            // onChange={handleChangeWebFieldUrl}
            onFocus={(e) => {
              setDisableOnFocus(true);
              setValue(
                "brandWebUrl",
                e.target.value.substring(0, e.target.value.indexOf("."))
              );
            }}
            placeholder=" "
            {...register("brandWebUrl", {
              onChange: (e) => {
                // setEditWebUrlField(true);
                setBrandWebUrlAvailable(true);
                if (e.target.value.length === 0) {
                  setPreviewWebUrl("");
                } else {
                  setValue(
                    "brandWebUrl",
                    e.target.value.replace(removewhitespace, "")
                  );
                  setPreviewWebUrl(
                    e.target.value.replace(removewhitespace, "") + postfixWelUrl
                  );
                }
              },
              onBlur: (e) => {
                if (e.target.value.length === 0) {
                  return;
                }

                setValue(
                  "brandWebUrl",
                  e.target.value.replace(removewhitespace, "") +
                    postfixWelUrl ?? ""
                );
                handleCheckBrandWebUrlExist();
                setDisableOnFocus(false);
              },
            })}
          />
          <label
            className={`text-xs text-grey-100  z-[-1]  absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
          >
            Domain Name*
          </label>
          <p className="mt-1 text-sm text-grey-100 ">
            {previewWebUrl && `Domain name : ${previewWebUrl}`}
          </p>

          <p className="mt-1 text-xs text-red-600">
            {errors?.brandWebUrl?.message}
          </p>
          {!brandWebUrlAvailable && (
            <p className="mt-1 text-xs text-red-600">
              Specified Domain Name is Not Available
            </p>
          )}
        </div>
        {/* Registration Number Input  */}
        <div className="relative z-10 mb-10">
          <input
            disabled={user?.clientDetail?.isClientApproved}
            type="text"
            name="registrationNumber"
            className={`${
              user?.clientDetail?.isClientApproved
                ? " cursor-not-allowed opacity-50"
                : ""
            } peer outline-none border-t-0 border-r-0 border-l-0 border-b border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent`}
            placeholder=" "
            {...register("registrationNumber", {
              onChange: (e) => {
                setIsBrandAvailable(true);
              },
            })}
          />
          <label
            className={`text-xs text-grey-100 z-[-1]   absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
          >
            Registration Number*
          </label>
          <p className="mt-1 text-xs text-red-600">
            {errors?.registrationNumber?.message}
          </p>
          <p className="mt-1 text-xs text-red-600">
            {!isBrandAvailable &&
              "Registration number not available! Choose different one."}
          </p>
        
        </div>
        {/** contributor field */}
        <div className="relative z-10 hidden mb-10">
          <input
            disabled={user?.clientDetail?.isClientApproved}
            type="text"
            name="contributorAddress"
            className={`${
              user?.clientDetail?.isClientApproved
                ? " cursor-not-allowed opacity-50"
                : ""
            } peer outline-none border-t-0 border-r-0 border-l-0 border-b border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent`}
            placeholder=" "
            {...register("contributorAddress", {
              onBlur: (e) => {
                if (
                  (e?.target?.value?.includes("0x") && e?.target?.value?.length === 18) ||
                  (!e.target?.value?.includes("0x") &&
                    e?.target?.value?.length === 16)
                ) {
                }else {
                  setError('contributorAddress',{ type: 'custom', message: 'Invalid wallet address' })
                }
              },
            })}
          />
          <label
            className={`text-xs text-grey-100 z-[-1]   absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
          >
            Contributor*
          </label>
          <p className="mt-1 text-xs text-red-600">
            {errors?.contributorAddress?.message}
          </p>

          <Tooltip
            className="text-[white] text-xs bg-[#0A130D] z-20 relative  border border-[#12221A] rounded max-w-[200px]"
            offset={10}
            placement={"top-center"}
            content={"Lorem ipsum dolor sit amet,  ex ea commodo consequat. "}
          >
            <img
              className="absolute right-0 cursor-pointer top-3"
              src={images.InputFieldInfoIcon}
              alt="info-icon"
            />
          </Tooltip>
        </div>

        {/* FLOATING LABEL TESTING */}
        {/* <div className="mb-10">
                            <div class="flex flex-col items-start">
                                <input type="text" id="username" placeholder="John Doe" className={inputFieldStyle + " peer placeholder-transparent"} />
                                <label for="username" class="ml-0 -mt-14 text-xs text-blue-600  
                                    peer-placeholder-shown:text-gray-400 
                                    peer-placeholder-shown:-mt-8
                                    peer-placeholder-shown:text-base 
                                    duration-300">
                                    username
                                </label>
                            </div>
                </div> */}

        {/* File Uploader */}
        <label htmlFor="inputFileSelector" className="mb-2 cursor-pointer">
          {/* Image Selector */}
          <input
            hidden={true}
            id="inputFileSelector"
            ref={fileInputRef}
            accept={".gif,.jpg,.jpeg,.png"}
            type="file"
            name="brandImage"
            // className={inputFieldStyle}
            {...register("brandImage", {
              onChange: (e) => {
                handleChange(e);
              },
            })}
          />

          <div
            onClick={() => browseHandler()}
            className="flex items-center justify-between text-white font-poppins  p-4 border border-[#A4B1A7]  border-solid rounded-xl w-full  min-h-[96px] h-full"
          >
            <div className="flex items-center justify-start flex-grow-1">
              <img className="mr-3" src={images.UploadIcon} alt="" />
              <div className="flex flex-col">
                <h4 className="text-base font-bold">Upload Logo</h4>
                <p className="mb-2 text-xs"> Browse Files</p>
                <p className="text-xs text-grey-100">
                  JPG, PNG ,GIF | Max file size: 5 MB
                </p>
              </div>
            </div>
            
            <p className="flex-shrink text-base font-bold text-center text-[#A5F33C]">
              Browse
            </p>
          </div>
        </label>

        {/* Image Displayer */}
        <div className="">
          {file !== null && clientImageUrl !== "" && (
            <img
              src={user.clientDetail.logo}
              className="object-contain mt-3 mr-2 w-7 h-7"
              alt="brand_logo"
            />
          )}

          {file && (
            <div className="flex justify-between items-center text-xs mt-8 flex-grow py-3 px-4 rounded-xl bg-transparent  border border-[#A4B1A7] min-w-[80px]">
              <div className="flex items-center">
                <img
                  className="object-contain mr-2 w-7 h-7"
                  src={file}
                  alt="logo"
                />
                <span className="w-full p-0 font-bold bg-transparent border-none focus:ring-transparent">
                  Brand Logo
                </span>
              </div>
              <span className="text-grey-100">{fileSize + "kb"}</span>
              <img
                className="cursor-pointer hover:opacity-80"
                src={images.cross_icon}
                alt="X"
                onClick={handleRemoveImage}
              />
            </div>
          )}
          <p className="mt-1 text-xs text-red-600">
            {errors?.brandImage?.message}
          </p>
          {imageSizeError && (
            <p className="mt-1 text-xs text-red-600">
              File size should be less than 5 MB
            </p>
          )}
        </div>
        {/* Description */}
        <div className="mt-8 mb-10 relative z-0 ">
          <textarea
            className="peer outline-none  px-3 py-2  border rounded-xl border-[#A4B1A7] text-white text-md  placeholder:text-white focus:ring-transparent focus:border-[#A4B1A7] focus:outline-none  block w-full  bg-transparent"
            rows="4"
            placeholder=""
            name="brandDescription"
            maxlength="1001"
            {...register("brandDescription")}
          ></textarea>
           <label
                className={`text-xs text-grey-100 z-[-1]  left-0 absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3   peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs peer-focus:left-0`}
              >
                Brand Description*
              </label>
            {!errors?.brandDescription?.message && <p className='mt-1 text-xs text-grey-100'>(Max  length 1000 characters)</p>}
          <p className="mt-1 text-xs text-red-600">
            {errors?.brandDescription?.message}
          </p>
        </div>

        {/* Save  */}
        <button
          type="submit"
          className={`btn-primary mb-16 disabled:cursor-not-allowed`}
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          disabled={
            loading ||
            imageSizeError ||
            !isValid ||
            !brandWebUrlAvailable ||
            disableOnFocus ||
            !isBrandAvailable
          }
        >
          {loading && <Spinner />}
          Save &amp; Continue
        </button>
      </form>
    </div>
  );
};

export default KybBrandDetails;
