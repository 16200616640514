import React, { useEffect, useState } from "react";
import { Chart as ChartJs, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import UseGetTransaction from "../../helpers/custom-hooks/UseGetTransaction";
import DropService from "../../services/drop.services";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../redux/features/loading/loaderSlice";
import { useMemo } from "react";
ChartJs.register(ArcElement, Tooltip, Legend);

function DoughnutChartSmall() {
  const dispatch = useDispatch();
  const clientInfo = useSelector((state) => state?.user?.clientDetail);

  const [clientSmallDonughtId, setclientSmallDonughtId] = useState({
    clientId: clientInfo?.clientUuid,
  });

  const [donughtData, setDonughtData] = useState({
    dropTotal: 0,
    airDropTotal: 0,
  });

  const outOfTotal = useSelector(
    (state) => state?.user?.clientDetail?.clientPackageResponse?.defaultNFtLimit
  );

  const data = {
    // labels: ["Drop", "AirDrop"],
    datasets: [
      {
        data: [
          donughtData?.dropTotal,
          donughtData?.airDropTotal,
          `${outOfTotal - (donughtData?.dropTotal + donughtData?.airDropTotal)
          }`,
        ],
        backgroundColor: ["#B0E577", "#E7E15A", "rgba(165, 243, 60, 0.01)"],
        hoverBackgroundColor: ["#B0E577", "#E7E15A", "rgba(165, 243, 60, 0.01)"],
        rotation: 360,
        circumference: 360,
        cutout: "80%",
        weight: 15,
      },
    ],
  };

  const getDonughtSmallChart = async () => {
    dispatch(setLoader(true));
    await DropService.createSmallDonughtChart(clientSmallDonughtId)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          dispatch(setLoader(false));

          const dropStats = response?.data?.payload;

          const updatedData = dropStats.reduce(
            (acc, item) => {
              if (item.dropType === "Drop") {
                acc.dropTotal += item.totalDrops;
              } else if (item.dropType === "AirDrop") {
                acc.airDropTotal += item.totalDrops;
              }
              return acc;
            },
            { ...donughtData }
          );

          setDonughtData(updatedData);
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
  useEffect(() => {
    getDonughtSmallChart();
  }, []);

  const options = {
    legendCallback: function (chart) { },
    plugins: {
      tooltip: {
       
        caretSize: 0,
      
        callbacks: {
          label: (context) => {

            const dataIndex = context.dataIndex;
            const value = context.dataset.data[dataIndex];
            const label = context.dataIndex === 0 ? "Drops: " : "AirDrop: ";
            return ` ${label}${value}`;
          },
        },
        filter: function (context) {
          return context.dataIndex !== 2;
        },
        backgroundColor: "#0A130D",
        padding: 16,
        position: "nearest",
        borderColor: 'rgba(164, 177, 167, 0.12)',
        borderWidth: 1,
        bodyColor: "#fff",
      },

      legend: {
        display: false,
        position: "left",
        align: "center",
        labels: {
          boxWidth: 10,
          padding: 10,
          borderRadius: 80,
          fontColor: "#8D9D91",
          generateLabels: (chart) => {
            const data = chart.data;

            if (data?.labels?.length && data?.datasets?.length) {
              return data.labels.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const ds = data.datasets[0];
                const customLabel = ds.data[i];
                const value = customLabel || 0;

                return {
                  fillStyle:
                    i === 0 ? ds.backgroundColor[0] : ds.backgroundColor[1],
                  fontColor: "#8D9D91",
                  hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                  index: i,
                };
              });
            }
            return [];
          },
        },
      },
    },

    cutout: "75%",
    responsive: true,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    borderRadius: 8,
    spacing: 2,
  };

  // const returnDropCount = (data, key) => {
  //   const count = data?.filter((item) => item?.dropType === key);

  //   if (count?.[0]?.totalDrops) {
  //     return count?.[0]?.totalDrops >= 10
  //       ? count?.[0]?.totalDrops
  //       : count?.[0]?.totalDrops.toString().padStart(2, "0");
  //   } else {
  //     return 0;
  //   }
  // };

  return (
    <div
      className="flex justify-between items-center px-1"
    >
      <div className="flex justify-between items-start flex-col">
        {/* drop */}
        <div className="text-xs font-normal text-[#8D9D91] mb-4 flex justify-center items-center">
          <span className="w-3 rounded-sm h-3 mr-2 bg-[#B0E577]"></span>
          <span className="text-xs  font-normal "> Drops: </span>
          <span className="ml-[2px] text-xs  font-semibold  text-[#fff]">
            {" "}
            {donughtData?.dropTotal < 10
              ? donughtData?.dropTotal.toString().padStart(2, "0")
              : donughtData?.dropTotal}
            {/* {returnDropCount(donughtData, "Drop")}{" "} */}
          </span>
        </div>
        {/* air drop */}
        <div className="text-xs font-normal text-[#8D9D91]  flex justify-center items-center">
          <span
            className="w-3 rounded-sm h-3 mr-2 
             bg-[#E7E15A]"
          ></span>
          <span className="text-xs  font-normal ">Airdrops:</span>
          <span className="text-xs ml-[2px]  font-semibold  text-[#fff]">
            {" "}
            {donughtData?.airDropTotal < 10
              ? donughtData?.airDropTotal.toString().padStart(2, "0")
              : donughtData?.airDropTotal}
          </span>
        </div>
      </div>
      <div className="w-[94px] h-[94px]  relative max-w-[350px] before:absolute before:w-full before:h-full before:bg-tranaparent before:border-[10px] before:border-[#A5F33C]/10 before:rounded-full before:z-[0]">
        <Doughnut
          data={data}
          options={options}
          width={94}
          height={94}
          className="z-10 relative"
        />
        <div className="absolute top-[50%] left-[50%] text-center -translate-x-[50%] -translate-y-[50%]">
          <span className="text-sm">
            <span className="text-[#A5F33C] align-baseline">
              {donughtData?.dropTotal + donughtData?.airDropTotal < 10
                ? (donughtData?.dropTotal + donughtData?.airDropTotal)
                  .toString()
                  .padStart(2, "0")
                : donughtData?.dropTotal + donughtData?.airDropTotal}
            </span>
            /{outOfTotal}
          </span>
        </div>
      </div>
    </div>
  );
}

export default DoughnutChartSmall;
