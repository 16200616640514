import React from "react";

const SocialMediaInputField = (props) => {
  const { icon, name, register, placeholder, type, error ,...rest } = props;
  return (
    <>
      <div className="mb-6">
        <div className="flex items-center max-w-[494px] px-4 py-3 mb-1 border rounded-full bg-none border-[#A4B1A7]">
          <img className="mr-2" src={icon} alt="instagram" />
          <input
          {...rest}
            className="w-full p-0 bg-transparent border-none placeholder:text-white focus:ring-transparent"
            type={type}
            name={name}
            placeholder={placeholder}
            {...register(name)}
          />
        </div>
        {error && <p className="mt-1 text-xs text-red-700">{error}</p>}
      </div>
    </>
  );
};

export default SocialMediaInputField;
