import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { images } from "../../../constants/images";
import {
  setMenuSelected,
  setSubMenuSelected,
} from "../../../redux/features/sidebar-menu/sidebarMenuSlice";

import SubMenuItem from "./SubMenuItem";

const MenuItem = (props) => {
  const dispatch = useDispatch();
  const { openSubMenu, menuSelected } = useSelector((state) => state.sidebar);
  const [hoverIcon, setHoverIcon] = useState(false)

  const { menuItem, handleSubMenu } = props;

  const triggers = {
    onMouseEnter: () => setHoverIcon(true),
    onMouseLeave: () => setHoverIcon(false),
  };

  return (
    <>
      <li
      {...triggers}
        className={`relative  pl-0  p-[1.4px]  
        ${
          menuSelected === menuItem.value  ||
          openSubMenu === menuItem.title
            ? "bg-[#A5F33C]/10 menu-selected-before menu-selected-after"
            : ""
        }	rounded-r-lg hover:text-white text-[#8D9D91] `}
        onClick={() => {
          if (menuItem.submenu) {
            if (openSubMenu === "") {
              dispatch(setMenuSelected(-1));
              return;
            }
          }
          dispatch(setMenuSelected(menuItem.value));
          //  setSelectedMenu(menuItem.value);
        }}
      >
        <Link
          to={menuItem.url}
          className="flex   items-center flex-grow cursor-pointer"
        >
          <div
            className="inline-flex  items-center flex-grow py-3 pl-6 pr-6 "
            onClick={() => {
              if (menuItem.submenu) {
                if (openSubMenu === "" ) {
                  dispatch(setMenuSelected(-1));

                  handleSubMenu(menuItem.title);
                  dispatch(setSubMenuSelected(1));
                } else {
                  if (openSubMenu !== menuItem.title){
                    handleSubMenu(menuItem.title);
                    dispatch(setSubMenuSelected(1));
                  }else {
                    handleSubMenu("");
                    dispatch(setSubMenuSelected(0));
                  }
                 
                }
              } else {
                handleSubMenu("");
                dispatch(setSubMenuSelected(0));
              }
            }}
          >
            <span className="z-10 ">
              <img
                src={
                  menuSelected === menuItem.value ||
                  openSubMenu === menuItem.title
                    ? menuItem.icon
                    : hoverIcon ? menuItem.icon : menuItem.inactiveIcon
                }
                alt="bloomly"
              />
            </span>
            <span
              className={`z-10 ml-4 grow text-sm   truncate ${
                menuSelected === menuItem.value ||
                openSubMenu === menuItem.title
                  ? "text-white hover:text-white "
                  : "  hover:text-white font-normal"
              }`}
            >
              {menuItem.title}
            </span>
            {menuItem.submenu && (
              <>
                <span className="z-10 inline-flex items-center justify-center ">
                  <img
                    src={
                      openSubMenu === menuItem.title
                        ? images.ExpandCloseIcon
                        : images.ExpandIcon
                    }
                    alt="bloomly"
                  />
                </span>
              </>
            )}
          </div>
        </Link>
      </li>

      {openSubMenu === menuItem.title && (
        <SubMenuItem
          submenuItems={menuItem.submenu}
          openSubMenu={openSubMenu}
          menuItem={menuItem}
        />
      )}
    </>
  );
};

export default MenuItem;
