import React from 'react';
import { images } from '../../../../../../constants';

const WhitelistFileUploader = (props) => {
	const {
		
		fileInputRef,
		reset,
		errors,
		register,
		watch,
		getValues,
		setSelectedWhitelistOption
	} = props;

	const handleRemoveImage = () => {
		reset({ ...getValues(),  airdropFile: '' }); // update the name
	};


	const checkAirDropFile = watch('airdropFile');
	

	return (
		<>
			{/* File Uploader */}
			<label htmlFor="inputFileSelector" className="mb-2 cursor-pointer">
				{/* Image Selector */}
				<input
					hidden={true}
					id="inputFileSelector"
					ref={fileInputRef}
					accept={'.xlsx, .xls'}
					type="file"
					name="airdropFile"
					// className={inputFieldStyle}
					{...register('airdropFile')}
				/>

				<div className="flex items-center justify-between  text-white font-poppins border border-[#A4B1A7] py-4 px-5 pr-8 border-grey-100-700 border-solid rounded-xl w-full min-h-[96px] h-full">
					<div className="flex items-center justify-start flex-grow-1">
						<img className="mr-3" src={images.UploadIcon} alt="" />
						<div className="flex flex-col">
							<h4 className="text-base font-bold">Upload File</h4>
							<p className="mb-2 text-xs"> Browse Files</p>
							<p className="text-xs text-grey-100">
								.xlsx, .xls | Max file size: 5 MB
							</p>
						</div>
					</div>
					{/* <p className="flex-shrink text-xs font-bold text-grey-100">OR</p> */}
					<p className="flex-shrink text-base font-bold text-center text-[#A5F33C] ">
						Browse
					</p>
				</div>
			</label>
			{/* Image Displayer */}
			<div className="">
				{checkAirDropFile  && (
					<div className="flex justify-between items-center text-xs mt-8 flex-grow py-3 px-4 rounded-xl bg-transparent border  border-[#A4B1A7] min-w-[80px]">
						<div className="flex items-center">
							<img
								className="object-contain mr-2 w-7 h-7"
								src={images.FileIcon}
								alt="logo"
							/>
							<span className="w-full p-0 font-bold bg-transparent border-none focus:ring-transparent">
								{checkAirDropFile[0]?.name ?? 'whitlist file' }
							</span>
						</div>
					{	checkAirDropFile[0]?.size && <span className="text-grey-100">{  (checkAirDropFile[0]?.size / 1024).toFixed(2)   + 'kb'}</span>}
						<img
							className="cursor-pointer hover:opacity-80"
							src={images.cross_icon}
							alt="X"
							onClick={handleRemoveImage}
						/>
					</div>
				)}

				<p className="mt-1 text-xs text-red-600">
					{errors?.airdropFile?.message}
				</p>
			</div>
		</>
	);
};

export default WhitelistFileUploader;
