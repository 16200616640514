import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clientService from "../../services/client.service";
import { setLoader } from "../../redux/features/loading/loaderSlice";
import { clientDetailAction } from "../../redux/features/user/userSlice";
import { useRef } from "react";

const useClientThemeHook = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [OpenAlertModal, setOpenAlertModal] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: "",
    description: "",
    path: "",
    sublabel: "",
  });

  const [homeData, setHomeData] = useState({
    heroHdn: "",
    heroDsc: "",
    heroImg: "",
    heroBtnTxt: "",
    upComingDropTxt: "",
    upComingDropBtnTxt: "",
    currentDropTxt: "",
  });

  const [themeColor, setthemeColor] = useState({
    primarybackground: "#A5F33C",
    primarytext: "#12221A",
    bodybackground: "#060C07",
    bodytext: "#FFFFFF",
    terytiarybackground: "#12221A",
    tertiarytext: "#FFFFFF",
    secondarytext: "#8D9D91",
  });
  const [faviconFile, setFaviconFile] = useState("");
  const [maintinanceFile, setMaintinanceFile] = useState("");

  const [aboutData, setAboutData] = useState({
    aboutUsString: "",
    enableAboutUs: true,
  });
  const [termsData, setTermsData] = useState({
    termsString: "",
    enableTerms: true,
  });
  const [privacyData, setPrivacyData] = useState({
    privacyString: "",
    enablePrivacy: true,
  });

  const [pagePreferences, setPagePreferences] = useState({
    homePage: true,
    termsPage: true,
    aboutPage: true,
    maintenancePage: false,
    privacyPage: true,
  });

  const [functionaltyPreference, setFunctionaltyPreference] = useState({
    socialLinks: true,
    additionalDrop: true,
    emailSubscription: true,
    socialSharing: true,
  });

  const [maintenanceData, setMaintenanceData] = useState({
    title: "",
    description: "",
    enableMaintenance: false,
    image: "",
  });

  const [clientThemeDetail, setClientThemeDetail] = useState();

  const [defaultDisablePreferences, setDefaultDisablePreferences] =
    useState(true);

  const aboutRef = useRef("");
  const termsRef = useRef();
  const privacytRef = useRef("");

  const handleChangePagePreferences = (event) => {
    const { name, checked } = event.target;
    setPagePreferences({
      ...pagePreferences,
      [name]: checked,
    });
    setDefaultDisablePreferences(false);
  };

  const handleChangeFuncPreferences = (event) => {
    const { name, checked } = event.target;
    setFunctionaltyPreference({
      ...functionaltyPreference,
      [name]: checked,
    });
    setDefaultDisablePreferences(false);
  };

  const updateDataFunc = async (formData) => {
    const updateData = await clientService
      .createUpdateClientTheme(formData)
      .then((res) => {
        setLoading(false);
        setResetLoading(false);
        if (res?.status === 200) {
          if (res?.data?.success === true) {
            setOpenAlertModal(true);
            setTimeout(() => {
              setOpenAlertModal(false);
            }, 3000);
            setAlertContent({
              title: "Success",
              mainTitle: "Thank you for Submission!",
              description: "Changes have been saved successfully.",
              sublabel: "Auto closing in 3 seconds...",
            });
            setClientThemeDetail(res?.data?.payload);
          } else {
            setOpenAlertModal(true);
            setTimeout(() => {
              setOpenAlertModal(false);
            }, 3000);
            setAlertContent({
              title: "Error",
              mainTitle: "Sorry !",
              description: "Failed to save changes. Please try again.",
              sublabel: "Auto closing in 3 seconds...",
            });
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setResetLoading(false);
      });
  };

  const getClientThemeDetails = async () => {
    dispatch(setLoader(true));
    await clientService
      .getClientThemeDetails(
        user?.clientDetail?.id,
        user?.userInfo?.access_token
      )
      .then((res) => {
        dispatch(setLoader(false));
        if (res?.data?.payload) {
          setClientThemeDetail(res?.data?.payload);
          let data = res?.data?.payload;
          setHomeData({
            heroHdn: data?.heroHeading,
            heroDsc: data?.heroDescription,
            heroImg: data?.heroImgUrl,
            heroBtnTxt: data?.heroButtonText,
            upComingDropTxt: data?.upComingDropsHeadingText,
            upComingDropBtnTxt: data?.upComingDropsBtnText,
            currentDropTxt: data?.currentDropsHeadingText,
          });
          setFile(data?.heroImgUrl);
          if (data?.themeColorJson) {
            let themeColorScheme = JSON.parse(data?.themeColorJson);
            setthemeColor({
              ...themeColor,
              primarybackground: themeColorScheme.backgroundPrimaryColor,
              primarytext: themeColorScheme.textPrimaryColor,
              bodybackground: themeColorScheme.backgroundColor,
              bodytext: themeColorScheme.textBodyColor,
              terytiarybackground: themeColorScheme.backgroundTertiaryColor,
              tertiarytext: themeColorScheme.textTertiaryColor,
              secondarytext: themeColorScheme.textSecondaryColor,
            });
          }
          if (data?.faviconImage) {
            setFaviconFile(data?.faviconImage);
          }
          setAboutData({
            aboutUsString: data?.aboutUsHtmlString,
            enableAboutUs: data?.showAboutUsPage,
          });

          aboutRef.current = data?.aboutUsHtmlString;

          setTermsData({
            ...termsData,
            termsString: data?.termsAndConditionsHtmlString,
            enableTerms: data?.showTermsAndConditionsPage,
          });
          termsRef.current = data?.termsAndConditionsHtmlString;
          setPrivacyData({
            privacyString: data?.privacypolicyHtmlString,
            enablePrivacy: data?.showPrivacyPolicyPage,
          });
          privacytRef.current = data?.privacypolicyHtmlString;

          setMaintenanceData({
            title: data?.maintinanceModeHeading,
            description: data?.maintinanceModeDescription,
            enableMaintenance: data?.showMaintinancePage,
            image: data?.maintinanceModeImgUrl,
          });
       
          setMaintinanceFile(data?.maintinanceModeImgUrl);
          setPagePreferences({
            homePage: true,
            termsPage: data?.showTermsAndConditionsPage,
            aboutPage: data?.showAboutUsPage,
            maintenancePage: data?.showMaintinancePage,
            privacyPage: data?.showPrivacyPolicyPage,
          });
          setFunctionaltyPreference({
            socialLinks: data?.showSocialMediaIcons,
            additionalDrop: data?.showAdditionalDrops,
            emailSubscription: data?.showEmailSubscription,
            socialSharing: data?.showSocialMediaSharingOption,
          });
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };

  const submitSocialMediaLink = (formdata) => {
    clientService
      .updateClient(formdata)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          setResetLoading(false);
          setLoading(false);
          dispatch(clientDetailAction(response.data.payload));
          setOpenAlertModal(true);
          setTimeout(() => {
            setOpenAlertModal(false);
          }, 3000);
          setAlertContent({
            title: "Success",
            description: "Changes have been saved successfully.",
            sublabel: "Auto closing in 3 seconds...",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setResetLoading(false);
      });
  };

  const updateMaintenance = (formdata) => {
    clientService
      .updateClientMaintenaceMode(formdata)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          setLoading(false);
          setResetLoading(false);
          setOpenAlertModal(true);
          setTimeout(() => {
            setOpenAlertModal(false);
          }, 3000);
          setAlertContent({
            title: "Success",
            description: "Changes have been saved successfully.",
            sublabel: "Auto closing in 3 seconds...",
          });
          setClientThemeDetail(response?.data?.payload);
        }
      })
      .catch((error) => {
        setLoading(false);
        setResetLoading(false);
      });
  };

  const handlePreferences = (formdata) => {
    clientService
      .createUpdateClientPreferences(formdata)
      .then((response) => {
        setLoading(false);
        setResetLoading(false);
        setOpenAlertModal(true);
        setTimeout(() => {
          setOpenAlertModal(false);
        }, 3000);
        setAlertContent({
          title: "Success",
          description: "Changes have been saved successfully.",
          sublabel: "Auto closing in 3 seconds...",
        });
        setClientThemeDetail(response?.data?.payload);
      })
      .catch((error) => {
        setLoading(false);
        setResetLoading(false);
      });
  };

  const handleResetPreference = () => {
    setFunctionaltyPreference({
      socialLinks: true,
      additionalDrop: true,
      emailSubscription: true,
      socialSharing: true,
    });
    setPagePreferences({
      homePage: true,
      termsPage: true,
      aboutPage: true,
      maintenancePage: false,
      privacyPage: true,
    });
  };

  return {
    getClientThemeDetails,
    updateDataFunc,
    pagePreferences,
    functionaltyPreference,
    handleChangePagePreferences,
    handleChangeFuncPreferences,
    loading,
    OpenAlertModal,
    setOpenAlertModal,
    alertContent,
    setAlertContent,
    homeData,
    file,
    setFile,
    clientThemeDetail,
    setLoading,
    aboutData,
    setAboutData,
    termsData,
    setTermsData,
    privacyData,
    setPrivacyData,
    setthemeColor,
    themeColor,
    faviconFile,
    setFaviconFile,
    submitSocialMediaLink,
    resetLoading,
    setResetLoading,
    updateMaintenance,
    maintenanceData,
    handlePreferences,
    handleResetPreference,
    defaultDisablePreferences,
    maintinanceFile,
    setMaintinanceFile,
    aboutRef,
    termsRef,
    privacytRef,
  };
};

export default useClientThemeHook;
