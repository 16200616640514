import React from "react";

const CopiedUrlClipBoard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        d="M8.63636 1H10.2727C10.6309 1 10.9855 1.0776 11.3164 1.22836C11.6473 1.37913 11.9479 1.6001 12.2012 1.87868C12.4545 2.15726 12.6553 2.48797 12.7924 2.85195C12.9295 3.21593 13 3.60603 13 4C13 4.39397 12.9295 4.78407 12.7924 5.14805C12.6553 5.51203 12.4545 5.84274 12.2012 6.12132C11.9479 6.3999 11.6473 6.62087 11.3164 6.77164C10.9855 6.9224 10.6309 7 10.2727 7H8.63636M5.36364 7H3.72727C3.36912 7 3.01448 6.9224 2.68359 6.77164C2.3527 6.62087 2.05205 6.3999 1.7988 6.12132C1.28734 5.55871 1 4.79565 1 4C1 3.20435 1.28734 2.44129 1.7988 1.87868C2.31026 1.31607 3.00396 1 3.72727 1H5.36364"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 4H9"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopiedUrlClipBoard;
