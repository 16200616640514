
import DateFormat from '../../../../helpers/DateFormat';
import { replaceslashwithquote } from '../../../../helpers/ReplaceSlachWithQuotes';

export const COLUMNS = [
  {
    Header: "Drop Name",
    Footer: "Drop Name",
    accessor: "title",
    Cell: ({ value }) => (
      <>
      
      {value?.replace(replaceslashwithquote, "$1")}
      </>
    ),
    
  },
  {
    Header: "Asset Name",
    Footer: "Asset Name",
    accessor: "assetName",
  },

  {
    Header: "Public Sales Start Date",
    Footer: "Public Sales Start Date",
    accessor: "publicSalesStartDate",
    Cell: ({ value }) => (
      <>
       <DateFormat date={value} />
      </>
    ),
  },
  {
    Header: "Public Sales End Date",
    Footer: "Public Sales End Date",
    accessor: "publicSaleEndDate",
    Cell: ({ value }) => (
      <>
       <DateFormat date={value} />
      </>
    ),
  },
 
  {
    Header: "Status",
    Footer: "Status",
    accessor: "b_C_Status",
    Cell: ({ row }) => (
      <>
        {row?.original?.b_C_Status===true?"Active":"Inactive"}
      </>
    ),
  },
  // {
  //   Header: "Actions",
  //   Footer: "Actions",
  //   accessor: "action",
  //   Cell: ({ row }) => (
  //     <>
  //       <img
  //         className="mr-1 text-center cursor-pointer hover:opacity-90"

  //         src={images.more_icon}
  //         alt="icon"
  //       />
  //     </>
  //   ),
  // },
];
