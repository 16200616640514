import { images } from "../../../../constants";
import stringTruncate from "../../../../helpers/StringTruncate";

export const COLUMNS = [
  {
    Header: "Asset",
    Footer: "Asset",
    accessor: "ArweaveAssetUri",
    Cell: ({ row }) => (
      <>
        {row?.values?.ArweaveAssetType === "Image" && (
          <img
            className=" rounded w-6 h-6 "
            onLoad={(e) => {
              e.currentTarget.attributes.src.value =
                row?.values?.ArweaveAssetUri;
            }}
            src={images.DefaultImageIcon}
            alt="icon"
          />
        )}
        {row?.values?.ArweaveAssetType === "Video" && (
          <img
            className=" rounded w-6 h-6 "
            src={images.DefaultImageIcon}
            alt="icon"
          />
        )}
        {row?.values?.ArweaveAssetType === "Audio" && (
          <img
            className=" rounded w-6 h-6 "
            src={images.AudioIcon}
            alt="icon"
          />
        )}
      </>
    ),
  },
  {
    Header: "Asset Name",
    Footer: "Asset Name",
    accessor: "Asset_Title",
    Cell: ({ row }) => (
      <>{<p>{stringTruncate(row?.values?.Asset_Title, 12)}</p>}</>
    ),
  },
  {
    Header: "Asset Type",
    Footer: "Asset Type",
    accessor: "ArweaveAssetType",
  },

  {
    Header: "Max Supply",
    Footer: "Max Supply",
    accessor: "maxSupply",
    Cell: ({ row }) => (
      <>
        <p>
          {row?.values?.maxSupply === null ? (
            <p>Unlimited</p>
          ) : (
            row?.values?.maxSupply
          )}
        </p>
      </>
    ),
  },
  {
    Header: "Asset Transfer",
    Footer: "Asset Transfer",
    accessor: "transferable",
    Cell: ({ row }) => (
      <>
        <p>
          {row?.values?.transferable ? (
            <>
              <img className="mr-1 inline-block" src={images.TransferableIcon} alt="icon" />
              <span>    Transferable
              </span>
            </>
          ) : (
            <>
              <img className="mr-1 inline-block" src={images.NonTransferableIcon} alt="icon" />
              <span>  Non-transferable
              </span>
            </>
          )}
        </p>
      </>
    ),
  },
  {
    Header: "Created",
    Footer: "Created",
    accessor: "Date_Created",
  },
];
