import { images } from "../../../../../constants";

const DropSearch = ({SearchAssetByNameHandler ,assetSearchValue}) => {

    return (
        <div className='bg-[#12221A4D]/30 rounded-lg py-4 px-6 mb-8 mr-8'>
          <div className="relative z-10 ">
          <img
            className="mr-2 absolute left-0 mt-[12px]"
            src={images.searchIcon}
            alt="search"
        
          />
          <input
            type="text"
            // name="email"
            autoComplete="false"
            className={` peer pl-8 outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-white/30 rounded-none  pr-7 text-white text-md   focus:ring-0 focus:border-b-white/30 block w-full p-3 bg-transparent `}
            placeholder=" "
            value={assetSearchValue}
  onChange={SearchAssetByNameHandler}
          />
          <label
            className={`text-xs left-0 text-grey-100 z-[-1] absolute  -top-[10px] peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-[12px]  peer-placeholder-shown:left-8   peer-placeholder-shown:text-base peer-placeholder-shown:text-white/30 peer-focus:-top-[10px] peer-focus:left-0   peer-focus:text-grey-100 peer-focus:text-xs`}
          >
            Search
          </label>
        </div>
          
        
            
            
           
        </div>
    );
} 
export default DropSearch;