import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setSubMenuSelected } from "../../../redux/features/sidebar-menu/sidebarMenuSlice";

const SubMenuItem = (props) => {
  const dispatch = useDispatch()
  const { submenuSelected } = useSelector(state => state.sidebar);
  const { submenuItems  } = props;

  return (
    <>
      {submenuItems?.map((submenuItem, index) => (
         
            <li onClick={() => {
              dispatch(setSubMenuSelected(submenuItem.value))
           
          }} className={`  ${submenuSelected === submenuItem.value ? "" : ""}  hover:text-white text-[#8D9D91] p-[1.5px] text-left text-sm mt-2 w-5/5  rounded-r-lg   font-bold    relative flex flex-row items-center h-12   border-transparent cursor-pointer    	 `}>
              <Link to={submenuItem?.url}  className="flex items-center flex-grow py-3 pl-6 pr-6 cursor-pointer ">
              <span className="z-10 ">
              {submenuSelected === submenuItem.value  ? <img src={submenuItem.icon} alt="bloomly" /> :<span className="w-6 h-6"></span> }  
              </span>
              <span className={`z-10 ml-4 text-sm   truncate ${submenuSelected === submenuItem.value?  'text-[#A5F33C] font-normal' :' font-normal'}`}>
                {submenuItem.title}
              </span>
              </Link>
            </li>
         
      ))}
    </>
  );
};

export default SubMenuItem;
