import React, { useState, useEffect } from "react";
import BRANDS from "../../data/BRANDS.json";
import clientService from "./../../services/client.service";
import { useNavigate } from "react-router-dom";
import { data } from "autoprefixer";
import Spinner from "./../ui-components/loader-components/Spinner";
import { update_menu_items_state } from "../../redux/features/menu/menuItemsSlice";
import { useDispatch, useSelector } from "react-redux";
import { clientDetailAction } from "../../redux/features/user/userSlice";

const KybBrandCategories = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [otherCategoryName, setOtherCategoryName] = useState("");

  const handleSetCategory = (brand) => {
    setCategory(brand.id);
  };

  const getCategories = async () => {
    const response = await clientService.getBrandCategories();
    setCategoriesList(response?.data?.payload);
  };

  useEffect(() => {
    getCategories();

    if (user.clientDetail.categoryTypeId) {
      if (user.clientDetail.categoryTypeId === 12) {
        setOtherCategoryName(user?.clientDetail?.othersCategoryText);
      }

      setCategory(user.clientDetail.categoryTypeId);
    }
  }, []);

  const callDispatch = (payload) => {
    dispatch(update_menu_items_state(3));
    dispatch(clientDetailAction(payload));
    navigate("/onboarding/brand-theme");
  };

  const submitCategoryForm = () => {
    // const body = {
    //     ThemeId: 1,
    //     stepsEnum: 4,
    // }
    setLoading(true);

    if (category === 12 && otherCategoryName !== "") {
      const formData = new FormData();
      formData.append("ClientUuid", user.clientUuid);
      formData.append("CategoryId", category);
      formData.append("OthersCategoryText", otherCategoryName);
      formData.append("stepsEnum", 3);
      UpdateClient(formData);
    } else {
      const formData = new FormData();
      formData.append("ClientUuid", user.clientUuid);
      formData.append("CategoryId", category);
      formData.append("stepsEnum", 3);
      UpdateClient(formData);
    }
  };

  const UpdateClient = (formData) => {
    clientService
      .updateClient(formData)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          setLoading(false);
          callDispatch(response.data.payload);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const activeClass = `text-[#A5F33C] bg-[#A5F33C]/10  border-[#A5F33C]`;
  const hoverClasses = `hover:text-[#A5F33C] hover:bg-[#A5F33C]/10 hover:bg-opacity-20 hover:border-[#A5F33C]`;

  return (
    <>
      <div>
        <h1 className="text-2xl font-bold">Brand Categories</h1>
        <p className="mt-1 mb-6 text-xs text-[#8D9D91] max-w-[448px]">
          Please choose one of the following categories that fits your brand. If
          you do not see your category, you can create by clicking on others.{" "}
          {categoriesList?.data?.message}
        </p>
        <div className="flex flex-wrap items-center justify-start max-w-lg mb-5">
          {categoriesList?.map((brand) => (
            <span
              key={brand.id}
              className={`px-8 py-2 mb-5 rounded-full text-white  bg-transparent 
                        cursor-pointer border-2 mr-6 ${hoverClasses} ${
                brand.id === category ? activeClass : "border-[#A4B1A7]/25"
              }`}
              onClick={() => {
                handleSetCategory(brand);
              }}
            >
              {brand.categoryName}
            </span>
          ))}
        </div>

        {category === 12 && (
          <div className="relative z-10 mt-8 mb-8 ">
            <input
              type="text"
              // name="email"
              className={`max-w-[448px] peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-white/70 rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-white block w-full p-3 bg-transparent `}
              placeholder=" "
              value={otherCategoryName}
              onChange={(e) => setOtherCategoryName(e.target.value)}
              maxLength={100}
              // required
            />
            <label
              className={`text-xs text-grey-100 z-[-1] absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
            >
              Add category
            </label>
          </div>
        )}

        <button
          onClick={submitCategoryForm}
          disabled={loading || (category === 12 && otherCategoryName === "") || !category}
          className="max-w-md mb-16 btn-primary disabled:pointer-events-none"
        >
          {loading && <Spinner />} Save &amp; Continue
        </button>
      </div>
    </>
  );
};

export default KybBrandCategories;
