import { useOutletContext } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addClientSchema } from "../../utils/ValidationSchemas/AddClientSchema";
import clientService from "./../../services/client.service";
import { useState } from "react";
import Spinner from "../ui-components/loader-components/Spinner";
import AlertModalContent from "./AlertModalContent";
import authService from "./../../services/auth.service";
import CloseIconView from "../ui-components/views-component/CloseIconView";
import { images } from "./../../constants/images";
import SelectPackage from "./../ui-components/select-package-component/SelectPackage";
import { Tooltip } from "@material-tailwind/react";

const AddClientModalContent = (props) => {
  const {
    setShowModal,
    alertMessage,
    setAlertMessage,
    handleCloseModal,
    ClientPackagesData,
  } = props;
  const { inputFieldStyle } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [checkuserEmail, setcheckuserEmail] = useState(false);
  const [disableOnFocus, setDisableOnFocus] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    unregister,
    formState: { errors, isDirty, isValid },
  } = useForm({ resolver: yupResolver(addClientSchema), mode: "onChange" });

  const bManualFee = watch("addPercent");
  const handleAddClient = (data) => {
    setLoading(true);
    const email = data?.email;
    authService
      .checkClientAlreadyExist(email)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          setcheckuserEmail(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.statusCodeValue === 404) {
          setcheckuserEmail(false);

          setLoading(true);
          clientService
            .getClientId(data)
            .then((response) => {
              if (response?.data?.statusCodeValue === 200) {
                const clientId = response.data.payload;
                clientService
                  .registerUser({ ...data, clientId: clientId })
                  .then((response) => {
                    if (response?.data?.statusCodeValue === 200) {
                      setAlertMessage({
                        title: "Success",
                        description: "Email sent successfully",
                      });

                      reset();
                      setLoading(false);
                    } else {
                      reset();
                      setLoading(false);
                    }
                  })
                  .catch((error) => {
                    setAlertMessage({
                      title: "Error",
                      description:
                        error?.response?.data?.message ??
                        "Something went wrong",
                    });

                    reset();
                    setLoading(false);
                  });
              }
            })
            .catch((error) => {
              setLoading(false);
              //  setShowModal(false);
              setAlertMessage({
                title: "Error",
                description:
                  error?.response?.data?.message ?? "Something went wrong",
              });
            });
        } else {
          setAlertMessage({
            title: "Error",
            description: err?.response?.data?.message ?? "Something went wrong",
          });
        }
      });
  };
  const handleCheckClientExist = (e) => {
    const email = e.target.value;
    authService
      .checkClientAlreadyExist(email)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          setcheckuserEmail(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        setcheckuserEmail(false);
        setLoading(false);
      });
  };

  return (
    <>
      {alertMessage?.title !== "" ? (
        <>
          <div className="relative">
            <AlertModalContent message={alertMessage} />
            <button
              type="button"
              onClick={() => {
                if (alertMessage?.title === "Success") {
                  reset();
                  handleCloseModal();
                }
                setcheckuserEmail(false);
                setLoading(false);
                //reset();

                //setShowModal(false);

                setAlertMessage({ title: "", description: "" });
              }}
              className="absolute -top-10 -right-10 text-white bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="authentication-modal"
            >
              <CloseIconView />
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="relative">
            <button
              type="button"
              onClick={() => {
                setcheckuserEmail(false);
                setLoading(false);
                setShowModal(false);
                reset();
              }}
              className="absolute -top-10 -right-10 text-white bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="authentication-modal"
            >
              <CloseIconView />
            </button>

            <h3 className="mb-4 text-2xl font-bold text-white">Add Client</h3>
            <form
              className="space-y-6"
              action="#"
              onSubmit={handleSubmit(handleAddClient)}
            >
              <div className="grid grid-cols-2 gap-3 mb-4">
                <div class="relative   z-10">
                  <input
                    type="text"
                    id="username"
                    placeholder=" "
                    className={`${inputFieldStyle} peer placeholder:text-white `}
                    {...register("firstName")}
                  />
                  <label
                    className={`text-xs text-grey-100 z-[-1] absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                  >
                    First Name
                  </label>
                  <p className="mt-2 text-xs text-red-600">
                    {errors?.firstName?.message}
                  </p>
                </div>

                <div className="relative z-10">
                  <input
                    type="text"
                    className={`${inputFieldStyle} peer placeholder:text-white `}
                    placeholder=" "
                    {...register("lastName")}
                  />
                  <label
                    className={`text-xs text-grey-100 z-[-1] absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                  >
                    Last Name
                  </label>
                  <p className="mt-1 text-xs text-red-600">
                    {errors?.lastName?.message}
                  </p>
                </div>
              </div>

              <div className="relative z-10">
                <input
                  onFocus={() => setDisableOnFocus(true)}
                  autoComplete="off"
                  type="email"
                  className={`${inputFieldStyle} peer placeholder:text-white `}
                  placeholder=" "
                  {...register("email", {
                    onBlur: (e) => {
                      handleCheckClientExist(e);
                      setDisableOnFocus(false);
                    },
                    onChange: (e) => {
                      setcheckuserEmail(false);
                    },
                  })}
                />
                <label
                  className={`text-xs text-grey-100 z-[-1] absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                >
                  Email
                </label>
                {checkuserEmail && (
                  <p className="mt-1 text-xs text-red-600">
                    Client already Registered
                  </p>
                )}
                <p className="mt-1 text-xs text-red-600">
                  {errors?.email?.message}
                </p>
              </div>

              <div className="mt-6">
                <h4 className="mb-3 text-base font-bold text-white">
                  Select Package
                </h4>
                {ClientPackagesData &&
                  ClientPackagesData?.map((reslt, i) => {
                    return (
                      <div className="mb-6">
                        <SelectPackage
                          packageName={reslt?.packageName}
                          platformFee={reslt?.platformFee}
                          register={register}
                          packageId={reslt?.id}
                          ClientPackagesData={ClientPackagesData}
                        />
                      </div>
                    );
                  })}
                <div className="mb-10 ">
                  <div className="flex items-center justify-between">
                    <div className="form-group form-check">
                      <input
                        {...register("addPercent", {
                          onChange: (e) => {
                            if (!e.target.checked) {
                              unregister(`platformFee`);
                            }
                          },
                        })}
                        type="checkbox"
                        className="float-left w-5 h-5 mt-1 mr-2 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border-2 border-gray-300 rounded-[4px] appearance-none cursor-pointer form-check-input checked:bg-[#A5F33C] checked:bg-[url('/src/assets/images/icons/checkedIconAuth.svg')] checked:border-[#A5F33C] focus:shadow-none focus:outline-none focus:outline-none focus:ring-0 focus:ring-offset-0"
                        id="exampleCheck2"
                      />
                      <label
                        className="inline-block text-xs font-semibold text-white form-check-label"
                        htmlFor="exampleCheck2"
                      >
                        Update Platform Fee
                      </label>
                    </div>
                  </div>
                </div>
                {bManualFee === true && (
                  <div className="relative mb-10">
                    <input
                      type="number"
                      className={`${inputFieldStyle} peer placeholder:text-white`}
                      placeholder=" "
                      {...register("platformFee")}
                    />
                    <label
                      className={`text-xs text-grey-100 z-[-1] absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                    >
                      Platform Fee (%)
                    </label>
                    <p className="mt-1 text-xs text-red-600">
                      {errors?.platformFee?.message}
                    </p>
                    <img
                      className="absolute right-0 cursor-pointer top-3"
                      src={images.PercentageIcon}
                      alt="info-icon"
                    />
                  </div>
                )}
              </div>
              <div className="relative z-0 mt-8">
                <textarea
                  className="peer outline-none  pl-3 pr-8 py-2  border rounded-xl border-[#A4B1A7] text-white text-md  placeholder:text-white focus:ring-transparent focus:border-[#A4B1A7] focus:outline-none  block w-full  bg-transparent"
                  rows="4"
                  placeholder=" "
                  name="Notes"
                  maxlength="501"
                  {...register("Notes")}
                ></textarea>
                <label
                  className={`text-xs text-grey-100 z-[-1]  left-0 absolute  -top-6 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3   peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-6   peer-focus:text-grey-100 peer-focus:text-xs peer-focus:left-0`}
                >
                  Notes
                  <Tooltip
                    className="custom-tooltip-style bg-[#0A130D] z-[100] text-xs  relative max-w-[220px]"
                    offset={10}
                    placement={"top-center"}
                    content={
                      "Notes are only for internal purposes. These will not be visible for the client. "
                    }
                  >
                    <img
                      className="inline ml-2 cursor-pointer -m-[2px] w-5 h-5 -mt-1"
                      src={images.InfoIcon}
                      alt="info-icon"
                    />
                  </Tooltip>
                </label>
                {!errors?.Notes?.message && (
                  <p className="mt-2 text-xs text-grey-100">
                    (Max length 500 characters)
                  </p>
                )}
                <p className="mt-1 text-xs text-red-600">
                  {errors?.Notes?.message}
                </p>
              </div>
              <button
                disabled={
                  !isDirty ||
                  !isValid ||
                  checkuserEmail ||
                  disableOnFocus ||
                  loading
                }
                type="submit"
                className=" disabled:cursor-not-allowed btn-primary"
              >
                {loading && <Spinner />}
                Send Email
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default AddClientModalContent;
