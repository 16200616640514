import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import KeyValueView from "../../../../components/ui-components/views-component/KeyValueView";
import useUSDToFlow from "../../../../helpers/custom-hooks/useUSDToFlow";
import ClientHeaderComponent from "../../components/ClientHeaderComponent";
import { images } from "./../../../../constants/images";
import MintTransferModal from "../../../../components/Modals/MintTransferModal";
import Modal from "../../../../common/ModalLayout";
import { Tooltip } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { GetStorageData } from "../../../../helpers/custom-hooks/getStorageData";

const ClientTransactionDetail = () => {
  const [stripeUrl, setStripeUrl] = useState("");
  const [openProcessModal, setOpenProcessModal] = useState(false);
  const { mintTransfer } = useSelector((state) => state.user);

  const flowPrice = useUSDToFlow();
  const location = useLocation();
  const { state } = location;
  const { data } = state || {};

  useEffect(() => {
    if (data?.paymentProcessor === "Stripe") {
      if (data?.paymentResponseJson === "null") return;
      else if (data?.paymentResponseJson === "") return;
      else {
        let stripeUrl = JSON.parse(data?.paymentResponseJson);
        stripeUrl = stripeUrl?.Content;
        stripeUrl = JSON.parse(stripeUrl);
        setStripeUrl(stripeUrl?.receipt_url);
      }
    }

    return () => {
      setStripeUrl("");
    };
  }, [stripeUrl]);

  const handleMintTransferModal = () => {
    setOpenProcessModal(true);
  };

  return (
    <>
      <ClientHeaderComponent
        title={"Back to Transaction History"}
        backLink={"/tx-reporting"}
      />
      <h3 className="text-white text-xl font-bold">Transaction Detail</h3>
      <p className="text-xs text-[#8D9D91] pb-6">
        Detailed breakdown of the transaction history is presented below.
      </p>

      {data === undefined || data === null ? (
        <>
          <h4 className="mt-10 text-2xl text-center">Record not found</h4>
        </>
      ) : (
        <>
          <div className="">
            <div className="  relative z-20 grid grid-cols-2  ">
              <div className=" ">
                <div className=" w-full max-w-[500px] p-3 max-h-[500px] bg-[#060C07]  rounded-2xl border border-[#A4B1A7]/[0.12] ">
                  {data?.assetVariationObject?.arweaveAssetType === "Image" && (
                    <img
                      className="w-full rounded max-h-[475px] object-contain"
                      // onLoad={(e) => {
                      //   e.currentTarget.attributes.src.value =
                      //     data?.assetVariationObject?.originalImage;
                      // }}
                      src={data?.assetVariationObject?.originalImage}
                      alt="icon"
                    />
                  )}
                  {data?.assetVariationObject?.arweaveAssetType === "Video" && (
                    <video
                      controls
                      className="w-full rounded xl:min-h-[475px] max-h-[475px] object-contain"
                      src={data?.assetVariationObject?.videoUri}
                      alt="icon"
                    />
                  )}
                  {data?.assetVariationObject?.arweaveAssetType === "Audio" && (
                    <video
                      controls
                      poster={images.AudioIcon}
                      className="rounded w-full max-h-[475px] object-contain"
                      src={data?.assetVariationObject?.audioUri}
                      alt="icon"
                    />
                  )}
                </div>
              </div>
              <div className="h-[500px] overflow-y-auto ">
                <div className="flex mb-6 min-w-[230px]">
                  <KeyValueView
                    title={"Blockchain Response"}
                    value={data?.orderStatus ?? "-"}
                    success={true}
                    mainStyle={"min-w-[230px] mr-6"}
                    beforeIconValue={true}
                  />
                  <KeyValueView
                    title={"Status"}
                    value={data?.orderStatus ?? "-"}
                    success={true}
                    mainStyle={"min-w-[230px]"}
                    beforeIconValue={true}
                  />
                </div>
                <div className="flex mb-6">
                  <KeyValueView
                    title={"Asset Name"}
                    value={data?.assetName ?? "-"}
                    mainStyle={"min-w-[230px] mr-6"}
                  />
                  <KeyValueView
                    title={"Purchaser"}
                    value={
                      data?.purchaserName !== " "
                        ? data?.purchaserName
                        : data?.emailAddress
                    }
                    mainStyle={"min-w-[230px]"}
                  />
                </div>

                <div className="flex mb-6">
                  <KeyValueView
                    title={"USD Price"}
                    value={`$ ${data?.totalPriceUsd}`}
                    mainStyle={"min-w-[230px] mr-6"}
                  />
                  <KeyValueView
                    title={"Flow Price"}
                    value={`${
                      flowPrice
                        ? `${(flowPrice * data?.totalPriceUsd)?.toFixed(
                            4
                          )} Flow`
                        : "-"
                    } `}
                    mainStyle={"min-w-[230px]"}
                  />
                </div>

                <div className="flex mb-6">
                  <KeyValueView
                    title={"Platform Fee"}
                    value={data?.platformFee ? `$${data?.platformFee}` : "-"}
                    mainStyle={"min-w-[230px] mr-6"}
                  />
                  <div className={`min-w-[170px]`}>
                    <Tooltip
                      className="custom-tooltip-style  bg-[#0A130D]  text-xs max-w-[220px]  z-[50] "
                      offset={10}
                      placement={"top-start"}
                      content={
                        "The payment method utilized by the customer for acquiring this asset."
                      }
                    >
                      <p className="text-xs text-grey-100">
                        Payment Source{" "}
                        <img
                          className="inline ml-1 align-sub w-4 h-4"
                          src={images.InfoIconSm}
                          alt="icon"
                        />{" "}
                      </p>
                    </Tooltip>
                    <img
                      className="inline w-4 h-4 mr-2"
                      src={
                        data?.paymentProcessor === "Stripe"
                          ? images.MasterCardIcon
                          : images.FlowFcIcon
                      }
                      alt="icon"
                    />{" "}
                    {data?.paymentProcessor === "Stripe" && stripeUrl && (
                      <a target="_blank" rel="noreferrer" href={stripeUrl}>
                        <span className="underline hover:text-[#A5F33C]">
                          Stripe
                        </span>
                      </a>
                    )}
                    {data?.paymentProcessor === "Blocto" && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_ACCESS_NODE?.includes("testnet")
                            ? `${process.env.REACT_APP_FLOW_TESTNET_STORAGE_TRANSACTION_LINK}${data?.transactionHash}`
                            : `${process.env.REACT_APP_FLOW_MAINNET_STORAGE_TRANSACTION_LINK}${data?.transactionHash}`
                        }
                      >
                        <span className="underline hover:text-[#A5F33C]">
                          Blocto
                        </span>
                      </a>
                    )}
                  </div>
                </div>
                <div className="flex mb-6">
                  <KeyValueView
                    title={"Order Date"}
                    value={moment(data?.orderDate).format("MMM D YYYY ") ?? "-"}
                    mainStyle={"min-w-[230px] mr-6"}
                  />
                  {data?.orderStatus?.toLowerCase() === "successful" && (
                    <div className={`min-w-[170px]`}>
                      <p className="text-xs text-grey-100 ">Transaction</p>

                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_ACCESS_NODE?.includes("testnet")
                            ? `${process.env.REACT_APP_FLOW_TESTNET_STORAGE_TRANSACTION_LINK}${data?.transactionHash}`
                            : `${process.env.REACT_APP_FLOW_MAINNET_STORAGE_TRANSACTION_LINK}${data?.transactionHash}`
                        }
                        className="underline hover:text-[#A5F33C]"
                      >
                        View Transaction
                      </a>
                    </div>
                  )}
                </div>
                <div className="flex mb-6">
                  <KeyValueView
                    title={"Purchaser Profile"}
                    // value={data?.platformFee ? `$${data?.platformFee}` : "-"}
                    mainStyle={"min-w-[230px] mr-6"}
                    value={
                      <a
                        className="underline hover:text-[#A5F33C]"
                        href={`https://${
                          GetStorageData()?.clientDomainName
                        }/collections/${data?.userWalletAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Profile
                      </a>
                    }
                  />
                  <div className={`min-w-[170px]`}>
                    <p className="text-xs text-grey-100 ">Asset Transfer</p>

                    <p>
                      {data?.isTransferAble ? (
                        <>
                          <div className="flex items-center">
                            <img
                              className="mr-1 inline-block"
                              src={images.TransferableIcon}
                              alt="icon"
                            />
                            <span> Transferable</span>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="flex items-center">
                            <img
                              className="mr-1 inline-block"
                              src={images.NonTransferableIcon}
                              alt="icon"
                            />
                            <span> Non-transferable</span>
                          </div>
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <div className="mb-6 max-w-[485px]">
                  <KeyValueView
                    title={"Description"}
                    value={data?.description ?? "-"}
                    mainStyle={"min-w-[230px] mr-6"}
                  />
                </div>
                {data?.orderStatus === "Mint Transfer Failed" &&
                  !mintTransfer && (
                    <button
                      className="btn-primary max-w-[490px] "
                      onClick={handleMintTransferModal}
                    >
                      Mint and Transfer
                    </button>
                  )}
              </div>
            </div>
          </div>
          {openProcessModal && (
            <Modal
              modalVisible={openProcessModal}
              closeModal={setOpenProcessModal}
              children={
                <MintTransferModal
                  data={data}
                  openProcessModal={openProcessModal}
                  setOpenProcessModal={setOpenProcessModal}
                />
              }
              modal_Id="Save_Modal"
              isClose={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default ClientTransactionDetail;
