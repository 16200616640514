import React from "react";
import { useReducer } from "react";
import { Link } from "react-router-dom";
import { INITIAL_STATE, menuItemsReducer } from "../Utils/menuItemsReducer";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const RoundedBorderNav = ({ items, activeItem }) => {
  // const [state] = useReducer(menuItemsReducer, INITIAL_STATE);

  const { menuItems } = useSelector((state) => state.menuItems);

  return (
    <ul className="flex flex-col items-start ml-[65px] justify-center">
      {menuItems?.map((item) => (
        <li
          key={item.id}
          className={`my-5 relative flex items-center step-line-after ${
            item.isActive && "active-step"
          }`}
        >
          {/* <div className={`rounded-xl w-[37px] h-[37px] flex items-center justify-center mr-4 ${item.isActive && "bg-[#A5F33C]"}`}> */}
          {/* <span className="absolute inset-0 rounded-full border-linear-gradient" />
                        <span className="absolute inset-0 rounded-full border-linear-gradient" />
                        <span className="absolute inset-0 rounded-full border-linear-gradient" />
                        <span className="absolute inset-0 rounded-full border-linear-gradient" /> */}
          <div
            className={`  border mr-4 ${
              item.isActive
                ? "bg-[#A5F33C] border-[#A5F33C] text-[#060C07] "
                : activeItem.includes(item.link)
                ? "border-[#A5F33C] text-white bg-[#213E28]"
                : "bg-[#213E28] border-[#4D6553] text-[#8D9D91]"
            }   w-8 h-8 px-2 flex items-center justify-center rounded-xl 
                        before:absolute before:w-100-2px before:h-100-2px before:top-px before:left-0 before:border-radius-top-right
                        `}
          >
            {item.step}
          </div>
          {/* </div> */}
          <Link
            to={item.isActive && "../onboarding" + item.link}
            className={`text-center ${
              activeItem.includes(item.link)
                ? "text-white font-bold"
                : " text-[#8D9D91] font-normal"
            } text-base  ${
              item.isActive ? "cursor-pointer" : "cursor-not-allowed"
            }`}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );

  // return (
  //     <div className="relative border-4 border-dashed rounded-full">
  //         <span className="absolute inset-0 bg-red-500 rounded-full" />
  //         <span className="absolute inset-0 bg-yellow-500 rounded-full" />
  //         <span className="absolute inset-0 bg-green-500 rounded-full" />
  //         <span className="absolute inset-0 bg-blue-500 rounded-full" />
  //     </div>
  // );
};

export default RoundedBorderNav;
