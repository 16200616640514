import React, { useRef, useState } from 'react'
import CopyIconView from '../../../components/ui-components/views-component/CopyIconView';
import { images } from '../../../constants';
import CopyIconViewSm from '../../../components/ui-components/views-component/CopyIconViewSm';

const AdminWalletAddress = (props) => {
    const {WalletBalance ,walletAddress ,title ,titleValue ,minWidth ,success,} = props;
    const copyAddress = useRef();
  const [showCopiedText, setShowCopiedText] = useState(false)

  const copyText = async (event) => {
    event.preventDefault();
    await navigator.clipboard
      .writeText(copyAddress.current.getAttribute("value"))
      .then(() => {
        setShowCopiedText(true)
        const interval = setTimeout(() => {
         setShowCopiedText(false);
          clearInterval(interval)
        }, 3000);
        
      })
      .catch(() => {});
  };
  return (
    <>
    <div className={`${minWidth ? minWidth : 'min-w-[170px]'} relative  `}>
                 <><p className={`text-xs text-grey-100`}>{title}
                 <span onClick={copyText} className='inline ml-2 cursor-pointer  text-white hover:text-[#A5F33C]'> <CopyIconViewSm  /></span>
                 </p>
                 <p className={`text-base ${success && 'text-[#B0E577] font-semibold '}`} ref={copyAddress} value={titleValue}>{titleValue}</p>
                 </>
                 {showCopiedText && <span className='absolute custom-tooltip-style bg-[#0A130D] right-5 -bottom-10 '>Copied to clipboard</span>}
              </div>



     {/* <div className={`relative flex-col flex items-center max-w-md px-8 py-2  mr-5  `}>
            <p className='w-full text-base bg-transparent text-[#A5F33C] font-bold'  ref={copyAddress} value={walletAddress}>
                <span className='font-normal text-white'>
                  <img className='inline mr-2' src={images.WalletIcon} alt='icon' />
                   </span>
                {
                

                walletAddress?.length > 5
                ? walletAddress?.substr(0, 6) +
                  "..." +
                  walletAddress?.substr(
                    walletAddress?.length - 4,
                    walletAddress?.length
                  )
                : walletAddress  ?? "-"
                
                }
                <span onClick={copyText} className='inline ml-2 cursor-pointer text-white hover:text-[#A5F33C]'> <CopyIconView  /></span>
                <span className='ml-3 text-base font-normal text-white'>Balance : {' '}<span className='text-[#A5F33C] font-bold'>{WalletBalance ? WalletBalance?.toFixed(2) : '0'} Flow </span> </span>
            </p>
            
            {showCopiedText && <span className='absolute p-2 text-white border border-[#A4B1A7]/25 bg-[#12221A] text-xs rounded-2xl -right-5 -bottom-10 '>Copied to clipboard</span>}
        </div> */}
    </>
  )
}

export default AdminWalletAddress