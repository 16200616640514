import React, { useEffect, useState } from "react";
import AddTextFieldComponent from "../AddTextFieldComponent";
import { useNavigate } from "react-router-dom";
import SwitchComponent from "../../../../../../components/ui-components/switch-component/SwitchComponent";
import MutableToggle from "./MutableToggle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AttachDocument from "./AttachDocument";
import { addMutableDocumentSchema } from "../../../../../../utils/ValidationSchemas/AddMutableDocumentSchema";

const MutableComponent = (props) => {
  const navigate = useNavigate();
  const {
    steps,
    setSteps,
    setAlertContent,
    setOpenAlertModal,
    fieldIndex,
    setFieldIndex,
    activeStep,
    setActiveStep,
    setMutableInputFields,
    mutableInputFields,
    mutableFilledData,
    updateAssetHandler,
    setShowMutableKeyValue,
    showMutableKeyValue,
    addMutableDocument,
    setAddMutableDocument,
    mutableDocumentData,
    setMutableDocumentData,
  } = props;
  /** Add dynamic set values */

  const [checkFieldkeyDuplicate, setcheckFieldDuplicate] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      addMutableDocument: addMutableDocument,
      mutableDocumentName: mutableDocumentData?.mutableDocumentName || "",
      mutableDocumentFile: mutableDocumentData?.mutableDocumentFile || "",
    },
    resolver: yupResolver(addMutableDocumentSchema),
    mode: "all",
  });
  useEffect(() => {
    if (
      mutableDocumentData?.mutableDocumentName &&
      mutableDocumentData?.mutableDocumentFile
    )
      setValue("addMutableDocument", mutableDocumentData?.addMutableDocument);
    setValue("mutableDocumentName", mutableDocumentData?.mutableDocumentName);
    setValue("mutableDocumentFile", mutableDocumentData?.mutableDocumentFile);
  }, [mutableDocumentData?.mutableDocumentName]);

  const handleCreateAssetMutableStep = () => {
    if (mutableInputFields?.length > 0) {
      if (
        mutableInputFields?.length === 1 &&
        mutableInputFields?.[0]?.fieldKey === "" &&
        mutableInputFields?.[0]?.fieldValue === ""
      ) {
        setActiveStep(3);
        setSteps(
          steps.map((step, index) =>
            step.value === 2 ? { ...step, IsCompleted: true } : step
          )
        );
      } else {
        let bEmptyPair = mutableInputFields?.filter((data) => {
          return data?.fieldKey === "" || data?.fieldValue === "";
        });
        if (bEmptyPair?.length > 0) {
          setAlertContent({
            title: "Error",
            description: "Please enter key value pair ",
          });
          setOpenAlertModal(true);
          setActiveStep(2);
        } else {
          setActiveStep(3);
          setSteps(
            steps.map((step, index) =>
              step.value === 2 ? { ...step, IsCompleted: true } : step
            )
          );
        }
      }
    }
  };
  const handleMutableKeyValue = () => {
    setShowMutableKeyValue(!showMutableKeyValue);
  };

  const handleMutableDocumentData = (values, e) => {
    setMutableDocumentData(values);
   
    if (mutableFilledData !== undefined) {
      if (values) {
        updateAssetHandler(e, values);
      } else {
        updateAssetHandler(e);
      }
    } else {
      handleCreateAssetMutableStep();
    }
  };
  const handleRemoveImage = () => {
    reset({ ...getValues(), mutableDocumentFile: "" }); // update the name
  };

  const handleAddMutableDocument = (e) => {
   
    setValue("addMutableDocument", e.target.checked);
    setAddMutableDocument(e.target.checked);
    setMutableDocumentData({
      ...mutableDocumentData,
      addMutableDocument: e.target.checked,
    });
    if (!e.target.checked) {
      clearErrors("mutableDocumentName");
      if (mutableFilledData === undefined) {
        reset({
          ...getValues(),
          mutableDocumentFile: "",
          mutableDocumentName: "",
        });
      }
    }
  };

  return (
    <>
      <form on onSubmit={handleSubmit(handleMutableDocumentData)}>
        <div className=" h-[calc(100vh-272px)] relative">
          <div className=" relative  grid h-full grid-cols-1 overflow-y-auto">
            <div className="columns-md max-w-[494px]">
              <div className="flex justify-between ">
                <h2 className="text-2xl font-semibold ">Mutable</h2>
                <MutableToggle
                  onChange={handleMutableKeyValue}
                  value={showMutableKeyValue}
                  id={"mutable"}
                />
              </div>
              <p className="mt-1 text-xs text-grey-100">
                Properties assigned to the asset under mutable section can be
                altered or changed later.
                <br />
                Property (Example: reward amount) Trait (Example: 20 points)
                <br />
                (Max length 200 characters)
              </p>
              {showMutableKeyValue && (
                <>
                  <AddTextFieldComponent
                    inputFields={mutableInputFields}
                    setInputFields={setMutableInputFields}
                    setcheckFieldDuplicate={setcheckFieldDuplicate}
                    checkFieldkeyDuplicate={checkFieldkeyDuplicate}
                    setFieldIndex={setFieldIndex}
                    mutableFilledData={mutableFilledData} //this data is for update case fetched from asset listing
                    fieldIndex={fieldIndex}
                    setOpenAlertModal={setOpenAlertModal}
                    setAlertContent={setAlertContent}
                    mutableSpace={true}
                  />
               
                </>
              )}
                 <div className="mb-8 mt-8  ">
                    <div className="flex justify-between ">
                      <h4 className="mb-1 text-xl font-bold text-white">
                        Attach Document{" "}
                      </h4>

                      <SwitchComponent
                        onChange={handleAddMutableDocument}
                        value={addMutableDocument}
                        id={"addmutableDocument"}
                      />
                    </div>
                    <p className={`text-xs text-grey-100  mb-12 `}>
                      Enhance your asset by attaching PDFs, images, or text
                      files, enabling purchasers to access additional
                      information on the storefront
                    </p>
                    {addMutableDocument && (
                      <div className="mb-20">
                        <AttachDocument
                          register={register}
                          errors={errors}
                          watch={watch}
                          getValues={getValues}
                          reset={reset}
                          documentName={"mutableDocumentName"}
                          fileName={"mutableDocumentFile"}
                          error={errors?.mutableDocumentName?.message}
                          fileError={errors?.mutableDocumentFile?.message}
                          handleRemoveImage={handleRemoveImage}
                        />
                      </div>
                    )}
                  </div>
            </div>
          </div>
          <div className="absolute bottom-0 bg-[#060C07] flex justify-between items-center w-full  z-50">
            <div className={` max-w-[494px] flex justify-between w-full `}>
              <button
                className={`btn-secondary capitalize  max-w-[227px] text-center ${
                  mutableFilledData !== undefined ? "hidden" : ""
                }`}
                onClick={() => setActiveStep(1)}
              >
                Back
              </button>
              {addMutableDocument ? (
                <>
                  <button
                    type="submit"
                    className="btn-primary max-w-[227px] text-center disabled:cursor-not-allowed"
                  >
                    {mutableFilledData !== undefined ? "Save Changes" : "Next"}
                  </button>
                </>
              ) : (
                <button
                  disabled={checkFieldkeyDuplicate}
                  className="btn-primary max-w-[227px] text-center disabled:cursor-not-allowed"
                  onClick={
                    mutableFilledData !== undefined
                      ? updateAssetHandler
                      : handleCreateAssetMutableStep
                  }
                >
                  {mutableFilledData !== undefined ? "Save Changes" : "Next"}
                </button>
              )}
            </div>
            <div className={`flex justify-end items-end `}>
              <span
                className=" py-3 font-bold text-base cursor-pointer hover:text-[#A5F33C]  hover:rounded-full "
                onClick={() => navigate("/asset-list")}
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default MutableComponent;
