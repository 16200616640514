import React from "react";
import { images } from "../../constants";

const AlertModalContent = (props) => {
  const { message, btnHandler } = props;
  return (
    <>
      <div className="flex items-start flex-col">
        <img
          className="mr-2 mb-2"
          src={
            message?.title === "Success"
              ? images.AlertSuccessIcon
              : images.AlertIconInfo
          }
          alt="icon"
        />
        <h3
          className={` text-2xl not-italic font-bold leading-9 ${message.mainTitle
            ? "text-white"
            : message?.title === "Success"
              ? "text-[#FFFFFF]"
              : "text-[#FFFFFF]"
            }`}
        >
          {message.mainTitle ?? message?.title}
          { }
        </h3>
      </div>

      <p className={`w-full py-2  mb-8 text-[#8D9D91] tracking-[0.0125] text-xs not-italic font-normal leading-4`} rows="5">
        {message?.description}
      </p>
      {message?.sublabel && (
        <p className="w-full py-2  mb-8 text-sm font-normal leading-6 text-[#8D9D91]   -mt-9">
          Auto closing in: <span className="leading-6 font-bold text-white align-top"> 3 seconds</span> 
        </p>
      )}
      {message?.alertHander && (
        <button className="btn-primary" onClick={() => btnHandler()}>
          {message?.btnTitle}
        </button>
      )}
    </>
  );
};

export default AlertModalContent;
