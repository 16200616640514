import * as yup from "yup";

export const MaintenanceSchema = yup.object().shape({
  title: yup
    .string()
    .required("Heading  is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),

  description: yup
    .string()
    .required("Description  is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(500, "Max  length 500 characters"),
    maintenanceImage : yup.mixed().when("ismaintenanceExist", {
      is: true,
      then: yup.mixed(),
      otherwise: yup
        .mixed()
        .test("required", "File is required", (file) => {
          if (file[0]) return true;
          return false;
        })
        .test("fileSize", "File size is too large", (value) => {
          if (!value[0]) return true;
          return value[0]?.size <= 200000;
        })
  
        // .test("dimensions", "Image dimensions must be 380x320 pixels", (value) => {
        //   if (!value[0]) return true; // Return true if no file is selected
  
        //   return new Promise((resolve) => {
        //     const image = new Image();
        //     if (value[0]) image.src = URL.createObjectURL(value[0]);
  
        //     image.onload = () => {
        //       resolve(image.width > 379 && image.height > 319);
        //     };
        //   });
        // }),
})
});