import React from 'react'
import { Link } from 'react-router-dom'

const PreviewHeader = () => {
  return (
    <>
      <div className="xl:container  md:max-w-[1150px] px-4 lg:px-16 m-auto ">
        <header className="relative z-20 flex items-center py-4 max-md:justify-between font-poppins">
          <Link to="/">
            <h3 className="text-4xl font-semibold text-white max-md:text-2xl max-md:mr-2">
              StoreFront

            </h3>
          </Link>

          <div
            className={`
              max-md:min-w-[300px] max-md:top-8 max-md:absolute max-md:right-0  max-md:block max-md:mt-3 max-md:px-3 max-md:bg-black max-md:rounded max-md:py-3
             
                flex items-center ml-auto `}
          >
            <Link
              to={"#"}
              className="pr-10 text-base text-white transition-all cursor-pointer max-md:text-sm hover:text-[#A5F33C] tracking-little"
            >
              About
            </Link>
            {/* when not logged in */}

            <div className="max-md:mt-2 max-md:flex max-md:flex-col">
              <button

                className="max-md:hover:bg-transparent max-md:active:bg-transparent max-md:border-0 max-md:mb-2 max-md:px-0 max-md:py-2 max-md:text-xs   btn w-fit py-2 mr-3 text-base font-semibold leading-snug text-white capitalize transition duration-150 ease-in-out bg-transparent rounded-full shadow-md px-10 border-[1.5px] border-[#A5F33C] hover:bg-blue-900 hover:shadow-lg focus:bg-black focus:shadow-lg focus:outline-none focus:ring-0 active:bg-black active:shadow-lg"
              >
                Sign In
              </button>
              <button

                className="px-10 py-2 text-base font-semibold leading-snug text-[#1221A] capitalize transition duration-150 ease-in-out bg-[#A5F33C] rounded-full shadow-md max-md:hover:bg-transparent max-md:active:bg-transparent max-md:bg-transparent max-md:py-2 max-md:text-xs max-md:px-0 btn w-fit hover:bg-[#A5F33C] hover:shadow-lg focus:bg-[#A5F33C] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#A5F33C] active:shadow-lg "
              >
                Sign Up
              </button>
            </div>


            {/* dropdown here when logged in */}


          </div>
        </header>
      </div>
    </>
  )
}

export default PreviewHeader