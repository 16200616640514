import { useState } from "react";

const UseCustomtooltip = () => {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleTooltipClick = () => {
        setTooltipVisible(true);
        setTimeout(() => {
            setTooltipVisible(false);
        }, 3000);
    };

    return {
        tooltipVisible,
        handleTooltipClick
    };
};

export default UseCustomtooltip;
