import React from "react";
import { images } from "../../constants";

const InvalidWalletContent = () => {
  return (
    <>
      <div className="flex items-start flex-col">
        <img
          className="mr-2 mb-2"
          src={
            images.AlertIconInfo
          }
          alt="icon"
        />
        <h3
          className={` text-2xl mb-2 font-bold text-white
        `}
        >
          Invalid wallet address detected
        </h3>
      </div>

      <p
        className={`w-full mb-2  text-[#8D9D91] text-base bg-transparent `}
        rows="5"
      >
        Please ensure that the wallet you're connecting is the same one used in
        the KYB process. If the problem persists, please contact
        <a className="hover:text-[#A5F33C] ml-1 text-[#B0E577]" href='mailto:support@bloomly.com' target={'_blank'}>support@bloomly.xyz </a>
      </p>
    </>
  );
};

export default InvalidWalletContent;
