import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { images } from "../../constants";
import { PriceConfigurationSchema } from "../../utils/ValidationSchemas/AddPriceConfiguratonSchema";
import CloseIconView from "../ui-components/views-component/CloseIconView";
import clientService from "../../services/client.service";
import Spinner from "../ui-components/loader-components/Spinner";
import AlertModalContent from "./AlertModalContent";

const AddPriceConfigureModal = (props) => {
  const { alertMessage, setAlertMessage, setOpenPriceConfigureModal, clientData } = props;
  const [loader, setLoader] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      platformFee: clientData?.plateFormFee?.toFixed(2)
    },
    resolver: yupResolver(PriceConfigurationSchema),
    mode: "onChange",
  });

  const handlePriceConfiguration = (values) => {
    setLoader(true);
    clientData.plateFormFee = values.platformFee;
    const formData = new FormData();
    formData.append("ClientUuId", clientData?.clientUuId);
    formData.append("PlatformFee", values.platformFee?.toFixed(2));
    formData.append("TransactionFee", 0);                 //  values.marketplaceFee?.toFixed(2)
    clientService.updateClientPriceConfigurations(formData).then((result) => {
      setLoader(false);
      if (result?.data?.payload) {
        setAlertMessage({ title: "Success", description: "Price Configurations updated successfully" });
        // setOpenPriceConfigureModal(false)
      }
    }).catch((error) => {
      setLoader(false);
      setAlertMessage({ title: "Error", description: "Failed to update price configurations" });
    })

  };

  return (
    <>
      {alertMessage?.title !== "" ? (
        <>
          <div className="relative">
            <AlertModalContent message={alertMessage} />
            <button
              type="button"
              onClick={() => {
                setLoader(false);
                //reset();
                setOpenPriceConfigureModal(false);
                setAlertMessage({ title: "", description: "" });
              }}
              className="absolute -top-10 -right-10 text-white bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="authentication-modal"
            >
              <CloseIconView />
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="relative">
            <button
              onClick={() => {
                setOpenPriceConfigureModal(false)
              }}
              className="absolute -top-10 -right-10 text-white bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="authentication-modal"
            >
              <CloseIconView />
            </button>
          </div>
          <div>
            <h4 className="mb-4 text-2xl font-bold text-white">
              Pricing and Configurations
            </h4>
            <form onSubmit={handleSubmit(handlePriceConfiguration)}>
              <div className="">

                <div className="relative z-10 mt-8 ">
                  <input
                    type="text"
                    className={`peer outline-none border-t-0 border-r-0 border-l-0 border-b-2 border-white/70 rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-white block w-full p-2.5 bg-transparent ${errors?.platformFee?.message && "focus:border-b-red-600"
                      } + " autofill:!bg-yellow-200"`}
                    placeholder=" "
                    {...register("platformFee")}
                  />
                  <label
                    className={`text-xs text-grey-100  z-[-1]  absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                  >
                    Platform Fee (%)
                  </label>

                  <p className="mt-2 text-xs text-red-600">
                    {errors?.platformFee?.message}
                  </p>
                  <img
                    className="absolute right-0 cursor-pointer top-3"
                    src={images.PercentageIcon}
                    alt="info-icon"
                  />
                </div>
              </div>
              <div className="relative z-10 mt-8 mb-10">
                <input
                  disabled={true}
                  type="text"
                  className={`peer disabled:cursor-not-allowed placeholder:text-grey-100 outline-none border-t-0 border-r-0 border-l-0 border-b-2 border-white/70 rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-white block w-full p-2.5 bg-transparent ${errors?.marketplaceFee?.message && "focus:border-b-red-600"
                    } + " autofill:!bg-yellow-200"`}
                  placeholder=" "
                  {...register("marketplaceFee")}
                />
                <label
                  className={`text-xs text-grey-100  z-[-1]  absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                >
                  Marketplace Fee%
                </label>

                <p className="mt-2 text-xs text-red-600">
                  {errors?.marketplaceFee?.message}
                </p>
                <img
                  className="absolute right-0 cursor-pointer top-3"
                  src={images.InfoIcon}
                  alt="info-icon"
                />
              </div>

              <button
                disabled={!isDirty || !isValid || loader}
                type="submit"
                className="btn-primary disabled:cursor-not-allowed"
              >
                {loader && <Spinner />}
                Save
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default AddPriceConfigureModal;
