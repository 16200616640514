import React, { useRef, useState } from 'react'
import CopyIconView from '../../ui-components/views-component/CopyIconView'
import { images } from '../../../constants'
import UseCustomtooltip from '../../../helpers/custom-hooks/UseCustomtooltip';


const CustomDomainModal = (props) => {
    const { btnTitle, btnClick, stepTwo } = props;
    const ipAddressRef = useRef(null);
    const { tooltipVisible, handleTooltipClick } = UseCustomtooltip()

    const copyUrl = () => {
        const ipAddress = ipAddressRef.current.innerText;
        navigator.clipboard.writeText(ipAddress);
        handleTooltipClick();
    };

    return (
        <div className='flex justify-start items-start flex-col'>
            <p className='text-xs not-italic font-normal leading-4 text-[#B0E577]'>{stepTwo}</p>
            <p className='text-2xl not-italic font-bold leading-9 text-white mb-4'>Domain Guide</p>
            <div className='shadow-md sm:rounded-lg rounded-lg border border-[#A4B1A7]/[0.12]'>
                <div className='bg-[#12221A] shadow-[6px_0px_5px_0px_#0000005C] rounded-t-[7px]'>
                    <p className='text-xs not-italic font-normal leading-4 text-white p-3'>How to Setup Domain</p>

                </div>
                <ul className='list-none mx-4 mt-6'>
                    <li className='mb-3'><span className='text-xs font-semibold leading-4 text-white before:content[•] before:inline-block before:w-[6px] before:h-[6px] before:bg-[#8D9D91] before:border-none  before:rounded-full before:mr-2'>Step 1:&nbsp;</span>
                        <span className='text-xs leading-4 text-[#8D9D91]'>
                            On your domain provider’s website, log in to your account.
                        </span>
                    </li>
                    <li className='mb-3'><span className='text-xs font-semibold leading-4 text-white before:content[•] before:inline-block before:w-[6px] before:h-[6px] before:bg-[#8D9D91] before:border-none  before:rounded-full before:mr-2'>Step 2:&nbsp;</span>
                        <span className='text-xs leading-4 text-[#8D9D91]'>
                            Find the DNS settings or domain management area.
                        </span>
                    </li>
                    <li className='flex justify-start items-start text-xs font-semibold leading-4 before:content[•] before:inline-block before:w-[6px] before:h-[6px] before:bg-[#8D9D91] before:border-none  before:rounded-full before:mr-2 before:mt-1'>
                        <span className='w-[44px]'>Step 3:&nbsp;</span>
                        <span className='text-xs font-normal leading-4 text-[#8D9D91]'>
                            Point the A record to the Bloomly IP address
                            <span className='text-xs leading-4 text-[#B0E577]'>
                            &nbsp;({process.env.REACT_APP_BLOOMLY_IP_ADDRESS}).
                            </span>
                            <br /> Save your changes.
                        </span>
                    </li>
                </ul>
                <div className='flex justify-between relative items-start py-2 px-3 bg-[#12221A] shadow-[6px_0px_5px_0px_#0000005C] rounded-full mt-2 mb-4 mx-4'>
                    <div>
                        <span className='pr-[6px]'><img src={images.IPAdress} alt="" /> </span>
                        <span className='text-xs not-italic font-normal leading-4 text-white'>IP Address:&nbsp;</span>
                        <span className='text-xs not-italic font-bold leading-4 text-[#B0E577]' ref={ipAddressRef} >{process.env.REACT_APP_BLOOMLY_IP_ADDRESS}</span>
                    </div>

                    <div onClick={copyUrl}>
                        <CopyIconView />
                        {tooltipVisible && (
                            <div className="  bg-[#0A130D] custom-tooltip-style absolute left-[80%] w-[138px] max-w-full bottom-full  transform -translate-x-1/2 mb-1 px-4 py-2">
                                Copied to clipboard
                            </div>
                        )}
                    </div>


                </div>
            </div>
            <button
                type="button"
                data-modal-toggle="Add_Modal"
                className="btn-primary rounded-full mt-10"
                onClick={btnClick}
            >
                {btnTitle}
            </button>
        </div>
    )
}

export default CustomDomainModal