import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ClientHeaderComponent from "../../../pages/ClientPortal/components/ClientHeaderComponent";
import InputField from "../../ui-components/form-component/InputFieldComponent";
import { MaintenanceSchema } from "../../../utils/ValidationSchemas/MaintenanceSchema";
import SwitchComponent from "../../ui-components/switch-component/SwitchComponent";
import { useSelector } from "react-redux";
import useClientThemeHook from "../useClientThemeHook";
import Modal from "../../../common/ModalLayout";
import AlertModalContent from "../../Modals/AlertModalContent";
import Spinner from "../../ui-components/loader-components/Spinner";
import themeConstants from "../../../constants/client-admin-constants/clientThemeConstants/ClientThemeConstant";
import themePreferencesConstants from "../../../constants/client-admin-constants/clientPreferenceConstants/ClientPreferenceConstant";
import { images } from "../../../constants";

const MaintenanceMode = () => {
  const { clientDetail } = useSelector((state) => state.user);

  const {
    updateMaintenance,
    maintenanceData,
    loading,
    setLoading,
    OpenAlertModal,
    setOpenAlertModal,
    alertContent,
    resetLoading,
    setResetLoading,
    getClientThemeDetails,
    maintinanceFile,
    setMaintinanceFile,
  } = useClientThemeHook();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(MaintenanceSchema),
    mode: "onChange",
    defaultValues: {
      enableMaintenanceMode: maintenanceData?.enableMaintenance,
      title: themeConstants.MAINTENANCE_DEFAULT_TITLE,
      description: themeConstants.MAINTENANCE_DEFAULT_DESC,
      ismaintenanceExist: false,
      maintenanceImage: "",
    },
  });

  useEffect(() => {
    if (maintenanceData?.title) {
      setValue("title", maintenanceData?.title);
      setValue("description", maintenanceData?.description);
    }
    setValue("enableMaintenanceMode", maintenanceData?.enableMaintenance);
    if (maintenanceData?.image) {
      setValue("ismaintenanceExist", true);
      // setValue('maintenanceImage' ,maintenanceData?.image)
    }
  }, [maintenanceData?.title]);

  useEffect(() => {
    getClientThemeDetails();
  }, []);

  const handleEnableMaintenance = (event) => {
    setValue("enableMaintenanceMode", event.target.checked);
  };
  const enableModeStatus = watch("enableMaintenanceMode");

  const handelMaintenance = (values) => {
    setLoading(true);
   
    let formData = new FormData();
    formData.append("ClientId", clientDetail?.clientUuid);
    formData.append("MaintinanceModeHeading", values?.title);
    formData.append("MaintinanceModeDescription", values?.description);
    formData.append("ShowMaintinanceMode", values?.enableMaintenanceMode);
    if (values?.maintenanceImage[0]) {
      formData.append(
        "MaintinanceModeImg",
        values?.maintenanceImage[0] ?? maintinanceFile
      );
    } else {
      formData.append("MaintinanceModeImgUrl", maintenanceData?.image ?? "");
    }
    // formData.append("MaintinanceModeImg", values?.maintenanceImage[0] ?? maintinanceFile);

    updateMaintenance(formData);
  };

  const handleReset = () => {
    setResetLoading(true);

    let formData = new FormData();
    formData.append("ClientId", clientDetail?.clientUuid);
    formData.append(
      "MaintinanceModeHeading",
      themeConstants.MAINTENANCE_DEFAULT_TITLE
    );
    formData.append(
      "MaintinanceModeDescription",
      themeConstants.MAINTENANCE_DEFAULT_DESC
    );
    formData.append("ShowMaintinanceMode", false);
    if (maintinanceFile) {
      formData.append(
        "MaintinanceModeImgUrl",
        maintinanceFile ?? maintenanceData?.image
      );
      setMaintinanceFile(maintinanceFile ?? maintenanceData?.image);
    }

    updateMaintenance(formData);
    reset({
      title: themeConstants.MAINTENANCE_DEFAULT_TITLE,
      description: themeConstants.MAINTENANCE_DEFAULT_DESC,
      enableMaintenanceMode: false,
    });
  };

  const handleChange = (e) => {
    setValue("ismaintenanceExist", false);
    setMaintinanceFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleRemoveImage = () => {
    setValue("ismaintenanceExist", false);
    setMaintinanceFile("");
    reset({ maintenanceImage: "" });
  };
 
  return (
    <>
      <ClientHeaderComponent
        title={"Back to Preferences"}
        backLink={"/preferences"}
      />
      <div className="flex justify-between items-center mb-1 max-w-[494px]">
        <h3 className="text-xl mb-1">Maintenance Mode</h3>
        <SwitchComponent
          onChange={handleEnableMaintenance}
          value={enableModeStatus}
          id={"enabledMaintenanceMode"}
        />
      </div>
      <p className="text-[#8D9D91] text-xs  mb-6 max-w-[494px]">
        {themePreferencesConstants.PREFERENCES.MAINTENANCE_DESC}
      </p>

      <form onSubmit={handleSubmit(handelMaintenance)}>
        <div className="grid grid-cols-2   gap-3">
          <div className="max-w-[494px] ">
            <div className="min-h-[348px]">


              <div className="relative mt-3 mb-10">
                <InputField
                  type="text"
                  label="Heading"
                  error={""}
                  register={register}
                  name={`title`}
                />

                <p className="mt-1 text-xs text-red-600">
                  {errors?.title?.message}
                </p>
              </div>
              <div className="relative z-0 mt-8">
                <textarea
                  className="peer outline-none  pl-3 pr-8 py-2  border rounded-xl border-[#A4B1A7] text-white text-md  placeholder:text-white focus:ring-transparent focus:border-[#A4B1A7] focus:outline-none  block w-full  bg-transparent"
                  rows="4"
                  placeholder=" "
                  maxlength="501"
                  {...register("description")}
                ></textarea>
                <label
                  className={`text-xs text-grey-100 z-[-1]  left-0 absolute  -top-6 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3   peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-6   peer-focus:text-grey-100 peer-focus:text-xs peer-focus:left-0`}
                >
                  Description
                </label>

                <p className="mt-1 text-xs text-red-600">
                  {errors?.description?.message}
                </p>
              </div>
            </div>
            <div className="flex justify-between mt-10 mb-[30px] ">
              {/** We will implement this is next phase  */}
              <button
                onClick={handleReset}
                type="button"
                className={`btn-secondary  mr-4 `}
                disabled={resetLoading}
              >
                {resetLoading && <Spinner />}
                Reset
              </button>
              <button
                type="submit"
                className={`btn-primary  `}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                disabled={loading}
              >
                {loading && <Spinner />}
                Save Changes
              </button>
            </div>
          </div>
          <div className="justify-self-end">
            <h1 className="text-xl font-bold mb-3">Upload Maintenance Image</h1>

            <label htmlFor="inputFileSelector" className="mb-2 cursor-pointer">
              <input
                hidden={true}
                id="inputFileSelector"
                accept={".gif,.jpg,.jpeg,.png"}
                type="file"
                name="maintenanceImage"
                // className={inputFieldStyle}
                {...register("maintenanceImage", {
                  onChange: (e) => {
                    handleChange(e);
                  },
                })}
              />
              {(!maintinanceFile || errors?.maintenanceImage?.message) && (
                <div className="flex items-center flex-col max-w-[346px] min-w-[346px] justify-center text-white font-poppins border max-h-[306px] border-[#A4B1A7] p-4 border-grey-100-700 border-solid rounded-[4px] w-full min-h-[96px] h-full">
                  <img className="" src={images.UploadIcon} alt="" />
                  <h4 className="text-base mb-3">
                    <span className="font-bold">Browse File</span>{" "}
                  </h4>

                  <p className="text-xs text-grey-100">
                    Size recommended, 380px X 320px
                  </p>
                </div>
              )}
            </label>
            <div className="">
              {maintinanceFile && !errors?.maintenanceImage?.message && (
                <div className="flex items-start">
                  <img
                    className="object-contain  max-w-full min-w-[346px] min-h-[96px] h-full max-h-[306px] rounded-2xl"
                    src={maintinanceFile}
                    alt="logo"
                  />
                  <img
                    className="-ml-4 cursor-pointer"
                    src={images.RemoveFieldIcon}
                    alt="crossimg"
                    onClick={handleRemoveImage}
                  />
                </div>
              )}

              <p className="mt-1 text-xs text-red-600">
                {errors?.maintenanceImage?.message}
              </p>
            </div>
          </div>
        </div>
      </form>

      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Save_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default MaintenanceMode;
