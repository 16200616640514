export const GetStorageData =  () => {
    if (sessionStorage.getItem("persist:root")) {
        let persist_Storage = sessionStorage.getItem("persist:root");
        if (persist_Storage?.length > 0) {
        let parsedValue = JSON.parse(persist_Storage);
        let data = JSON.parse(parsedValue?.user);
       return data?.clientDetail
        
    }
}
}