import React from "react";
import ClientHeaderComponent from "../../components/ClientHeaderComponent";
import { useMemo } from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../../redux/features/loading/loaderSlice";
import { COLUMNS } from "../../../../components/ClientConfiguration/ClientDomain/ConfigurationColumn";
import Modal from "../../../../common/ModalLayout";
import AddDomainModal from "../../../../components/ClientConfiguration/ClientDomain/AddDomainModal";
import ConfigurationService from "../../../../services/configuration.service";
import CustomDomainModal from "../../../../components/ClientConfiguration/ClientDomain/CustomDomainModal";
import DomainListing from "../../../../components/ClientConfiguration/ClientDomain/DomainListing";

const CustomDomain = () => {
    const [clientDomainDetails, setClientDomainDetails] = useState([]);
    const columns = useMemo(() => COLUMNS, []);
    const data = useMemo(() => clientDomainDetails, [clientDomainDetails]);
    const [OpenAlertModal, setOpenAlertModal] = useState(false);
    const [addDomainAlertModal, setAddDomainAlertModal] = useState(false);
    const dispatch = useDispatch();

    // API Data
    const getDomainDetails = async () => {
        dispatch(setLoader(true));
        await ConfigurationService.getClientDomainDetails()
            .then((response) => {
                if (response?.data?.statusCodeValue === 200) {
                    dispatch(setLoader(false));
                    const data = response?.data?.payload;
                    let arrayData;
                    const firstArray = data?.customDomainResponses;
                    arrayData = [...firstArray];
                    let defaultDomainData;
                    if (data?.defaultClientDomain?.defaultDomainName) {
                        defaultDomainData = data?.defaultClientDomain;
                        arrayData = [...firstArray, defaultDomainData];
                    }
                    setClientDomainDetails(arrayData);
                }
            })
            .catch((err) => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        getDomainDetails();
    }, []);

    const gotItHandle = () => {
        setOpenAlertModal(false);
    };

    return (
        <>
            <ClientHeaderComponent title={"Domain Settings"} />
            <div>
                <DomainListing
                    data={data}
                    columns={columns}
                    setOpenAlertModal={setOpenAlertModal}
                    setAddDomainAlertModal={setAddDomainAlertModal}
                    getDomainDetails={getDomainDetails}
                />
            </div>
            {OpenAlertModal && (
                <Modal
                    modalVisible={OpenAlertModal}
                    closeModal={setOpenAlertModal}
                    children={
                        <CustomDomainModal btnClick={gotItHandle} btnTitle="Ok, Got it" />
                    }
                    modal_Id="Save_Modal"
                    isClose={true}
                />
            )}
            {addDomainAlertModal && (
                <Modal
                    modalVisible={addDomainAlertModal}
                    closeModal={setAddDomainAlertModal}
                    children={
                        <AddDomainModal
                            setAddDomainAlertModal={setAddDomainAlertModal}
                            getDomainDetails={getDomainDetails}
                        />
                    }
                    modal_Id="Save_Modal"
                    isClose={true}
                />
            )}
        </>
    );
};

export default CustomDomain;
