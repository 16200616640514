import React, { useState } from "react";
import InputField from "../../ui-components/form-component/InputFieldComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfigurationService from "../../../services/configuration.service";
import { useDispatch, useSelector } from "react-redux";
import { DomainSchema } from "../../../utils/ValidationSchemas/addDomainSchema";
import { setLoader } from "../../../redux/features/loading/loaderSlice";
import clientService from "../../../services/client.service";

const AddExitingDomain = (props) => {
  const { getDomainDetails, setStep } = props;
  const { clientDetail } = useSelector((state) => state?.user);
  const [urlAvailable, setUrlAvailable] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(DomainSchema),
  });

  const submitForm = (data) => {
    handleCheckBrandWebUrlExist(data?.domainName);
  };

  const handleCheckBrandWebUrlExist = (domainName) => {
    setLoading(true);
    clientService
      .checkBrandWelUrlAvailibility(domainName)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          if (
            response?.data?.message?.toLowerCase() ===
            "specified domain name is available"
          ) {
            setUrlAvailable(true);
            postClientDetails({
              clientId: clientDetail?.clientUuid,
              customDomain: domainName,
            });
          } else {
            setUrlAvailable(false);
            let interval = setTimeout(() => {
              setUrlAvailable(true);
              clearTimeout(interval);
            }, 3000);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // API Data
  const postClientDetails = async (payload) => {
    dispatch(setLoader(true));
    await ConfigurationService.postClientDomainDetails(payload)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          setStep(2);
          getDomainDetails();
          dispatch(setLoader(false));
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };

  return (
    <>
      <div className="flex justify-start items-start flex-col">
        <p className="text-xs not-italic font-normal leading-4 text-[#B0E577]">
          Step: 1 of 2
        </p>
        <h2 className="mt-2 mb-2 text-2xl not-italic font-bold leading-9">
          Add Your Domain
        </h2>
        <p className="mb-4 text-xs not-italic font-normal leading-4 text-[#8D9D91]">
          Enhance your online presence by adding a new domain or subdomain. Once
          approved, designate the new domain as your default, ensuring a smooth
          redirection of your users to the revamped storefront.
        </p>
        <form className="text-white w-full" onSubmit={handleSubmit(submitForm)}>
          <div className="relative z-10">
            <InputField
              type="text"
              label="Add your domain or subdomain here"
              error={errors?.domainName?.message}
              register={register}
              name="domainName"
            />
            {!loading && (
              <p className="text-red-600 text-xs mt-1">
                {!urlAvailable && "domain already exists"}
              </p>
            )}{" "}
          </div>
          <div className="flex justify-center items-center mt-10">
            <button
              type="submit"
              data-modal-toggle="Add_Modal"
              className="btn-primary rounded-full"
              disabled={!urlAvailable}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddExitingDomain;
