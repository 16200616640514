import DateFormat from "../../helpers/DateFormat";
import mediaExtractor from "../../helpers/MediaDataExtracterInTable";
import stringTruncate from "../../helpers/StringTruncate";
import walletAddressShorten from "../../helpers/WalletAddressTruncate";
import { Tooltip } from "@material-tailwind/react";

export const COLUMNS = [
  {
    Header: "Asset",
    Footer: "Media",
    accessor: "assetVariationObject",
    Cell: ({ row }) => <>{mediaExtractor(row?.values?.assetVariationObject)}</>,
  },
  {
    Header: "Asset Name",
    Footer: "Asset Name",
    accessor: "assetName",
    Cell: ({ row }) => (
      <p>
        <Tooltip
   
          className="custom-tooltip-style bg-[#0A130D]"
          offset={12}
          placement={"top-start"}
          content={row?.values?.assetName}
        >
          <span className="">
            {stringTruncate(row?.values?.assetName, 17)}{" "}
          </span>
        </Tooltip>
      </p>
    ),
  },
  {
    Header: "Drop Name",
    Footer: "Drop Name",
    accessor: "dropName",
    Cell: ({ row }) => (
      <p>
        <Tooltip
      
          className="custom-tooltip-style bg-[#0A130D]"
          offset={12}
          placement={"top-start"}
          content={row?.values?.dropName}
        >
          <span className="">
            {" "}
            {stringTruncate(row?.values?.dropName, 17)}
          </span>
        </Tooltip>
      </p>
    ),
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "purchaserEmail",
    Cell: ({ row }) => (
      <p>
        <Tooltip
          className="custom-tooltip-style bg-[#0A130D]"
          offset={12}
          placement={"top-start"}
          content={row?.values?.purchaserEmail}
        >
          <span className="">
            {stringTruncate(row?.values?.purchaserEmail, 17)}
          </span>
        </Tooltip>
      </p>
    ),
  },
  {
    Header: "Wallet Address",
    Footer: "Wallet Address",
    accessor: "walletAddress",
    Cell: ({ row }) => (
      <p>{walletAddressShorten(row?.values?.walletAddress)}</p>
    ),
  },

  {
    Header: "Items Claimed",
    Footer: "Items Claimed",
    accessor: "itemsClaimedPerTranx",
  },
  {
    Header: "Claim Date",
    Footer: "Claim Date",
    accessor: "claimDate",
    Cell: ({ row }) => (
      <>
        <DateFormat date={row?.values?.claimDate} />
      </>
    ),
  },
  {
    Header: "Transaction",
    Footer: "Transaction",
    accessor: "transactionHash",
    Cell: ({ row }) => (
      <a
        className="underline hover:text-[#A5F33C] "
        href={
          process.env.REACT_APP_ACCESS_NODE?.includes("testnet")
            ? `${process.env.REACT_APP_FLOW_TESTNET_STORAGE_TRANSACTION_LINK}${row?.values?.transactionHash}`
            : `${process.env.REACT_APP_FLOW_MAINNET_STORAGE_TRANSACTION_LINK}${row?.values?.transactionHash}`
        }
        target="_blank"
        rel="noreferrer"
      >
        {" "}
        View Trx
      </a>
    ),
  },
];
