const CreateBrandScript = () => `\
import ${process.env.REACT_APP_IMSA_CONTRACT_NAME} from ${process.env.REACT_APP_IMSA_CONTRACT_ADDRESS}

transaction {

 prepare(acct: AuthAccount) {}

 execute {
   log(HelloWorld.hello())
    log("Hello Again")
 }
}`;
const getBrandScript = () => `\
import ${process.env.REACT_APP_IMSA_CONTRACT_NAME} from ${process.env.REACT_APP_IMSA_CONTRACT_ADDRESS}

transaction {

 prepare(acct: AuthAccount) {}

 execute {
   log(HelloWorld.hello())
    log("Hello Again")
 }
}`;
const CreateUpdateDropScript = (
	script,
	drop_id,
	brandId,
	drop_name,
	drop_description,
	startTime,
	endTime,
	price,
	templateId,
	dropTypeId,
	limit
	
) => {
	let replaceScript = script.current;
	replaceScript = replaceScript
		.replaceAll('${drop_name}', '"' + drop_name + '"')
		.replaceAll('${drop_description}', '"' + drop_description + '"')
		.replaceAll('${startTime}', startTime)
		.replaceAll('${endTime}', endTime)
		.replaceAll('${price}', price)
		.replaceAll('${drop_id}', drop_id)
		.replaceAll('${brandId}', brandId)
		.replaceAll('${templateId}', templateId)
		.replaceAll('${dropTypeId}', dropTypeId)
		.replaceAll('${userLimit}', limit);
	return replaceScript;
};


const deleteDropScript = (script, drop_id, brandId) => {
	let replaceScript = script;
	replaceScript = replaceScript
		.replaceAll('${dropId}', drop_id)
		.replaceAll('${brandId}', brandId);
	return replaceScript;
};

const script = {
	CreateBrandScript,
	getBrandScript,
	CreateUpdateDropScript,
	deleteDropScript,
};

export default script;
