import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";
import "./App.css";
import Client from "./pages/Client";
import ClientDetails from "./pages/ClientDetails";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import ProtectedRoutes from "./routes/protected/ProtectedRoutes";
import LoginComponent from "./components/Auth/LoginComponent";
import ForgotPassword from "./components/Auth/ForgotPassword";
import SetPassword from "./components/Auth/SetPassword";
import Onboarding from "./pages/Onboarding";
import KybBrandTheme from "./components/ClientOnboarding/KybBrandTheme";
import KybBrandCategories from "./components/ClientOnboarding/KybBrandCategories";
import KybBrandDetails from "./components/ClientOnboarding/KybBrandDetails";
import KybConnectWallet from "./components/ClientOnboarding/KybConnectWallet";
import StorefrontDemo1 from "./pages/StorefrontDemo1";
import ClientDashboard from "./pages/ClientPortal/ClientDashboard";
import CreateAsset from "./pages/ClientPortal/Minting/Assets/CreateAsset";
import AssetList from "./pages/ClientPortal/Minting/Assets/AssetListing";
import { useSelector } from "react-redux";
import ChangePassword from "./pages/ClientPortal/ChangePassword/ChangePassword";
import Loader from "./helpers/Loader";
import DropList from "./pages/ClientPortal/Minting/Drops/DropListing";
import ClientMultiTxContent from "./pages/ClientPortal/ClientMultiTxContent/ClientMultiTxContent";
import ProtectedRouteClient from "./routes/protected/ProtectedRouteClient";
import CreateDrop from "./pages/ClientPortal/Minting/Drops/CreateDrop";
import ClientTransactionReporting from "./pages/ClientPortal/ClientReporting/clientTransactionReporting/ClientTransactionReporting";
import ClientTransactionDetail from "./pages/ClientPortal/ClientReporting/clientTransactionReporting/ClientTransactionDetail";
import ArweaveReporting from "./pages/AdminPortal/ArweaveReporting/ArweaveReporting";
import AddCategory from "./pages/AdminPortal/ClientCategory/AddCategory";
import CategoryContent from "./pages/AdminPortal/ClientCategory/CategoryContent";
import PackagesContent from "./pages/AdminPortal/Packages/PackagesContent";
import ConnectWalletLayout from "./pages/ConnectWalletLayout";
import ContributorContent from "./pages/ClientPortal/Configuration/Contributor/ContributorContent";
import RoyaltyContent from "./pages/ClientPortal/Configuration/Royalty/RoyaltyContent";
import WhitelistListing from "./pages/ClaimWhitelist/WhitelistListing";
import ClientBranding from "./pages/ClientPortal/ClientTheme/ClientBranding";
import ClientPreferences from "./pages/ClientPortal/ClientTheme/ClientPreferences";
import HomePage from "./components/ClientTheme/ClientPreferences/HomePage";
import TermsAndConditions from "./components/ClientTheme/ClientPreferences/TermsAndConditions";
import AboutUs from "./components/ClientTheme/ClientPreferences/AboutUs";
import PrivacyPolicy from "./components/ClientTheme/ClientPreferences/PrivacyPolicy";
import MaintenanceMode from "./components/ClientTheme/ClientPreferences/MaintenanceMode";
import SocialsMedia from "./components/ClientTheme/ClientPreferences/SocialsMedia";
import CustomDomain from "./pages/ClientPortal/Configuration/CustomDomain/CustomDomain";


function App() {
  const { userRole, isLoggedIn, clientDetail } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    if (clientDetail?.clientName) {
      document.title = clientDetail?.clientName + " Admin Panel";
    }
  }, [clientDetail?.clientName]);

  return (
    <>
      <Loader />
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route element={<Login />}>
            <Route>
              <Route element={<Navigate to="login" />} path="/" />
              <Route element={<LoginComponent />} path="/login" />
              <Route element={<ForgotPassword />} path="/forgot-password" />
              <Route element={<SetPassword />} path="/set-password" />
            </Route>
          </Route>

          {/* ProtectedRoute Way */}
          <Route
            element={
              <ProtectedRoutes isLoggedIn={isLoggedIn} userRole={userRole} />
            }
          >
            {userRole === "Super Admin" ? (
              <>
                <Route element={<Layout />}>
                  <Route element={<Client />} path="/dashboard" />
                  <Route element={<ClientDetails />} path="/client/:id" />
                  <Route
                    element={<ArweaveReporting />}
                    path="/arweave-reporting"
                  />
                  <Route element={<CategoryContent />} path="/category" />
                  <Route element={<AddCategory />} path="/add-category" />
                  <Route element={<PackagesContent />} path="/choose-package" />

                  <Route
                    path="*"
                    element={<Navigate to="/dashboard" replace />}
                  />
                </Route>
              </>
            ) : (
              <>
                {userRole?.includes("Client") ? (
                  <>
                    <Route
                      element={
                        <ProtectedRouteClient clientDetail={clientDetail} />
                      }
                    >
                      <Route element={<Layout />}>
                        <Route
                          element={<ClientDashboard />}
                          path="/client-dashboard"
                        />

                        <Route element={<CreateAsset />} path="/create-asset" />
                        <Route element={<CreateDrop />} path="/create-drop" />
                        <Route element={<AssetList />} path="/asset-list" />
                        <Route element={<DropList />} path="/drop-list" />
                        <Route
                          element={<WhitelistListing />}
                          path="/airdrop"
                        />

                        <Route
                          element={<ClientTransactionReporting />}
                          path="/tx-reporting"
                        />
                        <Route
                          element={<ClientTransactionDetail />}
                          path="/tx-detail"
                        />
                        <Route
                          element={<CustomDomain />}
                          path="/configuration"
                        />
                        <Route
                          element={<ChangePassword />}
                          path="/client-change-password"
                        />
                        <Route
                          element={<ContributorContent />}
                          path="/add-contributor"
                        />
                        <Route
                          element={<RoyaltyContent />}
                          path="/add-royalty"
                        />
                        <Route element={<ClientBranding />} path="/branding" />
                        <Route
                          element={<ClientPreferences />}
                          path="/preferences"
                        />
                        <Route
                          element={<HomePage />}
                          path="/preferences/home"
                        />
                        <Route
                          element={<TermsAndConditions />}
                          path="/preferences/terms"
                        />
                        <Route
                          element={<AboutUs />}
                          path="/preferences/about"
                        />
                        <Route
                          element={<PrivacyPolicy />}
                          path="/preferences/privacy"
                        />
                        <Route
                          element={<MaintenanceMode />}
                          path="/preferences/maintenace"
                        />
                        <Route
                          element={<SocialsMedia />}
                          path="/preferences/socials-media"
                        />
                      </Route>
                    </Route>
                    {/** Client Blockchain storage create Multi Sign Transaction */}
                    <Route element={<ConnectWalletLayout />}>
                      <Route
                        element={<ClientMultiTxContent />}
                        path="/create-capability"
                      />
                    </Route>
                    {/* ONBOARDING */}
                    <Route path="onboarding" element={<Onboarding />}>
                      <Route
                        element={<Navigate to="connect-wallet" />}
                        path="/onboarding"
                      />
                      <Route
                        element={<KybConnectWallet />}
                        path="connect-wallet"
                      />
                      <Route
                        element={<KybBrandDetails />}
                        path="brand-details"
                      />
                      <Route
                        element={<KybBrandCategories />}
                        path="brand-categories"
                      />
                      <Route element={<KybBrandTheme />} path="brand-theme" />
                    </Route>
                    <Route
                      path="*"
                      element={<Navigate to="/client-dashboard" replace />}
                    />
                  </>
                ) : (
                  <>
                    <Route path="*" element={<div>You lost a track!</div>} />
                  </>
                )}
              </>
            )}

            {/* Client Admin routes */}
          </Route>

          {/* Catch All Route */}
          <Route
            path="*"
            element={<div className="text-white">You lost your track!</div>}
          />
          <Route
            element={<StorefrontDemo1 />}
            path="storefront-demo1"
            target="_blank"
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
