import React, { useEffect, useState } from "react";
import CopyIconView from "../../../components/ui-components/views-component/CopyIconView";
import { images } from "./../../../constants/images";
import { Tooltip } from "@material-tailwind/react";
import ArweaveBalanceHook from "../../../helpers/custom-hooks/FetchArweaveBalance";
import { clientFlowBalance } from "../../../redux/features/user/userSlice";
import { useDispatch } from "react-redux";
import WalletBalanceHook from "../../../helpers/custom-hooks/FetchWalletBalance";
import useCopyLink from "../../../helpers/custom-hooks/useCopyLink";

const WalletAddress = ({ walletAddress }) => {
  const { copyText, copyAddress, showCopiedText } = useCopyLink();
  const dispatch = useDispatch();
  const { getFlowUSDBalance } = ArweaveBalanceHook();

  const [openToolTip, setOpenToolTip] = useState(false);
  const [clientWalletBalance, setClientWalletBalance] = useState("");
  const [clientFlowPriceInUsd, setClientFlowPriceInUsd] = useState("");

  useEffect(() => {
    if (walletAddress) {
      getWalletBalance();
    }

    //eslint-disable-next-line
  }, []);

  const getWalletBalance = async () => {
    const { getWalletBalance } = WalletBalanceHook();
    const balance = await getWalletBalance(walletAddress);
    fetchFlowBalance(balance);
    setClientWalletBalance(balance);
    dispatch(clientFlowBalance(balance));
  };

  const fetchFlowBalance = async (clientWalletBalance) => {
    let flowUsd = await getFlowUSDBalance();
    if (flowUsd) {
      flowUsd = flowUsd * parseFloat(clientWalletBalance);
      setClientFlowPriceInUsd(flowUsd);
    } else {
      setClientFlowPriceInUsd("");
    }
  };

  const TextContent = () => {
    return (
      <>
        <p className="text-xs text-grey-100">
          Wallet Balance{" "}
          {showCopiedText && (
            <span className="text-xs text-[#A5F33C] ml-1 -mt-1">
              (Copied to clipboard)
            </span>
          )}
        </p>
        <div className="mb-4 flex   items-center">
          <span className="mr-3 text-base font-bold">
            {clientWalletBalance ? `${clientWalletBalance} FLOW` : ""}
          </span>
          <img className="mr-3" src={images.BiDirectionalIcon} alt="icon" />
          <span className="text-base font-bold">
            {clientFlowPriceInUsd
              ? `$${parseFloat(clientFlowPriceInUsd).toFixed(4)}`
              : ""}
          </span>
        </div>
        <div className="flex items-center p-3 border border-[#A4B1A7]/25 rounded-full ">
          <span className="mr-3" ref={copyAddress} value={walletAddress}>
            Address: {walletAddress}
          </span>

          <span
            onClick={copyText}
            className="inline ml-2 relative cursor-pointer text-white hover:text-[#A5F33C]"
          >
            {" "}
            <CopyIconView />
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        onMouseEnter={() => setOpenToolTip(true)}
        onMouseLeave={() => setOpenToolTip(false)}
        className={`group relative flex items-center max-w-md px-3 py-2 mr-4 bg-[#09120E] rounded-full bg-none`}
      >
        <Tooltip
          className="group-hover:hidden text-[white] text-xs bg-[#0A130D]  border border-[#12221A] relative z-[100]  rounded-lg max-w-[325px]"
          offset={18}
          open={openToolTip}
          placement={"top-center"}
          content={<TextContent />}
        >
          <div>
            <p
              className="w-full text-sm bg-transparent text-[#A5F33C] "
              ref={copyAddress}
              value={walletAddress}
            >
              <span className=" text-sm text-white pr-1">
              
                Wallet Address:{" "}
              </span>
              <span>
              {walletAddress?.length > 5
                ? walletAddress?.substr(0, 6) +
                "..." +
                walletAddress?.substr(
                  walletAddress?.length - 4,
                  walletAddress?.length
                )
                : walletAddress ?? "-"} </span>
              <span
                onClick={copyText}
                className="inline ml-2 cursor-pointer text-white hover:text-[#A5F33C]"
              >
                {" "}
                <CopyIconView />
              </span>
            </p>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default WalletAddress;
