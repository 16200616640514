import WalletDetails from "../../components/ClientDashboardComponent/WalletDetails";
import BrandStatistics from "../../components/ClientDashboardComponent/BrandStatistics";
import DropTransactionDetails from "../../components/ClientDashboardComponent/DropTransactionDetails";
import DashboardHeader from "./components/DasboardHeader";
import AssetNontransferIcon from "../../components/ui-components/views-component/AssetNontransferIcon";

const ClientDashboard = () => {
  return (
    <>
      <DashboardHeader title={"Dashboard"} />
      <div className="grid grid-cols-9 gap-5 order">
        <div className="col-span-3">
          <WalletDetails />
        </div>
        <div className="col-span-3">
          <DropTransactionDetails />
        </div>
        <div className="col-span-3">
          <BrandStatistics />
        </div>
      </div>
    </>
  );
};

export default ClientDashboard;
