import { images } from "../../../constants";
import DateFormat from "../../../helpers/DateFormat";

export const COLUMNS = [
    {
        Header: "Domain Name",
        Footer: "Domain Name",
        accessor: (row) => `${row.defaultDomainName} ${row.customDomain}`,
        Cell: ({ row }) => (
            <>
                {
                    <p>
                        <div className="flex">
                            <p className="flex items-cente min-w-[562px]">
                                {row?.original?.customDomain || row?.original?.defaultDomainName}
                                {row?.original?.isPrimary && (
                                    <>
                                        <img src={images.PrimaryIcon} className="ml-3" alt="Primary" />
                                        <span className=" ml-[3px] text-[#B0E577]">Default</span>
                                    </>
                                )}
                            </p>

                        </div>
                    </p>
                }
            </>
        ),
    },
    {
        Header: "Added Date",
        Footer: "Added Data",
        accessor: "addedData",
        Cell: ({ row }) => (
            <>
                {
                    <p>
                        {row?.original?.createdDate &&
                            (
                                <div className="flex">
                                    <p>
                                        <DateFormat date={row?.original?.createdDate} />
                                    </p>
                                </div>
                            )}
                    </p>
                }
            </>
        ),
    },
    {
        Header: "Status",
        Footer: "Status",
        accessor: "status",
        Cell: ({ row }) => (
            <>
                {
                    <p p className="group hover:text-[#A5F33C]" >
                        <div className="flex">
                            <img src={(row?.original?.domainStatus != undefined && !row?.original?.domainStatus) ? images?.Processing : row?.original?.isPrimary ? images?.Active : images?.Active} />
                            <p className={`ml-1 ${(row?.original?.domainStatus != undefined && !row?.original?.domainStatus) ? 'text-[#E7E15A]' : row?.original?.isPrimary ? 'text-[#B0E577]' : 'text-[#B0E577]'} `}>
                                {(row?.original?.domainStatus != undefined && !row?.original?.domainStatus) ? 'Processing' : row?.original?.isPrimary ? 'Active' : 'Active'}
                            </p>
                        </div>

                    </p >
                }
            </>
        ),
    },
];
