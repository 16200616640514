import React, { useEffect, useState } from "react";
import { images } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../redux/features/loading/loaderSlice";
import clientStripeService from "../../services/clientstripe.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AssetService from "../../services/asset.service";
import FetchTemplatesFromBC from "../../helpers/custom-hooks/FetchTemplatesFromBC";

import { ClientTxService } from "../../services/transaction.service";
import RightArrow from "./RightArrow";
import CustomTooltip from "./CustomTooltip";
import { Tooltip } from "@material-tailwind/react";

const BrandStatistics = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const IsStripeLinkExpired = queryParams.get("IsStripeLinkExpired");
  const IsStripeDetailsSubmitted = queryParams.get("IsStripeDetailsSubmitted");

  const dispatch = useDispatch();
  const clientInfo = useSelector((state) => state?.user?.clientDetail);
  const [assetDetails, setAssetDetails] = useState([]);

  const [stripeInfo, setStripeInfo] = useState();
  const [clientStripeAccountInfo, setClientStripeAccountInfo] = useState();
  const [openStripeConnectLink, setOpenStripeConnectLink] = useState({
    openLink: false,
    url: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    getAssetsCount();
    getStripeAccountInfo();
    fetchAssetList();
    getDashboardTransctionReport();
  }, []);
  const { fetchTemplatesList } = FetchTemplatesFromBC();
  const [dashboardTransactions, setDashboardTransactions] = useState([]);

  const [params, setParams] = useState({
    clientId: clientInfo?.id?.toString(),
  });
  // Dashboard Transaction Records
  const getDashboardTransctionReport = async () => {
    dispatch(setLoader(true));
    await ClientTxService.getDashboardTransaction(params)
      .then((response) => {
        if (response?.data?.payload) {
          setDashboardTransactions(response?.data?.payload);
        } else {
          setDashboardTransactions([]);
        }
      })
      .catch((err) => { });
  };

  const fetchAssetList = async () => {
    dispatch(setLoader(true));
    let templatesList = await fetchTemplatesList();
    if (templatesList != null) {
      if (typeof templatesList === "object") {
        templatesList = Object.values(templatesList);
        templatesList = templatesList.reverse();
        templatesList = templatesList.map((data, index) => {
          return { ...data.immutableData, ...data };
        });
        dispatch(setLoader(false));
      } else {
        dispatch(setLoader(false));
      }
    }
    dispatch(setLoader(false));
  };

  useEffect(() => {
    if (IsStripeLinkExpired) {
      GetClientStripeAccountUrl();
    }
    if (IsStripeDetailsSubmitted) {
      getStripeAccountInfo();
    }
    //eslint-disable-next-line
  }, [IsStripeLinkExpired, IsStripeDetailsSubmitted]);

  const getStripeAccountInfo = () => {
    dispatch(setLoader(true));
    if (clientInfo?.clientUuid) {
      clientStripeService
        .getStripeInfo(clientInfo?.clientUuid)
        .then((response) => {
          dispatch(setLoader(false));
          if (response?.data?.payload) {
            let verify = response?.data?.payload?.isAccoundVerified;
            setStripeInfo(response?.data?.payload);
            if (!verify) {
              getClientStripeAccountInfo(verify);
            }
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    }
  };
  
  const handleConnectStripeAccount = (values) => {
    dispatch(setLoader(true));
    const modal = {
      ClientId: clientInfo?.clientUuid,
    };
    clientStripeService
      .createClientStripeAccount(modal)
      .then((response) => {
        dispatch(setLoader(false));
        if (response?.data?.payload) {
          setOpenStripeConnectLink({
            openLink: true,
            url: response?.data?.payload?.url,
          });
          window.open(
            response?.data?.payload?.url,
            "_self",

          );

        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
  /** function to update the client status of stripe account */

  const UpdateClientStripeAccount = (stripeId) => {
    dispatch(setLoader(true));
    clientStripeService
      .updateClientStripeAccount(clientInfo?.clientUuid, stripeId)
      .then((response) => {
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };

  /** function to get Url for client for onboard on stripe dashboard */
  const GetClientStripeAccountUrl = () => {
    dispatch(setLoader(true));
    if (clientInfo?.clientUuid) {
      clientStripeService
        .getClientStripeAccountUrl(clientInfo?.clientUuid)
        .then((response) => {
          dispatch(setLoader(false));
          if (response?.data?.payload?.url) {
            // setOpenConnectStripeModal(true);
            setOpenStripeConnectLink({
              openLink: false,
              url: response?.data?.payload?.url,
            });
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    }
  };

  const getAssetsCount = () => {
    dispatch(setLoader(true));
    if (clientInfo?.clientUuid) {
      AssetService.assetsCount(clientInfo?.clientUuid)
        .then((response) => {
          dispatch(setLoader(false));
          if (response?.data?.statusCode === 200) {
            setAssetDetails(response?.data?.payload);
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    }
  };

  const getClientStripeAccountInfo = (verify) => {
    dispatch(setLoader(true));
    if (clientInfo?.clientUuid) {
      return clientStripeService
        .getClientStripeAccountInfo(clientInfo?.clientUuid)
        .then((response) => {
          dispatch(setLoader(false));
          if (response?.data?.payload) {
            setClientStripeAccountInfo(response?.data?.payload);
            let stripeid = response?.data?.payload.id;
            let detailsSubmitted = response?.data?.payload?.detailsSubmitted;
            if (!verify && !detailsSubmitted) {
              GetClientStripeAccountUrl();
            }
            if (!verify && detailsSubmitted) {
              UpdateClientStripeAccount(stripeid);
            }
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="p-4 border border-[#A4B1A7]/[0.12] rounded-xl bg-[#12221A]/30 ">
        <h3 className="font-bold text-base">
          {
            stripeInfo?.isAccoundVerified ? <>Stripe Connected</> :
              stripeInfo?.clientStripeAccountId && clientStripeAccountInfo?.detailsSubmitted ? 'Stripe Processing' : 'Stripe Setup'
          }</h3>
        {/* {stripeInfo?.isAccoundVerified ? (
            <>
              <span className="text-[#B0E577] text-xs font-bold ">
                <img
                  className="inline align-sub  mr-1"
                  src={images.ApprovedSmIcon}
                  alt="icon"
                />
                Completed
              </span>
            </>
          ) : (
            <>
              <span className="text-[#E7E15A] text-xs font-bold ">
                <img
                  className="inline align-sub mr-1"
                  src={images.PendingIcon}
                  alt="icon"
                />
                Pending
              </span>
            </>
          )} */}
        {stripeInfo?.isAccoundVerified ? (<>
          <p className="text-[#8D9D91] text-xs mb-4">
            Your Stripe account is successfully linked. To view more information you may proceed to Stripe Dashboard
          </p>
        </>) : (
          <>
            <p className="text-[#8D9D91] text-xs mb-4">
              {stripeInfo?.clientStripeAccountId && clientStripeAccountInfo?.detailsSubmitted ? (

                "Your Stripe account is currently under process. Please click on refresh button to view the updated status"

              ) : (
                "To accept fiat payments on your storefront, please connect your Stripe account."
              )}
            </p>
          </>
        )}

        <div className="flex justify-between items-center">
          <div>
            {stripeInfo?.isAccoundVerified ? (
              <div className="flex">
                <span className="text-[#B0E577] text-xs font-bold ">
                  <img
                    className="inline align-sub  mr-1"
                    src={images.ApprovedSmIcon}
                    alt="icon"
                  />
                  Connected
                </span>
              </div>
            ) : (
              <>
                {stripeInfo?.clientStripeAccountId && clientStripeAccountInfo?.detailsSubmitted ? <>
                  <span className="text-[#E7E15A] text-xs font-bold ">
                    <img
                      className="inline align-sub mr-1"
                      src={images.PendingIcon}
                      alt="icon"
                    />
                    Processing
                  </span>
                </> : <>
                  <span className="text-[#F87171] text-xs font-bold ">
                    <img
                      className="inline align-sub mr-1"
                      src={images.NotConnectIcon}
                      alt="icon"
                    />
                    Not connected
                  </span>

                </>}

              </>
            )}
          </div>
          <div>
            {!stripeInfo?.isAccoundVerified ? (
              <>
                <button
                  className=" btn-primary px-8 py-1"
                  onClick={
                    clientStripeAccountInfo?.detailsSubmitted
                      ? getStripeAccountInfo
                      : handleConnectStripeAccount
                  }
                >
                  {clientStripeAccountInfo?.detailsSubmitted
                    ? "Refresh"
                    : "Connect Now "}
                </button>
              </>
            ) : (
              <a
                className="underline"
                href={"https://dashboard.stripe.com/"}
                target="_blank"
              >
                <button
                  className=" btn-primary px-8 py-1"
                // onClick={navigate("https://dashboard.stripe.com/login")}
                >
                  Stripe Dashboard
                </button>
              </a>
            )}
          </div>
        </div>
        {/* {!stripeInfo?.isAccoundVerified && (
          <>
            <button
              className="btn-primary"
              onClick={
                clientStripeAccountInfo?.detailsSubmitted
                  ? getStripeAccountInfo
                  : handleConnectStripeAccount
              }
            >
              {clientStripeAccountInfo?.detailsSubmitted
                ? "Refresh"
                : "Connect Stripe"}
            </button>
          </>
        )} */}
      </div>
   

      <div className="border border-[#A4B1A7]/[0.12] rounded-[12px]  mt-5 p-4 min-h-[442px] relative grow">
        <p className="font-bold text-base mb-4">Transaction Status</p>

        {dashboardTransactions?.length === 0 ? (
          // <Spinner />
          <div className="flex justify-center items-center flex-col  h-[358px]">
            <img src={images.RecordNotFoundIcon} alt="icon" />{" "}
            <p className="text-sm text-[#8D9D91] mt-2 text-center">
              You do not have any transactions history at <br />the moment.
            </p>{" "}
          </div>
        ) : (
          dashboardTransactions
            ?.slice(0, 7)

            .map((ind, index) => {
              return (
                <div
                  className="flex justify-between items-center mb-3"
                  key={index}
                >
                  <div className="flex justify-between items-center">
                    {ind?.assetVariationObject?.originalImage ? (
                      <img
                        src={ind?.assetVariationObject?.originalImage}
                        className="w-8 h-8 rounded-md object-cover"
                        alt=""
                      />
                    ) : ind?.assetVariationObject?.audioUri ? (
                      <img
                        src={images?.AudioIcon}
                        className="w-8 h-8 rounded-md "
                        alt=""
                      />
                    ) : ind?.assetVariationObject?.videoUri ? (
                      <img
                        className=" rounded w-8 h-8 object-fit"
                        onLoad={(e) => {
                          e.currentTarget.attributes.src.value =
                            ind?.assetVariationObject?.videoThumbnailUri;
                        }}
                        src={images.DefaultImageIcon}
                        alt="icon"
                      />
                    ) : (
                      null
                    )}
                    <div className="flex justify-between flex-col items-start pl-2 ">
                      <p className="text-xs font-normal">
                        {ind?.dropName && ind?.dropName.length > 18 ? (
                          <Tooltip
                            className="text-[white] text-xs bg-[#0A130D]    border border-[#A4B1A7]/[0.12] rounded"
                            offset={10}
                            placement={"top-start"}
                            content={ind?.dropName}
                          >
                            <p className="text-xs cursor-pointer">
                              {ind?.dropName

                                ?.split(" ")
                                .map(
                                  (word) =>
                                    word?.charAt(0).toUpperCase() +
                                    word?.substr(1)
                                )
                                .join(" ")
                                .slice(0, 18)
                              }
                              ...
                            </p>
                          </Tooltip>
                        ) : (
                          ind?.dropName
                        )}
                      </p>
                      <div className="text-xs font-normal flex justify-center items-center">
                        <span>
                          <img
                            src={`${ind?.orderStatus === "Unsuccessful"
                              ? images.RedFail
                              : ind?.orderStatus === "Successful"
                                ? images.GreenSuccess
                                : ind?.orderStatus === "Mint Transfer Failed"
                                  ? images.MintFailed
                                  : ""
                              }`}
                            className="w-3 h-3 pr-1 "
                            alt=""
                          />
                        </span>
                        <span
                          className={`${ind?.orderStatus === "Unsuccessful"
                            ? "text-[#F87171]"
                            : ind?.orderStatus === "Successful"
                              ? "text-[#B0E577]"
                              : ind?.orderStatus === "Mint Transfer Failed"
                                ? "text-[#DC2626]"
                                : ""
                            }`}
                        >
                          {" "}
                          {ind?.orderStatus}
                        </span>
                      </div>
                    </div>
                  </div>
                  <p className="text-xs font-bold w-20 ">
                    <span className="pr-1">
                      <img
                        src={
                          ind?.paymentProcessor === "Stripe"
                            ? images.MasterCardSm
                            : images.FlowIconSm

                        }
                        className=" rounded-full "
                        alt=""
                      />
                    </span>
                    <span>
                      <CustomTooltip totalSlice={`$${ind?.totalPriceUsd}`} sliceUpto={6} />
                    </span>
                  </p>
                </div>
              );
            })
        )}

        <div className="absolute bottom-4">
          {dashboardTransactions?.length > 0 ? (
            <div className="flex justify-start items-center"
            >

              <Link className="text-xs not-italic font-bold cursor-pointer hover:text-[#A5F33C] mt-2" to={'/tx-reporting'}>
                View All
                <span className="ml-2 align-top" ><RightArrow /></span>
              </Link>

            </div>
          ) : (
            ""
          )}
        </div>
      </div >
    </div>
  );
};

export default BrandStatistics;

