import { useState } from "react";
import { CategoryService } from "../../services/category.service";
import CloseIconView from "../ui-components/views-component/CloseIconView";
import AlertModalContent from "./AlertModalContent";

const DeleteCategoryModal = (props) => {
  const { categoryDetail, setOpenDeleteCategoryModal, setCategoriesData, setCategoryDetail } = props;
  const [loader, setLoader] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: "",
    description: "",
    sublabel: "",
  });

  const handleDeleteCategory = () => {
    setLoader(true);
    let interval;

    CategoryService.DeleteCategory(categoryDetail.id)
      .then(response => {
        setLoader(false)
        if (response?.data?.statusCodeValue === 200) {
          setCategoriesData(response?.data?.payload?.reverse())
          setAlertMessage({
            title: "Success",
            description: "Category deleted successfully",
            sublabel: "Auto closing in few seconds...",
          });
          interval = setTimeout(() => {
            resetState()
            clearTimeout(interval);
          }, 5000);

        }
      })
      .catch(error => {
        setLoader(false)
        setAlertMessage({
          title: "Error",
          description: error?.response?.data?.message ?? "Something went wrong",
          sublabel: "Auto closing in few seconds...",
        });
        interval = setTimeout(() => {
          resetState()
          clearTimeout(interval);
        }, 5000);
      })
  }
  const resetState = () => {
    setOpenDeleteCategoryModal(false); setCategoryDetail()
  }
  return (
    <>
      <div className="relative">
        <button
          onClick={() => {
            resetState();
          }}
          className="absolute -top-10 -right-10 text-gray-400 bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          data-modal-toggle="authentication-modal"
        >
          <CloseIconView />
        </button>

      </div>
      {alertMessage?.title === "" ? (
        <>
          <h4 className="mb-2 text-2xl font-bold text-white">Delete Category</h4>
          <p className="mb-10 text-base text-grey-100">
            {`Are you sure you want to delete “${categoryDetail.categoryName}”?`}
          </p>
          <div>
            <button className="mb-6 btn-primary disabled:cursor-not-allowed" disabled={loader} onClick={handleDeleteCategory}>Yes, Delete</button>
            <button className="btn-secondary" onClick={() => { resetState() }} >Cancel</button>
          </div>
        </>
      ) : (
        <div className="relative">
          <AlertModalContent message={alertMessage} />
          <button
            type="button"
            onClick={() => {
              resetState()
            }}
            className="btn-secondary"
            data-modal-toggle="authentication-modal"
          >
            close
          </button>
        </div>

      )}

    </>
  );
};

export default DeleteCategoryModal;
