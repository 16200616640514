const PRIMARY_COLOR_DESC = [
    "Primary color reflects on button colors and hover effect.",
    "Button text color reflects on button text colors.",
];

const BODY_COLOR_DESC= [
    "Overall background color change for product except footer.",
    "Body text color reflects on main headings throughout the storefront.",
]

const FOOTER_COLOR_DESC = [
    "Footer Color reflects in footer, & side bar navigations.",
    "Text color reflects on footer heading text.",
]

const SECONDARY_COLOR_DESC = [
    "Paragraph color reflects in all places where we have sub text."
]

const MAINTENANCE_DEFAULT_TITLE = "We are Under Maintenance" ;
const MAINTENANCE_DEFAULT_DESC = "It will take not take a long time till we get the error fixed. We will live again" ;

const themeConstants = {
    PRIMARY_COLOR_DESC,
    BODY_COLOR_DESC,
    FOOTER_COLOR_DESC,
    SECONDARY_COLOR_DESC,
    MAINTENANCE_DEFAULT_TITLE,
    MAINTENANCE_DEFAULT_DESC
}

export default themeConstants ;