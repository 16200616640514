import * as yup from "yup";

export const ClientThemeSchema = yup.object().shape({
  heroHeading: yup
    .string()
    .required("Hero heading is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),
    heroButtonText: yup
    .string()
    .required("Hero button text is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),
    upcomingDropHeading: yup
    .string()
    .required("Upcoming drop heading is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),
    upcomingDropButtonText: yup
    .string()
    .required("Upcoming drop button is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),
    currentDropHeading: yup
    .string()
    .required("Current drop heading is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),

    heroImage: yup.mixed().when("isthemeDataExist", {
      is: true,
      then: yup.mixed(),
      otherwise:yup.mixed().test("required", "Hero image is required", (file) => {
        if (file && file[0]) return true;
        return false;
      })
      .test("fileSize", "File size is too large", (value) => {
        if (!value[0]) return true;
        return value[0]?.size <= 209715200;
      })
    
      .test("dimensions", "Image dimensions must be 610x540 pixels", (value) => {
        if (!value[0]) return true; // Return true if no file is selected
    
        return new Promise((resolve) => {
          const image = new Image();
          if (value[0]) image.src = URL.createObjectURL(value[0]);
    
          image.onload = () => {
            resolve(image.width > 610 && image.height > 540);
          };
        });
      }),
    }),
 
  
  // .test("filesize", "Max file size allowed is 5MB", (value) => {
  //   return value && value[0]?.size <= 5000000;
  // })
  // .test("type", "JPG, PNG & GIF supported only", (value) => {
  //   return (
  //     value && value[0]?.type == ("image/png" || "image/gif" || "image/jpg" || "image/jpeg")
  //   );
  // })
  heroDescription: yup
    .string()
    .required("Description is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(200, "Max  length 200 characters"),
});
