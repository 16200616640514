import React from "react";
import { useNavigate } from "react-router-dom";

const Modal = ({
  modalVisible,
  closeModal,
  children,
  modal_Id,
  isClose,
  width ,
}) => {
  const navigate = useNavigate();

  return (
    //  Main modal

    <>
      <div
        id={modal_Id}
        tabIndex="-1"
        aria-hidden="true"
        className={`${
          modalVisible ? "backdrop-blur-[14px] custom-gradient " : "hidden"
        } flex justify-center items-center  overflow-y-auto overflow-x-hidden fixed top-0 z-[60] right-0 left-0 p-4 w-full md:inset-0  md:h-full`}
      >
        <div className={`relative w-full ${width ? width : 'max-w-[532px]'}`}>
          {/* Modal content  */}
          {/*Wrapping div for border gradient */}

          {/* Actual Modal Content */}
          <div className="relative rounded-lg border border-[#A4B1A7]/[0.12] shadow bg-[#12221A]/30">
            {isClose && (
              <button
                type="button"
                onClick={() => {
                  closeModal(false);
                  if (children?.props?.eventHandler?.length > 0) {
                    children?.props?.eventHandler();
                  }

                  if (children?.props?.message?.path) {
                    navigate(children?.props?.message?.path);
                  }
                }}
                className="absolute top-3 right-2.5 text-white bg-transparent   rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            )}
            <div className="p-14 max-h-[600px] overflow-auto">{children}</div>
          </div>
        </div>
      </div>
      <div
        className={`${
          modalVisible ? "" : "hidden"
        } opacity-50 fixed inset-0 z-40 bg-[#12221A]`}
      ></div>
    </>
  );
};

export default Modal;
