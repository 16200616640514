
import { useDispatch } from "react-redux";
import { clientDetailAction } from "../../redux/features/user/userSlice";
import clientService from "../../services/client.service";

const useClientDetails = () => {
    const dispatch = useDispatch();
    const clientDetails = async (clientid) => {
        try {
            const clientId = await clientService.getClientDetailsById(
                clientid,
            );
            dispatch(clientDetailAction(clientId?.data?.payload));
          
        } catch (error) {
        }
    };

    return {
        clientDetails,
    };
};

export default useClientDetails;
