import React, { useState } from "react";
import { images } from "../../../../../constants";
import stringTruncate from "../../../../../helpers/StringTruncate";
import { Tooltip } from "@material-tailwind/react";
import AssetNontransferIcon from "../../../../../components/ui-components/views-component/AssetNontransferIcon";
import AssetTransferIcon from "../../../../../components/ui-components/views-component/AssetTransferIcon";

const AssetCheckbox = (props) => {
  const { name, media, isChecked, onChange, templateType, transferable } =
    props;
  return (
    <>
      <div
        className={`group mr-6 mb-8 d-inherit overflow-hidden p-[1px] max-w-[235px] h-[261px]  ${
          isChecked
            ? "background-border-rad rounded-3xl"
            : "background-border-rad rounded-3xl"
        }`}
      >
        <label
          className={`p-4 relative bg-[#060C07] border-1 rounded-3xl custom-checkbox ${
            isChecked ? "border-transparent" : "border-[#A4B1A7]/[.12]"
          }  `}
        >
          <input
            checked={isChecked}
            onChange={onChange}
            type={"checkbox"}
            className={
              "bg-transparent  absolute z-10  mt-2 ml-2   ring-offset-0 cursor-pointer  focus:ring-offset-0 ring-transparent focus:ring-transparent checked:bg-transparent checked:border-[#A5F33C] checked:bg-[url('/src/assets/images/icons/active-check-icon.svg')]   rounded-full w-6 h-6 border-2 border-[#94A3B8] mb-2"
            }
          />
          {transferable ? (
            <>

<div className="w-[102px]   inline-block absolute top-6 right-6  overflow-hidden  ">
               <span className=" bg-white w-[24px] text-[#12221A] transition-all duration-3000  flex  items-center group-hover:pl-1 pl-[5px] h-6  rounded-full float-right group-hover:w-[101px]  ">
                 <span className="group-hover:bg-[#12221A] group-hover:p-[1px] bg-white   group-hover:text-white h-fit rounded-full">
                   <AssetTransferIcon />
                 </span>
                 <span className=" ml-1 group-hover:inline-block hidden group-hover:relative group-hover:visible group-hover:opacity-[1] opacity-0 group-hover:z-[1] z-[-1]  relative text-xs text-black font-semibold">
                   Transferable
                 </span>
               </span>
             </div> 
              
            </>
          ) : (
           
            <div className="w-[124px] absolute top-6 right-6 inline-block overflow-hidden ">
            <span className=" bg-white w-[24px] text-[#12221A] transition-all duration-3000  flex items-center group-hover:pl-1 pl-[5px] h-6  rounded-full float-right group-hover:w-[124px]  ">
              <span className="group-hover:bg-[#12221A] bg-white   group-hover:text-white h-fit rounded-full">
                <AssetNontransferIcon />
              </span>
              <span className=" ml-1  whitespace-nowrap group-hover:inline-block hidden group-hover:relative group-hover:visible group-hover:transition-opacity group-hover:ease-in group-hover:duration-[70000] group-hover:opacity-[1] opacity-0 group-hover:z-[1] z-[-1]  relative text-xs text-black font-semibold">
              {`Non-transferable`}
              </span>
            </span>
          </div>
           
          )}

          <div className="flex justify-center ">
            {templateType === "Audio" && (
              <video
                id="audio"
                controls
                src={media}
                poster={images.audioPoster}
                onClick={() => onChange()}
                width="203"
                height={"203"}
                className=""
              ></video>
            )}
            {templateType === "Video" && (
              <div>
                <video
                  id="video"
                  controls
                  src={media}
                  width="203"
                  height={"203"}
                  className="h-[203px] w-[203px] object-cover"
                ></video>
              </div>
            )}
            {templateType === "Image" && (
              <div className="h-[203px]">
                <img
                  className="w-[203px] h-[203px] object-cover rounded-2xl"
                  src={media}
                  alt="icon"
                />
              </div>
            )}
          </div>

          <div className="">
            <Tooltip
              className=" bg-[#0A130D] z-50  custom-tooltip-style max-w-[200px]"
              offset={10}
              placement={"top-start"}
              content={name}
            >
              <h6 className=" mt-2 text-xs font-semibold">
                {stringTruncate(name, 18)}{" "}
              </h6>
            </Tooltip>
          </div>
        </label>
      </div>
    </>
  );
};

export default AssetCheckbox;
