import Resizer from "react-image-file-resizer";
import dataUrlToFile from "../../../../helpers/dataUrlToFile";
import { v4 as uuidv4 } from "uuid";
import { images } from "../../../../constants";

import ImageCropper from "../../../../components/Modals/ImageCropper";
import VideoThumbnail from "react-video-thumbnail";
import ModalLayoutMd from "../../../../common/ModalLayoutMd";
import Modal from "../../../../common/ModalLayout";
const AssetSchema = (props) => {
  /** Destructring the props */
  const {
    schemaUploadDiv,
    setschemaUploadDiv,
    UploadSchemaHandler,
    setshowUploadedSchema,
    showUploadedSchema,
    setuploadedSchemaSrc,
    uploadedSchemaSrc,
    setThumbnailSource,
    crossImgClickHandler,
    OrignalImgUrlRef,
    setSelectedSchemaFileValue,
    ImageUploadInputRef,
    croppedImgUrl,
    setshowCroppingPopup,
    showCroppingPopup,
    selectedSchemaType,
    thumbnailSource,
    setIsMediaSelect,
    assetCreationErrorMsgs,
    setOpenAlertModal,
    isMediaSelect,
  } = props;

  /** Click event handlers */

  const ImageChangeHandler = async (e) => {
    const file_data = e.target.files[0];

    ImageUploadInputRef.current = file_data;
    let reg = /(.*?)\.(jpg|jpeg|PNG|png|gif)$/;

    if (file_data === undefined || !file_data.name.match(reg)) {
      alert("Only jpg,png and gif images are supported");
      return false;
    }
    const checkFileSize = CheckSelectedFileSize(file_data.size);
    if (checkFileSize) {
      return;
    }
    Resizer.imageFileResizer(
      file_data,
      1024,
      1200,
      file_data.type.split("/")[1],
      100,
      0,
      (result) => {
        const blob = result;
        setshowUploadedSchema(""); //hide uploading and show uploaded div
        setIsMediaSelect({ status: false, message: "Please select crop" });
        setschemaUploadDiv("hidden");
        let fileName = uuidv4() + "." + file_data.type.split("/")[1];
        let fileData = dataUrlToFile(blob, fileName);
        setuploadedSchemaSrc(blob); //Image to be shown
        OrignalImgUrlRef.current = fileData; //img to be saved into our db
        setSelectedSchemaFileValue(file_data); //img to be sent to arweave
        setshowCroppingPopup(true);
      },
      "File"
    );
  };

  const getCroppedImgFile = (imgFile) => {
    croppedImgUrl.current = imgFile;
    setshowCroppingPopup(false);
  };
  const AudioChangeHandler = (e) => {
    const file_data = e.target.files[0];
    setIsMediaSelect({ status: true, message: "" });
    let reg = /(.*?)\.(mp3|wav)$/;
    if (file_data === undefined || !file_data.name.match(reg)) {
      alert("only mp3 and wav files are supported");
      return false;
    }
    if (file_data.size === 0) {
      alert("Please upload valid audio file");
      return false;
    }
    const checkFileSize = CheckSelectedFileSize(file_data.size);
    if (checkFileSize) {
      return;
    }
    let audioSrc = URL.createObjectURL(file_data);
    setuploadedSchemaSrc(audioSrc);
    OrignalImgUrlRef.current = file_data;
    setSelectedSchemaFileValue(file_data);

    setshowUploadedSchema("");
    setschemaUploadDiv("hidden");
  };
  const VideoChangeHandler = (e) => {
   
    const file_data = e.target.files[0];
    setIsMediaSelect({ status: true, message: "" });
    ImageUploadInputRef.current = file_data;
    let reg = /(.*?)\.(mp4)$/;
    if (file_data === undefined || !file_data.name.match(reg)) {
      setIsMediaSelect({ status: false, message: "Video is not compatible with format try other!" });
      return false;
    }
    const checkFileSize = CheckSelectedFileSize(file_data.size);
    if (checkFileSize) {
      return;
    }
    // if (file_data.size > 104857600) {
    //   alert.error("File Size Should be less than 100 MB");
    //   return false;
    // }
    let videoSrc = URL.createObjectURL(file_data);
    OrignalImgUrlRef.current = file_data;
    setuploadedSchemaSrc(videoSrc);

    setshowUploadedSchema("");
    setschemaUploadDiv("hidden");
    setSelectedSchemaFileValue(file_data);
  };
  const CheckSelectedFileSize = (size) => {
    if (size > 104857600) {
      // Byte into MB (10000000 = 10 MB)  Where (104857600 = 100 MB)
      setOpenAlertModal(true);
      assetCreationErrorMsgs("File Size Should be less than 100 MB");
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="w-full max-lg:min-h-[200px] min-h-[400px] h-full">
        {/* selectedSchemaType 1 IS FOR IMAGE,2 IS FOR AUDIO,3 IS FOR VIDEO */}

        <div className={schemaUploadDiv + ""} onClick={UploadSchemaHandler}>
          <label for="uploadSchema" className="">
            <input
              type="file"
              id="uploadSchema"
              className="hidden form-control"
              accept={
                selectedSchemaType === 1
                  ? ".gif,.jpg,.jpeg,.png"
                  : selectedSchemaType === 2
                  ? ".mp3,.wav"
                  : ".mp4"
              }
              name="SchemaTypeValue"
              onChange={
                selectedSchemaType === 1
                  ? ImageChangeHandler
                  : selectedSchemaType === 2
                  ? AudioChangeHandler
                  : VideoChangeHandler
              }
              ref={ImageUploadInputRef}
            />
            <h4 className="mb-3 text-xl font-bold text-white">
              {" "}
              Upload{" "}
              {selectedSchemaType === 1
                ? "Image"
                : selectedSchemaType === 2
                ? "Audio"
                : " Video"}{" "}
            </h4>

            <div className="">
              <div className="relative flex flex-col items-center justify-center border border-[#A4B1A7] border-solid rounded w-full max-lg:min-h-[200px] max-w-[403px] min-h-[400px] h-full">
                <img
                  className="text-center "
                  src={images.UploadAssetIcon}
                  alt="upload-icon"
                />

                <h5 className="text-base text-center text-white ">
                  <span className="font-bold">Browse File</span>
                </h5>
                <p className="text-xs text-grey-100">
                  Larger recommended, up to 100MB each.
                </p>
                {selectedSchemaType === 1 && (
                  <p className="text-xs text-grey-100">
                    Recommended aspect ratio is 5:6{" "}
                  </p>
                )}
              </div>
            </div>
          </label>
        </div>

        <div className={showUploadedSchema}>
          <div className="flex items-start justify-end">
            {selectedSchemaType === 1 ? (
              <img
                className={` max-w-[400px]  max-h-[400px]   w-full h-full object-contain`}
                src={
                   uploadedSchemaSrc
                }
                alt="uploadedimg"
              />
            ) : selectedSchemaType === 2 ? (
              <video
                id="audio"
                controls
                src={uploadedSchemaSrc}
                poster={images.audioPoster}
                className="w-[400px] h-full object-cover"
              ></video>
            ) : (
              <video
                id="video"
                controls
                src={uploadedSchemaSrc}
                className="object-cover w-1/2 h-full"
              ></video>
            )}

            <div className="col-md-4">
              {uploadedSchemaSrc && selectedSchemaType === 3 && (
                <div className="max-w-[200px] w-full ml-3 ">
                  <VideoThumbnail
                    snapshotAtTime={10}
                    videoUrl={uploadedSchemaSrc}
                    thumbnailHandler={(thumbnail) => {
                      let thumbnailFile = dataUrlToFile(
                        thumbnail,
                        "thumnailImg.png"
                      );
                      setThumbnailSource(thumbnailFile);
                    }}
                  />
                  <label>Thumbnail Image</label>
                </div>
              )}
            </div>
            <img
              className="-ml-4 cursor-pointer"
              src={images.RemoveFieldIcon}
              alt="crossimg"
              onClick={crossImgClickHandler}
            />
            <div className="">
              {showCroppingPopup && (
                <Modal
                  modalVisible={showCroppingPopup}
                  closeModal={setshowCroppingPopup}
                  children={
                    <ImageCropper
                      imgSrc={uploadedSchemaSrc}
                      getCroppedImgFile={getCroppedImgFile}
                      setIsMediaSelect={setIsMediaSelect}
                      isMediaSelect={isMediaSelect}
                      aspectRatio={5 / 6}
                      eventHandler={crossImgClickHandler}
                    />
                  }
                  modal_Id="Add_Modal"
                  isClose={true}
                />
              )}
              {croppedImgUrl.current && (
                <>
                  <img
                    src={URL.createObjectURL(croppedImgUrl.current)}
                    alt="copped img"
                    name="CroppedImage"
                    className="w-full max-w-[400px] ml-3 max-h-[200px] object-contain hidden"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {!showUploadedSchema && !isMediaSelect?.status && (
          <p className="mt-2 text-red">{isMediaSelect?.message}</p>
        )}
        <div className="hidden">
          {OrignalImgUrlRef.current && selectedSchemaType === 1 && (
            <button
              onClick={(e) => {
                if (OrignalImgUrlRef.current) {
                  e.preventDefault();
                  setshowCroppingPopup(true);
                }
              }}
              className={"btn-primary w-[200px] mt-4" + showUploadedSchema}
            >
              Cropped
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default AssetSchema;
