import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SwitchComponent from "../../ui-components/switch-component/SwitchComponent";
import ClientHeaderComponent from "../../../pages/ClientPortal/components/ClientHeaderComponent";
import { images } from "../../../constants";
import Spinner from "../../ui-components/loader-components/Spinner";
import SocialMediaInputField from "./ClientPreferenceComponents/SocialMediaInputField";
import { BrandSocialSchema } from "../../../utils/ValidationSchemas/BrandSocialSchema";
import { useSelector } from "react-redux";
import useClientThemeHook from "../useClientThemeHook";
import Modal from "../../../common/ModalLayout";
import AlertModalContent from "../../Modals/AlertModalContent";
import themePreferencesConstants from "../../../constants/client-admin-constants/clientPreferenceConstants/ClientPreferenceConstant";



const SocialsMedia = () => {
  const { clientDetail, clientUuid } = useSelector((state) => state.user);

  const {
    loading,
    setLoading,
    submitSocialMediaLink,
    OpenAlertModal,
    setOpenAlertModal,
    alertContent,
    resetLoading,
    setResetLoading,
    clientThemeDetail,
    getClientThemeDetails,
    functionaltyPreference
  } = useClientThemeHook();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BrandSocialSchema),
    mode: "onChange",
    defaultValues: {
      instagram: clientDetail.instagramUrl || "",
      linkedin: clientDetail.linkedInUrl || "",
      discord: clientDetail.discordUrl || "",
      twitter: clientDetail.twitterUrl || "",
      enableSocialMediaMode: clientDetail.showSocialMediaIcons ?? true,
    },
  });
  useEffect(() => {
    getClientThemeDetails()
  }, [])
  useEffect(() => {
    setValue('enableSocialMediaMode', functionaltyPreference?.socialLinks)
  }, [functionaltyPreference?.socialLinks])


  const handleSocialsMediaSubmit = (data) => {

    setLoading(true);
    let formData = new FormData();
    formData.append("ClientUuid", clientUuid);
    formData.append("InstagramUrl", data.instagram || "");
    formData.append("LinkedInUrl", data.linkedin || "");
    formData.append("DiscordUrl", data.discord || "");
    formData.append("TwitterUrl", data.twitter || "");
    formData.append("ShowSocialMediaIcons", data.enableSocialMediaMode);
    formData.append("stepsEnum", 5);
    submitSocialMediaLink(formData);
  };

  const handleReset = () => {
    setResetLoading(true);
    reset({ discord: "", instagram: "", linkedin: "", twitter: "", enableSocialMediaMode: true });
    let formData = new FormData();
    formData.append("ClientUuid", clientUuid);
    formData.append("stepsEnum", 5);
    submitSocialMediaLink(formData);
  };

  const handleEnableSocialIcon = (event) => {
    setValue("enableSocialMediaMode", event.target.checked);
  };

  const enableSocialModeStatus = watch("enableSocialMediaMode");


  return (
    <>
      <ClientHeaderComponent
        title={"Back to Preferences"}
        backLink={"/preferences"}
      />
      <div className="flex justify-between items-center mb-1 max-w-[495px] ">
        <h3 className="text-xl mb-1">Social Media Links</h3>
        <SwitchComponent onChange={handleEnableSocialIcon} value={enableSocialModeStatus} id={"enableSocialMediaMode"} />
      </div>
      <p className="text-[#8D9D91] text-xs  mb-6 max-w-[495px]">
        {themePreferencesConstants.PREFERENCES.SOCIAL_DESC}
      </p>
      <form
        className="w-6/12 text-white"
        onSubmit={handleSubmit(handleSocialsMediaSubmit)}
      >
        {/* Instagram */}
        <SocialMediaInputField
          icon={images.instagram_icon}
          name={"instagram"}
          register={register}
          placeholder={"https://"}
          type={"text"}
          error={errors?.instagram?.message}
        />
        <SocialMediaInputField
          icon={images.linkedin_icon}
          name={"linkedin"}
          register={register}
          placeholder={"https://"}
          type={"text"}
          error={errors?.linkedin?.message}
        />
        <SocialMediaInputField
          icon={images.discord_icon}
          name={"discord"}
          register={register}
          placeholder={"https://"}
          type={"text"}
          error={errors?.discord?.message}
        />
        <SocialMediaInputField
          icon={images.twitter_icon}
          name={"twitter"}
          register={register}
          placeholder={"https://"}
          type={"text"}
          error={errors?.twitter?.message}
        />

        {/* Submit BUTTON */}
        <div className="flex justify-between mt-10 mb-[30px] max-w-[494px] ">
          <button
            onClick={handleReset}
            type="button"
            className={`btn-secondary disabled:cursor-not-allowed mr-4 capitalize`}
          >
            {resetLoading && <Spinner />}
            Reset
          </button>
          <button
            type="submit"
            className={`btn-primary  disabled:cursor-not-allowed`}
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            disabled={loading}
          >
            {loading && <Spinner />}
            Save Changes
          </button>
        </div>
      </form>
      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Save_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default SocialsMedia;
