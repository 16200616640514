import React, { useEffect, useState } from "react";
import ClaimListingTable from "./ClaimListingTable";
import AirdropService from "../../services/airdrop.service";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../redux/features/loading/loaderSlice";

const WhitelistListing = () => {
  const dispatch = useDispatch()

  const [claimList, setClaimList] = useState([]);

  const { id } = useSelector((state) => state.user.clientDetail);

  useEffect(() => {
    getClaimReportList();
  }, []);

  const getClaimReportList = async () => {
    dispatch(setLoader(true))
    const payload = {
      clientId: id.toString(),
      fromDate: null,
      todate: null,
    };
    const response = await AirdropService.claimTransactionsReportList(payload);

    // })
    setClaimList(response?.data?.payload?.reverse());
    dispatch(setLoader(false))
  };

  return <div>{<ClaimListingTable defaultData={claimList} />}</div>;
};

export default WhitelistListing;
