import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ClientHeaderComponent from "../../../pages/ClientPortal/components/ClientHeaderComponent";
import { images } from "../../../constants";
import Modal from "../../../common/ModalLayout";
import AlertModalContent from "../../Modals/AlertModalContent";
import Spinner from "../../ui-components/loader-components/Spinner";
import { ClientThemeSchema } from "../../../utils/ValidationSchemas/ClientThemeSchema";
import useClientThemeHook from "../useClientThemeHook";
import InputField from "../../ui-components/form-component/InputFieldComponent";
import themePreferencesConstants from "../../../constants/client-admin-constants/clientPreferenceConstants/ClientPreferenceConstant";

const HomePage = () => {
  const fileInputRef = useRef();
  const [fileSize, setFileSize] = useState();

  const {
    getClientThemeDetails,
    updateDataFunc,
    loading,
    OpenAlertModal,
    setOpenAlertModal,
    alertContent,
    homeData,
    file,
    setFile,
    clientThemeDetail,
    setLoading,
    resetLoading,
    setResetLoading,
  } = useClientThemeHook();

  const user = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ClientThemeSchema),
    mode: "all",
  });

  useEffect(() => {
    if (homeData?.heroHdn) {
      setValue("isthemeDataExist", true);
      setValue("heroHeading", homeData?.heroHdn);
      setValue("heroDescription", homeData?.heroDsc);
      setValue("heroImage", homeData?.heroImg);
      setValue("heroButtonText", homeData?.heroBtnTxt);
      setValue("upcomingDropHeading", homeData?.upComingDropTxt);
      setValue("upcomingDropButtonText", homeData?.upComingDropBtnTxt);
      setValue("currentDropHeading", homeData?.currentDropTxt);
      setFile(homeData?.heroImg);
    } else {
      setValue("heroButtonText", "Explore Collection");
      setValue("upcomingDropHeading", "Upcoming Drops");
      setValue("upcomingDropButtonText", "View Drops Details");
      setValue("currentDropHeading", "Current Drops");
    }
  }, [homeData?.heroHdn]);

  const handleChange = (e) => {
    setValue("isthemeDataExist", false);
    setFileSize((e.target.files[0]?.size / 1024).toFixed(2));
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  // Cross icon to remove the selected file
  const handleRemoveImage = () => {
    setFile("");
    setFileSize("");
    reset({ ...getValues(), heroImage: "" });
    setValue("isthemeDataExist", false);
  };

  useEffect(() => {
    getClientThemeDetails();
  }, []);

  const submitForm = (data) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("clientThemeSteps", 1);
    formData.append(
      "ClientThemeUuid",
      clientThemeDetail?.clientThemeUuid
        ? clientThemeDetail?.clientThemeUuid
        : ""
    );

    formData.append("ClientId", user?.clientDetail?.clientUuid);
    formData.append("BrandName", user?.clientDetail?.clientName);
    formData.append("HeroHeading", data?.heroHeading);
    formData.append("HeroDescription", data?.heroDescription);
    formData.append("HeroImg", data?.heroImage[0]);
    formData.append("HeroButtonText", data?.heroButtonText);
    formData.append("UpComingDropsHeadingText", data?.upcomingDropHeading);
    formData.append("UpComingDropsBtnText", data?.upcomingDropButtonText);
    formData.append("CurrentDropsHeadingText", data?.currentDropHeading);
    setValue("");
    updateDataFunc(formData);
  };
  const handleReset = () => {
    setResetLoading(true);
    let formData = new FormData();
    formData.append("clientThemeSteps", 1);
    formData.append("ClientThemeUuid", clientThemeDetail?.clientThemeUuid);
    formData.append("ClientId", user?.clientDetail?.clientUuid);
    formData.append("HeroButtonText", "Explore Collection");
    formData.append("UpComingDropsHeadingText", "Upcoming Drops");
    formData.append("UpComingDropsBtnText", "View Drops Details");
    formData.append("CurrentDropsHeadingText", "Current Drops");
    updateDataFunc(formData);
    if (homeData?.heroImg) {
      setValue("isthemeDataExist", true);
      setValue("heroHeading", homeData?.heroHdn);
      setValue("heroDescription", homeData?.heroDsc);
      setValue("heroImage", homeData?.heroImg);

      setFile(file ?? homeData?.heroImg);
    }
    reset({
      ...getValues(),
      heroButtonText: "Explore Collection",
      upcomingDropHeading: "Upcoming Drops",
      upcomingDropButtonText: "View Drops Details",
      currentDropHeading: "Current Drops",
    });
  };

  return (
    <>
      <ClientHeaderComponent
        title={"Back to Preferences"}
        backLink={"/preferences"}
      />
      <h1 className="mb-1 text-xl font-bold">Home Page</h1>
      <p className="text-[#8D9D91] text-xs max-w-[494px] mb-6">
        {themePreferencesConstants.PREFERENCES.HOME_DESC}
      </p>
      <form className="  text-white" onSubmit={handleSubmit(submitForm)}>
        <div className="grid grid-cols-2 gap-3">
          <div className="max-w-[494px]">
            <h3 className="text-base font-bold mb-3">Hero Section </h3>

            <div className="relative z-10 mt-6 mb-6">
              <InputField
                type="text"
                label="Hero Heading"
                error={errors?.heroHeading?.message}
                register={register}
                name="heroHeading"
              />
            </div>
            <div className="relative z-10 mt-10 mb-6">
              <InputField
                type="text"
                label="Hero Button Text"
                error={errors?.heroButtonText?.message}
                register={register}
                name="heroButtonText"
              />
            </div>
            <div className="relative z-0 mt-12 mb-8 ">
              <textarea
                type="text"
                name="heroDescription"
                rows="4"
                maxLength="201"
                className={`peer outline-none  px-3 py-2  border rounded-xl border-[#A4B1A7] text-white text-md  placeholder:text-white focus:ring-transparent focus:border-[#A4B1A7] focus:outline-none  block w-full  bg-transparent `}
                placeholder=" "
                {...register("heroDescription")}
              ></textarea>
              <label
                className={`text-xs text-grey-100 z-[-1]  left-0 absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3   peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs peer-focus:left-0`}
              >
                Hero Description
              </label>
              {!errors?.heroDescription?.message && (
                <p className="mt-2 text-xs text-grey-100">
                  (Max length 200 characters)
                </p>
              )}
              <p className="mt-1 text-xs text-red-600">
                {errors?.heroDescription?.message}
              </p>
            </div>
            <h3 className="text-base mb-3 font-bold">Upcoming Drops</h3>
            <div className="relative z-10 mt-8 mb-6">
              <InputField
                type="text"
                label="Upcoming Heading"
                error={errors?.upcomingDropHeading?.message}
                register={register}
                name="upcomingDropHeading"
              />
            </div>
            <div className="relative z-10 mt-10 mb-8">
              <InputField
                type="text"
                label="Upcoming Button Text"
                error={errors?.upcomingDropButtonText?.message}
                register={register}
                name="upcomingDropButtonText"
              />
            </div>

            <h3 className="text-base mb-3 font-bold">Current Drops</h3>
            <div className="relative z-10 mt-8 mb-6">
              <InputField
                type="text"
                label="Current Drops Heading"
                error={errors?.currentDropHeading?.message}
                register={register}
                name="currentDropHeading"
              />
            </div>

            {/* Save  */}
            <div className="flex justify-between mt-10 mb-[30px]">
              {/** We will implement this is next phase  */}
              <button
                type="button"
                className={`btn-secondary disabled:cursor-not-allowed mr-4 capitalize`}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                disabled={resetLoading}
                onClick={handleReset}
              >
                {resetLoading && <Spinner />}
                Reset
              </button>
              <button
                type="submit"
                className={`btn-primary  disabled:cursor-not-allowed`}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                disabled={loading}
              >
                {loading && <Spinner />}
                Save Changes
              </button>
            </div>
          </div>
          <div className="justify-self-end">
            <h1 className="text-xl font-bold mb-3">Upload Hero Image</h1>

            <label htmlFor="inputFileSelector" className="mb-2 cursor-pointer">
              <input
                hidden={true}
                id="inputFileSelector"
                ref={fileInputRef}
                accept={".gif,.jpg,.jpeg,.png"}
                type="file"
                name="heroImage"
                // className={inputFieldStyle}
                {...register("heroImage", {
                  onChange: (e) => {
                    handleChange(e);
                  },
                })}
              />
              {(!file || errors?.heroImage?.message) && (
                <div className="flex items-center flex-col max-w-[346px] min-w-[346px] justify-center text-white font-poppins border max-h-[306px] border-[#A4B1A7] p-4 border-grey-100-700 border-solid rounded-[4px] w-full min-h-[96px] h-full">
                  <img className="" src={images.UploadIcon} alt="" />
                  <h4 className="text-base mb-3">
                    <span className="font-bold">Browse File</span>{" "}
                  </h4>

                  <p className="text-xs text-grey-100">
                    JPG, GIF, PNG | Max file size: 5 MB
                  </p>
                  <p className="text-xs text-grey-100">
                    Dimension 610 width * 540 height
                  </p>
                </div>
              )}
            </label>
            <div className="">
              {file && !errors?.heroImage?.message && (
                <div className="flex items-start">
                  <img
                    className="object-contain  w-full min-w-[346px] max-w-[346px]  min-h-[96px] h-full max-h-[320px] rounded-2xl"
                    src={file}
                    alt="logo"
                  />
                  <img
                    className="-ml-4 cursor-pointer"
                    src={images.RemoveFieldIcon}
                    alt="crossimg"
                    onClick={handleRemoveImage}
                  />
                </div>
              )}
              <p className="mt-1 text-xs text-red-600">
                {errors?.heroImage?.message}
              </p>
            </div>
          </div>
        </div>
      </form>
      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Save_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default HomePage;
