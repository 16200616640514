import React, { useState } from "react";
import { images } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import KeyValueView from "../ui-components/views-component/KeyValueView";
import { useLocation } from "react-router-dom";
import DoughnutChart from "./DonughtChartLarge";
import DoughnutChartSmall from "./DonughtChartSmall";
import UseDatePicker from "../../helpers/custom-hooks/UseDatePicker";
import { useEffect } from "react";
import EmphtyDonutChart from "./EmphtyDonutChart";
import { ClientTxService } from "../../services/transaction.service";
import { setLoader } from "../../redux/features/loading/loaderSlice";
import { startOfMonth, endOfMonth } from "date-fns";
import CalenderPopup from "./CalenderPopup";
import useClientDetails from "../../helpers/custom-hooks/useClientDetails";



const DropTransactionDetails = () => {
  const clientInfo = useSelector((state) => state?.user?.clientDetail);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    staticRanges,
    handleCancel,
    donughtStartDate,
    formatDateToMonthYear,
    handleChangeDonught,
    openPopover,
    setopenPopover
  } = UseDatePicker();


  // show it in the Popup
  const formatDate = (date) =>
    date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });

  const formattedStartDate = donughtStartDate[0]?.startDate ? formatDate(new Date(donughtStartDate[0]?.startDate)) : null;
  const formattedEndDate = formatDate(new Date(donughtStartDate[0]?.endDate));
  const donughtDateCalenderPopups = formattedStartDate ? `${formattedStartDate} - ${formattedEndDate ? formattedEndDate : ''}` : '';
  //ends here 

  const dateObject = donughtStartDate[0]?.startDate === null ? 'No Date Selected' : new Date(donughtStartDate[0]?.startDate);
  const formattedDate = dateObject.toLocaleString("en-US", {
    month: "short",
    year: "numeric",
  });


  const dispatch = useDispatch()
  const clientDetail = useSelector((state) => state?.user?.clientDetail);

  const [largeDonughtChart, setLargeDonughtChart] = useState({});
  const [donughtParams, setDonughtParams] = useState({
    clientId: clientDetail?.id?.toString(),
    fromDate: formatDateToMonthYear(startOfMonth(new Date() - 1)),
    todate: formatDateToMonthYear(endOfMonth(new Date())),
  });

  const handleParamsUpdate = () => {
    setopenPopover(false);
    if (formatDateToMonthYear(donughtStartDate[0]?.startDate)) {
      setDonughtParams({
        ...donughtParams,
        fromDate: formatDateToMonthYear(donughtStartDate[0]?.startDate),
        todate: formatDateToMonthYear(donughtStartDate[0]?.endDate),
      });
    }
  };

  // donught chart Fun
  const getDonughtChartFun = async () => {
    dispatch(setLoader(true));
    await ClientTxService.getDonughtChartDetails(donughtParams)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          dispatch(setLoader(false));
          setLargeDonughtChart(response?.data?.payload);
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };

  useEffect(() => {
    getDonughtChartFun();
  }, [donughtParams]);

  return (
    <>
      <div className="p-4 border border-[#A4B1A7]/[0.12] rounded-xl ">
        <div className="flex justify-between items-center mb-4">
          <p className="text-base font-bold">Transactions Details</p>
          <CalenderPopup
            donughtMonth={formattedDate}
            dateCalenderPopups={donughtDateCalenderPopups}
            handleChange={handleChangeDonught}
            handleUpdate={handleParamsUpdate}
            staticRanges={staticRanges}
            handleCancel={handleCancel}
            range={donughtStartDate}
            openPopover={openPopover}
            setopenPopover={setopenPopover}
          />
        </div>
        {largeDonughtChart?.totalTransactions <= 0 ? (
          <>
            <div className="flex justify-center  ">
              <EmphtyDonutChart />
            </div>
            <div className="flex justify-center items-center pt-4 w-full">
              <div className="flex justify-center items-center  mr-6">
                <p className="w-3 rounded-sm h-3 mr-2 bg-[#4F80FD]"></p>
                <p className="text-xs font-normal text-[#8D9D91] ">
                  Net Sale:
                </p>
                <p className="text-[#FFF] text-xs font-semibold pl-1">$0</p>
              </div>
              <div className="flex justify-center items-center ">
                <p className="w-3 rounded-sm h-3 mr-2 bg-[#E7E15A]"></p>
                <p className="text-xs font-normal text-[#8D9D91]">
                  Platform Fee:
                </p>
                <p className="text-[#FFF] text-xs font-semibold pl-1">$0</p>
              </div>
            </div>{" "}
          </>
        ) : (
          <DoughnutChart largeDonughtChart={largeDonughtChart} />
        )}
      </div >


      <div className="p-4 mt-5 border border-[#A4B1A7]/[0.12] rounded-xl bg-[#12221A]/30">
        <h2 className="text-base font-bold mb-4">Drops Created</h2>
        <DoughnutChartSmall />
      </div>
      <div className="p-4 mt-5 border border-[#A4B1A7]/[0.12] rounded-xl  bg-[#12221A]/30">
        <div className="flex justify-between items-center mb-4 ">
          <p className="text-base leading-6 font-bold">Brand Details</p>
          <div className="flex justify-between items-center">
            <img
              src={clientInfo?.isClientApproved ? images.ApprovedIcon : ""}
              alt=""
              className="w-4 h-4"
            />
            <p className="text-xs not-italic font-bold leading-4 text-[#B0E577] pl-1">
              {clientInfo?.isClientApproved ? "Approved" : "-"}
            </p>
          </div>
        </div>
        <div className=" mt-4">
          <KeyValueView
            title={"Domain Name"}
            value={clientInfo?.clientDomainName ?? "-"}
            path={true}
            style={"font-bold text-sm hover:text-[#A5F33C]"}
            media={images.LinkIcon}
            mainStyle={"mr-8"}
          />
        </div>
      </div>
    </>
  );
};

export default DropTransactionDetails;
