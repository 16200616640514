import { Fragment } from "react";
import PackageOption from "./PackageOption.";

const PackagesCommonListing = ({ ClientPackagesData, sm }) => {
 
  return (
    <>
      {ClientPackagesData?.map((item, index) => (
        <Fragment key={index}>
          <PackageOption option={`${item?.storeManager} - Store Manager`} sm={sm} />
          {item?.roleBaseAccess && (
            <PackageOption option={`Role-based access`} sm={sm}  />
          )}
          <PackageOption
            option={`${item?.collectionLimit === 1 ? `${item?.collectionLimit} - Collection` :`${item?.collectionLimit} - Collections`} `} sm={sm} 
          />
          <PackageOption option={`${item?.defaultNFtLimit === 0 ? `Unlimited NFTs / Storefront` :` ${item?.defaultNFtLimit === 10 ? `${item?.defaultNFtLimit} NFTs`:`${item?.defaultNFtLimit} NFTs / Storefront` }    `}  `} sm={sm}  />
          <PackageOption
            option={`Platform fee: ${item?.platformFee}%`} sm={sm} 
          />
          {item?.royaltyIntegration && (
            <PackageOption option={`Royalty Integration`} sm={sm}  />
          )}

          <PackageOption option={item?.blockChainStandard} sm={sm}  />
          {item?.themeSelectionAndCustomization && (
            <PackageOption
              option={`Theme selection & customize according to brand`} sm={sm} 
            />
          )}

          <PackageOption
            option={`${item?.gassFee === 0 ? "No gas fees" : ""}`}
            sm={sm} 
          />
          {item?.basicReporting && <PackageOption option={`Basic Reporting`} sm={sm}  />}
          {item?.advanceReporting && (
            <PackageOption option={`Advanced Reporting`} sm={sm}  />
          )}
          {item?.centralizedAndArweaveReporting && (
            <PackageOption option={`Centralized / Arweave storage`} sm={sm}  />
          )}
          {item?.whilteLabelDomain && (
            <PackageOption option={`Your own Brand Domain`} sm={sm}  />
          )}
          {item?.transactionHistoryReporting && (
            <PackageOption option={`Transaction History`} sm={sm}  />
          )}
          {item?.brandedEmailTemplates && (
            <PackageOption option={`Branded Email Template`} sm={sm}  />
          )}
          {item?.canAddCreators && (
            <PackageOption option={`Add creators under each storefront`} sm={sm}  />
          )

          }
          <PackageOption option={`Account management`} sm={sm}  />
        </Fragment>
      ))}
    </>
  );
};

export default PackagesCommonListing;
