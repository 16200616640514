import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import AlertModalContent from "./AlertModalContent";
import { ClientTxService } from "../../services/transaction.service";
import CloseIconView from "../ui-components/views-component/CloseIconView";
import { retryMintTransfer } from "../../redux/features/user/userSlice";
import transactionConstant from "../../constants/client-admin-constants/clientTransactionConstants/ClientTransactionConstants";

const MintTransferModal = (props) => {
  const dispatch = useDispatch();
  const {
    openProcessModal,
    setOpenProcessModal,
    data,
    getTransactionDetailReport,
  } = props;
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: "",
    description: "",
    sublabel: "",
  });
  const { clientDetail } = useSelector((state) => state?.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (openProcessModal) {
      handleOpenMintTransferModal();
    }
    return () => { };
  }, []);

  const handleOpenMintTransferModal = () => {
    let payload = {
      orderId: data?.orderId,
      dropUUId: data?.dropUuId,
      clientUUId: clientDetail?.clientUuid,
    };
    ClientTxService.transferMint(payload)
      .then((res) => {
       

        if (res?.data?.statusCode === 200) {
          dispatch(retryMintTransfer(true));
          setAlertMessage({
            title: "Success",
            mainTitle: "Mint Transferred Successful",
            description:
              transactionConstant.mintTransSuccess,
            btnTitle: "Back to Transaction History",
            alertHander: true,
          });
          setOpenAlertModal(true);
        }
      })
      .catch((error) => {
        setAlertMessage({
          title: "Error",
          mainTitle: "Mint Transferred Failed",
          description: error?.response?.data?.message ?? "Something went wrong",
          btnTitle: "Try Again ",
          alertHander: true,
        });
        setOpenAlertModal(true);
      });
  };
  const handleMintResponse = (error) => {
    if (alertMessage.title === "Success") {
      navigate("/tx-reporting");
      setOpenAlertModal(false);
      setOpenProcessModal(false);
      getTransactionDetailReport();
    } else {
      if (error) {
        setOpenAlertModal(false);
        setOpenProcessModal(false);
      } else {
        setOpenAlertModal(false);
        handleOpenMintTransferModal();
      }
    }
  };
  return (
    <>
      {openAlertModal ? (
        <>
          <div>
            <button
              type="button"
              onClick={() => {
                handleMintResponse(true);
              }}
              className="absolute top-6 right-6 text-white bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="authentication-modal"
            >
              <CloseIconView />
            </button>
          </div>
          <AlertModalContent
            message={alertMessage}
            btnHandler={handleMintResponse}
          />
        </>
      ) : (
        <>
          <h4 className="mb-2 text-2xl font-bold text-center text-white">
            Processing
          </h4>
          <p className="font-normal text-xs text-center text-white/70 mb-7">
            {transactionConstant.mintProcessDesc}
          </p>
          <div className="text-center">
            <BeatLoader
              color={'#A5F33C'}
              loading={true}
              size={12}
              speedMultiplier="1"
            />
          </div>
        </>
      )}
    </>
  );
};

export default MintTransferModal;
