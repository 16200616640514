import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { declinedSchema } from "../../utils/ValidationSchemas/DeclinedModalSchema";
import { Tooltip } from "@material-tailwind/react";
import { images } from "../../constants";

const DeclineModalContent = (props) => {
  const { handleApprovedOrDeclined } = props;

  const {
    register,
    handleSubmit,

    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(declinedSchema),
    mode: "all",
    defaultValues: {
      declinedDescription: "",
    },
  });
  const handleDeclined = (values) => {
    handleApprovedOrDeclined(false, values.declinedDescription);
  };


  return (
    <>
      <h3 className="mb-8 text-2xl font-bold text-white">
        Why Declined?
      </h3>
      <form onSubmit={handleSubmit(handleDeclined)}>

        <div className="relative z-0 mb-10">
          <textarea
            className="peer outline-none  pl-3 pr-8 py-2  border rounded-xl border-[#A4B1A7] text-white text-md  placeholder:text-white focus:ring-transparent focus:border-[#A4B1A7] focus:outline-none  block w-full  bg-transparent"
            rows="4"
            placeholder=" "
            name="Notes"
            maxlength="501"
            {...register("declinedDescription")}
          ></textarea>
          <label
            className={`text-xs text-grey-100 z-[-1]  left-0 absolute  -top-6 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3   peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-6   peer-focus:text-grey-100 peer-focus:text-xs peer-focus:left-0`}
          >
            Description
            <Tooltip
              className="text-[white] text-xs bg-[#0A130D] z-[100] text-center relative border border-[#12221A] rounded max-w-[200px]"
              offset={10}
              placement={"top-center"}
              content={"Write the reason for rejecting this client. This will be shared with the client via email. "}
            >
              <img className="inline ml-2 cursor-pointer -m-[2px]" src={images.InputFieldInfo} alt='info-icon' />
            </Tooltip>
          </label>
          <p className="mt-1 text-xs text-red-600">
            {errors?.declinedDescription?.message}
          </p>

        </div>

        {/* <div className="relative mb-10">
          <textarea
            className="peer outline-none  pl-3 pr-8 py-2  border rounded-xl border-[#A4B1A7] text-white text-md  placeholder:text-white focus:ring-transparent focus:border-[#A4B1A7] focus:outline-none  block w-full  bg-transparent"
            rows="5"
            placeholder=" "
            {...register("declinedDescription")}
          >
            <label
              className={`text-xs text-grey-100 z-[-1]  left-0 absolute  -top-6 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3   peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-6   peer-focus:text-grey-100 peer-focus:text-xs peer-focus:left-0`}
            >
              Description
              {/* <Tooltip
                className="text-[white] text-xs bg-[#12221A] z-[100] text-center relative border border-[#12221A] rounded max-w-[200px]"
                offset={10}
                placement={"top-center"}
                content={
                  "Write the reason for rejecting this client. This will be shared with the client via email.  "
                }
              >
                <img
                  className="inline ml-2 cursor-pointer"
                  src={images.InputFieldInfoIcon}
                  alt="info-icon"
                />
              </Tooltip> */}

        <button
          disabled={!isDirty || !isValid}
          type="submit"
          className=" disabled:cursor-not-allowed btn-primary"
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default DeclineModalContent;
