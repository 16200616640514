import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FetchTemplatesFromBC from "../../../../helpers/custom-hooks/FetchTemplatesFromBC";
import { setLoader } from "../../../../redux/features/loading/loaderSlice";

import AssetContent from "./AssetContent";

const AssetList = () => {
  /** Reading data from redux */
  const { blockChainBrandId, clientWalletAddress, clientPackageResponse ,clientUuid } =
    useSelector((state) => state?.user?.clientDetail);
  const dispatch = useDispatch();
  /** Hooks Initialization and assigning */
  const { fetchTemplatesList } = FetchTemplatesFromBC();
  const [assetListData, setAssetListData] = useState([]);
  const navigate = useNavigate();
  const [updateAssetList, setUpdateAssetList] = useState(false);

  useEffect(() => {
    fetchAssetList();
    //eslint-disable-next-line
  }, [updateAssetList]);

  const fetchAssetList = async () => {
    dispatch(setLoader(true));
    let templatesList = await fetchTemplatesList();
    if (templatesList != null) {
      if (typeof templatesList === "object") {
        templatesList = Object.values(templatesList);
        templatesList = templatesList.reverse();
        templatesList = templatesList.map((data, index) => {
          return { ...data.immutableData, ...data };
        });
      
        setAssetListData(templatesList);
        dispatch(setLoader(false));
      } else {
        setAssetListData([]);
        dispatch(setLoader(false));
      }
    }
    dispatch(setLoader(false));
  };

  const createAssetHandler = () => {
    navigate("/create-asset");
  };
  return (
    <AssetContent
      createAssetHandler={createAssetHandler}
      setUpdateAssetList={setUpdateAssetList}
      assetListData={assetListData}
      clientWalletAddress={clientWalletAddress}
      clientPackageResponse={clientPackageResponse}
      clientUuid={clientUuid}
    />
  );
};
export default AssetList;
