import React, { useState } from "react";
import InputField from "../../../../../../components/ui-components/form-component/InputFieldComponent";
import { images } from "../../../../../../constants";
import { getDocumentType } from "../../../../../../helpers/GetDocumentType";

const AttachDocument = (props) => {
  const {
    register,
    watch,
    documentName,
    fileName,
    error,
    fileError,
    handleRemoveImage,
  } = props;
  const File = watch(fileName);

  return (
    <>
      <InputField
        type="text"
        label="Document Name"
        error={error}
        register={register}
        name={documentName}
      />
      <div className="mt-6">
        {/* File Uploader */}
        <label htmlFor="immutableDoc" className="mb-2 cursor-pointer">
          {/* Image Selector */}
          <input
            hidden={true}
            id="immutableDoc"
            // ref={fileInputRef}
            accept={".gif,.jpg,.jpeg,.png,.pdf,.txt"}
            type="file"
            name={fileName}
            {...register(fileName)}
          />

          <div className="flex items-center justify-between  text-white font-poppins border border-[#A4B1A7] p-4 border-grey-100-700 border-solid rounded-xl w-full min-h-[96px] h-full">
            <div className="flex items-center justify-start flex-grow-1">
              <img className="mr-3" src={images.UploadIcon} alt="" />
              <div className="flex flex-col">
                <h4 className="text-base font-bold">Attach Document</h4>
                <p className="mb-1  text-xs"> Browse File</p>
                <p className="text-xs text-grey-100">
                  PDF,TXT,Image| Max file size: 5 MB
                </p>
              </div>
            </div>
            {/* <p className="flex-shrink text-xs font-bold text-grey-100">OR</p> */}
            <p className="flex-shrink text-base font-bold text-center text-[#A5F33C] ">
              Browse
            </p>
          </div>
        </label>
        {/* Image Displayer */}
        <div className="">
          {File && File?.length > 0 && (
            <div className="flex justify-between items-center text-xs mt-4 flex-grow py-3 px-4 rounded-xl bg-transparent border  border-[#A4B1A7] min-w-[80px]">
              <div className="flex items-center">
                <img
                  className="object-contain mr-2 w-7 h-7"
                  src={images.FileIcon}
                  alt="logo"
                />
                <span className="w-full p-0  font-bold bg-transparent border-none focus:ring-transparent">
                  {File[0]?.name ?  File[0]?.name : getDocumentType(File) === "image"
                    ? "Image" 
                    : getDocumentType(File) === "pdf" ? 'Pdf':
                    getDocumentType(File) === "txt" ? 'Txt' : ""}
                </span>
              </div>
              {File[0]?.size && (
                <span className="text-grey-100">
                  {(File[0]?.size / 1024).toFixed(2) + "kb"}
                </span>
              )}
              <img
                className="cursor-pointer hover:opacity-80"
                src={images.cross_icon}
                alt="X"
                onClick={handleRemoveImage}
              />
            </div>
          )}

          <p className="mt-1 text-xs text-red-600">{fileError}</p>
        </div>
      </div>
    </>
  );
};

export default AttachDocument;
