import { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import './quill.snow.css';
import EditorToolbar from "./EditorToolbar";

const EditorQuill = (props) => {

    const modules = {
        toolbar: {
            container: "#"+props.toolbarId,
        }
    };

    const Size = Quill.import("formats/size");
    Size.whitelist = ["small", "medium", "large", "huge"];
    Quill.register(Size, true);

    const formats = [
        "header",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "align",
        "background"
    ];

    return (
        <>
            <EditorToolbar id={props.toolbarId} />
            <ReactQuill id={"editor"+props.toolbarId} theme="snow" value={props.value} onChange={props.onChange} modules={modules}
                formats={formats} />
        </>
    )
}

export default EditorQuill;