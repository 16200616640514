import { getRequest, postRequest } from "../utils/api/axiosMethods";

const generateAssetHash=(hashModel)=>{
    return postRequest(`Asset/generate_asset_hash`,hashModel).then((response) => {
		return response;
	});
}
const createAsset=(formData)=>{
    return postRequest(`Asset/create_asset`,formData).then((response) => {
		return response;
	});
}
const deleteAssetFromDB=(deleteModal)=>{
  return postRequest(`Asset/delete_asset`,deleteModal).then((response) => {
  return response;
});
}
const updateAsset=(updatedModal)=>{
  return postRequest(`Asset/update_asset`,updatedModal).then((response) => {
  return response;
});
}

const assetsCount=(clientUuid)=>{
  return getRequest(`asset/get_asset_count?clientUuid=${clientUuid}`).then((response) => {
  return response;
});
}
const saveUploadedAssetHash=(hashModel)=>{
  return postRequest(`Asset/save_asset_hash`,hashModel).then((response) => {
  return response;
});
}

const AssetService = {
    generateAssetHash,
    createAsset,
    updateAsset,
    deleteAssetFromDB,
    assetsCount,
    saveUploadedAssetHash
  }

  export default AssetService;