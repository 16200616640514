import React, { useEffect, useState } from "react";
import Modal from "../../../../../common/ModalLayout";
import AlertModalContent from "../../../../../components/Modals/AlertModalContent";
import { images } from "../../../../../constants";
import { Tooltip } from "@material-tailwind/react";
import { getDocumentType } from "../../../../../helpers/GetDocumentType";

const AddTextFieldComponent = (props) => {
  const {
    inputFields,
    setInputFields,
    setcheckFieldDuplicate,
    checkFieldkeyDuplicate,
    setFieldIndex,
    fieldIndex,
    mutableFilledData,
    OpenAlertModal,
    setOpenAlertModal,
    setAlertContent,
    mutableSpace,
  } = props;

  useEffect(() => {
    if (mutableFilledData) {
      const filterdata = Object.fromEntries(
        Object.entries(mutableFilledData).filter(([key, value]) => {
          if (
            getDocumentType(value) === "image" ||
            getDocumentType(value) === "pdf" ||
            getDocumentType(value) === "txt"
          ) {
            return false;
          } else {
            return true;
          }
        })
      );
      let mutableDataJson = Object.entries(filterdata).map((data) => {
        return {
          ["fieldKey"]: data[0]?.trim(),
          ["fieldValue"]: data[1]?.trim(),
        };
      });
      setFieldIndex(fieldIndex + (mutableDataJson?.length - 1));
      setInputFields(mutableDataJson);
    }
    //eslint-disable-next-line
  }, []);

  const [openTooltip, setOpenTooltip] = useState({
    open: false,
    title: "",
    desc: "",
  });

  /** Add input Field */
  const addInputField = () => {
    if (
      inputFields[fieldIndex]?.fieldKey?.trim() === "" ||
      inputFields[fieldIndex]?.fieldValue?.trim() === ""
    ) {
      let interval;
      setOpenTooltip({
        open: true,
        title: "Add New Property",
        desc: "First you add property and traits value then add new property",
      });
      setTimeout(() => {
        setOpenTooltip({ open: false, title: "", desc: "" });
        clearInterval(interval);
      }, 3000);
    } else {
      setInputFields([...inputFields, { fieldKey: "", fieldValue: "" }]);
      setFieldIndex(fieldIndex + 1);
    }
  };
  /** Remove Input Field */
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setFieldIndex(fieldIndex - 1);
    setInputFields(rows);
    setcheckFieldDuplicate(false);
  };

  /** Handle Change to get field value */
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;

    if (name === "fieldKey") {
      setcheckFieldDuplicate(false);
    }
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields([...inputFields]);
  };

  const CheckKeyDuplication = (arr, evnt, index) => {
    const { value } = evnt.target;
    if (arr.length !== 1) {
      const rows = [...inputFields];
      rows.splice(index, 1);
      const found = rows.some((el) => el.fieldKey === value);
      if (found) {
        setcheckFieldDuplicate(true);
      }
    }
  };
  return (
    <>
      {/** dynamic field data  */}

      <div className="">
        {inputFields.map((element, index) => {
          return (
            <div className="relative mt-6" key={index}>
              <div className="grid grid-cols-2 gap-6 border border-[#A4B1A7]/[0.12] px-4 py-4 pt-8 rounded-lg form-group bg-[#12221A]/30">
                <div className="relative z-10">
                  <input
                    onBlur={(e) => CheckKeyDuplication(inputFields, e, index)}
                    className="  peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-[#A4B1A7] block w-full p-2.5 bg-transparent"
                    type="text"
                    name="fieldKey"
                    placeholder=" "
                    value={element.fieldKey || ""}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <label
                    className={`text-xs text-grey-100 z-[-1]   absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-2  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
                  >
                    Property
                  </label>
                  {checkFieldkeyDuplicate && index === fieldIndex && (
                    <p className="mt-2 text-xs text-red-600">
                      {OpenAlertModal ? <>''</> : "Field key must be unique"}
                    </p>
                  )}
                </div>
                <div className="relative z-10">
                  <input
                    className="  peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-[#A4B1A7] block w-full p-2.5 bg-transparent"
                    type="text"
                    name="fieldValue"
                    placeholder=" "
                    value={element.fieldValue || ""}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <label
                    className={`text-xs text-grey-100  z-[-1]  absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
                  >
                    Trait
                  </label>
                </div>
              </div>

              <div className="absolute top-[0px] w-[20px] h-[20px] right-[-4px] ">
                {inputFields.length !== 1 ? (
                  <img
                    src={images.RemoveFieldIcon}
                    className=""
                    alt="icon"
                    onClick={() => removeInputFields(index)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
        <div className="relative">
          {openTooltip.open && (
            <div className="text-[white] z-[51] absolute top-6 max-w-[337px] p-3 text-xs bg-[#0A130D]    border border-[#A4B1A7]/[0.12] rounded">
              <span className="flex items-center mb-1">
                <img
                  className="inline mr-1 "
                  src={images.TooltipInfoIcon}
                  alt="icon"
                />
                {openTooltip.title}{" "}
              </span>
              <span className="text-xs text-[#8D9D91]">{openTooltip.desc}</span>
            </div>
          )}
          {/* <Tooltip
          open={openTooltip.open}
          className="text-[white] relative max-w-[337px] p-3 text-xs bg-[#0A130D]  z-50  border border-[#A4B1A7]/[0.12] rounded"
          offset={-15}
          placement={"bottom-start"}
          content={
            <>
           
            </>
          }
        > */}
          <span
            disabled={checkFieldkeyDuplicate}
            className={`${
              checkFieldkeyDuplicate
                ? "disabled:text-opacity-60 disabled:cursor-not-allowed"
                : "cursor-pointer"
            }  flex items-center mt-1 ${
              mutableSpace ? "mb-8" : "mb-20"
            } text-xs  `}
            onClick={() => {
              if (checkFieldkeyDuplicate) {
                return;
              }
              addInputField();
            }}
          >
            <img className="mr-1" src={images.AddFieldIcon} alt="add-icon" />
            Add New Property
          </span>
          {/* </Tooltip> */}
        </div>
      </div>
      {/* {openAlertModal &&
       <Modal 
       modalVisible={openAlertModal}
        closeModal={setOpenAlertModal}
        children={
          <AlertModalContent
            message={{title:"Error",description:"Please add Key Value Pair "}}
          />
        }
        modal_Id="Delete_Modal"
        isClose={true}
      />} */}
    </>
  );
};

export default AddTextFieldComponent;
