import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../../redux/features/loading/loaderSlice";
import { ClientTxService } from "../../../../services/transaction.service";
import ClientHeaderComponent from "../../components/ClientHeaderComponent";
import TransactionListing from "./TransactionListing";
const ClientTransactionReporting = () => {
  const dispatch = useDispatch();
  const { clientDetail } = useSelector((state) => state?.user);
  const [transactionRecords, setTransactionRecords] = useState([]);
  const [SummaryData, setSummaryData] = useState({});

  const [params, setParams] = useState({
    clientId: clientDetail?.id?.toString(),
    startDate: null,
    endDate: null,
    paymentSource: null,
    OrderStatusType: 0,
    search: "",
  });

  useEffect(() => {
    getTransactionDetailReport();
    //eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    getTransactionSummary();
    //eslint-disable-next-line
  }, []);

  const getTransactionSummary = () => {
    ClientTxService.getTransactionSummary(clientDetail.clientUuid)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          setSummaryData(response?.data?.payload?.result);
        }
      })
      .catch((err) => { });
  };

  const getTransactionDetailReport = () => {
    dispatch(setLoader(true));
    ClientTxService.getTransactionDetail(params)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          dispatch(setLoader(false));
          if (response?.data?.payload?.result?.length > 0) {
            setTransactionRecords(response?.data?.payload?.result);
          } else {
            setTransactionRecords([]);
          }
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };

  return (
    <>
      <ClientHeaderComponent title={"Transaction History"} />
      {/** transaction listing table start */}
      <TransactionListing
        SummaryData={SummaryData}
        transactionRecords={transactionRecords}
        params={params}
        setParams={setParams}
        getTransactionDetailReport={getTransactionDetailReport}
      />
      {/**transaction listing table end */}
    </>
  );
};

export default ClientTransactionReporting;
