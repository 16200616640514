import React, { useMemo } from "react";
import ReviewDropAssetDescription from "./ReviewDropComponent/ReviewDropAssetDescription";
import ReviewDropDescription from "./ReviewDropComponent/ReviewDropDescription";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const DropReview = (props) => {
  const navigate = useNavigate();
  const { clientDetail } = useSelector((state) => state.user);
  const {
    dropAssetData,
    dropDetailData,
    bannerImgSrc,
    bannerVideoSrc,
    setActiveStep,
    DropCreationHandler,
    viewEditData,
    videoThumbNailViewOnly,
  } = props;
  // const calculateTotalPrice = (assetPrice ,platformFee ,transactionFee) => {
  //   return assetPrice + platformFee + transactionFee;
  // }
  const calculateTotalPrice = useMemo(() => {
    const value =
      parseInt(dropDetailData?.dropPrice) -
      (parseInt(dropDetailData?.dropPrice) *
        parseInt(clientDetail?.platformFee)) /
        100;
    return value.toFixed(2);
  }, [clientDetail?.platformFee, dropDetailData?.dropPrice]);

 

  // calculate value total minus percentage  in react js
  return (
    <div className="relative h-[calc(100vh-272px)]">
      <div className="h-full overflow-y-auto">
        <h4 className="mb-6 text-xl font-bold">Drop Details</h4>
        <div className=" relative z-20 grid grid-cols-2 ">
          <div className="  max-w-[464px]">
            <div className="flex mb-8">
              <ReviewDropDescription
                title={"Drop Name"}
                value={dropDetailData?.dropName}
              />
              {dropDetailData?.dropPrice && (
                  <ReviewDropDescription
                  title={"Drop Price"}
                  value={`$${dropDetailData?.dropPrice}`}
                />
              )}
            
            </div>
            <div className="flex mb-8">
              <ReviewDropDescription
                title={"Public Sale Start"}
                value={
                  moment(dropDetailData?.PublicSaleStartDate).format(
                    "MMMM D, YYYY HH:mm:ss"
                  ) ?? "-"
                }
              />
              <ReviewDropDescription
                title={"Public Sale End"}
                value={
                  moment(dropDetailData?.PublicSaleEndDate).format(
                    "MMMM D, YYYY HH:mm:ss"
                  ) ?? "-"
                }
              />
            </div>
            <div className="mb-16">
              <p className="text-xs text-grey-100">Description</p>
              <p className="text-base">{dropDetailData?.dropDescription}</p>
            </div>
          </div>
          <div className=" justify-self-end">
            <div className="max-h-[300px] max-w-[500px] ">
              {bannerImgSrc && (
                <>
                  <img
                    src={bannerImgSrc}
                    width="500"
                    height={"300"}
                    alt="icon"
                    className="rounded-2xl"
                  />
                </>
              )}
              {bannerVideoSrc && (
                <>
                  <video src={bannerVideoSrc} controls />
                </>
              )}
              {videoThumbNailViewOnly && (
                <div className="thumb-view max-h-[300px] max-w-[500px]">
                  <label className="d-block">Thumbnail Image:</label>{" "}
                  <img
                    src={videoThumbNailViewOnly}
                    className="img-fluid max-h-[300px] max-w-[500px]"
                    alt="thumbnailimg"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="container grid grid-cols-2 gap-12">
          <div className="pb-6">
            <h4 className="mb-6 text-xl font-bold">Drop Asset</h4>
            {dropAssetData?.map((dropAsset, index) => (
              <ReviewDropAssetDescription
                key={index}
                templateType={dropAsset?.templateType}
                assetIcon={dropAsset?.artSrc}
                assetName={dropAsset?.assetName}
                assetPrice={dropDetailData?.dropPrice}
                platformFee={clientDetail?.platformFee ?? "-"}
                transactionFee={"-"}
                totalFee={calculateTotalPrice}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`flex justify-between items-center absolute bottom-0 bg-[#060C07] w-full  z-50 ${
          viewEditData?.isview ? "hidden" : ""
        }`}
      >
        <div className="flex max-w-[494px] w-full">
          <button
            className="px-8 py-3 mr-10 text-center btn-secondary "
            type="submit"
            onClick={() => {
              setActiveStep(2);
            }}
          >
            Back
          </button>
          <button
            className="px-8 py-3 text-center btn-primary "
            onClick={() => DropCreationHandler()}
            type="submit"
          >
          {viewEditData?.isEdit ? `Save Changes` :  'Submit' }
          </button>
        </div>

        <span
          className=" text-base cursor-pointer   hover:text-[#A5F33C] hover:rounded-full  py-3 font-bold"
          onClick={() => navigate("/drop-list")}
        >
          Cancel
        </span>
      </div>
    </div>
  );
};

export default DropReview;
