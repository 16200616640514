import eye_off_icon from "../assets/images/icons/eye_off.svg";
import eye_on_icon from "../assets/images/icons/eye-on.svg";
import passwordLength from "../assets/images/icons/Password.svg";
import passwordLengthSuccess from "../assets/images/icons/PasswordLengthSuccess.svg";
import passwordNumber from "../assets/images/icons/Password1Number.svg";
import passwordNumberSuccess from "../assets/images/icons/Password1NumberSuccess.svg";
import passwordSpecialChar from "../assets/images/icons/PasswordSpecialChar.svg";
import passwordSpecialCharSuccess from "../assets/images/icons/PasswordSpecialCharSuccess.svg";
import passwordUpperLower from "../assets/images/icons/PasswordUpperLower.svg";
import passwordUpperLowerSuccess from "../assets/images/icons/PasswordUpperLowerSuccess.svg";
import instagram_icon from "../assets/images/icons/Instagram.png";
import linkedin_icon from "../assets/images/icons/LinkedIn.png";
import discord_icon from "../assets/images/icons/Discord.png";
import twitter_icon from "../assets/images/icons/Twitter.png";
import youtube_icon from "../assets/images/icons/Youtube.png";
import tiktok_icon from "../assets/images/icons/Tiktok.png";
import blocto from "../assets/images/icons/Blocto.png";
import cross_icon_blue_border from "../assets/images/icons/cross_icon.svg";
import cross_icon from "../assets/images/icons/cross_large.svg";
import upload_icon from "../assets/images/icons/Upload_icon.png";
import image_icon from "../assets/images/icons/image_icon.png";
import history_clock_icon from "../assets/images/icons/history_clock_icon.png";
import avatar from "../assets/images/avatar.png";
import RightArrowCalender from "../assets/images/icons/rightArrowCalender.svg";
import LeftArrowCalender from "../assets/images/icons/leftArrowCalender.svg";
import Processing from '../assets/images/icons/Processing.svg'
import Active from '../assets/images/icons/Active.svg'
import bloctoImg from "../assets/images/icons/blocto-icon.svg";

// Sidebar Image
import DashboardIcon from "../assets/images/icons/dashboard.svg";
import MintingIcon from "../assets/images/icons/minting-icon.svg";
import AgentIcon from "../assets/images/icons/agent-icon.svg";
import PriceConfigurationIcon from "../assets/images/icons/pricing-configuration-icon.svg";
import CategoryIcon from "../assets/images/icons/category-icon.svg";
import ReportingIcon from "../assets/images/icons/reporting-icon.svg";
import SettingIcon from "../assets/images/icons/setting-icon.svg";
import LogoutIcon from "../assets/images/icons/logout-icon.svg";
import ExpandIcon from "../assets/images/icons/chevron-down-icon.svg";
import ClientSettingIcon from "../assets/images/icons/client-management.svg";
import AssetIcon from "../assets/images/icons/asset-icon.svg";
import WhitelistIcon from "../assets/images/icons/whitelist-icon.svg";
import WhitelistInactive from "../assets/images/icons/whitelist-inactive.svg";
import UploadIcon from "../assets/images/icons/Upload_icon.png";
import DropIcon from "../assets/images/icons/drop-icon.svg";
import ExpandCloseIcon from "../assets/images/icons/chevron-up-icon.svg";
import ChangePasswordIcon from "../assets/images/icons/password-change.svg";
import EditProfileIcon from "../assets/images/icons/edit-profile.svg";

import DeclinedClientIcon from "../assets/images/icons/declined-icon.svg";
import ApprovedIcon from "../assets/images/icons/ApprovedIcon.svg";
import approve_icon from "../assets/images/icons/tick_icon.svg";
import decline_icon from "../assets/images/icons/cross_icon.svg";

import storefront_demo1 from "../assets/images/Storefront_theme1@2x.png";
import storefront_demo1_1024w from "../assets/images/Storefront_theme1@1024w.png";
import storefront_demo2_1024w from "../assets/images/Storefront_theme2@1024w.png";
import crossImg from "../assets/images/cross-bg.svg";
import audioPoster from "../assets/images/audio_background.png";
import ArweaveAssetImage from "../assets/images/icons/arweave-asset-image.svg";

import searchIcon from "../assets/images/icons/search.svg";
import AudioIcon from "../assets/images/icons/audio-icon.svg";
import ActiveCheckIcon from "../assets/images/icons/active-check-icon.svg";
import nftDefaultIcon from "../assets/images/icons/nft-default-icon.svg";
import CategoryAvatar from "../assets/images/icons/category-avator.svg";
import ArweaveReportingIcon from "../assets/images/icons/arweave-reporting-icon.svg";
import TransactionReportingIcon from "../assets/images/icons/transaction-history-icon.svg";
import TransactionCompletedIcon from "../assets/images/icons/completed-icon.svg";
import TransactionPendingIcon from "../assets/images/icons/pending-icon.svg";
import TransactionFailedIcon from "../assets/images/icons/failed-icon.svg";
import StripeIcon from "../assets/images/icons/stripe-icon.svg";
import BloctoIcon from "../assets/images/icons/blocto-icon.svg";
import ReceiptIcon from "../assets/images/icons/receipt-icon.svg";
import Location from '../assets/images/icons/location.svg'
import Tags from '../assets/images/icons/tags.svg'

import more_icon from "../assets/images/icons/ellipsis_icon.svg";
import UploadAssetIcon from "../assets/images/icons/upload-asset-icon.svg";
import DeleteIcon from "../assets/images/icons/delete-icon.svg";
import MonitorIcon from "../assets/images/icons/Monitor.svg";

import AddFieldIcon from "../assets/images/icons/add-field-icon.svg";
import RemoveFieldIcon from "../assets/images/icons/remove-field-icon.svg";
import CalanderIcon from "../assets/images/icons/calendar-icon.svg";

import ThemeImage from "../assets/images/icons/Storefront-theme-first.png";

import TabelSearchIcon from "../assets/images/icons/table-search-icon.svg";
import Sidebarbg from "../assets/images/icons/sidebar-bg.svg";

import AlertSuccessIcon from "../assets/images/icons/alert-success-icon.svg";
import AlertErrorIcon from "../assets/images/icons/alert-error-icon.svg";

import SocialMediaYouTubeIcon from "../assets/images/icons/social-media-youtube-icon.svg";
import SocialMediaTiktokIcon from "../assets/images/icons/social-media-tiktok-icon.svg";

import AscendingIcon from "../assets/images/icons/ascending-icon.svg";
import DecendingIcon from "../assets/images/icons/descending-icon.svg";

import OnBoardingAvator from "../assets/images/icons/onboarding-avator.svg";
import arweaveViewIcon from "../assets/images/icons/ImageViewerIcon.svg";
import RedFail from "../assets/images/icons/redUnsuccess.svg";
import GreenSuccess from "../assets/images/icons/greenSuccess.svg";
import MintFailed from "../assets/images/icons/mint-fail-icon.svg";

import imagePlaceHolder from "../assets/images/icons/image-view-placeholder-icon.svg";
import videoPlaceHolder from "../assets/images/icons/video-view-placeholder-icon.svg";
import audioPlaceHolder from "../assets/images/icons/audio-view-placeholder-icon.svg";

import ActionEyeIcon from "../assets/images/icons/action-eye-icon.svg";
import BackIcon from "../assets/images/icons/back-icon.svg";
import DefaultImagePlaceholder from "../assets/images/icons/No_image_faded-placeholder.png";

import InputFieldInfoIcon from "../assets/images/icons/input-info-icon.svg";
import DefaultPreviewHeroIcon from "../assets/images/icons/defaultHeroPreviewImage.svg";
import PreviewUpcomingDropIcon from "../assets/images/icons/preview-upcoming-drop-image.png";
import FlowFcIcon from "../assets/images/icons/ic_flow.svg";
import PreviewDropIcon from "../assets/images/icons/preview-drop-icon.svg";
import RadioCheckedIcon from "../assets/images/icons/radio-checked-icon.svg";
import LinkIcon from "../assets/images/icons/link-icon.svg";
import CopiedIconSvg from "../assets/images/icons/copiedUrl.svg";

import VisaCardIcon from "../assets/images/icons/visa-card-icon.svg";
import MasterCardIcon from "../assets/images/icons/mastercard-icon.svg";
import PercentageIcon from "../assets/images/icons/PercentageIcon.svg";
import FlowIconSm from "../assets/images/icons/flow-icon-sm.svg";
import MasterCardSm from "../assets/images/icons/mastercard-sm.svg";

import MenuIconPackages from "../assets/images/icons/packages-menu-icon.svg";
import PackageCheckIcon from "../assets/images/icons/package-check-icon.svg";

// updated Design Images
import BloomlyLogo from "../assets/images/icons/Bloomly_White_ Logo.svg";
import login_image from "../assets/images/login_images_group.png";
import whitelistTableTempImage from "../assets/images/login_image.svg";
import MoreButtonIconView from "../assets/images/icons/more_button-svg.svg";
import BloctoIconBlue from "../assets/images/icons/blocto-icon-blue.svg";

import DashboardInactive from "../assets/images/icons/dashboard-Inactive.svg";
import MintingInactive from "../assets/images/icons/minting-Inactive.svg";
import AssetInactive from "../assets/images/icons/asset-Inactive.svg";
import DropInactive from "../assets/images/icons/drop-Inactive.svg";
import ThemeInactive from "../assets/images/icons/theme-Inactive.svg";
import ReportingInactive from "../assets/images/icons/reporting-Inactive.svg";
import TransactionInactive from "../assets/images/icons/transaction-history-icon-Inactive.svg";
import SettingInactive from "../assets/images/icons/settings-Inactive.svg";
import EditProfileInactive from "../assets/images/icons/edit-profile-Inactive.svg";
import ChangePasswordInactive from "../assets/images/icons/change-password-Inactive.svg";
import PlusIcon from "../assets/images/icons/plus.svg";
import MinusIcon from "../assets/images/icons/Minus.svg";
import CustomDomain from '../assets/images/icons/Rectangle-path.svg'
import DroplegendIcon from "../assets/images/icons/drop-legend-icon.svg";
import CollectionLegendIcon from "../assets/images/icons/collection-legend-icon.svg";
import WalletIcon from "../assets/images/icons/wallet-icon.svg";
import CopyIcon from "../assets/images/icons/copy-icon.svg";
import ChevronLeftActive from "../assets/images/icons/Chevron-Left-active.svg";
import ChevronLeftDisable from "../assets/images/icons/Chevron-Left-disable.svg";
import ChevronRightActive from "../assets/images/icons/Chevron-right-active.svg";
import ChevronRightDisable from "../assets/images/icons/Chevron-Right-disable.svg";

import CategoryInactive from "../assets/images/icons/category-inactive-icon.svg";
import ClientDashboardInactive from "../assets/images/icons/client-management-inactive.svg";
import ArweaveReportingInactive from "../assets/images/icons/arweave-reporting-inactive.svg";
import PackageMenuInactive from "../assets/images/icons/packages-menu-inactive.svg";
import PaginationDot from "../assets/images/icons/pagination-dot.svg";

import ConfigurationMenuIcon from "../assets/images/icons/configuration-menu-icon.svg";
import ConfigurationMenuInactive from "../assets/images/icons/configuration-menu-icon-inactive.svg";
import ContributorMenuIcon from "../assets/images/icons/contributor-menu-icon.svg";
import ContributorMenuInactive from "../assets/images/icons/configuration-menu-icon-inactive.svg";
import RoyalityMenuIcon from "../assets/images/icons/royality-menu-icon.svg";
import RoyalityMenuInactive from "../assets/images/icons/royality-menu-inactive.svg";

import ThemeMemberShip from "../assets/images/theme-membership.png";
import ThemeDrop from "../assets/images/theme-drop.png";
import ThemeDark from "../assets/images/theme-green.png";

import BloomlyLogoLg from "../assets/images/icons/Bloomly_White 2.svg";
import FlowChainIcon from "../assets/images/icons/flowchain-icon.svg";

import BiDirectionalIcon from "../assets/images/icons/bi-direction-icon.svg";

import ThemebodyPage from "../assets/images/themebody.png";
import ThemeFooterImage from "../assets/images/themeFooterImage.png";
import ThemePrimaryColorImage from "../assets/images/themehoverButtonImage.png";
import ThemeSecondaryColorImage from "../assets/images/themeSecondaryColorImage.png";
import ThemeTertiaryBgImage from "../assets/images/themeTertiaryBgImage.png";
import ThemeParagraphColorImage from "../assets/images/themeParagraphColor.png";

import InfoIcon from "../assets/images/icons/theme-info-icon.svg";
import ColorDropIcon from "../assets/images/icons/color-drop-icon.svg";
import RightArrow from "../assets/images/icons/right-arrow.svg";
import MusicIcon from "../assets/images/music_icon.png";

import PendingIcon from "../assets/images/icons/clock-icon.svg";
import ApprovedSmIcon from "../assets/images/icons/approve-sm.svg";
import FileIcon from "../assets/images/icons/FileIcon.svg";
import DatePicker from "../assets/images/icons/datePickerr.svg";
import dateIcon from "../assets/images/icons/dateIcon.svg";
import TheRightArrow from "../assets/images/icons/TheRightArrow.svg";

// sidebar theme icon

import DefaultImageIcon from "../assets/images/default-image-icon.svg";

// sidebar theme icon

import BrandActiveIcon from "../assets/images/icons/brand-active-icon.svg";
import BrandInactiveIcon from "../assets/images/icons/brand-inactive-icon.svg";
import PreferenceActiveIcon from "../assets/images/icons/preference-active-icon.svg";
import PreferenceInactiveIcon from "../assets/images/icons/preference-inactive-icon.svg";

import NavigateIcon from "../assets/images/icons/navigate-icon.svg";
import BackLgIcon from "../assets/images/icons/back-lg-icon.svg";
import ConfigurationInactive from '../assets/images/icons/ConfigurationInactive.svg'

import ThemeHomeImage from "../assets/images/theme-home-image.png";
import ThemePrivacyImage from "../assets/images/theme-privacy-image.png";
import ThemeAboutImage from "../assets/images/theme-about-image.png";
import ThemeTermImage from "../assets/images/theme-terms-image.png";
import ThemeMaintenanceImage from "../assets/images/theme-maintenance-image.png";
import ThemeSocialSharingImage from "../assets/images/theme-social-sharing-image.png";
import ThemeSocialLinkImage from "../assets/images/theme-social-link-image.png";
import ThemeEmailSubsImage from "../assets/images/theme-emal-subs-page.png";
import ThemeAdditionDropImage from "../assets/images/theme-additon-drop-image.png";
import AlertIconInfo from "../assets/images/icons/alert-info-icon.svg";
import CalenderLgIcon from "../assets/images/icons/calender-large-icon.svg";
import QuestionIcon from "../assets/images/icons/question-icon.svg";
import InfoIconSm from "../assets/images/icons/info-icon-sm.svg";
import NotConnectIcon from "../assets/images/icons/not-connected-icon.svg";
import IPAdress from '../assets/images/icons/IPAddress.svg'

import SubmenuIcon from '../assets/images/icons/icon-select-menu.svg';
import ClientAvator from '../assets/images/icons/client-avator.svg';
import RecordNotFoundIcon from '../assets/images/icons/record-not-found-icon.svg';

import TableNotFoundIcon from '../assets/images/icons/table-default-not-found-icon.svg';
import QuestionIconSm from '../assets/images/icons/question-icon-sm.svg';
import DropNotfoundIcon from '../assets/images/icons/drop_table_not_found_icon.svg'
import TooltipInfoIcon from '../assets/images/icons/tooltip-info-icon.svg'
import DropdownIndicatorIcon from '../assets/images/icons/dropdown-icon.svg'

import InputFieldInfo from '../assets/images/icons/input_field_info_icon.svg'
import AirDropSeachIcon from '../assets/images/icons/airdropSearchIcon.svg'

import TransferableIcon from '../assets/images/icons/transferable-icon.svg';
import NonTransferableIcon from '../assets/images/icons/nontransferable-icon.svg'
import HoverTransferIcon from '../assets/images/icons/hovertransfericon.svg'
import HoverNontransferIcon from '../assets/images/icons/hovernontransfer.svg'
import Configuration from '../assets/images/icons/Configuration.svg'
import PrimaryIcon from '../assets/images/icons/PrimaryIcon.svg'

export const images = {
  PrimaryIcon,
  InputFieldInfo,
  Configuration,
  ConfigurationInactive,
  PackageCheckIcon,
  MenuIconPackages,
  PercentageIcon,
  VisaCardIcon,
  Processing,
  Active,
  LinkIcon,
  IPAdress,
  Location,
  Tags,
  RightArrowCalender,
  LeftArrowCalender,
  RadioCheckedIcon,
  MusicIcon,
  PreviewDropIcon,
  PreviewUpcomingDropIcon,
  FlowFcIcon,
  DefaultPreviewHeroIcon,
  InputFieldInfoIcon,
  DefaultImagePlaceholder,
  BackIcon,
  ActionEyeIcon,
  OnBoardingAvator,
  AscendingIcon,
  DecendingIcon,
  SocialMediaYouTubeIcon,
  SocialMediaTiktokIcon,
  AlertSuccessIcon,
  AlertErrorIcon,
  Sidebarbg,
  TabelSearchIcon,
  ReceiptIcon,
  BloctoIcon,
  TheRightArrow,
  StripeIcon,
  TransactionCompletedIcon,
  TransactionFailedIcon,
  TransactionPendingIcon,
  ArweaveAssetImage,
  CustomDomain,
  ArweaveReportingIcon,
  TransactionReportingIcon,
  CategoryAvatar,
  nftDefaultIcon,
  CopiedIconSvg,
  ActiveCheckIcon,
  AudioIcon,
  eye_off_icon,
  eye_on_icon,
  passwordLength,
  passwordLengthSuccess,
  passwordNumber,
  passwordNumberSuccess,
  passwordSpecialChar,
  passwordSpecialCharSuccess,
  passwordUpperLower,
  passwordUpperLowerSuccess,
  DashboardIcon,
  MintingIcon,
  AgentIcon,
  PriceConfigurationIcon,
  CategoryIcon,
  ReportingIcon,
  SettingIcon,
  LogoutIcon,
  ExpandIcon,
  ClientSettingIcon,
  AssetIcon,
  WhitelistIcon,
  WhitelistInactive,
  DropIcon,
  ExpandCloseIcon,
  DeclinedClientIcon,
  approve_icon,
  decline_icon,
  ApprovedIcon,
  DeleteIcon,
  searchIcon,
  RedFail,
  GreenSuccess,
  // DashboardIcon,
  // MintingIcon,
  // AgentIcon,
  // PriceConfigurationIcon,
  // CategoryIcon,
  // ReportingIcon,
  // SettingIcon,
  // LogoutIcon,
  // ExpandIcon,
  // ClientSettingIcon,
  // AssetIcon,
  // DropIcon,
  // ExpandCloseIcon,
  UploadIcon,
  EditProfileIcon,
  ChangePasswordIcon,
  instagram_icon,
  linkedin_icon,
  discord_icon,
  twitter_icon,
  youtube_icon,
  tiktok_icon,
  blocto,
  cross_icon_blue_border,
  cross_icon,
  upload_icon,
  image_icon,
  history_clock_icon,
  storefront_demo1,
  storefront_demo1_1024w,
  storefront_demo2_1024w,
  avatar,
  crossImg,
  audioPoster,
  UploadAssetIcon,
  more_icon,
  MonitorIcon,
  AddFieldIcon,
  RemoveFieldIcon,
  CalanderIcon,
  ThemeImage,
  arweaveViewIcon,
  imagePlaceHolder,
  videoPlaceHolder,
  audioPlaceHolder,
  BloomlyLogo,
  login_image,
  MoreButtonIconView,
  BloctoIconBlue,
  DashboardInactive,
  MintingInactive,
  AssetInactive,
  DropInactive,
  ThemeInactive,
  ReportingInactive,
  TransactionInactive,
  SettingInactive,
  EditProfileInactive,
  ChangePasswordInactive,
  PlusIcon,
  MinusIcon,
  DroplegendIcon,
  CollectionLegendIcon,
  WalletIcon,
  CopyIcon,
  ChevronLeftActive,
  ChevronLeftDisable,
  ChevronRightActive,
  ChevronRightDisable,
  CategoryInactive,
  ClientDashboardInactive,
  ArweaveReportingInactive,
  PackageMenuInactive,
  PaginationDot,
  ConfigurationMenuIcon,
  ConfigurationMenuInactive,
  ContributorMenuIcon,
  ContributorMenuInactive,
  RoyalityMenuIcon,
  RoyalityMenuInactive,
  ThemeMemberShip,
  ThemeDrop,
  ThemeDark,
  BloomlyLogoLg,
  FlowChainIcon,
  BiDirectionalIcon,
  ThemebodyPage,
  ThemeFooterImage,
  ThemePrimaryColorImage,
  ThemeSecondaryColorImage,
  ThemeTertiaryBgImage,
  ThemeParagraphColorImage,
  InfoIcon,
  ColorDropIcon,
  PendingIcon,
  ApprovedSmIcon,
  FileIcon,
  whitelistTableTempImage,
  DefaultImageIcon,
  BrandActiveIcon,
  BrandInactiveIcon,
  PreferenceActiveIcon,
  PreferenceInactiveIcon,
  NavigateIcon,
  BackLgIcon,
  ThemeHomeImage,
  ThemePrivacyImage,
  ThemeAboutImage,
  ThemeTermImage,
  ThemeMaintenanceImage,
  ThemeSocialSharingImage,
  ThemeSocialLinkImage,
  ThemeEmailSubsImage,
  ThemeAdditionDropImage,
  AlertIconInfo,
  ColorDropIcon,
  PendingIcon,
  ApprovedSmIcon,
  FileIcon,
  whitelistTableTempImage,
  DatePicker,
  dateIcon,
  CalenderLgIcon,
  QuestionIcon,
  MasterCardIcon,
  FlowIconSm,
  MasterCardSm,
  InfoIconSm,
  NotConnectIcon,
  SubmenuIcon,
  ClientAvator,
  RecordNotFoundIcon,
  MintFailed,
  TableNotFoundIcon,
  QuestionIconSm,
  DropNotfoundIcon,
  TooltipInfoIcon,
  DropdownIndicatorIcon,
  AirDropSeachIcon,
  TransferableIcon,
  NonTransferableIcon,
  HoverNontransferIcon,
  HoverTransferIcon,
};

// push object in array in javacript
