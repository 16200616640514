import React, { useState } from "react";
import { images } from "../../constants";
import CopyIconView from "../ui-components/views-component/CopyIconView";
import { useDispatch, useSelector } from "react-redux";
import WalletBalanceHook from "../../helpers/custom-hooks/FetchWalletBalance";
import { clientFlowBalance } from "../../redux/features/user/userSlice";
import ArweaveBalanceHook from "../../helpers/custom-hooks/FetchArweaveBalance";
import { useEffect } from "react";
import useCopyLink from "../../helpers/custom-hooks/useCopyLink";
import "react-datepicker/dist/react-datepicker.css";
import UseDatePicker from "../../helpers/custom-hooks/UseDatePicker";
import ColumnChart from "./ColumnChart";
import moment from "moment";
import { ClientTxService } from "../../services/transaction.service";
import { startOfMonth, addMonths, endOfMonth } from "date-fns";
import { setLoader } from "../../redux/features/loading/loaderSlice";
import CalenderPopup from "./CalenderPopup";
import FormatNumber from "../../helpers/FormatNumber";
const WalletDetails = () => {
  const dispatch = useDispatch();

  const { copyText, copyAddress, tooltipVisible } =
    useCopyLink();

  const {
    staticRanges,
    handleCancel,
    barDateState,
    handleChangeBar,
    range,
    formatDateToMonthYear,
    monthsGap,
    setMonthsGap,
    openPopover,
    setopenPopover
  } = UseDatePicker();

  const { getFlowUSDBalance } = ArweaveBalanceHook();
  const clientDetail = useSelector((state) => state?.user?.clientDetail);
  const [loading, setLoading] = useState(false);

  const clientWalletAddress = useSelector(
    (state) => state?.user?.clientDetail?.clientWalletAddress
  );

  // show it in the Popup
  const formatDate = (date) =>
    date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });

  const formattedStartDate = barDateState[0]?.startDate ? formatDate(new Date(barDateState[0]?.startDate)) : null
  const formattedEndDate = formatDate(new Date(barDateState[0]?.endDate));
  const barDateCalenderPopups = formattedStartDate ? `${formattedStartDate} - ${formattedEndDate ? formattedEndDate : ''}` : '';
  //ends here 

  const [dropPerformanceData, setdropPerformanceData] = useState([]);

  const [dropPerformanceState, setdropPerformanceState] = useState({
    clientId: clientDetail?.id?.toString(),
    fromDate: formatDateToMonthYear(startOfMonth(addMonths(new Date(), -2))),
    todate: formatDateToMonthYear(endOfMonth(new Date())),
  });
  const handleBarUpdate = () => {
    setopenPopover(false);
    // const monthgap = differenceInMonths(
    //   dropDateRange[0]?.endDate,
    //   dropDateRange[0]?.startDate
    // );
    // if (monthgap > 2) {
    //   setMonthsGap(monthgap);
    //   return;
    // }
    if (formatDateToMonthYear(barDateState[0]?.startDate)) {
      setdropPerformanceState({
        ...dropPerformanceState,
        fromDate: formatDateToMonthYear(barDateState[0]?.startDate),
        todate: formatDateToMonthYear(barDateState[0]?.endDate),
      });
    }
  };

  const getDropPerformanceFun = async () => {
    dispatch(setLoader(true));
    await ClientTxService.getDropPerformance(dropPerformanceState)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          dispatch(setLoader(false));
          setdropPerformanceData(response?.data?.payload);
          setLoading(false);
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
  useEffect(() => {
    getDropPerformanceFun();
  }, [dropPerformanceState]);

  const [clientWalletBalance, setClientWalletBalance] = useState("");
  const [clientFlowPriceInUsd, setClientFlowPriceInUsd] = useState("");

  useEffect(() => {
    if (clientWalletAddress) {
      getWalletBalance();
    }
  }, []);

  const getWalletBalance = async () => {
    const { getWalletBalance } = WalletBalanceHook();
    const balance = await getWalletBalance(clientWalletAddress);
    fetchFlowBalance(balance);
    setClientWalletBalance(balance);
    dispatch(clientFlowBalance(balance));
  };

  const fetchFlowBalance = async (clientWalletBalance) => {
    let flowUsd = await getFlowUSDBalance();
    if (flowUsd) {
      flowUsd = flowUsd * parseFloat(clientWalletBalance);
      setClientFlowPriceInUsd(flowUsd);
    } else {
      setClientFlowPriceInUsd("");
    }
  };

  const barDateFrontShow = barDateState[0]?.startDate ? (moment(barDateState[0]?.endDate).diff(moment(barDateState[0]?.startDate), "months") === 0
    || moment(barDateState[0]?.endDate).add(1, "days").diff(moment(barDateState[0]?.startDate), "months") === 1)
    ? "1 Month"
    : moment(barDateState[0]?.endDate)
      .add(1, "days")
      .diff(moment(barDateState[0]?.startDate), "months") + " Months" : 'No Date Selected'

  return (
    <>
      <div className=" relative border border-[#A4B1A7]/[0.12] rounded-xl bg-[#12221A]/30 gap-4">
        <div className="p-4">
          <h6 className="font-bold text-base leading-6">Wallet Balance</h6>


          <div className="mb-4 flex items-center">
            <div >
              <p className="text-xs text-[#8D9D91] pt-4">FLOW</p>
              <span className=" text-xl font-bold text-[#A5F33C] ">
                {clientWalletBalance ? `${FormatNumber(clientWalletBalance)}` : ""}
                <img className=" mr-3 ml-3 inline align-baseline" src={images.BiDirectionalIcon} alt="icon" />
              </span>

            </div>

            <div >
              <p className="text-xs text-[#8D9D91] pt-4">USD</p>
              <span className="text-xl font-bold">
                {clientFlowPriceInUsd
                  ? `$${FormatNumber(clientFlowPriceInUsd)}`
                  : ""}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 px-3  rounded-full w-full bg-[#12221A]">
            <span
              ref={copyAddress}
              value={clientWalletAddress}

            >
              <img src={images.WalletIcon} className="inline w-4 mr-2" alt="" />
              <p className="font-normal text-xs inline">
                Address:
                <span className="font-bold  pl-1 align-baseline">
                  {clientWalletAddress}
                </span>
              </p>
            </span>
            <span
              className="inline relative cursor-pointer text-white hover:text-[#A5F33C] pl-3"
              onClick={copyText}
            >
              {" "}
              <CopyIconView />
            </span>
            {tooltipVisible && (
              <p className=" custom-tooltip-style bg-[#0A130D] px-4 py-2  absolute right-7 top-[77px]   bottom-full h-9">
                Copied to clipboard
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="p-4 border border-[#A4B1A7]/[0.12] rounded-xl  col-span-3 relative">
          <div className="flex justify-between items-center mb-2">
            <p className="text-base font-bold">Drop Performance</p>
            <CalenderPopup
              donughtMonth={barDateFrontShow}
              dateCalenderPopups={barDateCalenderPopups}
              handleChange={handleChangeBar}
              handleUpdate={handleBarUpdate}
              staticRanges={staticRanges}
              handleCancel={handleCancel}
              range={barDateState}
              monthsGap={monthsGap}
              setMonthsGap={setMonthsGap}
              openPopover={openPopover}
              setopenPopover={setopenPopover}
            />
          </div>
          <ColumnChart dropPerformanceData={dropPerformanceData} />
        </div>
      </div>
    </>
  );
};

export default WalletDetails;
