import React from "react";

const AssetTransferIcon = () => {
  
  return (
    <>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 2L11.1667 3.66667L9.5 5.33333" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.83301 6.16602V5.33268C2.83301 4.89065 3.02811 4.46673 3.3754 4.15417C3.72269 3.84161 4.19372 3.66602 4.68486 3.66602H11.1663" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.49967 11.9993L2.83301 10.3327L4.49967 8.66602" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.1663 7.83398V8.66732C11.1663 9.10935 10.9712 9.53327 10.6239 9.84583C10.2767 10.1584 9.80563 10.334 9.31449 10.334H2.83301" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


    </>
  );
};

export default AssetTransferIcon;
