import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../ui-components/loader-components/Spinner";
import EditorQuill from "../EditorQuill";
import { useSelector } from "react-redux";
import AlertModalContent from "../../Modals/AlertModalContent";
import Modal from "../../../common/ModalLayout";
import ClientHeaderComponent from "../../../pages/ClientPortal/components/ClientHeaderComponent";
import SwitchComponent from "../../ui-components/switch-component/SwitchComponent";
import useClientThemeHook from "../useClientThemeHook";
import themePreferencesConstants from "../../../constants/client-admin-constants/clientPreferenceConstants/ClientPreferenceConstant";

const AboutUs = () => {
  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState(false);
  const {
    updateDataFunc,
    loading,
    OpenAlertModal,
    setOpenAlertModal,
    alertContent,
    aboutData,
    setAboutData,
    clientThemeDetail,
    setLoading,
    getClientThemeDetails,
    resetLoading,
    setResetLoading,
    aboutRef,
  } = useClientThemeHook();
  const user = useSelector((state) => state.user);

  const handleChangeAboutUs = (value) => {
    aboutRef.current = value;
    if (aboutRef.current) {
      setButtonClicked(false);
    }
  };

  const handleChangeEnableAboutUs = (event) => {
    setAboutData({ ...aboutData, enableAboutUs: event.target.checked });
  };

  useEffect(() => {
    getClientThemeDetails();
  }, []);

  const submitForm = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("clientThemeSteps", 2);
    formData.append(
      "ClientThemeUuid",
      clientThemeDetail?.clientThemeUuid
        ? clientThemeDetail?.clientThemeUuid
        : ""
    );
    formData.append("ClientId", user?.clientDetail?.clientUuid);
    formData.append("BrandName", user?.clientDetail?.clientName);
    formData.append("AboutUsHtmlString", aboutRef.current.toString());
    formData.append("ShowAboutUsPage", aboutData?.enableAboutUs);
    setAboutData({ ...aboutData, aboutUsString: aboutRef.current.toString() });
    updateDataFunc(formData);
  };

  const handleReset = () => {
    setResetLoading(true);
    let formData = new FormData();
    formData.append("clientThemeSteps", 2);
    formData.append(
      "ClientThemeUuid",
      clientThemeDetail?.clientThemeUuid
        ? clientThemeDetail?.clientThemeUuid
        : ""
    );
    formData.append("ClientId", user?.clientDetail?.clientUuid);
    formData.append("BrandName", user?.clientDetail?.clientName);
    formData.append("AboutUsHtmlString", "<p><br></p>");
    formData.append("ShowAboutUsPage", true);
    setAboutData({
      ...aboutData,
      aboutUsString: "<p><br></p>",
      enableAboutUs: true,
    });
    aboutRef.current="<p><br></p>";
    updateDataFunc(formData);
  };
  return (
    <>
      <ClientHeaderComponent
        title={"Back to Preferences"}
        backLink={"/preferences"}
      />
      <div className="flex justify-between items-center mb-1">
        <h1 className="text-xl font-bold ">About us</h1>
        <SwitchComponent
          onChange={handleChangeEnableAboutUs}
          value={aboutData?.enableAboutUs}
          id={"enableAboutUs"}
          ref={aboutRef}
        />
      </div>
      <p className="text-[#8D9D91] text-xs  mb-6">
        {themePreferencesConstants.PREFERENCES.ABOUT_DESC}
      </p>

      <div className="mt-6">
        <EditorQuill
          name="editor1"
          toolbarId={"t1"}
          value={aboutData?.aboutUsString}
          onChange={handleChangeAboutUs}
        />

        {aboutData?.aboutUsString === "" && buttonClicked === true && (
          <p className="mt-1 text-xs text-red-600">
            Please write something to save.
          </p>
        )}

        <div>
          <button
            onClick={handleReset}
            className={`btn-secondary mt-10 mr-3  max-w-[240px] disabled:cursor-not-allowed  px-12`}
            disabled={resetLoading}
          >
            {resetLoading && <Spinner />}
            Clear
          </button>

          <button
            onClick={submitForm}
            type="submit"
            className={`btn-primary mt-10   max-w-[240px] disabled:cursor-not-allowed  px-12`}
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            disabled={loading}
          >
            {loading && <Spinner />}
            Save Changes
          </button>
        </div>
      </div>

      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Add_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default AboutUs;
