import { config } from "@onflow/fcl";
console.log(process.env.REACT_APP_WALLET_HAND_SHAKE)
config()
  .put(
    "accessNode.api",
    process.env.REACT_APP_ACCESS_NODE
  )
  .put("discovery.wallet", process.env.REACT_APP_WALLET_HAND_SHAKE)
  //.put("discovery.wallet", process.env.REACT_APP_MULTI_WALLET_DISCOVERY) // Configure FCL's Wallet Discovery mechanism
  // .put("discovery.wallet.method", "HTTP/POST")
  .put("app.detail.icon", 'https://bloomly.xyz/wp-content/themes/bloomly/favicon.ico')
  .put("app.detail.title", process.env.REACT_APP_TITLE);
