import React from "react";
import { images } from "../../../../../../constants";
import { Tooltip } from "@material-tailwind/react";

const ReviewDropAssetDescription = (props) => {
  const {
    assetIcon,
    assetName,
    assetPrice,
    platformFee,
    transactionFee,
    totalFee,
    templateType,
  } = props;
  return (
    <>
      <div className="background-border-rad  rounded-3xl p-[1px] mb-10  max-w-[494px]">
        <div className="grid grid-cols-2 gap-3 p-4 bg-[#060C07] rounded-3xl">
          <div>
            {templateType === "Audio" && (
              <video
                id="audio"
                controls
                src={assetIcon}
                poster={images.audioPoster}
                className=""
                width={203}
                height={203}
              ></video>
            )}
            {templateType === "Video" && (
              <video
                id="video"
                controls
                src={assetIcon}
                width={203}
                height={203}
                className=""
              ></video>
            )}
            {templateType === "Image" && (
              <img
                className="max-h-[203px] w-[203px] rounded-2xl"
                src={assetIcon}
                alt="icon"
              />
            )}
          </div>
          <div>
            <h4 className="mb-3 text-base font-semibold">{assetName}</h4>
            {assetPrice && (
              <div className="flex justify-between mb-3">
                <span className="text-xs text-grey-100">Asset Price</span>
                <span className="text-xs text-grey-100">
                  {"$" + assetPrice}
                </span>
              </div>
            )}
            {/* Platform Fee */}

            {assetPrice && (
              <div className="flex justify-between mb-3">
                <span className="text-xs text-grey-100">
                  Platform Fee (%){" "}
                  <Tooltip
                    className="custom-tooltip-style text-xs bg-[#0A130D]   z-[100] text-center relative   max-w-[200px]"
                    offset={10}
                    placement={"top-center"}
                    content={
                      "This helps us improve product features, experience & manage operational costs "
                    }
                  >
                    <img
                      className="inline ml-2 cursor-pointer -m-[2px] align-sub w-5 h-5"
                      src={images.InfoIconSm}
                      alt="info-icon"
                    />
                  </Tooltip>
                </span>
                <span className="text-xs text-grey-100">
                  {platformFee + " %"}
                </span>
              </div>
            )}

            {/* <div className="flex justify-between mb-3">
              <span className="text-xs text-grey-100">Transaction Fee</span>
              <span className="text-xs text-grey-100">{transactionFee}</span>
            </div> */}
            {assetPrice && (
              <div className="flex justify-between mb-3">
                <span className="text-xs text-grey-100">
                  Receivable Amount :
                </span>
                <span className="text-xs font-semibold text-grey-100">
                  {"$" + totalFee}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewDropAssetDescription;
